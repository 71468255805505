<template>
    <section class="olcForms mlrDeletionForm">
        <UserLoginRegisterVue v-if="!isUserLogged"></UserLoginRegisterVue>
        <div class="mlrDeleteError" id="mlrDeleteError" style="display:none">
            Sorry, the credentials you've entered do not match our records.
        </div>
        <div class="halfForm clear" v-if="isUserLogged && loaded">
            <label for="deleteAccountEmail"></label>
            <input type="text" id="deleteAccountEmail" class="reqTxt disabled" :placeholder="user.user.email" readonly="readonly">
        </div>
        <div class="halfForm halfFormRight" v-if="isUserLogged && loaded">
            <label for="deleteAccountPass"></label>
            <input type="password" id="deleteAccountPass" class="reqTxt" placeholder="Password *" @input="comparePassword" v-model="passInput">
        </div>
        <div class="fullForm" v-if="isUserLogged && loaded">
            <!-- <button class="mlrAccountDeletion button btn_purple" :disabled="disable" @click="confirmDelete">Confirm Deletion</button> -->
        </div>
    </section>
</template>

<script>
    import $ from 'jquery';
    import UserLoginRegisterVue from '../../ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        components: {
           UserLoginRegisterVue
        },
        data: function () {
            return {
                token: '',
                user: '',
                pass: '',
                passInput: '',
                disable: true,
                error: false,
                isUserLogged: false,
                loaded: false,
                txtEmail: document.getElementById('txtEmail'),
                txtUserId: document.getElementById('txtUserId'),
                txtPassword: document.getElementById('txtPassword'),
                btnGet: document.getElementById('btnGet')
            }
        },
        mounted: function () {
            this.getToken();
            this.isUserLogged = this.isUserAuthed();
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.user = this.$cookies.get('apiToken');
                    this.getPasswordRules();
                    this.populateFields();
                });
            },
            getPasswordRules() {
                var apiToken = this.token,
                    url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetPasswordRules';

                return fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    this.rules = json.data;
                    this.loaded = true;
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
            },
            populateFields() {
                this.txtEmail.value = this.user.user.email;
                this.txtUserId.value = this.user.user.userId;
            },
            comparePassword(pass) {
                let password = this.passInput,
                    _passInput = document.getElementById('txtPassword');

                let target = document.getElementById('btnGet');

                if (password.length >= 1) {
                    this.disable = false;
                    target.disabled = false;
                    _passInput.value = password;
                } else {
                    this.disable = true;
                    target.disabled = true;
                }



                this.pass = password;
            },
            confirmDelete() {
                this.txtPassword.value = this.pass;
                this.btnGet.click();
            }
		}
    };
</script>