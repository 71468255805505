<template>
    <div class="">
        <SignInRegister v-if="!isLoading && !isUserLogged"></SignInRegister>

        <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" v-if="isLoading" />

        <!-- current application info -->
        <div v-if="!isLoading && isUserLogged && updateFound && !readOnly && !noUpdate" class="eAppUpdates">
            <div class="eAppHeader cf">
                <!-- ifIsInRole: eApp-Retailer || eAppPrincipal -->
                <a v-if="showAppUpdatesHome" class="button eAppDashBtn" href="/eApplications/Updates">Application Updates Home</a>
                <!-- ifIsInRole: eApp-Retailer && eApp-ChainApplicant -->
                <a v-if="showAppHome" class="button eAppDashBtn" href="/eApplications">Application Home</a>
                <!-- ifIsInRole: eApp-CentralLicensingManager || eApp-CentralSalesManager || eApp-EFTReviewer || eApp-RegionalManager || eAppDistrictRep || eApp-Security -->
                <a v-if="CentralSalesManager" class="button eAppDashBtn" href="/eApplications/Dashboard/Regular">Application Dashboard</a>
                <!-- ifIsInRole: eApp-ChainSalesRep || eApp-CentralLicensingManager || eApp-CentralSalesManager || eApp-EFTReviewer || eApp-Security -->
                <a v-if="ChainSalesRep" class="button eAppDashBtn" href="/eApplications/Dashboard/Chain">Chain Application Dashboard</a>

                <h1>Agent Number: <strong>{{ updateWrapper.agentNumber }}</strong></h1>
                <h3 style="clear:both;">Batch Number: <strong>{{ updateWrapper.batchNumber }}</strong></h3>

            </div>
            <div class="ErrorLabel alert-error" v-if="showErrorMessage">There was an error processing your request.</div>
            <div class="moduleContent cf eAppWelcome">
                <h2>Application Update</h2>
                <div v-html="pageContent.urDetailIntro"></div>
            </div>

            <CurrentAppInfo :userId="userId" :agentId="agentId" :isLoading="isLoading" :hasRenewal="hasRenewal"
                @licenseReady="processLicense" :eAppToken="token" :transfer="true"></CurrentAppInfo>

            <div class="cf updateCreateForm module">
                <h3>Business Information</h3>
                <div class="moduleContent cf">
                    <div class="formRow checkboxRow cf">
                        <input @change="toggleBusinessForm()" id="UpdateBusinessInfo" type="checkbox"
                               name="UpdateBusinessInfo">
                        <label for="UpdateBusinessInfo">I want to update Business Information</label>
                    </div>
                    <BusinessUpdate v-if="showBusinessUpdate" :pageContent="pageContent" ref="businessForm" :readOnly="readOnly"
                                    :businessInformationUpdate="businessInformationUpdate" :token="token" :userId="userId" :rejected="rejectedSummary">
                    </BusinessUpdate>
                </div>
            </div>
            <div class="cf updateCreateForm module">
                <h3>EFT / Bank Information</h3>
                <div class="moduleContent cf">
                    <div class="formRow checkboxRow cf">
                        <input @change="toggleEFTForm()" id="UpdateEFTInfo" type="checkbox" name="UpdateEFTInfo">
                        <label for="UpdateEFTInfo">I want to update EFT / Bank Information</label>
                    </div>
                    <EFTUpdate v-if="showEftUpdate" :eftUpdate="eftUpdate" :token="token" :userId="userId" :showSecondary="showSecondary"
                               :pageContent="pageContent" ref="eftForm" :readOnly="readOnly" @rejectedSecondary="rejectedSecondary"></EFTUpdate>
                </div>
            </div>
            <div class="cf updateCreateForm module">
                <h3>W9 Information</h3>
                <div class="moduleContent cf">
                    <div class="formRow checkboxRow cf">
                        <input @change="toggleW9Form()" id="UpdateW9Info" type="checkbox" name="UpdateW9Info">
                        <label for="UpdateW9Info">I want to update W9 Information</label>
                    </div>
                    <W9Update v-if="showTaxUpdate" :taxUpdate="taxUpdate" :token="token" :userId="userId"
                              :pageContent="pageContent" ref="taxForm"></W9Update>
                </div>
            </div>
            <div class="cf updateCreateForm module">
                <h3>Principals</h3>
                <div class="moduleContent cf">
                    <div class="formRow checkboxRow cf">
                        <input @change="togglePrincipalForm()" id="UpdatePrincipalInfo" type="checkbox" name="UpdatePrincipalInfo">
                        <label for="UpdatePrincipalInfo">I want to update Principal Information</label>
                    </div>
                    <UpdatePrincipals v-if="showPrincipalUpdate" :principalGroupUpdate="principalGroupUpdate"
                                      :token="token" :principalCrimeConvictions="principalCrimeConvictions"
                                      :principalCrimeConvictionsExplanation="principalCrimeConvictionsExplanation"
                                      :existingPrincipals="existingPrincipals"
                                      :updateGuid="updateWrapper.updateWrapperGuid"
                                      :apiToken="token"
                                      :hasRenewal="updateWrapper.hasRenewalUpdates"
                                      :userId="userId" :pageContent="pageContent" ref="principalForm"
                                      :percentage="ownerPercentage"></UpdatePrincipals>
                </div>
                <div class="percentAlert error" v-if="ownerPercentage < 100 && showPrincipalUpdate === true">
                    <p>Total business ownership entered does not total 100%.</p>
                    <div class="formRow checkboxRow cf">
                        <input id="chkLessThanTotal" type="checkbox" v-model="principalZero" name="chkLessThanTotal">
                        <label for="chkLessThanTotal">I acknowledge that this is correct</label>
                    </div>
                    <textarea rows="2" cols="20" id="txbxPercentageMissingMessage" class="percentReason" placeholder="Please provide a reason" v-model="principalZeroExplanation"></textarea>
                </div>
            </div>
            <div class="moduleContent cf">
                <!-- Convictions? -->
                <div v-if="showPrincipalConvictions" class="grid-x grid-margin-x princiaplAuthRadios crimeCheck cf">
                    <div class="cell small-3 medium-2 large-1">
                        <div>
                            <label class="radio">
                                <input type="radio" name="convictions" 
                                    @click="setConviction(0)" value="false" /> No
                            </label>
                        </div>
                        <div>
                            <label class="radio"><input type="radio" name="convictions" 
                                    @click="setConviction(1)" value="true" /> Yes
                            </label>
                        </div>
                    </div>

                    <label for="convictions" class="cell small-9 medium-10 large-11 radioConvictionsLabel">Have you ever been
                        convicted of any criminal offense, including ALL FELONIES AND ALL MISDEMEANORS, in ANY state
                        and/or ANY jurisdiction?</label>

                    <div class="cell small-12">
                        <div v-if="convictionError" class="error">Please provide an answer above.</div>
                    </div>

                    <div v-if="principalCrimeConvictions" class="cell medium-offset-2 medium-10 large-offset-1 large-11 txtExplainYes">
                        <label for="txbxConvictionsExplanation">Please list the type of offense and the nature of
                            ALL such convictions below (failure to report ANY and ALL convictions on this renewal,
                            and any future renewals, will be considered a fraudulent misrepresentation, which may
                            result in the suspension or revocation of your lottery license):</label>
                        <input v-model="principalCrimeConvictionsExplanation" type="text" id="txbxConvictionsExplanation"
                            class="inputText" />
                        <div v-if="convictionInfoError" class="error">Please provide conviction details above.</div>
                    </div>
                </div>
                <!-- Sign and Submit -->
                <div class="formRow eAppSignature appSigManual cf">
                    <span class="req">*</span>
                    <label for="txbxSignature">Signature:</label>
                    <input name="txbxSignature" type="text" id="txbxSignature" maxlength="200" class="appSignature"
                           v-model="updateWrapper.updateSignature" autocomplete="off"
                           :disabled="updateWrapper.signatureCertification"
                           :class="{ scripty: updateWrapper.signatureCertification }" @keyup="charCount()">
                    <div v-if="!signatureValid" class="error signatureError">Please provide a digital signature above.</div>
                </div>
                <div class="formRow checkboxRow cf appSignatureCert">
                    <span class="req">*</span>
                    <input id="chkSignature" type="checkbox"
                           name="chkSignature" :disabled="!isSigned" @click="canHazSubmit(1)">
                    <label for="chkSignature">
                        Enter your legal name above to digitally sign this application, the <a href="#eAppTandCmodal" class="eAppModalTerms" data-open="eAppTandCmodal">Terms and Conditions</a>, <a href="#indemnificationModal" class="eAppModalIndemn" data-open="indemnificationModal">
                            Indemnification Agreement.
                        </a>, authority to install equipment, Electronic Fund Authorization,
                        the W-9 form and the background check.
                    </label>
                    <div v-if="!signatureCertValid" class="error signatureError">
                        Please provide a digital signature above.
                    </div>
                </div>

                <div class="submitErrors error" v-if="submitErrors">
                    <ul class="errorList">
                        <!-- <div class="error" id="busFormError" style="display:none;">
                            There are errors with your business update above.
                        </div> -->
                        <!-- <li class="error" id="eftFormError" style="display:none;">
                            There are errors with your EFT update above.
                        </li>
                        <li class="error" id="w9FormError" style="display:none;">
                            There are errors with your W9 update above.
                        </li>
                        <li class="error" id="princeFormError" style="display:none;">
                            There are errors with your principal update above.
                        </li> -->
                        <li v-for="error in errorList">
                            {{ error }}
                        </li>
                    </ul>
                </div>

                <div class="Actions cf">
                    <button 
                        type="button" 
                        @click="submitUpdate()" 
                        name="btnSave" 
                        id="btnSave" 
                        class="btn-primary button btnSave submitWatch"
                        :disabled="disabledSubmitUpdates"
                        >Submit Updates</button>

                    <button type="button" name="btnCancel" id="btnCancel" class="btn btnCancel button" data-open="cancelChangesConfirm">Cancel Change Request</button>
                </div>


                <!-- modal for T&C -->
                <div class="reveal large" id="eAppTandCmodal" data-reveal>
                    <static propKey="eAppTandC"></static>
                    <button class="close-button" data-close aria-label="Close modal" type="button">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- modal for Indemnification -->
                <div class="reveal large" id="indemnificationModal" data-reveal>
                    <static propKey="eAppIndemnification"></static>
                    <button class="close-button" data-close aria-label="Close modal" type="button">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>
        <div v-else>
            <h3>No Update Found</h3>
        </div>

        <!-- Update Already Submitted Section -->
        <div v-if="!isLoading && isUserLogged && updateFound && readOnly" class="eAppUpdates">
            <div class="eAppHeader cf">
                <a v-if="showAppUpdatesHome" class="button eAppDashBtn" href="/eApplications/Updates">Application Updates Home</a>
                <h1>Agent Number: <strong>{{ updateWrapper.agentNumber }}</strong></h1>
                <h3 style="clear:both;">Batch Number: <strong>{{ updateWrapper.batchNumber }}</strong></h3>
            </div>
            <div class="moduleContent cf" v-if="updateSummary">
                <div>
                    <h3>Application Update</h3>
                    <div v-html="pageContent.updateIntro"></div>
                </div>
                <div class="statusReview addEditListWrap cf">
                    <h3>Summary</h3>
                    <div class="addEditList cf" v-if="updateWrapper.businessInformationUpdate !== null">
                        <!-- <span class="icon-Status"></span> -->
                        <span>Business Information Update</span>
                        <span v-if="businessRejected === false"><button class="link" @click="toggleSummary('bus', false, true)">View</button></span>
                        <span v-if="businessRejected === true"><button class="link" @click="toggleSummary('bus', true, true)">Edit</button></span>
                    </div>
                    <div class="addEditList cf" v-if="updateWrapper.eftUpdate !== null">
                        <!-- <span class="icon-Status"></span> -->
                        <span>EFT Update</span>
                        <span v-if="eftRejected == false"><button class="link" @click="toggleSummary('eft', false, true)">View</button></span>
                        <span v-if="eftRejected === true"><button class="link" @click="toggleSummary('eft', true, true)">Edit</button></span>
                    </div>
                    <div class="addEditList cf" v-if="updateWrapper.taxUpdate !== null">
                        <!-- <span class="icon-Status"></span> -->
                        <span>Federal Tax Information Update</span>
                        <span v-if="taxRejected === false"><button class="link" @click="toggleSummary('tax', false, true)">View</button></span>
                        <span v-if="taxRejected === true"><button class="link" @click="toggleSummary('tax', true, true)">Edit</button></span>
                    </div>
                    <div class="addEditList cf" v-if="updateWrapper.principalGroupUpdate !== null">
                        <!-- <span class="icon-Status"></span> -->
                        <span>Principal Updates</span>
                        <!-- <span><button class="link" @click="toggleSummary('prince')">View</button></span> -->
                        <span v-if="principalsRejected === false">
                            <!-- <button class="link" @click="toggleSummary('prince', false, true)">View</button> -->
                            <a :href="'/eapplications/updates/license-edit/background-check?updateId=' + updateGUID + '&en=' + agentId" class="link">View</a>
                        </span>
                        <span v-if="principalsRejected === true"><button class="link" @click="toggleSummary('prince', true, true)">Edit</button></span>
                    </div>
                    <!-- If Renwal without Update -->
                    <div class="addEditList cf" v-if="hasRenewal && !hasRenewalUpdates">
                        <span>Renewal Request: This application has been submitted for renewal without any changes to the previous information. </span>
                    </div>
                    <div class="addEditList cf" v-if="hasRenewal && hasRenewalUpdates">
                        <span>Renewal Request</span>
                        <!-- Need to figure out what to show here -->
                    </div>
                    
                </div>
            </div>
            <div class="moduleContent cf" v-if="updateDetail">
                <!-- BusinessUpdate -->
                <div class="moduleContent cf appReview cf" v-if="business">
                    <h3>Business Information Update</h3>
                    <h4>Business Name</h4>
                    <dl class="reviewFields cf">
                        <dt>Legal Business Name</dt>
                        <dd>{{ updateWrapper.businessInformationUpdate.businessLegalName }}</dd>
                        <dt>Doing Business As</dt>
                        <dd>{{ updateWrapper.businessInformationUpdate.businessDoingBusinessAs }}</dd>
                        <dt>Type of Business</dt>
                        <dd>{{ updateWrapper.businessInformationUpdate.businessType }}</dd>
                        <dt>Business Phone</dt>
                        <dd>{{ updateWrapper.businessInformationUpdate.businessPhoneNumber }}</dd>
                        <dt>Alternate Phone</dt>
                        <dd>{{ updateWrapper.businessInformationUpdate.businessPhoneNumberSecondary }}</dd>
                        <dt>Business Fax</dt>
                        <dd>{{ updateWrapper.businessInformationUpdate.businessFaxNumber }}</dd>
                    </dl>
                    <h4>Business Address</h4>
                    <dl class="reviewFields cf">
                        <dt>Legal Business Address</dt>
                        <dd>
                            {{ updateWrapper.businessInformationUpdate.legalStreetAddress }}<br>
                            {{ updateWrapper.businessInformationUpdate.legalCity }} {{ updateWrapper.businessInformationUpdate.legalState }}, {{ updateWrapper.businessInformationUpdate.legalZip }} {{ updateWrapper.businessInformationUpdate.legalZipPlus4 }}<br>
                            {{ updateWrapper.businessInformationUpdate.legalCounty }}
                            <a :href="'https://www.google.com/maps/place/' + updateWrapper.businessInformationUpdate.legalStreetAddress + ',+' + updateWrapper.businessInformationUpdate.legalCity + ',+' + updateWrapper.businessInformationUpdate.legalState + '+' + updateWrapper.businessInformationUpdate.legalZip" target="_blank">View on map</a>
                        </dd>
                        <dt v-if="updateWrapper.businessInformationUpdate.mailingSameAsBusness === false">Mailing Address</dt>
                        <dd v-if="updateWrapper.businessInformationUpdate.mailingSameAsBusness === false">
                            {{ updateWrapper.businessInformationUpdate.mailingStreetAddress }}
                            {{ updateWrapper.businessInformationUpdate.mailingCity }} {{ updateWrapper.businessInformationUpdate.mailingState }}, {{ updateWrapper.businessInformationUpdate.mailingZip }} {{ updateWrapper.businessInformationUpdate.mailingZipPlus4 }}
                        </dd>
                    </dl>
                    <h4>Contact Information</h4>
                    <dl class="reviewFields cf">
                        <dt>Primary Contact Name</dt>
                        <dd>{{ updateWrapper.businessInformationUpdate.primaryContactName }}</dd>
                        <dt>Primary Contact Title</dt>
                        <dd>{{ updateWrapper.businessInformationUpdate.primaryContactTitle }}</dd>
                        <dt>Primary Contact E-mail</dt>
                        <dd>{{ updateWrapper.businessInformationUpdate.primaryContactEmail }}</dd>
                        <dt>Primary Contact Phone</dt>
                        <dd>{{ updateWrapper.businessInformationUpdate.primaryContactPhone }}</dd>
                        <dt>Secondary Contact Name</dt>
                        <dd>{{ updateWrapper.businessInformationUpdate.secondaryContactName }}</dd>
                        <dt>Secondary Contact Title</dt>
                        <dd>{{ updateWrapper.businessInformationUpdate.secondaryContactTitle }}</dd>
                        <dt>Secondary Contact E-mail</dt>
                        <dd>{{ updateWrapper.businessInformationUpdate.secondaryContactEmail }}</dd>
                        <dt>Secondary Contact Phone</dt>
                        <dd>{{ updateWrapper.businessInformationUpdate.secondaryContactPhone }}</dd>
                    </dl>
                    <!-- <h4 v-if="rejectedSummary">Business Hours</h4>
                    <dl class="reviewFields cf"  v-if="rejectedSummary">
                        <dt>Monday</dt><dd>{{ this.xmlBusinessHours(updateWrapper.businessInformationUpdate.businessHours1) }}</dd>
                        <dt>Tuesday</dt><dd>{{ this.xmlBusinessHours(updateWrapper.businessInformationUpdate.businessHours2) }}</dd>
                        <dt>Wednesday</dt><dd>{{ this.xmlBusinessHours(updateWrapper.businessInformationUpdate.businessHours3) }}</dd>
                        <dt>Thursday</dt><dd>{{ this.xmlBusinessHours(updateWrapper.businessInformationUpdate.businessHours4) }}</dd>
                        <dt>Friday</dt><dd>{{ this.xmlBusinessHours(updateWrapper.businessInformationUpdate.businessHours5) }}</dd>
                        <dt>Saturday</dt><dd>{{ this.xmlBusinessHours(updateWrapper.businessInformationUpdate.businessHours6) }}</dd>
                        <dt>Sunday</dt><dd>{{ this.xmlBusinessHours(updateWrapper.businessInformationUpdate.businessHours7) }}</dd>
                    </dl> -->
                </div>
                <!-- EFTUpdate -->
                <div class="moduleContent cf appReview cf" v-if="eft">
                    <h4>Electronic Funds Transfer Update</h4>
                    <dl class="reviewFields cf">
                        <dt>DFI Name</dt>
                        <dd>{{ updateWrapper.eftUpdate.dfiName }}</dd>
                        <dt>EFT Account Name</dt>
                        <dd>{{ updateWrapper.eftUpdate.eftAccountName }}</dd>
                        <dt>Checking Account Number</dt>
                        <dd>{{ updateWrapper.eftUpdate.checkingAccountNumber }}</dd>
                        <dt>Checking Account Routing Number</dt>
                        <dd>{{ updateWrapper.eftUpdate.checkingAccountRoutingNumber }}</dd>
                        <dt v-if="updateWrapper.eftUpdate.sportsgamingActive">Is EFT shared?</dt>
                        <dd v-if="updateWrapper.eftUpdate.sportsgamingActive">{{ updateWrapper.eftUpdate.secondaryEFTShared }}</dd>
                        <dt v-if="updateWrapper.eftUpdate.sportsgamingActive">Secondary DFI Name</dt>
                        <dd v-if="updateWrapper.eftUpdate.sportsgamingActive">{{ updateWrapper.eftUpdate.secondaryDFIName }}</dd>
                        <dt v-if="updateWrapper.eftUpdate.sportsgamingActive">Secondary Account Name</dt>
                        <dd v-if="updateWrapper.eftUpdate.sportsgamingActive">{{ updateWrapper.eftUpdate.secondaryEFTAccountName }}</dd>
                        <dt v-if="updateWrapper.eftUpdate.sportsgamingActive">Secondary Checking Account Number</dt>
                        <dd v-if="updateWrapper.eftUpdate.sportsgamingActive">{{ updateWrapper.eftUpdate.secondaryCheckingAccountNumber }}</dd>
                        <dt v-if="updateWrapper.eftUpdate.sportsgamingActive">Secondary Routing Number</dt>
                        <dd v-if="updateWrapper.eftUpdate.sportsgamingActive">{{ updateWrapper.eftUpdate.secondaryCheckingAccountRoutingNumber }}</dd>
                        
                    </dl>
                </div>
                <!-- TaxUpdate -->
                <div class="moduleContent cf appReview cf" v-if="tax">
                    <h4>Federal Tax Information Update</h4>
                    <dl class="reviewFields cf">
                        <dt>Name</dt>
                        <dd>{{ updateWrapper.taxUpdate.w9Name }}</dd>
                        <dt>Legal Business Name</dt>
                        <dd>{{ updateWrapper.taxUpdate.w9LegalName }}</dd>
                        <dt>Legal Street Address</dt>
                        <dd>{{ updateWrapper.taxUpdate.w9StreetAddress }}<br>
                            {{ updateWrapper.taxUpdate.w9City }}, {{ updateWrapper.taxUpdate.w9State }} {{ updateWrapper.taxUpdate.w9Zip }} {{ updateWrapper.taxUpdate.w9ZipPlus4 }}
                        </dd>
                        <dt>Entity Type</dt>
                        <dd>{{ updateWrapper.taxUpdate.w9LegalEntityType }}</dd>
                        <dt>Tax Classification</dt>
                        <dd>{{ updateWrapper.taxUpdate.w9TaxClassification }}</dd>
                        <dt>Tax ID</dt>
                        <dd>XXXXXX{{ updateWrapper.taxUpdate.w9TaxID[5] }}{{ updateWrapper.taxUpdate.w9TaxID[6] }}{{ updateWrapper.taxUpdate.w9TaxID[7] }}{{ updateWrapper.taxUpdate.w9TaxID[8] }}</dd>
                        <dt>Tax ID Type</dt>
                        <dd>{{ updateWrapper.taxUpdate.w9TaxIDType }}</dd>
                        <dt>Exempt Payee Code</dt>
                        <dd>{{ updateWrapper.taxUpdate.w9ExemptPayeeCode }}</dd>
                        <dt>FATCA Exemption Code</dt>
                        <dd>{{ updateWrapper.taxUpdate.w9FATCAExemptionCode }}</dd>
                        <dt v-if="updateWrapper.taxUpdate.w9LegalContactFirstName">Legal Contact First Name</dt>
                        <dd v-if="updateWrapper.taxUpdate.w9LegalContactFirstName">{{ updateWrapper.taxUpdate.w9LegalContactFirstName }}</dd>
                        <dt v-if="updateWrapper.taxUpdate.w9LegalContactLastName">Legal Contact Last Name</dt>
                        <dd v-if="updateWrapper.taxUpdate.w9LegalContactLastName">{{ updateWrapper.taxUpdate.w9LegalContactLastName }}</dd>
                    </dl>
                </div>
                <!-- PrincipalUpdate -->
                <div class="moduleContent cf appReview cf" v-if="prince">
                    <h4>Principal Update</h4>
                    <p>To approve your own background check please click Authorize on the background check page.</p>

                    <span><a :href="'/eapplications/updates/license-edit/background-check?updateId=' + updateGUID + '&en=' + agentId" class="link">View</a></span>
                </div>

                <button class="button" type="button btn-secondary" @click="toggleSummary('', false, true)">Back</button>
                <span v-if="rejectedSummary">
                    <button class="button" type="button btn-secondary" data-open="rejectedModal">Submit Update</button>
                </span>
            </div>
            <div class="moduleContent" v-if="updateEdit">
                <!-- Update Rejected View -->
                <div class="cf updateCreateForm module" v-if="business">
                    <h3>Business Information</h3>
                    <div class="moduleContent cf">
                        <BusinessUpdate :pageContent="pageContent" ref="businessFormUpdate" :readOnly="false"
                                        :businessInformationUpdate="businessInformationUpdate" :token="token" :userId="userId">
                        </BusinessUpdate>
                    </div>
                </div>
                <div class="cf updateCreateForm module" v-if="eft">
                    <h3>EFT / Bank Information</h3>
                    <div class="moduleContent cf">
                        <EFTUpdate :eftUpdate="eftUpdate" :token="token" :userId="userId" :showSecondary="showSecondary"
                                    :pageContent="pageContent" ref="eftFormUpdate" :readOnly="false" @rejectedSecondary="rejectedSecondary"></EFTUpdate>
                    </div>
                </div>
                <div class="cf updateCreateForm module" v-if="tax">
                    <h3>W9 Information</h3>
                    <div class="moduleContent cf">
                        <W9Update :taxUpdate="taxUpdate" :token="token" :userId="userId"
                                    :pageContent="pageContent" ref="taxFormUpdate"></W9Update>
                    </div>
                </div>
                <div class="cf updateCreateForm module" v-if="prince">
                    <h3>Principals</h3>
                    <div class="moduleContent cf">
                        <UpdatePrincipals :principalGroupUpdate="principalGroupUpdate"
                                            :token="token" :principalCrimeConvictions="principalCrimeConvictions"
                                            :principalCrimeConvictionsExplanation="principalCrimeConvictionsExplanation"
                                            :existingPrincipals="existingPrincipals"
                                            :updateGuid="updateWrapper.updateWrapperGuid"
                                            :apiToken="token"
                                            :hasRenewal="updateWrapper.hasRenewalUpdates"
                                            :userId="userId" :pageContent="pageContent" ref="principalForm"
                                            :percentage="ownerPercentage"></UpdatePrincipals>
                    </div>
                    <div class="percentAlert error" v-if="ownerPercentage < 100 && showPrincipalUpdate === true">
                        <p>Total business ownership entered does not total 100%.</p>
                        <div class="formRow checkboxRow cf">
                            <input id="chkLessThanTotal" type="checkbox" v-model="principalZero" name="chkLessThanTotal">
                            <label for="chkLessThanTotal">I acknowledge that this is correct</label>
                        </div>
                        <textarea rows="2" cols="20" id="txbxPercentageMissingMessage" class="percentReason" placeholder="Please provide a reason" v-model="principalZeroExplanation"></textarea>
                    </div>
                </div>
                <div class="moduleContent cf">
                    <!-- Convictions? -->
                    <div v-if="showPrincipalConvictions" class="grid-x grid-margin-x princiaplAuthRadios crimeCheck cf">
                        <div class="cell small-3 medium-2 large-1">
                            <div>
                                <label class="radio">
                                    <input type="radio" name="convictions" 
                                        @click="setConviction(0)" value="false" /> No
                                </label>
                            </div>
                            <div>
                                <label class="radio"><input type="radio" name="convictions" 
                                        @click="setConviction(1)" value="true" /> Yes
                                </label>
                            </div>
                        </div>

                        <label for="convictions" class="cell small-9 medium-10 large-11 radioConvictionsLabel">Have you ever been
                            convicted of any criminal offense, including ALL FELONIES AND ALL MISDEMEANORS, in ANY state
                            and/or ANY jurisdiction?</label>

                        <div class="cell small-12">
                            <div v-if="convictionError" class="error">Please provide an answer above.</div>
                        </div>

                        <div v-if="principalCrimeConvictions" class="cell medium-offset-2 medium-10 large-offset-1 large-11 txtExplainYes">
                            <label for="txbxConvictionsExplanation">Please list the type of offense and the nature of
                                ALL such convictions below (failure to report ANY and ALL convictions on this renewal,
                                and any future renewals, will be considered a fraudulent misrepresentation, which may
                                result in the suspension or revocation of your lottery license):</label>
                            <input v-model="principalCrimeConvictionsExplanation" type="text" id="txbxConvictionsExplanation"
                                class="inputText" />
                            <div v-if="convictionInfoError" class="error">Please provide conviction details above.</div>
                        </div>
                    </div>
                </div>

                <button class="button" type="button btn-secondary" @click="toggleSummary('', true, true)">Back</button>
                <button class="button" type="button btn-secondary" v-if="business" @click="validateToggle('bus', true, false)">Continue</button>
                <button class="button" type="button btn-secondary" v-if="eft" @click="validateToggle('eft', true, false)">Continue</button>
                <button class="button" type="button btn-secondary" v-if="tax" @click="validateToggle('tax', true, false)">Continue</button>
                <button class="button" type="button btn-secondary" v-if="prince" @click="validateToggle('prince', true, false)">Continue</button>
            </div>
        </div>

        <div class="ErrorLabel alert-message" v-else-if="!isLoading && isUserLogged && !updateFound">
            We could not locate a license for you.
        </div>

        <!-- pop-up cancel confirm messages -->
        <div class="reveal small" id="cancelBusinessConfirm" data-reveal>
            <p class="bold">Are you sure you want to cancel your Business Information Updates?</p>
            <p>Any changes you have made will be lost.</p>
            <button class="button btnCancel" type="button" @click="keepBusiness()">Keep Changes</button>
            <button class="button btnConfirm" type="button" @click="cancelChanges('business')">Cancel Changes</button>
        </div>

        <div class="reveal small" id="cancelEFTConfirm" data-reveal>
            <p class="bold">Are you sure you want to cancel your EFT / Bank Information Updates?</p>
            <p>Any changes you have made will be lost.</p>
            <button class="button btnCancel" type="button" @click="keepEFT()">Keep Changes</button>
            <button class="button btnConfirm" type="button" @click="cancelChanges('eft')">Cancel Changes</button>
        </div>

        <div class="reveal small" id="cancelW9Confirm" data-reveal>
            <p class="bold">Are you sure you want to cancel your W9 Information Updates?</p>
            <p>Any changes you have made will be lost.</p>
            <button class="button btnCancel" type="button" @click="keepW9()">Keep Changes</button>
            <button class="button btnConfirm" type="button" @click="cancelChanges('w9')">Cancel Changes</button>
        </div>

        <div class="reveal small" id="cancelPrincipalsConfirm" data-reveal>
            <p class="bold">Are you sure you want to cancel your Business Information Updates?</p>
            <p>Any changes you have made will be lost.</p>
            <button class="button btnCancel" type="button" @click="keepPrincipal()">Keep Changes</button>
            <button class="button btnConfirm" type="button" @click="cancelChanges('principals')">Cancel Changes</button>
        </div>

        <div class="reveal small" id="cancelChangesConfirm" data-reveal>
            <p class="bold">Are you sure you want to cancel your changes?</p>
            <p>This will delete all changed information for this license.</p>
            <button class="button btnCancel" type="button" data-close>Keep Changes</button>
            <button class="button btnConfirm" type="button" @click="cancelChanges()">Cancel Changes</button>
        </div>

        <div data-reveal class="reveal" id="rejectedModal">
            <button data-close>Close</button>
            <!-- 
                Convictions radios, Signature and Checkbox
             -->
             <!-- Sign and Submit -->
            <div class="formRow eAppSignature appSigManual cf">
                <span class="req">*</span>
                <label for="txbxSignature">Signature:</label>
                <input name="txbxSignature" type="text" id="txbxSignature" maxlength="200" class="appSignature"
                        v-model="updateWrapper.updateSignature" autocomplete="off"
                        :disabled="updateWrapper.signatureCertification"
                        :class="{ scripty: updateWrapper.signatureCertification }" @keyup="charCount()">
                <div v-if="!signatureValid" class="error signatureError">Please provide a digital signature above.</div>
            </div>
            <div class="formRow checkboxRow cf appSignatureCert">
                <label for="chkSignature">
                    <span class="req">*</span>
                    <input id="chkSignature" type="checkbox" name="chkSignature" :disabled="!isSigned" @click="canHazSubmit(1)">
                    Enter your legal name above to digitally sign this application, the <a href="#eAppTandCmodal" class="eAppModalTerms" data-open="eAppTandCmodal">Terms and Conditions</a>, <a href="#indemnificationModal" class="eAppModalIndemn" data-open="indemnificationModal">
                        Indemnification Agreement.
                    </a>, authority to install equipment, Electronic Fund Authorization,
                    the W-9 form and the background check.
                </label>
                <div v-if="!signatureCertValid" class="error signatureError">
                    Please provide a digital signature above.
                </div>
            </div>
            <button class="button" type="button btn-secondary" v-if="business" @click="submitRejection('bus')" :disabled="!updateWrapper.signatureCertification">Submit Update</button>
            <button class="button" type="button btn-secondary" v-if="eft" @click="submitRejection('eft')" :disabled="!updateWrapper.signatureCertification">Submit Update</button>
            <button class="button" type="button btn-secondary" v-if="tax" @click="submitRejection('tax')" :disabled="!updateWrapper.signatureCertification">Submit Update</button>
            <button class="button" type="button btn-secondary" v-if="prince" @click="submitRejection('prince')" :disabled="!updateWrapper.signatureCertification">Submit Update</button>
        </div>

    </div>
</template>
 
<script>
import $ from 'jquery';
import moment from "moment";
import { HollowDotsSpinner } from 'epic-spinners';
import SignInRegister from '/ViewComponents/SignInRegister/SignInRegister.vue';
import CurrentAppInfo from './current-app-info.vue';
import BusinessUpdate from './update-business.vue';
import EFTUpdate from './update-eft.vue';
import W9Update from './update-w9.vue';
import Static from "../../Shared/Static.vue";
import UpdatePrincipals from './update-principals.vue';
import {ref, nextTick} from 'vue'
// import UpdateBackgroundCheck from './update-background-check.vue';

export default {
    components: {
        HollowDotsSpinner,
        SignInRegister,
        CurrentAppInfo,
        Static,
        BusinessUpdate,
        EFTUpdate,
        W9Update,
        UpdatePrincipals,
        // UpdateBackgroundCheck
    },
    data() {
        return {
            isUserLogged: false,
            updateGUID: '', // primary identifier
            contentKey: 'eapp-updates-renewals-details',
            pageContent: {}, // JSON for basic text content
            updateWrapper: {}, // JSON for entire Update
            applicationData: {}, // JSON for just the current application info
            isUserAllowed: false, // proper role and identified on the Update
            isLoading: false,
            updateFound: false,
            userId: 0,
            agentId: '0',
            token: '',
            readOnly: false,
            businessAddressUpdate: {},
            businessInformationUpdate: {},
            showBusinessUpdate: false,
            eftUpdate: {},
            showEftUpdate: false,
            principalGroupUpdate: {},
            existingPrincipals: [],
            showPrincipalUpdate: false,
            taxUpdate: {},
            showTaxUpdate: false,
            hasRenewal: false,
            hasRenewalUpdates: false,
            renewal: {},
            isSigned: false,
            hasSignatureError: false,
            isSignatureChecked: false,
            signatureValid: true,
            signatureCertValid: true,
            termsValid: false,
            showPrincipalConvictions: false,
            principalCrimeConvictions: null,
            principalCrimeConvictionsExplanation: '',
            convictionError: false,
            updateCSV: '',
            showSecondary: false,
            showAppDash: false,
            showAppHome: false,
            showAppUpdatesHome: false,
            showShainAppDash: false,
            CentralSalesManager: '',
            ChainSalesRep: '',
            showErrorMessage: false,
            submitErrors: false,
            errorList: [],
            updateSummary: false,
            updateDetail: false,
            updateEdit: false,
            // businessUpdate: false,
            // eftUpdate: false,
            // taxUpdate: false,
            // principalUpdate: false,
            business: false,
            eft: false,
            tax: false,
            prince: false,
            currentLicense: "",
            ownerPercentage: 100,
            principalZero: false,
            principalZeroExplanation: "",

            disabledSubmitUpdates: true,
            businessRejected: false,
            eftRejected: false,
            taxRejected: false,
            principalsRejected: false,
            rejectedSummary: false,
            noUpdate: false

            // SAVE FOR LATER, GETS SENT IN REQUEST
            // Business,
            // BusinessAddress,
            // Tax,
            // Eft,
            // Principal,
            // Wrapper,
            // Renewal
            // "updatePieceCSV": "Eft,Business"
        }
    },
    mounted: function () {
        $(document).foundation();

        this.isUserLogged = this.isUserAuthed();
        if (this.isUserLogged) {
            this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.getContent();
                });
        }

        // get the user ID
        this.userId = this.getLoggedInUserId();

        // get the agent number
        this.agentId = this.getURLQuery('an');
        this.isLoading = false;

        // showSecondary - check if user has sports gaming and should see a secondary banking form for EFT
        // this.showSecondary = true;


        // get roles and show buttons
        this.toggleTopButtons();

    },
    computed: {
        GET_UPDATE_WRAPPER_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/user/' + this.userId + '/update/' + this.updateGUID;
        },
        SUBMIT_UPDATE_WRAPPER_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/SubmitUpdateWrapper';
        },
        DELETE_UPDATE_WRAPPER_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/DeleteUpdateWrapper/' + this.userId + '/update/' + this.updateGUID;
        },
        SUBMIT_UPDATE_BUSINESS_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/SubmitUpdateBusiness';
        },
        SUBMIT_UPDATE_EFT_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/SubmitUpdateEft';
        },
        SUBMIT_UPDATE_TAX_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/SubmitUpdateTax';
        },
        SUBMIT_UPDATE_PRINCE_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/SubmitUpdatePrincipalGroup';
        }
    },
    methods: {
        rejectedSecondary(data) {
            this.showSecondary = data;
        },
        validateToggle(form, reject, summary) {
            let valid = this.validateSubmit(true);
            
            if (form === 'bus') {
                this.showBusinessUpdate = true;
                valid === true ? this.toggleSummary(form, reject, summary) : false;
            } else if (form === 'eft') {
                this.showEftUpdate = true;
                valid === true ? this.toggleSummary(form, reject, summary) : false;
            } else if (form === 'tax') {
                this.showTaxUpdate = true;
                valid === true ? this.toggleSummary(form, reject, summary) : false;
            } else if (form === 'prince') {
                this.showPrincipalUpdate = true;
                valid === true ? this.toggleSummary(form, reject, summary) : false;
            }
        },
        toggleSummary(d, reject, summary) {
            let section = d,
                rejected = reject,
                sum = summary;

            this.rejectedSummary = false;

            // sum === true ? this.updateSummary = !this.updateSummary : this.updateDetail = !this.updateDetail, this.rejectedSummary = true;
            if (sum === true) {
                this.updateSummary = !this.updateSummary;
            } else {
                this.updateDetail = !this.updateDetail;
                this.rejectedSummary = true;
            }
            rejected === false ? this.updateDetail = !this.updateDetail : this.updateEdit = !this.updateEdit;
            

            if (section === 'bus') {
                this.business = true;
                this.eft = false;
                this.tax = false;
                this.prince = false;
            } else if (section === 'eft') {
                this.business = false;
                this.eft = true;
                this.tax = false;
                this.prince = false;
            } else if (section === 'tax') {
                this.business = false;
                this.eft = false;
                this.tax = true;
                this.prince = false;
            } else if (section === 'prince') {
                this.business = false;
                this.eft = false;
                this.tax = false;
                this.prince = true;
            }
        },
        toggleTopButtons() {
            if (this.isUserInRole('eApp-Retailer') || this.isUserInRole('eAppPrincipal')) {
                this.showAppUpdatesHome = true;
            }
            if (this.isUserInRole('eApp-Retailer') || this.isUserInRole('eApp-ChainApplicant')) {
                this.showAppHome = true;
            }
            if (this.isUserInRole('eApp-CentralSalesManager') || this.isUserInRole('eApp-CentralLicensingManager') || this.isUserInRole('eApp-EFTReviewer') || this.isUserInRole('eApp-RegionalManager') || this.isUserInRole('eAppDistrictRep') || this.isUserInRole('eApp-Security')) {
                this.showAppDash = true;
            }
            if (this.isUserInRole('eApp-ChainSalesRep') || this.isUserInRole('eApp-RegionalManager') || this.isUserInRole('eApp-CentralLicensingManager') || this.isUserInRole('eApp-CentralSalesManager') || this.isUserInRole('eApp-EFTReviewer') || this.isUserInRole('eApp-Security')) {
                this.showChainAppDash = true;
            }
        },
        getContent() {
            this.isLoading = true;
            // get page content 
            this.pageContent = this.getContentByKey(this.contentKey, 'ur_content');
            this.getUpdateWrapper();
        },
        getUpdateWrapper() {

            // get the wrapper GUID from the querystring 'updateId'
            // to be used to populate the updateWrapper
            this.updateGUID = this.getURLQuery('updateId');
            // if (this.updateGUID == 'undefined') {
            //     this.updateFound = false;
            // } else {
                let uId = this.getLoggedInUserId();
                if (uId) {
                    this.userId = uId;
                    // this.userId = 133; // FOR TESTING RETAILER
                    // this.userId = 2505547; // FOR TESTING
                    this.fetchData();
                }
            // }

        },
        async fetchData() {
            const appsRequestOptions = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
            };
            this.updateWrapperResponse = await (await fetch(this.GET_UPDATE_WRAPPER_API_URL, appsRequestOptions)).json();
            // // console.log(this.updateWrapperResponse);
            if (this.updateWrapperResponse && this.updateWrapperResponse.updateWrapper) {
                this.updateFound = true;
                this.updateWrapper = this.updateWrapperResponse.updateWrapper;

                if (this.updateWrapper.eftupdate) {
                    if (this.updateWrapper.eftUpdate.sportsgamingActive === true) {
                        // this.showSecondary = true;
                    }
                }
            } else {
                // using "hasRenewalUpdates" because we don't need to even see this form if there is not an update
                this.updateFound = false;
            }
            this.parseUpdate();
        },
        parseUpdate() {
            // all paths lead here before showing anything
            let allowSubmit = false;
            
            this.agentId = this.updateWrapper.agentNumber;

            // get general Update status:
            // updateSubmitted?
            // this.readOnly = this.updateWrapper.updateSubmitted;
            this.readOnly = this.updateWrapper.updateSubmitted;
            
            this.updateSummary = true;
            // show read-only version of everything if true

            // is renewal?
            this.hasRenewal = this.updateWrapper.hasRenewal;
            this.hasRenewalUpdates = this.updateWrapper.hasRenewalUpdates
            this.showPrincipalConvictions = this.hasRenewal && this.hasRenewalUpdates;

            // Get the data for each form:

            if (this.updateWrapperResponse.updateWrapper === null) {
                this.noUpdate = true;
            } else {
                // business info - for Update + Renewals
                if (this.updateWrapper.businessInformationUpdate !== null) {
                    this.businessInformationUpdate = this.updateWrapper.businessInformationUpdate;
                    if (this.updateWrapper.businessInformationUpdate.elementStatus === 2) {
                        this.businessRejected = true;
                    } 
                    allowSubmit = true;
                }
                else {
                    this.businessInformationUpdate = {};
                }

                //eft
                if (this.updateWrapper.eftUpdate !== null) {
                    this.eftUpdate = this.updateWrapper.eftUpdate;
                    if (this.updateWrapper.eftUpdate.elementStatus === 5) {
                        this.eftRejected = true;
                    } 
                    allowSubmit = true;
                }
                else {
                    this.eftUpdate = {};
                }

                //w9
                if (this.updateWrapper.taxUpdate !== null) {
                    this.taxUpdate = this.updateWrapper.taxUpdate;
                    if (this.updateWrapper.taxUpdate.elementStatus === 2) {
                        this.taxRejected = true;
                    } 
                    allowSubmit = true;
                }
                else {
                    this.taxUpdate = {};
                }

                //principals
                if (this.updateWrapper.principalGroupUpdate !== null) {
                    this.principalGroupUpdate = this.updateWrapper.principalGroupUpdate;
                    if (this.updateWrapper.principalGroupUpdate.elementStatus === 3) {
                        this.principalsRejected = true;
                    } 
                } 
                else {
                    this.principalGroupUpdate = {};
                    this.principalGroupUpdate.principalUpdates = [];
                }

                // see if an update has been started yet
                if (this.updateWrapper.principalGroupUpdate && !this.readOnly) {
                    if (this.updateWrapper.principalGroupUpdate.principalUpdates.length !== 0){
                        
                        allowSubmit = true;
                        this.$nextTick(() => {                 
                            this.togglePrincipalForm();   
                            // this.showPrincipalUpdate = true;
                            this.$nextTick(() => {
                                if(this.showPrincipalUpdate){
                                    document.getElementById('UpdatePrincipalInfo').checked = true;
                                    // this.showPrincipalUpdate = true;
                                }
                            })
                        })
                    }
                }

                if (this.updateWrapperResponse.existingPrincipals !== null) {
                    this.existingPrincipals = this.updateWrapperResponse.existingPrincipals;
                }
                else {
                    this.existingPrincipals = [];
                }

                // updateWrapperGUID
                if (this.updateWrapper.updateWrapperGuid !== null) {
                    this.updateGUID = this.updateWrapper.updateWrapperGuid;
                    this.updateWrapper.batchNumber = this.updateWrapper.updateWrapperGuid;
                    // // console.log(this.updateWrapper.batchNumber)
                } else {
                    this.updateGUID = this.getURLQuery('updateId');
                    if (this.updateGUID === 'undefined') {
                        this.updateFound = false;
                    }
                }


                // signature - terms
                // clear out the Signature and Checkbox on first load, every time.
                this.updateWrapper.updateSignature = '';
                this.updateWrapper.signatureCertification = false;
                // if(this.updateWrapper.updateSignature){
                //     if (this.updateWrapper.updateSignature.length < 5) {
                //         this.updateWrapper.signatureCertification = null;
                //     }
                // }

                // allow submit?
                // this.disabledSubmitUpdates = !allowSubmit;
                // // console.log(this.updateWrapper.signatureCertification)
            }
            
            this.isLoading = false;
        },
        processLicense(appJSON) {
            // runs after CurrentAppInfo has fetched the applicationData
            this.applicationData = appJSON;
            // console.log(this.applicationData)
        },
        canHazSubmit(int) {
            // // console.log(this.updateWrapper)
            if (this.updateWrapper.signatureCertification === false && int === 1) {
                this.updateWrapper.signatureCertification = true;
            } else if (this.updateWrapper.signatureCertification === true && int === 1) {
                this.updateWrapper.signatureCertification = false;
            }

            if (this.showBusinessUpdate && this.updateWrapper.signatureCertification === true || this.showEftUpdate && this.updateWrapper.signatureCertification === true || this.showTaxUpdate && this.updateWrapper.signatureCertification === true) {

                this.disabledSubmitUpdates = false;

            } else if (this.showPrincipalUpdate && this.updateWrapper.principalGroupUpdate !== null) {
                // console.log("Principal Update")
                // // console.log('good 1')
                if (this.updateWrapper.principalGroupUpdate.principalUpdates.length > 0 && this.updateWrapper.signatureCertification === true) {
                    this.disabledSubmitUpdates = false;
                    // // console.log('good 2')
                } else {
                    this.disabledSubmitUpdates = true;
                    // // console.log('bad 1')
                }

            } else {
                this.disabledSubmitUpdates = true;
            }
        },
        submitUpdate(d) {
            let valid = this.validateSubmit();

            let updateCSV = "";

            if (valid) {
                // this.updateWrapper.batchNumber = this.updateGUID;
                if (this.showBusinessUpdate) {
                    this.businessInformationUpdate.businessHours1 = this.applicationData.businessHours1;
                    this.businessInformationUpdate.businessHours1 = this.applicationData.businessHours2;
                    this.businessInformationUpdate.businessHours1 = this.applicationData.businessHours3;
                    this.businessInformationUpdate.businessHours1 = this.applicationData.businessHours4;
                    this.businessInformationUpdate.businessHours1 = this.applicationData.businessHours5;
                    this.businessInformationUpdate.businessHours1 = this.applicationData.businessHours6;
                    this.businessInformationUpdate.businessHours1 = this.applicationData.businessHours7;
                    this.updateWrapper.businessInformationUpdate = this.businessInformationUpdate;
                    updateCSV += "0";
                } else {
                    this.updateWrapper.businessInformationUpdate = null;
                }
                if (this.showTaxUpdate) {
                    this.updateWrapper.taxUpdate = this.taxUpdate;
                    if (updateCSV.length > 0) {
                        updateCSV += ",2";
                    } else {
                        updateCSV += "2";
                    }
                } else {
                    this.updateWrapper.taxUpdate = null;
                }
                if (this.showEftUpdate) {
                    this.updateWrapper.eftUpdate = this.eftUpdate;
                    if (updateCSV.length > 0) {
                        updateCSV += ",3";
                    } else {
                        updateCSV += "3";
                    }
                } else {
                    this.updateWrapper.eftUpdate = null;
                }
                if (this.showPrincipalUpdate) {
                    if (this.principalZero === true) {
                        this.updateWrapper.principalGroupUpdate.principalOwnershipCheck = this.principalZero;
                        this.updateWrapper.principalGroupUpdate.principalPercentOwnershipMessage = this.principalZeroExplanation;
                    }
                    
                    this.updateWrapper.principalGroupUpdate = this.principalGroupUpdate;
                    if (updateCSV.length > 0) {
                        updateCSV += ",4";
                    } else {
                        updateCSV += "4"
                    }
                } else {
                    this.updateWrapper.principalGroupUpdate = null;
                }
                // // console.log(this.principalGroupUpdate)
                this.principalCrimeConvictions = this.principalCrimeConvictions;
                this.principalCrimeConvictionsExplanation = this.principalCrimeConvictionsExplanation;

                this.updateCSV = updateCSV;

                this.postSaveData(d);
                /* redirect? show message? what to do?? */
                if (!this.submitErrors && this.errorList.length ) {
                    // alert('Submitted: check console');
                    location.href = "/eApplications/Updates";
                }
            } else {
                // console.log("Not Valid");
            }
        },
        submitRejection(d) {
            let form = d;
            
            if (form === 'bus') {
                this.showBusinessUpdate = true;
                this.postSaveData('bus');
            } else if (form === 'eft') {
                this.showEftUpdate = true;
                this.postSaveData('eft');
            } else if (form === 'tax') {
                this.showTaxUpdate = true;
                this.postSaveData('tax');
            } else if (form === 'prince') {
                this.showPrincipalUpdate;
                this.postSaveData('prince');
            }
        },
        async postSaveData(d) {
            // console.log('Post Save Data')
            this.isLoading = true;
            let body = {};
            body.userId = this.userId;
            body.updateWrapper = this.updateWrapper;
            body.principalCrimeConvictions = this.principalCrimeConvictions;
            body.principalCrimeConvictionsExplanation = this.principalCrimeConvictionsExplanation;
            body.updatePieceCSV = this.updateCSV;
            // console.log(body);
            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    body
                )
            };

            // await (await fetch(this.SUBMIT_UPDATE_WRAPPER_API_URL, appsRequestOptions)).json();

            if (d === null || d === undefined) {
                // console.log('Update');
                fetch(this.SUBMIT_UPDATE_WRAPPER_API_URL, appsRequestOptions)
                .then((response) => response.json())
                .then((json) => {
                    // console.log(json);
                    if (json.errorList.length) {
                        this.submitErrors = true;
                        this.errorList = json.errorList;
                    } else {
                        this.submitErrors = false;
                        this.errorList = [];
                        // alert('Saved: check console');
                        window.location = window.location.origin + "/eapplications/updates";
                    }
                })
            } else if (d === 'bus') {
                // console.log("BUS")
                fetch(this.SUBMIT_UPDATE_BUSINESS_API_URL, appsRequestOptions)
                .then((response) => response.json())
                .then((json) => {
                    // console.log(json);
                    if (json.errorList.length) {
                        this.submitErrors = true;
                        this.errorList = json.errorList;
                    } else {
                        this.submitErrors = false;
                        this.errorList = [];
                        // alert('Saved: check console');
                        window.location = window.location.origin + "/eapplications/updates";
                    }
                })
            } else if (d === 'eft') {
                // console.log("EFT")
                fetch(this.SUBMIT_UPDATE_EFT_API_URL, appsRequestOptions)
                .then((response) => response.json())
                .then((json) => {
                    // console.log(json);
                    if (json.errorList.length) {
                        this.submitErrors = true;
                        this.errorList = json.errorList;
                    } else {
                        this.submitErrors = false;
                        this.errorList = [];
                        // alert('Saved: check console');
                        window.location = window.location.origin + "/eapplications/updates";
                    }
                })
            } else if (d === 'tax') {
                // console.log("TAX")
                fetch(this.SUBMIT_UPDATE_TAX_API_URL, appsRequestOptions)
                .then((response) => response.json())
                .then((json) => {
                    // console.log(json);
                    if (json.errorList.length) {
                        this.submitErrors = true;
                        this.errorList = json.errorList;
                    } else {
                        this.submitErrors = false;
                        this.errorList = [];
                        // alert('Saved: check console');
                        window.location = window.location.origin + "/eapplications/updates";
                    }
                })
            } else if (d === 'prince') {
                fetch(this.SUBMIT_UPDATE_PRINCE_API_URL, appsRequestOptions)
                .then((response) => response.json())
                .then((json) => {
                    if (json.errorList.length) {
                        this.submitErrors = true;
                        this.errorList = json.errorList;
                    } else {
                        this.submitErrors = false;
                        this.errorList = [];
                        // alert('Saved: check console');
                        window.location = window.location.origin + "/eapplications/updates";
                    }
                })
            }

            this.isLoading = false;

            return Promise.resolve();

        },
        async deleteUpdateWrapper() {
            this.isLoading = true;

            const appsRequestOptions = {
                method: "DELETE",
                headers: {
                    'Authorization': 'Bearer ' + this.token,
                    "Content-Type": "application/json"
                },

            };

            fetch(this.DELETE_UPDATE_WRAPPER_API_URL, appsRequestOptions)
                .then(res => res.json()).then(res => {
                    if (res.statusCode == 200) {
                        // alert('Deleted: check console');
                        location.href = "/eApplications/Updates";
                    }
                    else {
                        this.showErrorMessage = true;
                    }
                }).catch(error => {
                    // console.log(error);
                });
            this.isLoading = false;

            return Promise.resolve();
        },
        toggleBusinessForm() {
            if (this.showBusinessUpdate) {
                this.showBusinessUpdate = false;
                this.businessInformationUpdate = {};
            } else {
                this.mapBusinessUpdate();
                this.showBusinessUpdate = true;
            }
            this.canHazSubmit();
        },
        mapBusinessUpdate() {
            // LICENSE DATA Business LOAD-UP 
            // businessInformationUpdate will be added to UpdateWrapper
            this.businessInformationUpdate.businessLegalName =          this.applicationData.businessLegalName;
            this.businessInformationUpdate.businessDoingBusinessAs =    this.applicationData.businessDoingBusinessAs;
            this.businessInformationUpdate.businessType =               ''; // this.applicationData.businessType;
            this.businessInformationUpdate.legalStreetAddress =         this.applicationData.legalStreetAddress;
            this.businessInformationUpdate.legalCity =                  this.applicationData.businessCity;
            this.businessInformationUpdate.legalState =                 this.applicationData.businessState;
            this.businessInformationUpdate.legalZip =                   this.applicationData.businessZip;
            this.businessInformationUpdate.legalZipPlus4 =              this.applicationData.businessZipPlus4;
            // create mixin and map the Name to the numeric identifeir (see the <select> on update-business.vue)
            this.businessInformationUpdate.legalCounty =                this.applicationData.businessCounty;
            // format the phone numbers
            this.businessInformationUpdate.businessPhoneNumber =        this.pastedPhoneFormat(this.applicationData.businessPhoneNumber);
            this.businessInformationUpdate.businessPhoneNumberSecondary = this.pastedPhoneFormat(this.applicationData.businessPhoneNumberSecondary);
            this.businessInformationUpdate.businessFaxNumber =          this.pastedPhoneFormat(this.applicationData.businessFaxNumber);
            this.businessInformationUpdate.mailingSameAsBusiness =      this.applicationData.mailingSameAsBusiness;
            this.businessInformationUpdate.mailingName =                this.applicationData.mailingName;
            this.businessInformationUpdate.mailingStreetAddress =       this.applicationData.mailingStreetAddress;
            this.businessInformationUpdate.mailingCity =                this.applicationData.mailingCity;
            this.businessInformationUpdate.mailingState =               this.applicationData.mailingState;
            this.businessInformationUpdate.mailingZip =                 this.applicationData.mailingZip;
            this.businessInformationUpdate.mailingZipPlus4 =            this.applicationData.mailingZipPlus4;
            this.businessInformationUpdate.primaryContactName =         this.applicationData.primaryContactName;
            this.businessInformationUpdate.primaryContactTitle =        this.applicationData.primaryContactTitle;
            this.businessInformationUpdate.primaryContactEmail =        this.applicationData.primaryContactEmail;
            // format the phone number
            this.businessInformationUpdate.primaryContactPhone =        this.pastedPhoneFormat(this.applicationData.primaryContactPhone);
            this.businessInformationUpdate.secondaryContactName =       this.pastedPhoneFormat(this.applicationData.secondaryContactName);
            this.businessInformationUpdate.secondaryContactTitle =      this.applicationData.secondaryContactTitle;
            this.businessInformationUpdate.secondaryContactEmail =      this.applicationData.secondaryContactEmail;
            // format the phone number
            this.businessInformationUpdate.secondaryContactPhone =      this.pastedPhoneFormat(this.applicationData.secondaryContactPhone);
            this.businessInformationUpdate.isPartOfARenewal =           this.hasRenewal;
            this.businessInformationUpdate.agentNumber =                this.agentId;
            // this.businessInformationUpdate.businessInformationUpdateGuid = this.updateWrapper.updateWrapperGuid;

        },
        keepBusiness() {
            this.showBusinessUpdate = true;
            // $('#cancelBusinessConfirm').foundation('close');
        },
        toggleEFTForm() {
            if (this.showEftUpdate) {
                this.showEftUpdate = false;
                this.eftUpdate = {};
            } else {
                this.mapEftUpdate();
                this.showEftUpdate = true;
            }
            this.canHazSubmit();
        },
        mapEftUpdate() {

            // Load up EFT - but set PPI data to empty
            // eftUpdate will be added to UpdateWrapper
            this.eftUpdate.dfiName =                            this.applicationData.dfiName;
            this.eftUpdate.eftAccountName =                     this.applicationData.eftAccountName;
            this.eftUpdate.checkingAccountNumber =              ''; // this.applicationData.checkingAccountNumber;
            // this.eftUpdate.checkingAccountEncryptedNumber =  this.applicationData.checkingAccountEncryptedNumber;
            this.eftUpdate.checkingAccountRoutingNumber =       ''; // this.applicationData.checkingAccountRoutingNumber;
            // this.eftUpdate.checkingAccountRoutingEncryptedNumber = this.applicationData.checkingAccountRoutingEncryptedNumber;
            // hasSportsGaming
            this.eftUpdate.sportsgamingActive =                 this.applicationData.sportsgamingActive;
            this.eftUpdate.secondaryEFTShared =                 this.applicationData.secondaryEFTShared;
            this.eftUpdate.secondaryCheckingAccountNumber =     ''; // this.applicationData.secondaryCheckingAccountNumber;
            this.eftUpdate.secondaryDFIName =                   this.applicationData.secondaryDFIName;
            this.eftUpdate.secondaryEFTAccountName =            this.applicationData.secondaryEFTAccountName;
            // this.eftUpdate.secondaryCheckingAccountEncryptedNumber = this.applicationData.;
            this.eftUpdate.secondaryCheckingAccountRoutingNumber = ''; // this.applicationData.secondaryCheckingAccountRoutingNumber;
            // Create if statment to choose sportsgamingActive if this is a rejected application (status = 8)
            // console.log(this.applicationData)
            // this.showSecondary =                                this.applicationData.hasSportsGaming;
            this.eftUpdate.sportsgamingActive =                 this.applicationData.hasSportsGaming;
            // this.eftUpdate.secondaryCheckingAccountRoutingEncryptedNumber = this.applicationData.;
        },
        keepEFT() {
            this.showEftUpdate = true;
            // $('#cancelEFTConfirm').foundation('close');
        },
        toggleW9Form() {
            if (this.showTaxUpdate) {
                this.showTaxUpdate = false;
                this.taxUpdate = {};
            } else {
                this.mapTaxUpdate();
                this.showTaxUpdate = true;
            }
            this.canHazSubmit();
        },
        mapTaxUpdate() {

            // taxUpdate will be added to UpdateWrapper
            this.taxUpdate.w9Name =                     this.applicationData.w9Name;
            this.taxUpdate.w9LegalName =                this.applicationData.w9LegalName;
            this.taxUpdate.w9LegalEntityType =          this.filterEntityType(this.applicationData.w9LegalEntityType);
            this.taxUpdate.w9StreetAddress =            this.applicationData.w9StreetAddress;
            this.taxUpdate.w9City =                     this.applicationData.w9City;
            this.taxUpdate.w9Zip =                      this.applicationData.w9Zip;
            this.taxUpdate.w9ZipPlus4 =                 this.applicationData.w9ZipPlus4;
            this.taxUpdate.w9State =                    this.applicationData.w9State;
            this.taxUpdate.w9TaxClassification =        this.applicationData.w9TaxClassification;
            this.taxUpdate.w9TaxClassificationOther =   this.applicationData.w9TaxClassificationOther;
            this.taxUpdate.w9TaxID =                    ""; // this.applicationData.w9TaxID;
            this.taxUpdate.w9TaxIDType =                this.applicationData.w9TaxIDType;
            this.taxUpdate.w9ExemptPayeeCode =          this.applicationData.w9ExemptPayeeCode;
            this.taxUpdate.w9FATCAExemptionCode =       this.applicationData.w9FATCAExemptionCode;
            this.taxUpdate.w9DifferentLegalContact =    this.applicationData.w9DifferentLegalContact;
            this.taxUpdate.w9AssociatedPrincipalID =    this.applicationData.w9AssociatedPrincipalID;
            this.taxUpdate.w9LegalContactFirstName =    this.applicationData.w9LegalContactFirstName;
            this.taxUpdate.w9LegalContactLastName =     this.applicationData.w9LegalContactLastName;

        },
        filterEntityType(entityType) {
            switch (entityType) {
                case "":
                    return 1;
                case "Corporation":
                    return 2;
                case "":
                    return 3;
                case "":
                    return 4;
                case "":
                    return 5;
                default: 
                    return entityType;
            }
        },
        keepW9() {
            this.showTaxUpdate = true;
            // $('#cancelW9Confirm').foundation('close');
        },
        togglePrincipalForm() {
            if (this.showPrincipalUpdate) {
                this.showPrincipalUpdate = false;
            } else {
                this.showPrincipalUpdate = true;
            }
            this.canHazSubmit();
        },
        mapPrincipalUpdate() {

            // Map previous principals to UpdateWrapper element
            // existingPrincipals will be added to UpdateWrapper
            this.applicationData.principals.forEach(element => {
                let newPrincipal = {}

                newPrincipal.principal_salutation = element.principalSalutation; 
                newPrincipal.principal_name =       element.principalName; 
                newPrincipal.principal_title =      element.principalTitle; 
                newPrincipal.principal_email =      element.principalEmail; 
                newPrincipal.principal_share =      element.principalPercentOwnership; 
                newPrincipal.principal_IAD_Uid =    element.principal_IAD_Uid; 
                newPrincipal.principal_IAD_RowID =  element.principal_IAD_RowID; 

                this.existingPrincipals.push(newPrincipal);
            });

        },
        keepPrincipal() {
            this.showPrincipalUpdate = true;
            // $('#cancelPrincipalsConfirm').foundation('close');
        },
        cancelChanges(thisForm) {
            switch (thisForm) {
                case 'business':
                    this.showBusinessUpdate = false;
                    $('#cancelBusinessConfirm').foundation('close');
                    break;
                case 'eft':
                    this.showEftUpdate = false;
                    $('#cancelEFTConfirm').foundation('close');
                    break;
                case 'w9':
                    this.showTaxUpdate = false;
                    $('#cancelW9Confirm').foundation('close');
                    break;
                case 'principals':
                    this.showPrincipalUpdate = false;
                    $('#cancelPrincipalsConfirm').foundation('close');
                    break;

                default:
                    // cancel all changes, delete the UpdateWrapper
                    this.deleteUpdateWrapper();
                    break;
            }

        },
        backgroundCheckApproval() {
            this.bgCheckApproved = !this.bgCheckApproved;
            this.bgCheckError = !this.bgCheckApproved;
        },
        termsClick() {
            this.signatureTermsChecked = !this.signatureTermsChecked;
            this.renewalProps.SignatureAcknowledgement = this.signatureTermsChecked;
        },
        tryGetFromQueryString() {
            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);

            if (urlParams.has('updateWrapperGuid')) {
                this.updateGUID = urlParams.get('updateWrapperGuid');

                if (this.updateGUID === 'undefined') {
                    // just gonna redirect you back to safe waters
                    window.location = window.location.origin + "/eapplications/updates";
                }
            }
        },
        charCount() {
            // // console.log(this.updateWrapper.updateSignature.length);
            if (this.updateWrapper.updateSignature.length >= 4) {
                this.isSigned = true;
                this.signatureValid = true;
                
            } else if (this.updateWrapper.updateSignature.length < 4) {
                this.isSigned = false;
                this.signatureValid = false;
            } else {
                this.isSigned = false;
            }
        },
        customFormatter(dateVal) {
            return moment(dateVal).format("MM/DD/YYYY");
        },
        getInformation(d) {
            this.currentLicense = d;
            // console.log(this.currentLicense)
        },
        validateSubmit(con) {
            let businessValid = false,
                eftValid = false,
                w9Valid = false,
                principalValid = false,
                isValid = false;

            this.submitErrors = false;
            this.errorList = [];

            if (
                this.showBusinessUpdate === false &&
                this.showEftUpdate === false &&
                this.showTaxUpdate === false &&
                this.showPrincipalUpdate === false
            ) {
                isValid = false;
                return isValid;
            }

            // Business
            if (this.showBusinessUpdate === true) {
                businessValid = this.validateBusiness();
            } else {
                $('#businessFormError').hide();
                businessValid = true;
            }
            
            // EFT
            if (this.showEftUpdate) {
                eftValid = this.validateEFT();
            } else {
                $('#eftFormError').hide();
                eftValid = true;
            }

            // W9
            if (this.showTaxUpdate) {
                w9Valid = this.validateW9();
            } else {
                $('#w9FormError').hide();
                w9Valid = true;
            }
            
            // Principals
            if (this.showPrincipalUpdate) {
                // Ownership Percentage
                let owners = $('.ownership'),
                    percent = 0,
                    zero = false;
                for (let i = 0; i < owners.length; i++) {
                    let rawPercent = owners[i].value,
                        number = parseInt(rawPercent);

                    number === 0 ? this.zeroPercent = true : this.zeroPercent = false;

                    percent = percent + number;
                }

                this.ownerPercentage = percent;

                if (percent < 100 && this.principalZero === false && this.principalZeroExplanation === "" || percent > 100 || percent === 0) {
                    principalValid = false;
                    isValid = false;
                }
                else {
                    principalValid = true;
                }
            } else {
                principalValid = true;
            }

            // Convictions
            if (this.showPrincipalConvictions) {
                // console.log(this.showPrincipalConvictions)
                // Did they make a choice?
                if (this.principalCrimeConvictions == null) {
                    // Conviction Not Answered.
                    this.convictionError = true;
                } else {
                    this.convictionError = false;
                    // isValid = false;
                }
                // If Yes, explain?
                if (this.principalCrimeConvictions && this.principalCrimeConvictionsExplanation == '') {
                    this.convictionInfoError = true;
                    isValid = false;
                } else {
                    this.convictionInfoError = false;
                }
            }

            if (businessValid === false || eftValid === false || w9Valid === false || principalValid === false) {
                this.submitErrors = true;
                isValid = false;
            }

            if (businessValid === false) {
                this.errorList.push('There are errors with the business section.')
            }

            if (eftValid === false) {
                this.errorList.push('There are errors with the eft section.')
            }

            if (w9Valid === false) {
                this.errorList.push('There are errors with the w9 section.')
            }

            if (principalValid === false) {
                this.errorList.push('There are errors with the principal section.')
            }

            // Signature
            if (this.isSigned === true || con === true) {
                this.signatureCertValid = true;
            } else {
                this.signatureCertValid = false;
                isValid = false;
            }

            if (con === true) {
                if (
                    businessValid === true &&
                    eftValid === true &&
                    w9Valid === true &&
                    principalValid === true &&
                    this.errorList.length === 0
                ) {
                    isValid = true;
                }
            } else {
                if (
                    businessValid === true &&
                    eftValid === true &&
                    w9Valid === true &&
                    principalValid === true &&
                    this.isSigned === true &&
                    this.signatureCertValid === true &&
                    this.errorList.length === 0
                ) {
                    isValid = true;
                }
            }
            

            console.log(businessValid)
            console.log(eftValid)
            console.log(w9Valid)
            console.log(principalValid)
            console.log(isValid)

            return isValid;
        },
        validateBusiness() {
            let busLegalName = $('#BusinessLegalName').val(),
                busAs = $('#DBA').val(),
                busType = $('#BusinessType').val(),
                busAdd = $('#LegalStreetAddress').val(),
                busCity = $('#LegalCity').val(),
                busState = $('#LegalState').val(), // invalid === null
                busZip = $('#LegalZip').val(),
                busZip4 = $('#LegalZipPlus4').val(),
                busCounty = $('#LegalCounty').val(),
                busPhone = $('#BusinessPhoneNumber').val(),
                mailCheck = document.getElementById('chkMSAB'),
                mailName,
                mailAdd,
                mailCity,
                mailState,
                mailZip,
                mailZip4,
                conName = $('#PrimaryContactName').val(),
                conTitle = $('#PrimaryContacttitle').val(),
                conEmail = $('#PrimaryContactEmail').val(),
                conPhone = $('#PrimaryContactPhone').val();

            let busLegalNameValid = true,
                busAsValid = true,
                busTypeValid = true,
                busAddValid = true,
                busCityValid = true,
                busStateValid = true,
                busZipValid = true,
                busZip4Valid = true,
                busCountyValid = true,
                busPhoneValid = true,
                mailNameValid = true,
                mailAddValid = true,
                mailCityValid = true,
                mailStateValid = true,
                mailZipValid = true,
                mailZip4Valid = true,
                conNameValid = true,
                conTitleValid = true,
                conEmailValid = true,
                conPhoneValid = true;

            // Business Form
            if (busLegalName === null || busLegalName === undefined || busLegalName === '' || busLegalName === ' ') {
                $('#BusinessLegalNameError').show();
                busLegalNameValid = false;
            } else {
                $('#BusinessLegalNameError').hide();
                busLegalNameValid = true;
            }
            if (busAs === null || busAs === undefined || busAs === '' || busAs === ' ') {
                $('#busAsError').show();
                busAsValid = false;
            } else {
                $('#busAsError').hide();
                busAsValid = true;
            }
            if (busType === null || busType === undefined || busType === '' || busType === ' ') {
                $('#busTypeError').show();
                busTypeValid = false;
            } else {
                $('#busTypeError').hide();
                busTypeValid = true;
            }
            if (busAdd === null || busAdd === undefined || busAdd === '' || busAdd === ' ') {
                $('#busAddError').show();
                busAddValid = false;
            } else {
                $('#busAddError').hide();
                busAddValid = true;
            }
            if (busCity === null || busCity === undefined || busCity === '' || busCity === ' ') {
                $('#busCityError').show();
                busCityValid = false;
            } else {
                $('#busCityError').hide();
                busCityValid = true;
            }
            if (busState === null || busState === undefined || busState === '' || busState === ' ') {
                $('#busStateError').show();
                busStateValid = false;
            } else {
                $('#busStateError').hide();
                busStateValid = true;
            }
            if (busZip === null || busZip === undefined || busZip === '' || busZip === ' ' || busZip.length < 5) {
                $('#busZipError').show();
                busZipValid = false;
            } else {
                $('#busZipError').hide();
                busZipValid = true;
            }
            if (busZip4 === null || busZip4 === undefined || busZip4 === '' || busZip4 === ' ' || busZip4.length < 4) {
                $('#busZip4Error').show();
                busZip4Valid = false;
            } else {
                $('#busZip4Error').hide();
                busZip4Valid = true;
            }
            if (busCounty === null || busCounty === undefined || busCounty === '' || busCounty === ' ') {
                $('#busCountyError').show();
                busCountyValid = false;
            } else {
                $('#busCountyError').hide();
                busCountyValid = true;
            }
            if (busPhone === null || busPhone === undefined || busPhone === '' || busPhone === ' ') {
                $('#busPhoneError').show();
                busPhoneValid = false;
            } else {
                $('#busPhoneError').hide();
                busPhoneValid = true;
            }
            
            // Mail Address is NOT the same as Business Address
            if (mailCheck.checked === false) {
                mailName = $('#MailingName').val();
                mailAdd = $('#MailingStreetAddress').val();
                mailCity = $('#MailingCity').val();
                mailState = $('#MailingState').val();
                mailZip =  $('#MailingZip').val();
                mailZip4 = $('#MailingZipPlus4').val();

                if (mailName === null || mailName === undefined || mailName === '' || mailName === ' ') {
                    $('#mailNameError').show();
                    mailNameValid = false;
                } else {
                    $('#mailNameError').hide();
                    mailNameValid = true;
                }
                if (mailAdd === null || mailAdd === undefined || mailAdd === '' || mailAdd === ' ') {
                    $('#mailAddError').show();
                    mailAddValid = false;
                } else {
                    $('#mailAddError').hide();
                    mailAddValid = true;
                }
                if (mailCity === null || mailCity === undefined || mailCity === '' || mailCity === ' ') {
                    $('#mailCityError').show();
                    mailCityValid = false;
                } else {
                    $('#mailCityError').hide();
                    mailCityValid = true;
                }
                if (mailState === null || mailState === undefined || mailState === '' || mailState === ' ') {
                    $('#mailStateError').show();
                    mailStateValid = false;
                } else {
                    $('#mailStateError').hide();
                    mailStateValid = true;
                }
                if (mailZip === null || mailZip === undefined || mailZip === '' || mailZip === ' ' || mailZip.length < 5) {
                    $('#mailZipError').show();
                    mailZipValid = false;
                } else {
                    $('#mailZipError').hide();
                    mailZipValid = true;
                }
                if (mailZip4 === null || mailZip4 === undefined || mailZip4 === '' || mailZip4 === ' ' || mailZip4.length < 4) {
                    $('#mailZip4Error').show();
                    mailZip4Valid = false;
                } else {
                    $('#mailZip4Error').hide();
                    mailZip4Valid = true;
                }
            }

            // Contact Form
            if (conName === null || conName === undefined || conName === '' || conName === ' ') {
                $('#conNameError').show();
                conNameValid = false;
            } else {
                $('#conNameError').hide();
                conNameValid = true;
            }
            if (conTitle === null || conTitle === undefined || conTitle === '' || conTitle === ' ') {
                $('#conTitleError').show();
                conTitleValid = false;
            } else {
                $('#conTitleError').hide();
                conTitleValid = true;
            }
            if (conEmail === null || conEmail === undefined || conEmail === '' || conEmail === ' ') {
                $('#conEmailError').show();
                conEmailValid = false;
            } else {
                $('#conEmailError').hide();
                conEmailValid = true;
            }
            if (conPhone === null || conPhone === undefined || conPhone === '' || conPhone === ' ') {
                $('#conPhoneError').show();
                conPhoneValid = false;
            } else {
                $('#conPhoneError').hide();
                conPhoneValid = true;
            }

            // Final Validation
            if (
                busLegalNameValid === false ||
                busAsValid === false ||
                busTypeValid === false ||
                busAddValid === false ||
                busCityValid === false ||
                busStateValid === false ||
                busZipValid.length < 5 ||
                busZip4Valid === false ||
                busZip4.length < 4 ||
                busCountyValid === false ||
                busPhoneValid === false ||
                mailNameValid === false ||
                mailAddValid === false ||
                mailCityValid === false ||
                mailStateValid === false ||
                mailZipValid.length < 5 ||
                mailZip4Valid.length < 4 ||
                conNameValid === false ||
                conTitleValid === false ||
                conEmailValid === false ||
                conPhoneValid === false
            ) {
                $('#busFormError').show();
                return false;
            } else {
                $('#busFormError').hide();
            }

            return true;
        },
        validateEFT() {
            let bankName = $('#dfiName').val(),
                accountName = $('#eftAccountName').val(),
                accountNum = $('#checkingAccountNumber').val(),
                accountNumValidation = $('#checkingAccountNumberVerify').val(),
                routeNum = $('#checkingAccountRoutingNumber').val(),
                routeNumValidation = $('#checkingAccountRoutingNumberVerify').val();

            let secondaryBankName = $('#secondaryDfiName').val(),
                secondaryAccountName = $('#secondaryEftAccountName').val(),
                secondaryAccountNum = $('#secondaryCheckingAccountNumber').val(),
                secondaryAccountNumValidation = $('#secondaryCheckingAccountNumberVerify').val(),
                secondaryRouteNum = $('#secondaryCheckingAccountRoutingNumber').val(),
                secondaryRouteNumValidation = $('#secondaryCheckingAccountRoutingNumberVerify').val(); 

            let dfI = document.getElementById('checkDFIauth'),
                sigAck = document.getElementById('checkSignature');

            let bankNameValid = true,
                accountNameValid = true,
                accountNumValid = true,
                accountNumValidationValid = true,
                accountCompareValid = true,
                routeNumValid = true,
                routeNumValidationValid = true,
                routeCompareValid = true,
                dfIValid = true,
                sigAckValid = true;

            let secondaryBankNameValid = true,
                secondaryAccountNameValid = true,
                secondaryAccountNumValid = true,
                secondaryAccountNumValidationValid = true,
                secondaryAccountCompareValid = true,
                secondaryRouteNumValid = true,
                secondaryRouteNumValidationValid = true,
                secondaryRouteCompareValid = true;

            // Bank Validation
            if (bankName === null || bankName === undefined || bankName === '' || bankName === ' ') {
                $('#bankNameError').show();
                bankNameValid = false;
            } else {
                $('#bankNameError').hide();
                bankNameValid = true;
            }

            if (accountName === null || accountName === undefined || accountName === '' || accountName === ' ') {
                $('#accountNameError').show();
                accountNameValid = false;
            } else {
                $('#accountNameError').hide();
                accountNameValid = true;
            }

            // Account Number Validation
            if (accountNum === null || accountNum === undefined || accountNum === '' || accountNum === ' ' || accountNum.length < 8) {
                // $('#accountNumError').show();
                let accountNumEr = document.getElementById('accountNumError');
                accountNumEr.style.display = 'block';
                accountNumValid = false;
            } else {
                $('#accountNumError').hide();
                accountNumValid = true;
            }
            if (accountNumValidation === null || accountNumValidation === undefined || accountNumValidation === '' || accountNumValidation === ' ' || accountNumValidation.length < 8) {
                $('#accountNumValidationError').show();
                accountNumValidationValid = false;
            } else {
                $('#accountNumError').hide();
                accountNumValidationValid = true;
            }
            if (accountNum !== accountNumValidation) {
                $('#accountCompareError').show();
                accountCompareValid = false;
            } else {
                $('#accountCompareError').hide();
                accountCompareValid = true;
            }

            // Route Number Validation
            if (routeNum === null || routeNum === undefined || routeNum === '' || routeNum === ' ' || routeNum.length < 9) {
                $('#routeNumError').show();
                routeNumValid = false;
            } else {
                $('#routeNumError').hide();
                routeNumValid = true;
            }
            if (routeNumValidation === null || routeNumValidation === undefined || routeNumValidation === '' || routeNumValidation === ' ' || routeNumValidation.length < 9) {
                $('#routeNumValidationError').show();
                routeNumValidationValid = false;
            } else {
                $('#routeNumValidationError').hide();
                routeNumValidationValid = true;
            }
            if (routeNum !== routeNumValidation) {
                $('#routeCompareError').show();
                routeCompareValid = false;
            } else {
                $('#routeCompareError').hide();
                routeCompareValid = true;
            }

            // SECONDARY ACCOUNT VALIDATION
            if(this.showSecondary === true) {
                
                let secondaryCheckbox = $('#chkShareEFT');
                if (secondaryCheckbox[0].checked === false) {
                    // Bank Validation
                    if (secondaryBankName === null || secondaryBankName === undefined || secondaryBankName === '' || secondaryBankName === ' ') {
                        $('#secondaryBankNameError').show();
                        secondaryBankNameValid = false;
                    } else {
                        $('#secondaryBankNameError').hide();
                        secondaryBankNameValid = true;
                    }

                    if (secondaryAccountName === null || secondaryAccountName === undefined || secondaryAccountName === '' || secondaryAccountName === ' ') {
                        $('#secondaryAccountNameError').show();
                        secondaryAccountNameValid = false;
                    } else {
                        $('#secondaryAccountNameError').hide();
                        secondaryAccountNameValid = true;
                    }

                    // Account Number Validation
                    if (secondaryAccountNum === null || secondaryAccountNum === undefined || secondaryAccountNum === '' || secondaryAccountNum === ' ' || secondaryAccountNum.length < 8) {
                        // $('#secondaryAccountNumError').css('display', 'block');
                        let accountNumEr = document.getElementById('secondaryAccountNumError');
                        accountNumEr.style.display = 'block';
                        secondaryAccountNumValid = false;
                    } else {
                        // $('#secondaryAccountNumError').hide();
                        secondaryAccountNumValid = true;
                    }
                    if (secondaryAccountNumValidation === null || secondaryAccountNumValidation === undefined || secondaryAccountNumValidation === '' || secondaryAccountNumValidation === ' ' || secondaryAccountNumValidation.length < 8) {
                        $('#secondaryAccountNumValidationError').show();
                        secondaryAccountNumValidationValid = false;
                    } else {
                        $('#secondaryAccountNumValidationError').hide();
                        secondaryAccountNumValidationValid = true;
                    }
                    if (secondaryAccountNum == secondaryAccountNumValidation) {
                        $('#secondaryAccountCompareError').hide();
                        secondaryAccountCompareValid = true;
                    } else {
                        $('#secondaryAccountCompareError').show();
                        secondaryAccountCompareValid = false;
                    }

                    // Route Number Validation
                    if (secondaryRouteNum === null || secondaryRouteNum === undefined || secondaryRouteNum === '' || secondaryRouteNum === ' ' || secondaryRouteNum.length < 9) {
                        $('#secondaryRouteNumError').show();
                        secondaryRouteNumValid = false;
                    } else {
                        $('#secondaryRouteNumError').hide();
                        secondaryRouteNumValid = true;
                    }
                    if (secondaryRouteNumValidation === null || secondaryRouteNumValidation === undefined || secondaryRouteNumValidation === '' || secondaryRouteNumValidation === ' ' || secondaryRouteNumValidation.length < 9) {
                        $('#secondaryRouteNumValidationError').show();
                        secondaryRouteNumValidationValid = false;
                    } else {
                        $('#secondaryRouteNumValidationError').hide();
                        secondaryRouteNumValidationValid = true;
                    }
                    if (secondaryRouteNum !== secondaryRouteNumValidation) {
                        $('#secondaryRouteCompareError').show();
                        secondaryRouteCompareValid = false;
                    } else {
                        $('#secondaryRouteCompareError').hide();
                        secondaryRouteCompareValid = true;
                    }

                    if (
                        secondaryBankNameValid === false ||
                        secondaryAccountNameValid === false ||
                        secondaryAccountNumValid === false ||
                        secondaryAccountNumValidationValid === false ||
                        secondaryAccountCompareValid === false ||
                        secondaryRouteNumValid === false ||
                        secondaryRouteNumValidationValid === false ||
                        secondaryRouteCompareValid === false
                    ) {
                        $('#eftFormError').show();
                        return false;
                    } else {
                        $('#eftFormError').hide();
                    }
                }
            }

            if (dfI.checked === false) {
                dfIValid = false;
                $('#dfIautorizationError').show();
            } else {
                dfIValid = true;
                $('#dfIautorizationError').hide();
            }

            if (sigAck.checked === false) {
                sigAckValid = false;
                $('#signatureAcknowledgmentError').show();
            } else {
                dfIValid = true;
                $('#signatureAcknowledgmentError').hide();
            }

            // Final Validation
            if (
                bankNameValid === false ||
                accountNameValid === false ||
                accountNumValid === false ||
                accountNumValidationValid === false ||
                accountCompareValid === false ||
                routeNumValid === false ||
                routeNumValidationValid === false ||
                routeCompareValid === false ||
                dfIValid === false ||
                sigAckValid === false
            ) {
                $('#eftFormError').show();
                return false;
            } else {
                $('#eftFormError').hide();
            }

            return true;
        },
        validateW9() {
            let name = $('#w9Name').val(),
                legalName = $('#w9LegalName').val(),
                type = $('#w9LegalEntityType').val(),
                address = $('#w9StreetAddress').val(),
                city = $('#w9City').val(),
                zip = $('#w9Zip').val(),
                zip4 = $('#w9ZipPlus4').val(),
                state = $('#w9State').val(),
                taxClass = $('#w9TaxClassification').val(),
                taxClassOther = $('#w9TaxClassificationOther'),
                taxId = $('#w9TaxID').val(),
                taxType = $('input[name=w9TaxIDType]'),
                fedCertCheck = $('#w9AgreeFederalCertificationRequirements'),
                termsCheck = $('#w9AgreeTerms'),
                contactCheck = $('#w9DifferentLegalContact'),
                contactFirstName,
                contactLastName;

            let nameValid = true,
                legalNameValid = true,
                typeValid = true,
                addressValid = true,
                cityValid = true,
                zipValid = true,
                zip4Valid = true,
                stateValid = true,
                taxClassValid = true,
                taxClassOtherValid = true,
                taxIdValid = true,
                taxTypeValid = false,
                fedCertCheckValid = true,
                termsCheckValid = true,
                contactFirstNameValid = true,
                contactLastNameValid = true;

            let nameError = $('#w9NameError'),
                legalNameError = $('#w9LegalNameError'),
                typeError = $('#w9LegalEntityTypeError'),
                addressError = $('#w9StreetAddressError'),
                cityError = $('#w9CityError'),
                zipError = $('#w9ZipError'),
                zip4Error = $('#w9ZipPlus4Error'),
                stateError = $('#w9StateError'),
                taxClassError = $('#w9TaxClassificationError'),
                taxClassOtherError = $('#w9TaxClassificationOtherError'),
                taxIdError = $('#w9TaxIDError'),
                taxTypeError = $('#w9TaxIDTypeError'),
                fedCertCheckError = $('#w9AgreeFederalCertificationRequirementsError'),
                termsCheckError = $('#w9AgreeTermsError'),
                contactFirstNameError,
                contactLastNameError;

            if (name === null || name === undefined || name === '' || name === ' ') {
                nameError.show();
                nameValid = false;
            } else {
                nameError.hide();
            }
            if (legalName === null || legalName === undefined || legalName === '' || legalName === ' ') {
                legalNameError.show();
                legalNameValid = false;
            } else {
                legalNameError.hide();
            }
            if (type === null || type === undefined || type === '' || type === ' ') {
                typeError.show();
                typeValid = false;
            } else {
                typeError.hide();
            }
            if (address === null || address === undefined || address === '' || address === ' ') {
                addressError.show();
                addressValid = false;
            } else {
                addressError.hide();
            }
            if (city === null || city === undefined || city === '' || city === ' ') {
                cityError.show();
                cityValid = false;
            } else {
                cityError.hide();
            }
            if (zip === null || zip === undefined || zip === '' || zip === ' ') {
                zipError.show();
                zipValid = false;
            } else {
                zipError.hide();
            }
            if (zip4 === null || zip4 === undefined || zip4 === '' || zip4 === ' ') {
                zip4Error.show();
                zip4Valid = false;
            } else {
                zip4Error.hide();
            }
            if (state === null || state === undefined || state === '' || state === ' ') {
                stateError.show();
                stateValid = false;
            } else {
                stateError.hide();
            }
            if (taxClass === 'null' || taxClass === undefined || taxClass === '' || taxClass === ' ') {
                taxClassError.show();
                taxClassValid = false;
            } else {
                if (taxClass === 'Other') {
                    if (taxClassOther === null || taxClassOther === undefined || taxClassOther === '' || taxClassOther === ' ') {
                        taxClassOtherError.show();
                        taxClassOtherValid = false;
                    } else {
                        taxClassOtherError.hide();
                    }
                }
                taxClassError.hide();
            }
            const taxIDReg = /^\d+$/;
            let taxIDNum = taxId;
            if (taxId === null || taxId === undefined || taxId === '' || taxId === ' ' || taxId.length !== 9 || !taxIDReg.test(taxIDNum)) {
                taxIdError.show();
                taxIdValid = false;
            } else {
                taxIdError.hide();
            }

            // Additional Contact
            // console.log(contactCheck[0].checked)
            if(contactCheck[0].checked === true) {
                contactFirstName = $('#w9LegalContactFirstName').val();
                contactLastName = $('#w9LegalContactLastName').val();
                contactFirstNameError = $('#w9LegalContactFirstNameError');
                contactLastNameError = $('#w9LegalContactLastNameError');

                if (contactFirstName === null || contactFirstName === undefined || contactFirstName === '' || contactFirstName === ' ') {
                    contactFirstNameError.show();
                    contactFirstNameValid = false;
                } else {
                    contactFirstNameError.hide();
                }
                if (contactLastName === null || contactLastName === undefined || contactLastName === '' || contactLastName === ' ') {
                    contactLastNameError.show();
                    contactLastNameValid = false;
                } else {
                    contactLastNameError.hide();
                }
            }

            // Checkboxes
            if (fedCertCheck[0].checked === false) {
                fedCertCheckError.show();
                fedCertCheckValid = false;
            } else {
                fedCertCheckError.hide();
            }
            if (termsCheck[0].checked === false) {
                termsCheckError.show();
                termsCheckValid = false;
            } else {
                termsCheckError.hide();
            }
            
            // Tax Type Radio Validation
            for (let i = 0; i < taxType.length; i++) {
                if (taxType[i].checked === true) {
                    taxTypeError.hide();
                    taxTypeValid = true;
                }
            }

            if (taxTypeValid === false) {
                taxTypeError.show();
            }

            if (
                nameValid === false ||
                legalNameValid === false ||
                typeValid === false ||
                addressValid === false ||
                cityValid === false ||
                zipValid === false ||
                zip4Valid === false ||
                stateValid === false ||
                taxClassValid === false ||
                taxClassOtherValid === false ||
                taxIdValid === false ||
                taxTypeValid === false ||
                fedCertCheckValid === false ||
                termsCheckValid === false ||
                contactFirstNameValid === false ||
                contactLastNameValid === false
            ) {
                return false;
            }

            return true;
        },
        validatePrincipals() {

        },
        setConviction(val) {
            if (val == 1) {
                this.principalCrimeConvictions = true;
            } else {
                this.principalCrimeConvictions = false;
                this.principalCrimeConvictionsExplanation = '';
            }
            this.convictionInfoError = false;
        }
    }
}
</script>
<style lang="scss" scoped>
    .errorList {
        list-style-type: none;
        font-size: 1.6rem;
        padding: 10px 0;
    }

    .eAppSignature.appSigManual,
    .checkboxRow.appSignatureCert {
        label {
            display: inline-block;
        }
    }
</style>