<template>
    <div class="sf-wrapper">
        <!-- <div style="display:none;">{{ isRunOfFair }}</div> -->
    <section class="sf-content sf-entry" v-if="!isParticipating">
        <img src="/getattachment/e87ddf0c-51bb-4711-aba6-4ad484e9b15c/sf-statue-search-logo.png" alt="Statue Search Logo">
        <h2>Join the Search</h2>
        <p>Five Ohio Lottery-themed statues are hidden throughout the fairgrounds. Scan and collect them all and enter for a chance to <strong>win&nbsp;$1,000&nbsp;in&nbsp;Scratch-Offs.</strong></p>
        <hr class="sf-hr">
        <p>Enter your email address<br> to start collecting statues.</p>
        <div class="sf-emailEntry">
            <label for="sf-email" class="hide">Email address</label>
            <input type="email" name="sf-email" placeholder="Email address" v-model="emailAddress" @input="validateEmail" required>
            <span class="emailError" v-if="emailError">Please enter a valid email address.</span>
            <div class="sf-checkbox sf-age-acknowledge">
                <input type="checkbox" name="sf-age" id="sf-age" v-model="ageAcknowledge">
                <label for="sf-age">I confirm I am at least 18 years old</label>
            </div>
            <div class="sf-checkbox sf-consent-email">
                <input type="checkbox" name="sf-email-consent" id="sf-email-consent" v-model="emailConsent">
                <label for="sf-email-consent">(Optional) I consent to receive marketing emails</label>
            </div>
            <button class="button" @click="startSearch()" :disabled="!isValidForm">
                <!-- <img src="/getattachment/6e2a5ef5-1118-4603-b4e1-9223e84c0d0a/sf-button-start.png" alt="Start Collecting"> -->
                 &nbsp;
            </button>
            <a href="/$1000-Scratch-Offs-Statue-Search/How-To-Collect" class="sf-link">How To Collect Statues</a>
            <a href="/$1000-Scratch-Offs-Statue-Search/Rules" class="sf-link">Drawing Rules</a>
        </div>
    </section>
    <section class="sf-content" v-else>
        <div v-if="!statues.length">
            Loading...
        </div>
        <div v-else class="sf-statues">
            <ul class="statues" :class="[currentStatue, {notfound: currentNotFound}]">
                <li v-for="(statue, index) in statues" :class="'stat' + index" :id="statue.statueId" @click="changeCurrentStatue(statue.statueId, $event)">
                    <div :class="['statue', statue.statueId, {collected: statue.isCollected}, {empty: !statue.isCollected}, {pulse: statue.statueId === currentStatue}, {throb: allCollected}]" :data-stat="statue.statueId"></div>
                </li>
            </ul>
            <section class="sf-found" :class="currentStatue">
                <transition name="slide-fade" mode="out-in">
                    <div :key="currentStatue">
                        <h2>{{ currentStatueTitle }}</h2>
                        <p>{{ currentStatueParagraph }}</p>
                        <p class="sfAddTxt" v-if="notFoundAddText">Use your phone's camera to snap the <br> statue's QR code and add it to your <br>collection.</p>
                        <p>
                            <span v-if="isDayOf">Day Of Event: </span>
                            <span v-else>Run Of Fair Event:</span>
                            You have {{ statueCount }} 
                            <span v-if="statueCount === 1">entry</span>
                            <span v-else>entries</span>.</p>
                        <p>{{ returnErrorMsg }}</p>
                    </div>
                </transition>
            </section>
            <!-- <section class="sf-error">
                <p>{{ returnErrorMsg }}</p>
            </section> -->
        </div>
    </section>
    <div class="sf-modal" v-if="showAllCollected">
        <div class="sf-modal-content">
            <div class="sf-modal-toggle">
                <button type="button" class="sf-btn-modal" @click="toggleSFmodal()">&nbsp;</button>
            </div>
            <h2>Great job!</h2>
            <div class="sf-modal-img"><img src="/getattachment/c80065b2-98fc-44ed-bc0d-067c3b9f5451/pin-great-job-min.png" alt="Collected Pin" width="130px"></div>
            <h3>You've collected all five statues!</h3>
            <p v-if="isDayOf" class="sf-modal-txt">Show this message by 3:15 p.m. at the Ohio Lottery booth – located north of 17th Avenue, in front of the Rhodes Building – to be entered to win $1,000 in Ohio Lottery Scratch-Offs and four Ohio Lottery SkyGlider tickets.</p>
            <p v-else>Nice going! You've got 5 entries into the drawing for your chance to win $1,000 in Scratch-Offs.</p>
        </div>
    </div>
    </div>
</template>
<script>

    export default {
        
        data: function() {
           return {
            today: new Date(),
            dayOfEvent: new Date('08/03/2024'),
            isEmailValid: false,
            ageAcknowledge: false,
            emailConsent: false,
            isParticipating: false,
            emailAddress: '',
            statues: [],
            currentStatue: '',
            currentStatueTitle: '',
            currentStatueParagraph: '',
            emailError: false,
            allCollected: false,
            showAllCollected: false,
            isError: false,
            notFoundAddText: false,
            isDayOf: null,
            currentNotFound: false,
            statueCount: 0
           }
        },
        created: function() {
            //setup some data properties here
            //set the current statue
            //set the isRunOfFair
            console.log("Component is created");
            // this.isError = false;

        },
        mounted: function() {
            //set if it is Day Of or Run Of
            this.setFairType();
            //determines if the sign up screen should be shown or not
            this.isUserParticipating();
            //get users statues
            this.getUsersStatues();
            //set the current statue, for collecting and styling
            this.getCurrentStatue();
            //set the pulses
            this.pulseAnimation();
            
        },
        methods: {
            isUserParticipating() {
                const isParticipating = getCookie("sfUser");
                console.log(isParticipating);

                if (isParticipating) {
                    this.isParticipating = true;
                }
            },
            getCurrentStatue() {
                //need user, statueId, isDayOf, acceptedMarketing
                let userEmail = $cookies.get("sfUser");
                let emailConsent = $cookies.get("sfEmailConsent");
                let userStatues = $cookies.get("sfStatues");
                //if query string exists we scanned a qr code to collect
                const searchParam = this.getURLQuery('location');
                console.log(searchParam);
                //if the param exists set the current statue
                if (searchParam) {
                    this.currentStatue = searchParam;
                    //submit it to backend
                    // this.submitStateFair(userEmail, this.mapTheStatueToId(searchParam), this.isDayOf, emailConsent);
                    
                } else {
                    console.log("Hit the My Statues page - not collecting - just displaying");
                    //no searchParam because base URL was hit - or MyStatues link
                    //need to hit getStatus if the userEmail !== null
                    console.log("USER STATUES ON MY STATUES PAGE");
                    console.log(userStatues);
                    let foundStatue = userStatues.find((element) => element.isCollected);
                    console.log(foundStatue);
                    this.currentStatue = foundStatue.statueId;
                    
                }

                if (userEmail !== null) {
                    this.submitStateFair(userEmail, this.mapTheStatueToId(searchParam), this.isDayOf, emailConsent);
                }

                this.setStatueTitle();
                this.setStatueParagraph(true);
            },
            submitStateFair(userEmail, statueId, isDayOf, acceptedMarketing) {
                let url = window.location.origin + '/SeasonalPromotion/StateFair2024Submit?email=' + userEmail + '&statueID=' + statueId + '&dayOf=' + isDayOf + '&acceptMarketing=' + acceptedMarketing + '';

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json'
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json);
                    this.processStatues(json);

                    //check for the isError on already scanned here
                    if (json.isError) {
                        this.isError = true;
                    }
                    
                })
                .catch((error) => {
                    console.log(error);
                })
            },
            stateFairGetStatus(email, dayOf) {
                let url = window.location.origin + '/SeasonalPromotion/StateFair2024GetStatus?email=' + email + '&dayOf=' + dayOf + '';

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json'
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json);
                    this.processStatues(json);
                })
                .catch((error) => {
                   console.log(error);
                })
            },
            startSearch() {
                //grab the first statue qs param
                let location = this.getURLQuery('location');
                //for now just to get flow set
                this.isParticipating = true;
                //here we need to set the cookie for isParticipating - kickoff search
                $cookies.set("sfEmailConsent", this.emailConsent);
                $cookies.set("sfUser", this.emailAddress);
                //collect the first statue
                this.submitStateFair(this.emailAddress, this.mapTheStatueToId(location), this.isDayOf, this.emailConsent);

            },
            validateEmail() { 
               this.isEmailValid = this.emailValidation(this.emailAddress);
            }, 
            validateCheckboxes() { 
                this.isValid = this.firstCheckbox;    
            },
            setStatueTitle() {
                const currentStatue = this.currentStatue;
                let txt = '';

                switch(currentStatue) {
                    case 'qzy1e':
                        txt = 'Community'
                    break;
                    case 'rvo4y':
                        txt = 'Fun'
                    break;
                    case 'tbp5u':
                        txt = 'Education'
                    break;
                    case 'eci3t':
                        txt = 'Retail'
                    break;
                    case 'wxu2r':
                        txt = 'Winning'
                    break;
                    default:
                        txt = 'Default'
                }

                this.currentStatueTitle = txt;
            },
            setStatueParagraph(isFound) {
                const currentStatue = this.currentStatue;
                let txt = '';
                //reset the additional text
                this.notFoundAddText = false;

                    if (isFound) {

                        switch(currentStatue) {
                        case 'qzy1e':
                            txt = 'For 50 years, the Ohio Lottery has brought our communities together with a variety of games, optimism and fun.';
                        break;
                        case 'rvo4y':
                            txt = 'For 50 years, the Ohio Lottery has introduced thousands of entertaining games, offering Ohioans the chance to win millions!';
                        break;
                        case 'tbp5u':
                            txt = "For 50 years, the Ohio Lottery has transferred over $32 billion to Ohio's K-12 education programs!";
                        break;
                        case 'eci3t':
                            txt = 'For 50 years, retailers have made fun accessible at over 10,500 locations across Ohio.';
                        break;
                        case 'wxu2r':
                            txt = "For 50 years, the Ohio Lottery has been all about celebrating wins and creating winners across Ohio every day.";
                        break;
                        default:
                            txt = 'Default';
                    }

                    } else {

                        txt = 'Looks like you still need this statue.';
                        this.notFoundAddText = true;
                    }
                

                this.currentStatueParagraph = txt;
            },
            getUsersStatues() {
                let cookie = $cookies.get('sfStatues');
                console.log("Users statues:");
                console.log(cookie);
            },
            checkForStatueInCollection(qsVal, arr) {
                let usersCollection = arr;
                let statueToSearchFor = qsVal;

                return usersCollection.includes(statueToSearchFor) ? true : false;
            },
            changeCurrentStatue(statueId, event) {
                this.currentNotFound = false;
                this.currentStatue = statueId;
                let isCollected = true;
                console.log("Event");
                console.log(event.target.classList);

                if (event.target.classList.contains("empty")) {
                    isCollected = false;
                    this.currentNotFound = true;
                }

                if (event.target.classList.contains("collected")) {
                    this.isError = true;
                } else {
                    this.isError = false;
                }

                this.setStatueTitle();
                this.setStatueParagraph(isCollected);
            },
            mapTheStatueToId(qsParam) {
                let statueString = qsParam;
                let txt;

                switch(statueString) {
                    case 'qzy1e':
                        txt = 1;
                    break;
                    case 'rvo4y':
                        txt = 4;
                    break;
                    case 'tbp5u':
                        txt = 5;
                    break;
                    case 'eci3t':
                        txt = 3;
                    break;
                    case 'wxu2r':
                        txt = 2;
                    break;
                    default:
                        txt = 'Default';
                }

                return txt;
            },
            processStatues(json) {
                //convert the data retrieved from submitStateFair into the data property statues
                let statueData = json;
                let statuesArr = [];

                if (statueData.statue5ScanDate !== '0001-01-01T00:00:00') {
                    let statueObj = {};
                    statueObj.statueId = 'tbp5u';
                    statueObj.isCollected = true;
                    statuesArr.push(statueObj);
                } else {
                    let statueObj = {};
                    statueObj.statueId = 'tbp5u';
                    statueObj.isCollected = false;
                    statuesArr.push(statueObj);
                }

                if (statueData.statue1ScanDate !== '0001-01-01T00:00:00') {
                    let statueObj = {};
                    statueObj.statueId = 'qzy1e';
                    statueObj.isCollected = true;
                    statuesArr.push(statueObj);
                } else {
                    let statueObj = {};
                    statueObj.statueId = 'qzy1e';
                    statueObj.isCollected = false;
                    statuesArr.push(statueObj);
                }

                if (statueData.statue2ScanDate !== '0001-01-01T00:00:00') {
                    let statueObj = {};
                    statueObj.statueId = 'wxu2r';
                    statueObj.isCollected = true;
                    statuesArr.push(statueObj);
                } else {
                    let statueObj = {};
                    statueObj.statueId = 'wxu2r';
                    statueObj.isCollected = false;
                    statuesArr.push(statueObj);
                }

                if (statueData.statue4ScanDate !== '0001-01-01T00:00:00') {
                    let statueObj = {};
                    statueObj.statueId = 'rvo4y';
                    statueObj.isCollected = true;
                    statuesArr.push(statueObj);
                }  else {
                    let statueObj = {};
                    statueObj.statueId = 'rvo4y';
                    statueObj.isCollected = false;
                    statuesArr.push(statueObj);
                }

                if (statueData.statue3ScanDate !== '0001-01-01T00:00:00') {
                    let statueObj = {};
                    statueObj.statueId = 'eci3t';
                    statueObj.isCollected = true;
                    statuesArr.push(statueObj);
                } else {
                    let statueObj = {};
                    statueObj.statueId = 'eci3t';
                    statueObj.isCollected = false;
                    statuesArr.push(statueObj);
                }

                $cookies.set("sfStatues", statuesArr);
                this.statues = statuesArr;
                console.log("Do you see this?");
                console.log(this.statues);

                this.didWeCollectAllFive(this.statues)
                
            },
            didWeCollectAllFive(statues) {
                let count = 0;
                statues.forEach((statue) => {
                    if (statue.isCollected) {
                        count = count + 1;
                    }
                });
                console.log("in did we collect all 5");
                console.log(statues);
                console.log(count);

                this.statueCount = count;

                if (count === 5) {
                    console.log("We collected all five");
                    this.allCollected = true;
                    // document.querySelector('.pulse').classList.remove('pulse');
                    // document.querySelectorAll('.statue').forEach((element) => element.classList.add('throb'));
                    setTimeout(() => {
                        this.showAllCollected = true;
                    }, 5000);
                }
            },
            pulseAnimation() {
                let currentStatue = this.currentStatue;
                console.log(currentStatue);
                let currentEl = document.querySelector("[data-stat='" + currentStatue + "']");
                console.log(currentEl);
            },
            setFairType() {
                console.log("Today : " + this.today);
                console.log("Day Of Event : " + this.dayOfEvent);
                this.isDayOf = this.today.getDate() === this.dayOfEvent.getDate();
                console.log("Is it the day of event?")
                console.log(this.isDayOf);
            },
            toggleSFmodal() {
                document.querySelector(".sf-modal").classList.toggle('tucked');
                // document.querySelector(".sf-modal-content h2").remove();

                if (document.querySelector(".sf-modal-content h2") !== null) {
                    document.querySelector(".sf-modal-content h2").remove();
                }
            }
        },
        computed: {
            isValidForm() {
                return this.isEmailValid && this.ageAcknowledge;
            },
            // isRunOfFair() {
            //     console.log("Today : " + this.today);
            //     console.log("Day Of Event : " + this.dayOfEvent);
            //     this.isDayOf = this.today.getDate() === this.dayOfEvent.getDate();
            //     console.log("Is it the day of event?")
            //     console.log(this.isDayOf);
            // },
            isAllCollected() {
                let statues = this.statues;
                let collectedCount = 0;
                statues.forEach((element) => {
                    if (element.isCollected === true) {
                        collectedCount = collectedCount + 1;
                    }
                });
                this.allCollected = collectedCount === 5;
            },
            returnErrorMsg() {
                return this.isError ? "You've already scanned this statue." : "";
            }
        }
    }
</script>
<style></style>