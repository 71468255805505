<template>
    <div class="eAppModule  businessHours">
        <h3>Business Hours</h3>

        <span class="EditingFormError" style="display: none;">

            <span class="ErrorLabel alert-error hoursFormError" style="display:none;">Please provide hours for each day of
                the week.</span>
        </span>

        <div class="eAppForm">
            <div id="oneDayNeedsHoursError" class="error hoursError" style="display:none;">Please select open hours for at least one day of the week.</div>
            <div class="hoursDay cf">
                <span class="req">*</span> <label>Monday</label>
                <div class="businessHoursInput cf">
                    <div class="hoursHours cf">
                        <legend>Hours of operation</legend>
                        <select v-model="hoursOpen1" :disabled="isDisabled" class="hoursSelect hoursHour"
                            @change="dropDownCahnge('1')">
                            <option value="" disabled>--</option>
                            <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                {{ option.display }}
                            </option>
                        </select>
                        <span class="hoursTo">to</span>
                        <select v-model="hoursClose1" :disabled="isDisabled" class="hoursSelect hoursHour"
                            @change="dropDownCahnge('1')">
                            <option value="" disabled>--</option>
                            <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                {{ option.display }}
                            </option>
                        </select>
                        <span class="hoursCheckboxes cf">
                            <span class="checkboxSet cf">
                                <span class="hoursCheck hoursOpen24"><input id="BusinessHours1_open24hours"
                                        :disabled="isDisabled" @change="openChange('1')" v-model="open2471" type="checkbox"
                                        name="BusinessHours1_open24hours"></span>
                                <label for="BusinessHours1_open24hours" id="BusinessHours1_open24hoursLbl"
                                    class="hoursCheckLbl hoursOpen24lbl">Open 24 hrs</label>
                            </span>

                            <span class="checkboxSet cf">
                                <span class="hoursCheck hoursClosed"><input id="BusinessHours1_closed"
                                        :disabled="isDisabled" @change="closeChange('1')" v-model="closed1" type="checkbox"
                                        name="BusinessHours1_closed"></span>
                                <label for="BusinessHours1_closed" id="BusinessHours1_closedLbl"
                                    class="hoursCheckLbl hoursClosedLbl">Closed</label>
                            </span>
                        </span>
                        <div id="businessHours1Error" class="error hoursError" style="display:none;">Please select hours for this day.</div>
                    </div>
                </div>

            </div>

            <div class="hoursDay cf">
                <span class="req">*</span> <label>Tuesday</label>
                <div class="businessHoursInput cf">

                    <div class="hoursHours cf">
                        <legend>Hours of operation</legend>
                        <select v-model="hoursOpen2" :disabled="isDisabled" class="hoursSelect hoursHour"
                            @change="dropDownCahnge('2')">
                            <option value="" disabled>--</option>
                            <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                {{ option.display }}
                            </option>
                        </select>
                        <span class="hoursTo">to</span>
                        <select v-model="hoursClose2" :disabled="isDisabled" class="hoursSelect hoursHour"
                            @change="dropDownCahnge('2')">
                            <option value="" disabled>--</option>
                            <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                {{ option.display }}
                            </option>
                        </select>
                        <span class="hoursCheckboxes cf">
                            <span class="checkboxSet cf">
                                <span class="hoursCheck hoursOpen24"><input id="BusinessHours2_open24hours"
                                        :disabled="isDisabled" v-model="open2472" @change="openChange('2')" type="checkbox"
                                        name="BusinessHours2_open24hours"></span>
                                <label for="BusinessHours2_open24hours" id="BusinessHours2_open24hoursLbl"
                                    class="hoursCheckLbl hoursOpen24lbl">Open 24 hrs</label>
                            </span>

                            <span class="checkboxSet cf">
                                <span class="hoursCheck hoursClosed"><input id="BusinessHours2_closed"
                                        :disabled="isDisabled" @change="closeChange('2')" v-model="closed2" type="checkbox"
                                        name="BusinessHours2_closed"></span>
                                <label for="BusinessHours2_closed" id="BusinessHours2_closedLbl"
                                    class="hoursCheckLbl hoursClosedLbl">Closed</label>
                            </span>
                        </span>
                        <div id="businessHours2Error" class="error hoursError" style="display:none;"> Please select hours
                            for this day.</div>
                    </div>
                </div>

            </div>

            <div class="hoursDay cf">
                <span class="req">*</span> <label>Wednesday</label>

                <div class="businessHoursInput cf">

                    <div class="hoursHours cf">
                        <legend>Hours of operation</legend>
                        <select v-model="hoursOpen3" :disabled="isDisabled" class="hoursSelect hoursHour"
                            @change="dropDownCahnge('3')">
                            <option value="" disabled>--</option>
                            <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                {{ option.display }}
                            </option>
                        </select>
                        <span class="hoursTo">to</span>
                        <select v-model="hoursClose3" :disabled="isDisabled" class="hoursSelect hoursHour"
                            @change="dropDownCahnge('3')">
                            <option value="" disabled>--</option>
                            <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                {{ option.display }}
                            </option>
                        </select>
                        <span class="hoursCheckboxes cf">
                            <span class="checkboxSet cf">
                                <span class="hoursCheck hoursOpen24"><input id="BusinessHours3_open24hours"
                                        :disabled="isDisabled" type="checkbox" @change="openChange('3')" v-model="open2473"
                                        name="BusinessHours3_open24hours"></span>
                                <label for="BusinessHours3_open24hours" id="BusinessHours3_open24hoursLbl"
                                    class="hoursCheckLbl hoursOpen24lbl">Open 24 hrs</label>
                            </span>

                            <span class="checkboxSet cf">
                                <span class="hoursCheck hoursClosed"><input id="BusinessHours3_closed"
                                        :disabled="isDisabled" @change="closeChange('3')" v-model="closed3" type="checkbox"
                                        name="BusinessHours3_closed"></span>
                                <label for="BusinessHours3_closed" id="BusinessHours3_closedLbl"
                                    class="hoursCheckLbl hoursClosedLbl">Closed</label>
                            </span>
                        </span>
                        <div id="businessHours3Error" class="error hoursError" style="display:none;">
                            Please select hours for this day.
                        </div>
                    </div>
                </div>

            </div>

            <div class="hoursDay cf">
                <span class="req">*</span> <label>Thursday</label>

                <div class="businessHoursInput cf">
                    <div class="hoursHours cf">
                        <legend>Hours of operation</legend>
                        <select v-model="hoursOpen4" :disabled="isDisabled" class="hoursSelect hoursHour"
                            @change="dropDownCahnge('4')">
                            <option value="" disabled>--</option>
                            <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                {{ option.display }}
                            </option>
                        </select>
                        <span class="hoursTo">to</span>
                        <select v-model="hoursClose4" :disabled="isDisabled" class="hoursSelect hoursHour"
                            @change="dropDownCahnge('4')">
                            <option value="" disabled>--</option>
                            <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                {{ option.display }}
                            </option>
                        </select>
                        <span class="hoursCheckboxes cf">
                            <span class="checkboxSet cf">
                                <span class="hoursCheck hoursOpen24"><input id="BusinessHours4_open24hours"
                                        :disabled="isDisabled" type="checkbox" @change="openChange('4')" v-model="open2474"
                                        name="BusinessHours4_open24hours"></span>
                                <label for="BusinessHours4_open24hours" id="BusinessHours4_open24hoursLbl"
                                    class="hoursCheckLbl hoursOpen24lbl">Open 24 hrs</label>
                            </span>

                            <span class="checkboxSet cf">
                                <span class="hoursCheck hoursClosed"><input id="BusinessHours4_closed"
                                        :disabled="isDisabled" @change="closeChange('4')" v-model="closed4" type="checkbox"
                                        name="BusinessHours4_closed"></span>
                                <label for="BusinessHours4_closed" id="BusinessHours4_closedLbl"
                                    class="hoursCheckLbl hoursClosedLbl">Closed</label>
                            </span>
                        </span>
                        <div id="businessHours4Error" class="error hoursError" style="display:none;">
                            Please select hours for this day.
                        </div>
                    </div>
                </div>
            </div>

            <div class="hoursDay cf">
                <span class="req">*</span> <label>Friday</label>
                <div class="businessHoursInput cf">
                    <div class="hoursHours cf">
                        <legend>Hours of operation</legend>
                        <select v-model="hoursOpen5" :disabled="isDisabled" class="hoursSelect hoursHour"
                            @change="dropDownCahnge('5')">
                            <option value="" disabled>--</option>
                            <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                {{ option.display }}
                            </option>
                        </select>
                        <span class="hoursTo">to</span>
                        <select v-model="hoursClose5" :disabled="isDisabled" class="hoursSelect hoursHour"
                            @change="dropDownCahnge('5')">
                            <option value="" disabled>--</option>
                            <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                {{ option.display }}
                            </option>
                        </select>
                        <span class="hoursCheckboxes cf">
                            <span class="checkboxSet cf">
                                <span class="hoursCheck hoursOpen24"><input id="BusinessHours5_open24hours"
                                        :disabled="isDisabled" type="checkbox" @change="openChange('5')" v-model="open2475"
                                        name="BusinessHours5_open24hours"></span>
                                <label for="BusinessHours5_open24hours" id="BusinessHours5_open24hoursLbl"
                                    class="hoursCheckLbl hoursOpen24lbl">Open 24 hrs</label>
                            </span>

                            <span class="checkboxSet cf">
                                <span class="hoursCheck hoursClosed"><input id="BusinessHours5_closed"
                                        :disabled="isDisabled" @change="closeChange('5')" v-model="closed5" type="checkbox"
                                        name="BusinessHours5_closed"></span>
                                <label for="BusinessHours5_closed" id="BusinessHours5_closedLbl"
                                    class="hoursCheckLbl hoursClosedLbl">Closed</label>
                            </span>
                        </span>
                        <div id="businessHours5Error" class="error hoursError" style="display:none;">
                            Please select hours for this day.
                        </div>
                    </div>
                </div>
            </div>

            <div class="hoursDay cf">
                <span class="req">*</span> <label>Saturday</label>

                <div class="businessHoursInput cf">

                    <div class="hoursHours cf">
                        <legend>Hours of operation</legend>
                        <select v-model="hoursOpen6" :disabled="isDisabled" class="hoursSelect hoursHour"
                            @change="dropDownCahnge('6')">
                            <option value="" disabled>--</option>
                            <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                {{ option.display }}
                            </option>
                        </select>
                        <span class="hoursTo">to</span>
                        <select v-model="hoursClose6" :disabled="isDisabled" class="hoursSelect hoursHour"
                            @change="dropDownCahnge('6')">
                            <option value="" disabled>--</option>
                            <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                {{ option.display }}
                            </option>
                        </select>
                        <span class="hoursCheckboxes cf">
                            <span class="checkboxSet cf">
                                <span class="hoursCheck hoursOpen24"><input :disabled="isDisabled"
                                        id="BusinessHours6_open24hours" type="checkbox" @change="openChange('6')"
                                        v-model="open2476" name="BusinessHours6_open24hours"></span>
                                <label for="BusinessHours6_open24hours" id="BusinessHours6_open24hoursLbl"
                                    class="hoursCheckLbl hoursOpen24lbl">Open 24 hrs</label>
                            </span>

                            <span class="checkboxSet cf">
                                <span class="hoursCheck hoursClosed"><input :disabled="isDisabled"
                                        id="BusinessHours6_closed" @change="closeChange('6')" v-model="closed6"
                                        type="checkbox" name="BusinessHours6_closed"></span>
                                <label for="BusinessHours6_closed" id="BusinessHours6_closedLbl"
                                    class="hoursCheckLbl hoursClosedLbl">Closed</label>
                            </span>
                        </span>
                        <div id="businessHours6Error" class="error hoursError" style="display:none;">
                            Please select hours for this day.
                        </div>
                    </div>
                </div>
            </div>

            <div class="hoursDay cf">
                <span class="req">*</span> <label>Sunday</label>
                <div class="businessHoursInput cf">
                    <div class="hoursHours cf">
                        <legend>Hours of operation</legend>
                        <select v-model="hoursOpen7" :disabled="isDisabled" class="hoursSelect hoursHour"
                            @change="dropDownCahnge('7')">
                            <option value="" disabled>--</option>
                            <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                {{ option.display }}
                            </option>
                        </select>
                        <span class="hoursTo">to</span>
                        <select v-model="hoursClose7" :disabled="isDisabled" class="hoursSelect hoursHour"
                            @change="dropDownCahnge('7')">
                            <option value="" disabled>--</option>
                            <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                {{ option.display }}
                            </option>
                        </select>
                        <span class="hoursCheckboxes cf">
                            <span class="checkboxSet cf">
                                <span class="hoursCheck hoursOpen24"><input :disabled="isDisabled"
                                        id="BusinessHours7_open24hours" type="checkbox" @change="openChange('7')"
                                        v-model="open2477" name="BusinessHours7_open24hours"></span>
                                <label for="BusinessHours7_open24hours" id="BusinessHours7_open24hoursLbl"
                                    class="hoursCheckLbl hoursOpen24lbl">Open 24 hrs</label>
                            </span>

                            <span class="checkboxSet cf">
                                <span class="hoursCheck hoursClosed"><input :disabled="isDisabled"
                                        id="BusinessHours7_closed" @change="closeChange('7')" v-model="closed7"
                                        type="checkbox" name="BusinessHours7_closed"></span>
                                <label for="BusinessHours7_closed" id="BusinessHours7_closedLbl"
                                    class="hoursCheckLbl hoursClosedLbl">Closed</label>
                            </span>
                        </span>
                        <div id="businessHours7Error" class="error hoursError" style="display:none;">
                            Please select hours for this day.
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="btn-actions">
            <input type="button" name="" @click="onSaveClick('back')" value="Save &amp; Back" class=" btn-primary btnBack"
                title="Save the document and return to previous page">
            <input type="button" name="" @click="onSaveClick('next')" value="Save &amp; Next" class=" btn-primary btnNext"
                title="Save the document and proceed to next page">
        </div>
    </div>
</template>
<script>
export default {
    props: {
        businessInfoJson: String,
        readOnly: Boolean
    },
    data() {
        return {
            hoursOpen1: '',
            hoursClose1: '',
            closed1: false,
            open2471: false,
            hoursOpen2: '',
            hoursClose2: '',
            closed2: false,
            open2472: false,
            hoursOpen3: '',
            hoursClose3: '',
            closed3: false,
            open2473: false,
            hoursOpen4: '',
            hoursClose4: '',
            closed4: false,
            open2474: false,
            hoursOpen5: '',
            hoursClose5: '',
            closed5: false,
            open2475: false,
            hoursOpen6: '',
            hoursClose6: '',
            closed6: false,
            open2476: false,
            hoursOpen7: '',
            hoursClose7: '',
            closed7: false,
            open2477: false,
            hoursOptions:
                [
                    { hr: "00:00", display: "12:00 AM" },
                    { hr: "00:30", display: "12:30 AM" },
                    { hr: "01:00", display: "1:00 AM" },
                    { hr: "01:30", display: "1:30 AM" },
                    { hr: "02:00", display: "2:00 AM" },
                    { hr: "02:30", display: "2:30 AM" },
                    { hr: "03:00", display: "3:00 AM" },
                    { hr: "03:30", display: "3:30 AM" },
                    { hr: "04:00", display: "4:00 AM" },
                    { hr: "04:30", display: "4:30 AM" },
                    { hr: "05:00", display: "5:00 AM" },
                    { hr: "05:30", display: "5:30 AM" },
                    { hr: "06:00", display: "6:00 AM" },
                    { hr: "06:30", display: "6:30 AM" },
                    { hr: "07:00", display: "7:00 AM" },
                    { hr: "07:30", display: "7:30 AM" },
                    { hr: "08:00", display: "8:00 AM" },
                    { hr: "08:30", display: "8:30 AM" },
                    { hr: "09:00", display: "9:00 AM" },
                    { hr: "09:30", display: "9:30 AM" },
                    { hr: "10:00", display: "10:00 AM" },
                    { hr: "10:30", display: "10:30 AM" },
                    { hr: "11:00", display: "11:00 AM" },
                    { hr: "11:30", display: "11:30 AM" },
                    { hr: "12:00", display: "12:00 PM" },
                    { hr: "12:30", display: "12:30 PM" },
                    { hr: "13:00", display: "1:00 PM" },
                    { hr: "13:30", display: "1:30 PM" },
                    { hr: "14:00", display: "2:00 PM" },
                    { hr: "14:30", display: "2:30 PM" },
                    { hr: "15:00", display: "3:00 PM" },
                    { hr: "15:30", display: "3:30 PM" },
                    { hr: "16:00", display: "4:00 PM" },
                    { hr: "16:30", display: "4:30 PM" },
                    { hr: "17:00", display: "5:00 PM" },
                    { hr: "17:30", display: "5:30 PM" },
                    { hr: "18:00", display: "6:00 PM" },
                    { hr: "18:30", display: "6:30 PM" },
                    { hr: "19:00", display: "7:00 PM" },
                    { hr: "19:30", display: "7:30 PM" },
                    { hr: "20:00", display: "8:00 PM" },
                    { hr: "20:30", display: "8:30 PM" },
                    { hr: "21:00", display: "9:00 PM" },
                    { hr: "21:30", display: "9:30 PM" },
                    { hr: "22:00", display: "10:00 PM" },
                    { hr: "22:30", display: "10:30 PM" },
                    { hr: "23:00", display: "11:00 PM" },
                    { hr: "23:30", display: "11:30 PM" },
                ],
            businessInfo: {},
            isDisabled: false

        }
    },
    mounted: function () {
        this.businessInfo = JSON.parse(this.businessInfoJson);
        this.parseHours();
        this.isDisabled = this.readOnly;
        this.openChange()
    },
    methods: {
        onSaveClick(dir) {
            this.updateBusinessHours();
            let request = {};
            request.dir = dir;
            request.businessInformation = this.businessInfo;
            this.$emit('onSaveClick', request);
        },
        dropDownCahnge(hoursNum) {
            switch (hoursNum) {
                case "1":
                    this.open2471 = false;
                    this.closed1 = false;

                    break;
                case "2":
                    this.open2472 = false;
                    this.closed2 = false;
                    break;
                case "3":
                    this.open2473 = false;
                    this.closed3 = false;
                    break;
                case "4":
                    this.open2474 = false;
                    this.closed4 = false;
                    break;
                case "5":
                    this.open2475 = false;
                    this.closed5 = false;
                    break;
                case "6":
                    this.open2476 = false;
                    this.closed6 = false;
                    break;
                case "7":
                    this.open2477 = false;
                    this.closed7 = false;
                    break;
                default:
                    break;
            }

        },
        openChange(hoursNum) {
            switch (hoursNum) {
                case "1":
                    if (this.open2471) {
                        this.hoursOpen1 = ''
                        this.hoursClose1 = '';
                        this.closed1 = false;
                    }
                    break;
                case "2":
                    if (this.open2472) {
                        this.hoursOpen2 = ''
                        this.hoursClose2 = '';
                        this.closed2 = false;
                    }
                    break;
                case "3":
                    if (this.open2473) {
                        this.hoursOpen3 = ''
                        this.hoursClose3 = '';
                        this.closed3 = false;
                    }
                    break;
                case "4":
                    if (this.open2474) {
                        this.hoursOpen4 = ''
                        this.hoursClose4 = '';
                        this.closed4 = false;
                    }
                    break;
                case "5":
                    if (this.open2475) {
                        this.hoursOpen5 = ''
                        this.hoursClose5 = '';
                        this.closed5 = false;
                    }
                    break;
                case "6":
                    if (this.open2476) {
                        this.hoursOpen6 = ''
                        this.hoursClose6 = '';
                        this.closed6 = false;
                    }
                    break;
                case "7":
                    if (this.open2477) {
                        this.hoursOpen7 = ''
                        this.hoursClose7 = '';
                        this.closed7 = false;
                    }
                    break;
                default:
                    break;
            }

        },
        closeChange(hoursNum) {
            switch (hoursNum) {
                case "1":
                    if (this.closed1) {
                        this.hoursOpen1 = ''
                        this.hoursClose1 = '';
                        this.open2471 = false;
                    }
                    break;
                case "2":
                    if (this.closed2) {
                        this.hoursOpen2 = ''
                        this.hoursClose2 = '';
                        this.open2472 = false;
                    }
                    break;
                case "3":
                    if (this.closed3) {
                        this.hoursOpen3 = ''
                        this.hoursClose3 = '';
                        this.open2473 = false;
                    }
                    break;
                case "4":
                    if (this.closed4) {
                        this.hoursOpen4 = ''
                        this.hoursClose4 = '';
                        this.open2474 = false;
                    }
                    break;
                case "5":
                    if (this.closed5) {
                        this.hoursOpen5 = ''
                        this.hoursClose5 = '';
                        this.open2475 = false;
                    }
                    break;
                case "6":
                    if (this.closed6) {
                        this.hoursOpen6 = ''
                        this.hoursClose6 = '';
                        this.open2476 = false;
                    }
                    break;
                case "7":
                    if (this.closed7) {
                        this.hoursOpen7 = ''
                        this.hoursClose7 = '';
                        this.open2477 = false;
                    }
                    break;
                default:
                    break;
            }
        },
        updateBusinessHours() {
            let b1 = {};
            b1.OpenHour = this.hoursOpen1;
            b1.CloseHour = this.hoursClose1;
            b1.Open24hours = this.open2471;
            b1.Closed = this.closed1;
            this.businessInfo.businessHours1 = JSON.stringify(b1);


            let b2 = {};
            b2.OpenHour = this.hoursOpen2;
            b2.CloseHour = this.hoursClose2;
            b2.Open24hours = this.open2472;
            b2.Closed = this.closed2;
            this.businessInfo.businessHours2 = JSON.stringify(b2);


            let b3 = {};
            b3.OpenHour = this.hoursOpen3;
            b3.CloseHour = this.hoursClose3;
            b3.Open24hours = this.open2473;
            b3.Closed = this.closed3;
            this.businessInfo.businessHours3 = JSON.stringify(b3);


            let b4 = {};
            b4.OpenHour = this.hoursOpen4;
            b4.CloseHour = this.hoursClose4;
            b4.Open24hours = this.open2474;
            b4.Closed = this.closed4;
            this.businessInfo.businessHours4 = JSON.stringify(b4);

            let b5 = {};
            b5.OpenHour = this.hoursOpen5;
            b5.CloseHour = this.hoursClose5;
            b5.Open24hours = this.open2475;
            b5.Closed = this.closed5;
            this.businessInfo.businessHours5 = JSON.stringify(b5);

            let b6 = {};
            b6.OpenHour = this.hoursOpen6;
            b6.CloseHour = this.hoursClose6;
            b6.Open24hours = this.open2476;
            b6.Closed = this.closed6;
            this.businessInfo.businessHours6 = JSON.stringify(b6);

            let b7 = {};
            b7.OpenHour = this.hoursOpen7;
            b7.CloseHour = this.hoursClose7;
            b7.Open24hours = this.open2477;
            b7.Closed = this.closed7;
            this.businessInfo.businessHours7 = JSON.stringify(b7);
        },
        parseHours() {
            if (this.businessInfo.businessHours1) {
                let hours1 = JSON.parse(this.businessInfo.businessHours1);
                this.hoursOpen1 = hours1.OpenHour;
                this.hoursClose1 = hours1.CloseHour;
                this.open2471 = hours1.Open24hours;
                this.closed1 = hours1.Closed;
            }
            if (this.businessInfo.businessHours2) {
                let hours2 = JSON.parse(this.businessInfo.businessHours2);
                this.hoursOpen2 = hours2.OpenHour;
                this.hoursClose2 = hours2.CloseHour;
                this.open2472 = hours2.Open24hours;
                this.closed2 = hours2.Closed;

            }
            if (this.businessInfo.businessHours3) {
                let hours3 = JSON.parse(this.businessInfo.businessHours3);
                this.hoursOpen3 = hours3.OpenHour;
                this.hoursClose3 = hours3.CloseHour;
                this.open2473 = hours3.Open24hours;
                this.closed3 = hours3.Closed;

            }
            if (this.businessInfo.businessHours4) {
                let hours4 = JSON.parse(this.businessInfo.businessHours4);
                this.hoursOpen4 = hours4.OpenHour;
                this.hoursClose4 = hours4.CloseHour;
                this.open2474 = hours4.Open24hours;
                this.closed4 = hours4.Closed;

            }
            if (this.businessInfo.businessHours5) {
                let hours5 = JSON.parse(this.businessInfo.businessHours5);
                this.hoursOpen5 = hours5.OpenHour;
                this.hoursClose5 = hours5.CloseHour;
                this.open2475 = hours5.Open24hours;
                this.closed5 = hours5.Closed;

            }
            if (this.businessInfo.businessHours6) {
                let hours6 = JSON.parse(this.businessInfo.businessHours6);
                this.hoursOpen6 = hours6.OpenHour;
                this.hoursClose6 = hours6.CloseHour;
                this.open2476 = hours6.Open24hours;
                this.closed6 = hours6.Closed;

            }
            if (this.businessInfo.businessHours7) {
                let hours7 = JSON.parse(this.businessInfo.businessHours7);
                this.hoursOpen7 = hours7.OpenHour;
                this.hoursClose7 = hours7.CloseHour;
                this.open2477 = hours7.Open24hours;
                this.closed7 = hours7.Closed;
            }
        }
    }
}
</script>