<template>
    <div>
        <!-- <button class="button" @click="getString">Test URL</button> -->
    </div>
</template>


<script>

import $ from 'jquery';

export default {
    data() {
        return {
            string: 'fromEmail',
            input: 'fromEmail_Value'
        };
    },

    mounted() {
        // console.log(showBullets);
        this.getString();
    },
    methods: {
        getString() {
            let string = this.getURLQuery(this.string),
                input = document.querySelector('[id*=' + this.input + ']');            

            if (string != false) {
                input.value = string;
            }
        }
    }
}
</script>
<style lang="scss" scoped>

</style>