<template>
    <div class="cf eAppFormEdit eapp_w9information">       

        <div class="formRow cf" style="padding-bottom:20px;">
            <label for="ddlPrincipalList">Legal Contact: </label>
            <select v-model="tax.w9AssociatedPrincipalID" v-if="!principalOptions.length" id="ddlPrincipalList" CSSClass="selectPrincipal" @change="dropDownChange()" :disabled="isDisabled">
                <option value="" selected="selected">Select a Principal</option>
                <option value="0">Use a different contact</option>
            </select>
            <select v-model="tax.w9AssociatedPrincipalID" v-if="principalOptions.length" id="ddlPrincipalList" CSSClass="selectPrincipal" @change="dropDownChange()" :disabled="isDisabled">
                <option value="undefined">Select a Principal</option>
                <option value="0">Use a different contact</option>
                <option v-for="(option, index) in principalOptions" :value="option.value" v-bind:key="index">
                    {{ option.text }}
                </option>
            </select>
            <span v-if="!validPrincipalSelected" class="error">Please select a principal or add a different legal contact</span>
            <p>This address is where your tax information will be mailed.</p>
        </div>
        <!--<div class="formRow checkboxRow cf dividerRow">
            <input v-model="useDifferentContact" type="checkbox" id="chkDifferentLegalContact" @change="checkboxChange()" class="toggleSwitch" />
            <label for="chkDifferentLegalContact">Use a different Legal Contact</label>
        </div>-->
        <div v-if="useDifferentContact" class="togglePanel grid-x grid-margin-x grid-margin-y dividerRow formRow">
            <div class="formRow cell medium-6">
                <span class="req">*</span> 
                <label for="txbxFirstName">First Name</label>
                <div class="formRowRight cf">
                    <input v-model="tax.w9LegalContactFirstName" :disabled="isDisabled" id="txbxFirstName" type="text" maxlength="200">
                    <span v-if="!validLegalFirstName" class="error">Please add a first name</span>
                </div>
            </div>
            <div class="formRow cell medium-6">
                <span class="req">*</span> 
                <label for="txbxLastName">Last Name:</label>
                <div class="formRowRight cf">
                    <input v-model="tax.w9LegalContactLastName" :disabled="isDisabled" type="text" maxlength="200" id="txbxLastName">
                    <span v-if="!validLegalLastName" class="error">Please add a last name</span>
                </div>
            </div>
            <div class="formRow cell">
                <span class="req">*</span> 
                <label for="w9LegalContactStreetAddress">Street Address:</label>
                <div class="formRowRight cf">
                    <input v-model="tax.w9LegalContactStreetAddress" :disabled="isDisabled" type="text" maxlength="200" id="w9LegalContactStreetAddress" />
                    <span v-if="!validLegalStreet" class="error">Please add a Street Address</span>
                </div>
            </div>
            <div class="formRow cell">
                <span class="req">*</span> 
                <label for="txbxCity">City:</label>
                <div class="formRowRight cf">
                    <input v-model="tax.w9LegalContactCity" :disabled="isDisabled" id="txbxCity" type="text" maxlength="200">
                    <span v-if="!validLegalCity" class="error">Please add a City</span>
                </div>
            </div>
            <div class="formRow cell medium-6">
                <span class="req">*</span> 
                <label id="lblStates">State:</label>
                <select v-model="tax.w9LegalContactState" :disabled="isDisabled" name="W9State_dropDownList" id="W9LegalState_dropDownList" aria-describedby="W9LegalState_error">
                    <option value="null">State</option>
                    <option value="AL"> AL</option>
                    <option value="AK"> AK</option>
                    <option value="AZ"> AZ</option>
                    <option value="AR"> AR</option>
                    <option value="CA"> CA</option>
                    <option value="CO"> CO</option>
                    <option value="CT"> CT</option>
                    <option value="DE"> DE</option>
                    <option value="FL"> FL</option>
                    <option value="GA"> GA</option>
                    <option value="HI"> HI</option>
                    <option value="ID"> ID</option>
                    <option value="IL"> IL</option>
                    <option value="IN"> IN</option>
                    <option value="IA"> IA</option>
                    <option value="KS"> KS</option>
                    <option value="KY"> KY</option>
                    <option value="LA"> LA</option>
                    <option value="ME"> ME</option>
                    <option value="MD"> MD</option>
                    <option value="MA"> MA</option>
                    <option value="MI"> MI</option>
                    <option value="MN"> MN</option>
                    <option value="MS"> MS</option>
                    <option value="MO"> MO</option>
                    <option value="MT"> MT</option>
                    <option value="NE"> NE</option>
                    <option value="NV"> NV</option>
                    <option value="NH"> NH</option>
                    <option value="NJ"> NJ</option>
                    <option value="NM"> NM</option>
                    <option value="NY"> NY</option>
                    <option value="NC"> NC</option>
                    <option value="ND"> ND</option>
                    <option value="OH"> OH</option>
                    <option value="OK"> OK</option>
                    <option value="OR"> OR</option>
                    <option value="PA"> PA</option>
                    <option value="RI"> RI</option>
                    <option value="SC"> SC</option>
                    <option value="SD"> SD</option>
                    <option value="TN"> TN</option>
                    <option value="TX"> TX</option>
                    <option value="UT"> UT</option>
                    <option value="VT"> VT</option>
                    <option value="VA"> VA</option>
                    <option value="WA"> WA</option>
                    <option value="DC"> DC</option>
                    <option value="WV"> WV</option>
                    <option value="WI"> WI</option>
                    <option value="WY"> WY</option>
                </select>

                <div v-if="!validLegalState" class="error" role="alert">Please select a state.</div>
            </div>
            <div class="formRow zipFields cell medium-6">
                <span class="req">*</span> 
                <label for="txbxZip">Zip Code:</label>
                <div class="formRowRight cf">
                    <input v-model="tax.w9LegalContactZip" :disabled="isDisabled" id="txbxZip" maxlength="5">
                    <div v-if="!validLegalZip" class="error">Please add a Zip Code</div>
                </div>
            </div>
        </div>

        <div class="formRow checkboxRow cf">
            <span class="req">*</span>
            <input type="checkbox" v-model="tax.w9AgreeFederalCertificationRequirements" :disabled="isDisabled" id="chkAgree" />
            <label for="chkAgree">I have read and agree to the following Federal Terms:</label>
            <div v-if="!validSigCert" class="error">Please accept that you have read and agree the following Federal Terms</div>
            <ol>
                <li>The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and </li>
                <li>I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and </li>
                <li>I am a U.S. citizen or other U.S. person (defined below); and </li>
                <li>The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.</li>
            </ol>
        </div>
        <div class="formRow checkboxRow cf">
            <span class='req'>*</span>
            <input v-model="tax.w9SignatureCertification" type="checkbox" :disabled="isDisabled" id="chkSignature" />
            <label for="chkSignature">I acknowledge my digital signature will be added to this document at time of submission</label>
            <div v-if="!validSigAck" class="error">Please acknowledge that your digital signature will be added to this document at time of submission.</div>
        </div>

        <div class="btn-actions">
            <button type="button" @click="saveBack()" class=" btn-primary btnBack" title="Save the document and return to previous page">Save &amp; Back</button>
            <button type="button" @click="saveNext()" class=" btn-primary btnNext" title="Save the document and proceed to next page">Save &amp; Next</button>
        </div>

    </div>
</template>

<script>

    export default {
        components: {},
        props: {
            taxJson: String,
            principalsJson: String,
            readOnly: Boolean,
            applicationInfoJson: String
        },
        data: function () {
            return {
                tax: {},
                principals: '',
                principalOptions: [],
                validFormTwo: true,
                validPrincipalSelected: true,
                useDifferentContact: false,
                validLegalFirstName: true,
                validLegalLastName: true,
                validLegalStreet: true,
                validLegalZip: true,
                validLegalCity: true,
                validLegalState: true,
                validSigCert: true,
                validSigAck: true,
                isDisabled: false,
                token: ''
            };
        },
        mounted: function () {
            this.deserializeJson();
            this.isDisabled = this.readOnly;
            this.getToken();
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.getPrincipals();
                });
            },
            deserializeJson() {
                this.tax = JSON.parse(this.taxJson);
                this.application = JSON.parse(this.applicationInfoJson);
                if (this.tax.w9AssociatedPrincipalID == null) {
                    this.tax.w9AssociatedPrincipalID = '';
                }
                // this.createPrincipalDropDown();
                // this.dropDownChange();
                this.tax.w9SignatureCertification = null;
            },
            dropDownChange() {
                let id = parseInt(this.tax.w9AssociatedPrincipalID);
                if (id > 0) {
                    this.useDifferentContact = false;
                    this.tax.w9LegalContactFirstName = '';
                    this.tax.w9LegalContactLastName = '';
                    this.tax.w9LegalContactStreetAddress = '';
                    this.tax.w9LegalContactZip = '';
                    this.tax.w9LegalContactCity = '';
                    this.tax.w9LegalContactState = '';

                    this.tax.w9DifferentLegalContact = false;
                }
                else if (id == 0) {
                    this.useDifferentContact = true;
                    this.tax.w9DifferentLegalContact = true;
                }
            },
            validate() {
                return this.validateStepTwo();
            },
            saveNext() {
                if (this.validate()) {
                    this.$emit('onTaxSaveClick', { tax: this.tax, dir: 'next' });
                }
            },
            saveBack() {
                if (this.validate()) {
                     this.$emit('onTaxSaveClick', { tax: this.tax, dir: 'back' });
                }
            },
            validateStepTwo() {
                let validContact = false;
                this.validLegalFirstName = !!this.tax.w9LegalContactFirstName;
                this.validLegalLastName = !!this.tax.w9LegalContactLastName;
                this.validStreet = !!this.tax.w9LegalContactStreetAddress;
                this.validLegalZip = !!this.tax.w9LegalContactZip;
                this.validLegalCity = !!this.tax.w9LegalContactCity;
                this.validLegalState = !!this.tax.w9LegalContactState;
                this.validSigCert = !!this.tax.w9AgreeFederalCertificationRequirements;
                this.validSigAck = !!this.tax.w9SignatureCertification;

                validContact = this.validLegalFirstName
                    && this.validLegalLastName
                    && this.validStreet
                    && this.validLegalZip
                    && this.validLegalCity
                    && this.validLegalState
                   

                this.validPrincipalSelected = parseInt(this.tax.w9AssociatedPrincipalID) > 0;

                this.validFormTwo = (this.validPrincipalSelected || (this.useDifferentContact && validContact)) && this.validSigCert && this.validSigAck;
                return this.validFormTwo;
            },
            createPrincipalDropDown() {
                this.principals.forEach((p) => {
                    let pName = p.principalFirstName + ' ' + p.principalLastName;
                    let id = p.principalInformationID;
                    let principalOption = { value: id, text: pName };
                    this.principalOptions.push(principalOption);
                });

                this.dropDownChange();

                let select = document.getElementById('ddlPrincipalList');

                setTimeout(() => {
                    select.value = "undefined";
                }, 400)
            },
            getPrincipals() {
                if (this.application.applicationIspartOfChain === true && this.application.applicationChainIsCoorporate === false) {
                    let url = process.env.VUE_APP_EAPPAPIURL + '/1.0/RegularApplication/GetPrincipals/' + this.application.applicationMasterChain,
                        apiToken = this.token;

                    fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken
                        }
                    })
                    .then((response) => response.json())
                    .then((json) => {
                        this.principals = json.data;

                        this.createPrincipalDropDown();
                    });
                } else {
                    this.principals = JSON.parse(this.principalsJson);

                    this.createPrincipalDropDown();
                }
            }
        },
        computed: {}
    };
</script>
