<template>
    <div class="drawing-box">

        <a :href="drawing.nodeAliasPath" v-if="!kycMode">
            <img :src="drawing.image" alt="">
        </a>
        <a :href="drawing.nodeAliasPath" v-if="kycMode && isKYCVerified">
            <img :src="drawing.image" alt="">
        </a>
        <a @click="handleClick" v-if="kycMode && !isKYCVerified">
            <img :src="drawing.image" alt="">
        </a>

        <a :href="drawing.nodeAliasPath" class="drawingTtl" v-if="!kycMode">
            {{ drawing.name }}
        </a>
        <a :href="drawing.nodeAliasPath" class="drawingTtl" v-if="kycMode && isKYCVerified">
            {{ drawing.name }}
        </a>
        <a @click="handleClick" class="drawingTtl" v-if="kycMode && !isKYCVerified">
            {{ drawing.name }}
        </a>


        <span class="drawingPts">
            {{ drawing.price }} Points
        </span>
        <span class="drawingPeriod">
            {{ drawing.parameter3 }} - {{ drawing.parameter4 }}
        </span>

        <div id="modal-kyc" class="reveal modal-kyc reveal-modal" data-reveal data-close-on-click="false" data-close-on-esc="false">
            <h2>Please verify your account</h2>
            <p>We have updated security for MyLotto Rewards. You must verify your ID before making any transactions using your MyLotto Rewards points.</p>
            <div class="modal-kyc_actions">
                <a @click="goVerify" class="button btn-primary">Verify My Id</a>
                <button type="button" class="button btn-primary" data-close="modal-kyc">I'll do it later</button>
            </div>
        </div>
        
    </div>
</template>

<script>
    import $ from 'jquery';
    import Foundation from "@/foundation-sites/js/foundation.js";

    export default {
        props: ['drawing'],
        data: function() {
            return {
                kycMode: process.env.VUE_APP_KYCMODE,
                isKYCVerified: false
            }
        },
        mounted: function() {
            console.log(this.drawing)
            this.getKYCVerified();
        },
        methods: {
            handleClick() {
               //should only run when kycMode is true and isKYCVerified is false
               console.log("kycMode is true and isKYCVerified is false");
               this.openKYCModal();
            },
            openKYCModal() {
                let popup = new Foundation.Reveal($('#modal-kyc'));
                popup.open();
            },
            getKYCVerified() {
                let user = this.$cookies.get("apiToken");
                let url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetUserKYCStatus/?userId=' + user.user.userId;
                
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + user.token
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json.data);
                    this.isKYCVerified = json.data.userIsVerified;
                    // this.verifyLink = json.data.kycVerificationLink;
                    // this.kyc = true;

                    if (!this.kycMode) {
                        this.isKYCVerified = true;
                    }

                    console.log(`KYC : ${this.isKYCVerified}`);
                    // console.log(`Link URL : ${this.verifyLink}`);
                })
                .catch((error) => {
                    return (this.errorMessage = error)
                })
            },
            goVerify() {
                //set the return link into a cookie
                //go to verify page
                let goURL = window.location.origin + "/mylotto-rewards/my-account/verify-my-identity";
                this.$cookies.set("kycReturnUrl", JSON.stringify(window.location));
                window.location.assign(goURL);

            }
        }
    }
</script>