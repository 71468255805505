<template>
    <div>
        <section v-if="products.length" class="catalogList">
            <span>
                <span v-for="product in products" :key="product.skuNumber" >
                    <div class="productItem cf">
                        <a :href="product.nodeAliasPath" class="productImg" v-if="!kycMode">
                            <img :src="product.image" :alt="product.name">
                        </a>
                        <a :href="product.nodeAliasPath" class="productImg" v-if="kycMode && isKycVerified">
                            <img :src="product.image" :alt="product.name">
                        </a>
                        <a class="productImg" v-if="kycMode && !isKycVerified" @click="handleClick">
                            <img :src="product.image" :alt="product.name">
                        </a>
                        <article class="productInfo">
                            <a :href="product.nodeAliasPath" class="productTitle" v-if="!kycMode">
                                {{product.name}}
                            </a>
                            <a class="productTitle" :href="product.nodeAliasPath" v-if="kycMode && isKycVerified">
                                {{product.name}}
                            </a>
                            <a class="productTitle" @click="handleClick" v-if="kycMode && !isKycVerified">
                                {{product.name}}
                            </a>
                            <span class="productPrice">
                                {{product.price}} Points
                            </span>
                        </article>
                    </div>
                </span>
            </span>
        </section>
        <section v-if="!products.length" class="noProductsAvailable">
            No products currently available. Please check back soon!
        </section>
        <span class="note red">* Point costs are subject to change</span>
        <span class="note red">* The merchants represented are not sponsors of the rewards or otherwise affiliated with The Ohio Lottery. The logos and other identifying marks attached are trademarks of and owned by each represented company and/or its affiliates. Please visit each company's website for additional terms and conditions.</span>
        <br/>
        <AdditionalRewardsVue></AdditionalRewardsVue>
        <div id="modal-kyc" class="reveal modal-kyc reveal-modal" data-reveal data-close-on-click="false" data-close-on-esc="false">
            <h2>Please verify your account</h2>
            <p>We have updated security for MyLotto Rewards. You must verify your ID before making any transactions using your MyLotto Rewards points.</p>
            <div class="modal-kyc_actions">
                <a @click="goVerify" class="button btn-primary">Verify My Id</a>
                <button type="button" class="button btn-primary" data-close="modal-kyc">I'll do it later</button>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    import Foundation from "@/foundation-sites/js/foundation.js";
    import AdditionalRewardsVue from '../AdditionalRewards.vue'

    export default {
        components: [
            AdditionalRewardsVue
        ],
        data: function() {
            return {
                token: '',
                products: [],
                games: [
                    {
                        name: 1,
                        id: 2
                    }
                ],
                kycMode: process.env.VUE_APP_KYCMODE,
                isKycVerified: false,
                user: '',
                userId: ''
            }
        },
        mounted: function() {
            this.getToken();
        },
        methods: {
            getToken() {
                console.log('getting token')
                this.getAPItoken()
                .then((token) => {
                    this.getProducts(token);
                    this.user = this.$cookies.get('apiToken');
                    // this.isKycVerified = this.user.user.KYCVerified; //KYC Verified Check needs enabled once returning t/f
                    console.log(this.user);
                    console.log(this.isKycVerified);
                })
                .then(() => {
                    this.getKYCVerified();
                });
            },
            getProducts(token) {
                console.log("Getting Products")
                let apiToken = token,
                    urlProtocol = this.getProtocol(),
                    url = process.env.VUE_APP_APIURL+'/1.0/Ecommerce/GetProductList?type=3&top=100';

                return fetch(urlProtocol +  url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + apiToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    this.setStock(json.data);
                })
                .catch(error => {
                    console.log(this.errorMessage = error);
                })
            },
            setStock(json) {
                let data = json,
                    products = [];

                for (let i = 0; i < data.length; i++) {
                    data[i].stock > 0 ? products.push(data[i]) : false;
                }

                this.products = products;
            },
            getKYCVerified() {
                let url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetUserKYCStatus/?userId=' + this.user.user.userId;
                
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.user.token
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json.data);
                    this.isKycVerified = json.data.userIsVerified;
                    // this.verifyLink = json.data.kycVerificationLink;
                    // this.kyc = true;

                    if (!this.kycMode) {
                        this.isKycVerified = true;
                    }

                    console.log(`KYC : ${this.isKycVerified}`);
                    // console.log(`Link URL : ${this.verifyLink}`);
                })
                .catch((error) => {
                    return (this.errorMessage = error)
                })
            },
            handleClick() {
               //should only run when kycMode is true and isKYCVerified is false
               console.log("kycMode is true and isKYCVerified is false");
               this.openKYCModal();
            },
            openKYCModal() {
                let popup = new Foundation.Reveal($('#modal-kyc'));
                popup.open();
            },
            goVerify() {
                //set the return link into a cookie
                //go to verify page
                let goURL = window.location.origin + "/mylotto-rewards/my-account/verify-my-identity";
                this.$cookies.set("kycReturnUrl", JSON.stringify(window.location));
                window.location.assign(goURL);

            }
        }
    }
</script>