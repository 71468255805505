<template>
    <form id="contactsForm" data-abide data-validate-on-blur="true" class="eAppModule eAppContactInfoEdit">
        <h4>Primary Contact</h4>

        <div class="EditingFormError" style="display: none;">

        </div>

        <div class="eAppForm primarycontactForm grid-x grid-margin-x grid-margin-y">
            <div class="formRow cell medium-6">
                <span class="req">*</span>
                <label for="PrimaryContactName">Primary Contact Name:</label>
                <input v-model="contactInfo.primaryContactName" :disabled="isDisabled" name="PrimaryContactName" type="text" maxlength="200" id="PrimaryContactName" required>
                <div id="primaryContactNameError" class="form-error">Please provide a Primary Contact Name</div>
            </div>
            <div class="formRow cell medium-6">
                <span class="req">*</span>
                <label for="PrimaryContactTitle">Primary Contact Title:</label>
                <input v-model="contactInfo.primaryContactTitle" :disabled="isDisabled" name="PrimaryContactTitle" type="text" maxlength="200" id="PrimaryContactTitle" required>
                <div id="primaryContactTitleError" class="form-error">Please provide a Primary Contact Title</div>
            </div>
            <div class="formRow cell medium-6">
                <span class="req">*</span>
                <label for="PrimaryContactEmail">Primary Contact E-mail:</label>
                <input v-model="contactInfo.primaryContactEmail" :disabled="isDisabled" name="PrimaryContactEmail" type="text" maxlength="254" id="PrimaryContactEmail" required pattern="email">
                <div id="primaryContactEmailError" class="form-error">Please provide a valid Primary Contact Email</div>
            </div>
            <div class="formRow phoneField cell medium-6">
                <span class="req">*</span>
                <label for="PrimaryContactPhone">Primary Contact Phone:</label>
                <input v-model="contactInfo.primaryContactPhone" :disabled="isDisabled" name="PrimaryContactPhone" type="tel" maxlength="12" id="PrimaryContactPhone" placeholder="xxx-xxx-xxxx" required>
                <div id="primaryContactPhoneError" class="form-error">Please provide a valid Primary Contact Phone</div>
                <!-- pattern="^\(?([0-9]{3})\)?[\-. ]?([0-9]{3})[\-. ]?([0-9]{4})$"  -->
            </div>
            <div class="formRow radioRow sportsGaming cell">
                <span class="req">*</span>
                <label>Are you interested in selling Lottery Sports Gaming?</label> 
                <input id="PrincipalAnyOffense_0" v-model="contactInfo.sportsGamingInterest" @click="principalAnyOffenseClick(0)" type="radio" name="PrincipalAnyOffense" value="false">
                <label for="PrincipalAnyOffense_0">No</label>
                <br>
                <input id="PrincipalAnyOffense_1" v-model="contactInfo.sportsGamingInterest" @click="principalAnyOffenseClick(1)" type="radio" name="PrincipalAnyOffense" value="true">
                <label for="PrincipalAnyOffense_1">Yes</label>
                <div class="error" v-if="sportsGamingError"><br>Please select an answer.</div>
            </div>
            <div class="formRow checkboxRow cell addSecondaryRow">
                <input v-model="contactInfo.secondaryContactInfo" :disabled="isDisabled" @change="secondaryContactInfoClick()" id="SecondaryContactInfo" type="checkbox" name="SecondaryContactInfo">
                <label for="SecondaryContactInfo">Add a Secondary Contact</label>
            </div>
        </div>
        <div v-if="contactInfo.secondaryContactInfo" class="eAppForm secondaryContactForm grid-x grid-margin-x grid-margin-y">
            <h4 class="cell">Secondary Contact</h4>
            <div class="formRow cell medium-6">
                <span class="req">*</span>
                <label for="SecondaryContactName">Secondary Contact Name:</label>
                <input v-model="contactInfo.secondaryContactName" :disabled="isDisabled" name="SecondaryContactName" type="text" maxlength="200" id="SecondaryContactName" >
                <div id="secondaryContactNameError" class="form-error">Please provide a Secondary Contact Name</div>
            </div>
            <div class="formRow cell medium-6">
                <span class="req">*</span>
                <label for="SecondaryContactTitle">Secondary Contact Title:</label>
                <input v-model="contactInfo.secondaryContactTitle" :disabled="isDisabled" name="SecondaryContactTitle" type="text" maxlength="200" id="SecondaryContactTitle">
                <div id="secondaryContactTitleError" class="form-error">Please provide a Secondary Contact Title</div>
            </div>
            <div class="formRow cell medium-6">
                <span class="req">*</span>
                <label for="SecondaryContactEmail">Secondary Contact E-mail:</label>
                <input v-model="contactInfo.secondaryContactEmail" :disabled="isDisabled" name="SecondaryContactEmail" type="text" maxlength="254" id="SecondaryContactEmail" required pattern="email">
                 <div id="secondaryContactEmailError" class="form-error">Please provide a valid Secondary Contact Email</div>
            </div>
            <div class="formRow cell medium-6 phoneField">
                <span class="req">*</span>
                <label for="SecondaryContactPhone">Secondary Contact Phone:</label>
                <input v-model="contactInfo.secondaryContactPhone" :disabled="isDisabled" name="SecondaryContactPhone" type="tel" maxlength="12" id="SecondaryContactPhone" placeholder="xxx-xxx-xxxx" pattern="^\(?([0-9]{3})\)?[\-. ]?([0-9]{3})[\-. ]?([0-9]{4})$" required>
                <div id="secondaryContactPhoneError" class="form-error">Please provide a valid Secondary Contact Phone</div>
            </div>
        </div>

        <div class="btn-actions">
            <input type="button" name="" value="Save &amp; Back" @click="onSaveClick('back')" class=" btn-primary btnBack" title="Save the document and return to previous page">
            <input type="button" name="" value="Save &amp; Next" @click="onSaveClick('next')" class=" btn-primary btnNext" title="Save the document and proceed to next page">
        </div>

    </form>
</template>
<script>

    import $ from 'jquery';
    
    export default {
        props: {
            businessInfoJson: String,
            contactInfoJson: String,
            readOnly: Boolean
        },
        data() {
            return {
                businessInfo: {},
                contactInfo: {},
                isDisabled: false,
                sportsGamingError: false
            }
        },
        mounted: function () {
    
            this.businessInfo = JSON.parse(this.businessInfoJson);
            this.contactInfo = JSON.parse(this.contactInfoJson);
            this.isDisabled = this.readOnly;
            setTimeout(() => {
                this.loading = false;
                this.$nextTick(() =>
                {
                    this.phoneNumberFormat(document.querySelector('#PrimaryContactPhone'));
                    this.nameNoSpecialFormat(document.querySelector('#PrimaryContactName'));
                });

                $('#contactsForm').foundation();
                
            }, 1000);
        },
        methods: {
            principalAnyOffenseClick(option) {
                if (option == "0") {
                    this.contactInfo.sportsGamingInterest = false;
                } else {
                    this.contactInfo.sportsGamingInterest = true;
                }
                this.sportsGamingError = false;
            },
            onSaveClick(dir) {
                let request = {};
                request.dir = dir;
                request.businessInformation = this.businessInfo;
                request.contactInformation = this.contactInfo;
                if (this.contactInfo.sportsGamingInterest === null) {
                    this.sportsGamingError = true;
                } else {
                    this.sportsGamingError = false;
                    this.$emit('onSaveClick', request);
                }
            },
            secondaryContactInfoClick() {
                if (this.contactInfo.secondaryContactInfo) {            
                    $('#contactsForm').foundation();
                    setTimeout(() => {
                        this.loading = false
                        this.$nextTick(() =>
                        {

                            this.phoneNumberFormat(document.querySelector('#SecondaryContactPhone'));
                            this.nameNoSpecialFormat(document.querySelector('#SecondaryContactName'));
                        })
                    }, 1000);
                    
                } else {
                    // probabaly need to clear the fields so they don't get sent to API
                }
            }
        }
    }
</script>