<template>
    <div class="eAppModule eAppSOB">
        <h3>Sale of Business</h3>

        <div>
            <div class="EditingFormError" style="display: none;">
                <div id="cvBusinessName" class="error" style="visibility:hidden;">
                    Please complete the <a href="/eApplications/Application?form=Business-Information">business legal name</a> for your application before starting the Sale of Business process.
                </div>
            </div>
            <div class="eAppForm">
                <div class="formRow checkboxRow">
                    <input id="chkIsSellOfBusiness" :disabled="isDisabled || !checkBox" v-model="applicationIsASoB" type="checkbox" name="chkIsSellOfBusiness" >
                    <label for="chkIsSellOfBusiness" id="lblchkIsSellOfBusiness" class="labelSOB">Are you purchasing a location that currently sells lottery or has Ohio lottery equipment already present? If yes, please click the box to the left and provide the current owner's email address.</label>
                </div>
                <div id="pnlSubmission" v-if="applicationIsASoB">

                    <div class="formRow">
                        <span class="req">*</span>
                        <label for="txbxEmail" id="lbltxbxEmail">Owner Email:</label>
                        <input name="txbxEmail" :disabled="isDisabled" v-model="businessInfo.businessOriginalOwnerEmail" type="text" id="txbxEmail">
                        <div id="emailError" class="fieldError" style="display:none;">Please enter email</div>
                        <div v-if="!validEmail" class="error">Please enter valid email address. Eg. Something@domain.com</div>
                        <br><span>The current business owner will receive a notification and be required to log-on to the website and complete the Sale of Business Agreement prior to your application being approved.</span>

                    </div>

                </div>
            </div>

            <div class="btn-actions">
                <button type="button" @click="onSaveClick('back')" class=" btn-primary btnBack" title="Save the document and return to previous page">Save &amp; Back</button>
                <button type="button" @click="onSaveClick('next')" class=" btn-primary btnNext" title="Save the document and proceed to next page">Save &amp; Next</button>
            </div>
        </div>

    </div>
</template>

<script>
    import $ from 'jquery';
    export default {
        components: {
        },
        props: {
            appIsASoB: Boolean,
            businessInfoJson: String,
            readOnly: Boolean,
            checkBox: Boolean,
        },
        data() {
            return {
                applicationIsASoB: this.appIsASoB,
                validEmail: true,
                businessInfo: {},
                isDisabled: false
            }
        },
        mounted: function () {
            this.businessInfo = JSON.parse(this.businessInfoJson);
            this.isDisabled = this.readOnly;
        },
        methods: {
            onSaveClick(dir) {
                let request = {};
                request.dir = dir;
                request.applicationIsASoB = this.applicationIsASoB;
                request.businessInformation = this.businessInfo;

                let valid = true;
                if (this.applicationIsASoB) {
                    valid = this.validateEmail(this.businessInfo.businessOriginalOwnerEmail);
                }
                if (valid) {
                    this.$emit('onSaveClick', request);
                }
            },
             validateEmail(thisEmail) {
                 this.validEmail = this.emailValidation(thisEmail);
                 return this.validEmail;
             }
        }
    };
</script> 

<style lang="scss" scoped>

</style>