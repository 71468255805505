var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',[(!_vm.isUserLogged)?_c('SignInRegisterVue'):_vm._e(),(_vm.isUserLogged && _vm.loaded)?_c('span',[_c('h1',{staticClass:"productName"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('section',[_c('section',{staticClass:"productImg cf"},[_c('a',{staticClass:"enalrgeProductImg productDetailImg",attrs:{"title":"Title Here","target":"_blank"},on:{"click":_vm.openImage}},[_c('img',{attrs:{"src":_vm.image,"alt":""}})]),_c('br'),_c('a',{staticClass:"enalrgeProductImg productDetailImg",attrs:{"title":"Title Here","target":"_blank"},on:{"click":_vm.openImage}},[_vm._v(" View Larger ")])]),_c('section',{staticClass:"productOrderForm cf"},[_c('div',{staticClass:"CartItemSelectorContainer"},[_c('div',{staticClass:"TotalPriceContainer"},[_c('span',{staticClass:"TotalPrice"},[_vm._v(_vm._s(_vm.price.toLocaleString())+" Points")])]),_c('div',{staticClass:"AddToCartContainer add-to-card-container control-group-inline"},[(_vm.pointsAvailable)?_c('DetailCatalog',{attrs:{"data":{
                            isUserLogged: this.isUserLogged,
                            userPoints: this.userPoints,
                            pointsAvailable: this.pointsAvailable,
                            category: this.category,
                            description: this.description,
                            image: this.image,
                            name: this.name,
                            parameter1: this.parameter1,
                            parameter2: this.parameter2,
                            parameter3: this.parameter3,
                            parameter4: this.parameter4,
                            parameter5: this.parameter5,
                            price: this.price,
                            productId: this.productId,
                            skuNumber: this.skuNumber,
                            stock: this.stock,
                            token: this.token,
                            user: this.user,
                            address: this.address
                        }}}):_vm._e(),(!_vm.pointsAvailable)?_c('p',[_vm._v("You do not have enough points for this product.")]):_vm._e()],1)])]),_c('h3',[_vm._v("Additional Information")]),_c('section',{staticClass:"productDescription",domProps:{"innerHTML":_vm._s(_vm.description)}}),_c('p',{staticClass:"note red"},[_vm._v("* Point costs are subject to change")]),_c('div',{staticClass:"reveal modal-image reveal-modal",attrs:{"id":"modal-image","data-reveal":""}},[_c('section',{staticClass:"shoppingCartImage"},[_c('picture',{staticClass:"productImg"},[_c('img',{staticClass:"fullSizeImg",attrs:{"src":_vm.image}})])])])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }