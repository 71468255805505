<template>
    <div class="moduleContent cf adminDashboards">

        <h3>{{ dashTitle }} Dashboard</h3>
        
        <SignInRegister v-if="!isUserLogged"></SignInRegister>

        <section class="module" v-if="isUserLogged">

            <div v-if="!userEappRole" class="InfoLabel alert-message cf">
                This page requires special credentials to access. Contact your administrator if you have questions.
            </div>

            <!-- Link to Chain Dashboard for Chain Roles only -->
            <a v-if="isChainUser" class="button button-secondary eAppDashBtn" href="/eApplications/Dashboard/Chain"><span class="icon-chevron-double-left"></span>Chain Application Dashboard</a>

            <!-- Retailer Name search -->
            <div class="eAppFilter cf" v-if="isEappUser">
                <form id="search">
                    <div v-if="isEappRegionalManager">
                        <label for="query" id="queryLabel" class="filterLbl">Search by District</label>
                        <select v-model="district">
                            <option value="">All Districts</option>
                            <option v-for="(district, index) in districtList" :key="index" :value="district.districtNumber">{{ district.districtNumber }}</option>
                        </select>
                    </div>
                    <label for="query" id="queryLabel" class="filterLbl">Search by Retailer Name</label>
                    <input name="businessName" id="businessName" v-model="searchQuery" placeholder="Retailer Name" ref="searchInput" class="filterInput">
                    <button ref="searchButton" @click="handleSearch" id="btnFilter" class="btn-primary btn--filter button">Search Applications</button>
                    <button ref="clearSearchButton" @click="clearSearch" class="btn-secondary btn--filterClear button" v-if="showClearSearch">Clear Search</button>
                </form>
            </div>

            <!-- Tabs for Applications/Updates view toggle -->
            <ul class="tabs appTypeTabs" v-if="!isCSMuser && isEappUser && isRegionalManager === false && isSalesRep === false">
                <li class="tabs-title " :class="{ 'is-active': appType == 'regular' }" @click="setQueryString('regular', activeStatus)"><span>Applications</span></li>
                <li class="tabs-title" :class="{ 'is-active': appType == 'updates' }" @click="setQueryString('updates', activeStatus)"><span>Updates</span></li>
            </ul>

            <!-- Status switcher -->
            <div class="eAppStatusFilter" v-if="isEappUser">
                <span :class="{ selected: activeStatus == 'pending' }" @click="setQueryString(appType, 'pending')">Pending</span> |
                <span :class="{ selected: activeStatus == 'open' }" @click="setQueryString(appType, 'open')">Open</span> <span> | </span>
                <span :class="{ selected: activeStatus == 'rejected' }" @click="setQueryString(appType, 'rejected')">Rejected</span>
            </div>

            <!-- Apps associated to this user && of selected app type && of the selected status -->
            <div class="eAppList cf" v-if="!isLoading && isEappUser">

                <table class="eAppListTbl" v-if="gridData && gridData.length">
                    <thead>
                        <tr>
                            <th v-for="(header, index) in headers" :key="index">
                                <span @click="sortListBy(header, sortOrder)" :class="{ active: sortColumn == header, sortable: header!=='District', sortOrder}">
                                    {{ header }}
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="appType == 'regular'">
                        <tr v-for="(row, index) in gridData" :key="index" class="detailRow">
                            <td>
                                {{row['applicationNumber']}}
                            </td>
                            <td>

                                <a v-if="!showQuickview" :href="'/eapplications/eapplication?id='+row['businessGuid']">{{row['businessName']}}</a>

                                <span v-else>{{row['businessName']}}</span>

                                <div v-if="showQuickview">

                                    <!-- quickview only for SR, RM, & CLM Roles -->
                                    <a :href="'#qv'+index" @click="openQuickview(index)" class="quickViewOpen">Quickview</a> |
                                    <a :href="'/eapplications/eapplication?id='+row['businessGuid']">Review</a>

                                    <!-- create modal for Quickview -->
                                    <div :id="'qv'+index" data-reveal class="quickView reveal">
                                        <button class="close-button" data-close aria-label="Close modal" type="button">
                                            <span aria-hidden="true">&times;</span>
                                        </button>

                                        <strong>
                                            {{row['businessLegalName']}} -
                                            {{row['applicationNumber']}}
                                        </strong>

                                        <div class="quickViewAddress">
                                            {{row['businessStreetAddress']}}<br />
                                            {{row['businessCity']}}, {{row['businessZip']}}
                                        </div>

                                        <!-- for Sales Rep &  Regional Mngr -->
                                        <div v-if="row['applicationIsASoB'] === true && userEappRole.codename == 'eAppDistrictRep'">
                                            <span class="alertSOB">
                                                Sale of Business: 
                                                <span :class="{'icon-exclamation-triangle': !row['isBusinessTransferCompleted']}" class="icon-Status statusSOB"></span> 
                                                <span v-if="row['isBusinessTransferCompleted'] === true"> Completed</span>
                                                <span v-if="row['isBusinessTransferCompleted'] === false"> Pending</span>
                                            </span>
                                        </div>
                                        <!-- for Sales Rep -->
                                        <div v-if="userEappRole.codename == 'eAppDistrictRep'">
                                            Status: {{ getStatusName(row['applicationStatus']) }}
                                        </div>
                                        <!-- for Regional Mngr -->
                                        <div v-if="userEappRole.codename == 'eApp-RegionalManager'">
                                            Application Status: {{ getStatusName(row['applicationStatus']) }}
                                            <!-- <select id="" class="statusSelect">
                                        <option value="-">In review</option>
                                        <option value="Review">Mark as Reviewed</option>
                                        <option value="RecommendRejection">Recommend Rejection</option>
                                    </select> -->
                                        </div>
                                        <div v-if="userEappRole.codename == 'eApp-RegionalManager'">
                                            District: {{row['applicationDistrict']}}<br />
                                            Sales Rep: {{row['associatedSalesRepName']}}
                                        </div>
                                        <!-- for Licensing -->
                                        <div v-if="userEappRole.displayname == 'Licensing'" class="quickViewStatus quickViewLicensingStatus">
                                            <span class="icon-Status statusSecurity" :class="{ needsData: (row['securityReview'] == 0) }"></span> Principals Approved<br />
                                            <span class="icon-Status statusEFT" :class="{ needsData: (row['eFTReview'] == 0) }"></span> EFT Approved<br />
                                            <span class="bold">District:</span> {{row['applicationDistrict']}}
                                        </div>
                                        <!-- action(s) -->
                                        <div class="quickViewActions">
                                            <a :href="'/eapplications/eapplication?id='+row['businessGuid']">Review</a>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {{row['businessCity']}}
                            </td>
                            <td v-if="userEappRole.codename !== 'eAppDistrictRep'">
                                {{row['applicationDistrict']}}
                            </td>
                            <td>
                                <!-- Format date as: 4/27/2015 3:16:49 PM -->
                                {{appDateFormatter(row['createdOn'])}}
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr v-for="(row, index) in gridData" :key="index" class="detailRow">
                            <td>
                                {{row['agentNumber']}}
                                <!-- for Licensing -->
                                <span v-if="isCLMuser"> - {{row['businessName']}}</span>
                            </td>
                            <td>
                                {{row['businessGuid']}}
                            </td>
                            <td>
                                <!-- this might change based on how we access the updates -->
                                <a :href="row['elementLink']+'&status='+statusGrouping">{{row['elementType']}}</a>
                            </td>
                            <td>
                                <!-- Format date as: 4/7/2015 (no time) -->
                                {{updateDateFormatter(row['createdOn'])}}
                            </td>
                            <td :class="{ renealCol: row['isPartOfRenewal'] }">
                                <span class="icon-check-circle" v-if="row['isPartOfRenewal']"></span>
                            </td>
                            <td v-if="isCLMuser">
                                <!-- for Licensing -->
                                <span v-if="row['principalCrimeConvictions']">
                                    Yes: {{row['principalCrimeConvictionsExplanation']}}
                                </span>
                                <span v-else>
                                    No
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div v-else class="InfoLabel alert-message cf">There are currently no applications to display for the selected status category.</div>

                <grid-paginate v-if="totalPageCount > 1"
                               :totalPages="totalPageCount"
                               :currentPage="currentPage"
                               :itemsPerPage="pageSize"
                               @changePage="changePage" />

            </div>


            <hollow-dots-spinner :animation-duration="1000"
                                 :dot-size="15"
                                 :dots-num="3"
                                 color="#0FD8C9"
                                 v-if="isLoading" />

        </section>
    </div>
</template>
<script>
    import $ from 'jquery';
    import moment from 'moment';
    import Foundation from "@/foundation-sites/js/foundation.js";
    import GridPaginate from "../../Pagination/pagination.vue";
    import { HollowDotsSpinner } from 'epic-spinners';
    import SignInRegister from '../../../ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        components:{
            SignInRegister,
            HollowDotsSpinner,
            GridPaginate
        },
        data: function () {
            return {
                isUserLogged: false,
                isEappUser: false,
                userEappRole: '',
                dashTitle: 'Application',
                userId: 0,
                totalPageCount: 0,
                isChainUser: false,
                isCLMuser: false,
                isCSMuser: false, // not sure is necessary
                isEFTuser: false,
                isSecurityuser: false,
                isSalesRep: false,
                isRegionalManager: false,
                appType: 'regular',
                activeStatus: 'pending',
                gridData: [],
                headers: [],
                sortColumn: 'Application Date',
                sortOrder: 'asc',
                statusGrouping: 2,
                currentPage: 1,
                pageSize: 20,
                isLoading: false,
                searchQuery: '',
                showClearSearch: false,
                showQuickview: false,
                regularHeaders: ["Application","Business Name","City","District","Application Date"],
                salesRepHeaders: ["Application","Business Name","City","Application Date"],
                updateHeaders: ["Agent Number", "Update Identifier", "Updated Element", "Creation Date", "Is Part of a Renewal"],
                clmUpdateHeaders: ["Agent #", "Update ID", "Updated Element(s)", "Date", "Renewal?", "Conviction?"],
                districtList: [],
                district: '',
                isEappRegionalManager: false
            }
        },
        mounted: function () {
            this.prepDisplay();
            $(document).foundation(); // recall to activate reveal modals
        },
        methods: {
            prepDisplay() {
                this.isUserLogged = this.isUserAuthed();
                if(this.isUserLogged) {
                    this.userEappRole = this.getEappRole();
                    if(this.userEappRole !== false) {
                        this.isEappUser = true;
                        
                        let urlParams = new URLSearchParams(window.location.search);
                        if (urlParams.has('appType')) {
                            this.appType = urlParams.get('appType');
                        }
                        
                        if (this.appType == 'regular') {
                            this.headers = this.regularHeaders; // set the default
                            this.sortColumn = 'Application Date';


                            //is regional manager
                            if (this.userEappRole.codename == 'eApp-RegionalManager') {
                                this.isEappRegionalManager = true;
                            }
                            // is Sales Rep?
                            if (this.userEappRole.codename == 'eAppDistrictRep' && this.appType == 'regular') {
                                this.headers = this.salesRepHeaders;
                            }
                            // also show Quickview?
                            if (this.userEappRole.codename == 'eAppDistrictRep' || this.userEappRole.codename == 'eApp-RegionalManager' || this.userEappRole.codename == 'eApp-CentralLicensingManager') {
                                this.showQuickview = true;
                            }
                            // is in a Chain role?
                            if (this.userEappRole.codename == 'eApp-ChainSalesRep' || this.userEappRole.codename == 'eApp-RegionalManager' || this.userEappRole.codename == 'eApp-Security' || this.userEappRole.codename == 'eApp-CentralLicensingManager' || this.userEappRole.codename == 'eApp-EFTReviewer') {
                                this.isChainUser = true;
                            }
                            
                        } else {
                            this.headers = this.updateHeaders;
                            this.sortColumn = 'Date';

                            if (this.userEappRole.codename == 'eApp-EFTReviewer') {
                                this.isEFTuser = true;
                            }

                            if (this.userEappRole.codename == 'eApp-Security') {
                                this.isSecurityuser = true;
                            }
                            
                            // is Central Licensing?
                            if (this.userEappRole.codename == 'eApp-CentralLicensingManager') {
                                this.headers = this.clmUpdateHeaders;
                                this.isCLMuser = true;
                            }
                            
                        }
                        // Central Sales Mngr doesn't see Updates
                        console.log(this.userEappRole.codename)
                        if (this.userEappRole.codename == 'eApp-CentralSalesManager') {
                                this.isCSMuser = true;
                        }
                        if (this.userEappRole.codename == 'eApp-ChainSalesRep') {
                            this.isSalesRep = true;
                        }
                        if (this.userEappRole.codename == 'eApp-RegionalManager') {
                            this.isRegionalManager = true;
                        }

                        this.dashTitle = this.userEappRole.displayname;

                        this.getToken();

                    } else {
                        // no eApp Role found
                        this.isEappUser = false;
                        // console.log('no eApp Role found');
                    }
                }
            },
            getToken() {
                let uId = this.getLoggedInUserId();
                if (uId) {
                    // console.log('uid: ' + uId);
                    this.userId = uId;
                    // this.userId = 131;
                    this.getAPItoken()
                        .then((token) => {
                            this.token = token;
                            this.sortBy = "createdOn";
                            this.sortOrder = "asc"
                            this.tryGetFromQueryString().then(() => {
                                this.fetchData(token, this.currentPage, this.pageSize, this.searchQuery, this.district, this.sortBy, this.sortOrder, this.statusGrouping);
                            });
                        });
                }
            },
            async fetchData(token, _pageNumber, _pageSize, _businessName, _district, _sortBy, _sortOrder, _statusGrouping) {
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        pageNumber: _pageNumber,
                        pageSize: _pageSize,
                        businessName: _businessName,
                        district: _district,
                        sortBy: _sortBy,
                        sortOrder: _sortOrder, 
                        statusGrouping: _statusGrouping
                    })
                };
                let url = this.appType == 'updates' ? this.GET_UPDATES_API_URL : this.GET_APPS_API_URL;
                let applicationsRequest = await (await fetch(url, appsRequestOptions)).json();
                
                // Sort based on selected filter

                this.gridData = applicationsRequest.results;
                console.log(this.gridData);
                this.totalPageCount = applicationsRequest.totalPageCount;
                this.isLoading = false;
                if (this.userEappRole.codename == 'eApp-RegionalManager') {
                    await this.loadDistricts();
                }

            },
            tryGetFromQueryString() {
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                if (urlParams.has('page')) {
                    this.currentPage = Number(urlParams.get('page'));
                }
                if (urlParams.has('businessName')) {
                    this.searchQuery = urlParams.get('businessName');
                }
                if (urlParams.has('sortBy')) {
                    this.sortBy = urlParams.get('sortBy');
                }
                if (urlParams.has('sortOrder')) {
                    this.sortOrder = urlParams.get('sortOrder');
                }
                if (urlParams.has('appType')) {
                    this.appType = urlParams.get('appType');
                }
                if (urlParams.has('status')) {
                    this.activeStatus = urlParams.get('status');
                }
                this.setStatusGroupingId(this.activeStatus);

                return Promise.resolve();
            },
            setQueryString(newAppType, newStatus) {
                // route all requests thorugh queryString and reload the page
                let currentPage = window.location.origin + window.location.pathname;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                
                if (urlParams.has('appType')) {
                    urlParams.set('appType', newAppType);
                } else {
                    urlParams.append('appType', newAppType);
                }
                if (urlParams.has('status')) {
                    urlParams.set('status', newStatus);
                } else {
                    urlParams.append('status', newStatus);
                }
                    
                currentPage += '?' + urlParams.toString();
                window.location.href = currentPage;

            },
            // getAppsByType(getAppType) {
            //     // switch the type
            //     this.appType = getAppType;
            //     // this.activeStatus = 'pending';
            //     // reset the stage
            //     this.prepDisplay();
            // },
            // getAppsByStatus(getStatus) {
            //     // set the grouping id
            //     this.setStatusGroupingId(getStatus);
            //     // fetch the data
            //     this.fetchData(this.token, this.currentPage, this.pageSize, this.searchQuery, this.sortBy, this.sortOrder, this.statusGrouping);
            // },
            // loadUpdate(thisUpdate) {
            //     // gow to the Update review screen for this
            //     // todo gina
            //     // console.log('go to this update: '+thisUpdate);
            // },
            setStatusGroupingId(status) {
                // switch the type
                // if (this.appType == 'regular') {
                    switch (status) {
                        case 'open':
                            this.statusGrouping = 1;
                            break;
                        case 'rejected':
                            this.statusGrouping = 3;
                            break;
                        case 'pending':
                        default:
                            this.statusGrouping = 2;
                            break;
                    }
                    
                // } else {
                //     switch (status) {
                //         case 'open':
                //             this.statusGrouping = 1;
                //             break;
                //         case 'rejected':
                //             this.statusGrouping = 3;
                //             break;
                //         case 'pending':
                //         default:
                //             this.statusGrouping = 2;
                //             break;
                //     }
                // }
            },
            async loadDistricts() {
                // we should also store the list in session and only fetch from the API the first time, 
                //in case someone gets clicky with it.
                if (!this.districtList.length > 0) {
                    // populate the district list
                    this.isLoading = true;
                    const appsRequestOptions = {
                        method: "GET",
                        headers: {
                            'Authorization': 'Bearer ' + this.token,
                            "Content-Type": "application/json"
                        }
                        
                    };
                    let response = await (await fetch(this.GET_DISTRICTS_API_URL, appsRequestOptions)).json();
                    if (response != null && response.statusCode == 200) {
                        this.districtList = response.data;
                        this.isLoading = false;
                    }
                    else {
                        this.errorMessageShow = true;
                        this.isLoading = false;
                    }
                    

                }
            },
            getStatusName(int) {
                return this.getEappStatus(int);
            },
            async sortListBy(key, order) {
                //if the same key is clicked again, toggle the asc/desc
                if (key === this.sortBy) {
                    if (order === 'asc') {
                        this.sortOrder = 'desc';
                    }
                    else {
                        this.sortOrder = 'asc';
                    }
                }
                //otherwise, sort the new column selected by asc order
                else {
                    this.sortOrder = 'asc';
                }
                this.sortBy = key;
                // console.log(this.sortBy);
                await this.fetchData(this.token, this.currentPage, this.pageSize, this.searchQuery, this.district, this.sortBy, this.sortOrder, this.statusGrouping);
            },
            async changePage(pageNumber) {
                this.currentPage = pageNumber;
                await this.fetchData(this.token, this.currentPage, this.pageSize, this.searchQuery, this.district, this.sortBy, this.sortOrder, this.statusGrouping);
            },
            openQuickview(i) {
                let popup = new Foundation.Reveal($('#qv'+i));
                popup.open();
            },
            appDateFormatter(date) {
                // 4/27/2015 3:16:49 PM 
                return moment(date).format('M/D/yyyy h:mm:ss A');
            },
            updateDateFormatter(date) {
                return moment(date).format('M/D/yyyy');
            },
            async clearSearch() {
                this.$refs.searchInput.value = '';
                this.searchQuery = '';
                await this.fetchData(this.token, this.currentPage, this.pageSize, this.searchQuery, this.sortBy, this.sortOrder, this.statusGrouping);
                this.showClearSearch = false;
            },
            async handleSearch(e) {
                e.preventDefault()
                let value = this.$refs.searchInput.value;
                this.searchQuery = value;
                await this.fetchData(this.token, this.currentPage, this.pageSize, this.searchQuery, this.district, this.sortBy, this.sortOrder, this.statusGrouping);
                
                this.showClearSearch = true;
            }
        },
        computed: {
            GET_APPS_API_URL() {
                let url = '';
                switch (this.userEappRole.codename) {
                    case "eApp-CentralSalesManager":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/GetApplicationListForCentralSales?userId=` + this.userId;
                        break;
                    case "eAppDistrictRep":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/GetApplicationListForSalesRep?userId=` + this.userId;
                        break;
                    case "eApp-RegionalManager":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/GetApplicationListForRegionalManager?userId=` + this.userId;
                        break;
                    case "eApp-CentralLicensingManager":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/GetApplicationListForLicensing?userId=` + this.userId;
                        break;
                    case "eApp-EFTReviewer":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/GetApplicationListForEFT?userId=` + this.userId;
                        break;
                    case "eApp-Security":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/GetApplicationListForSecurity?userId=` + this.userId;
                        break;
                    default:
                        break;
                }
                return url;
            },
            GET_UPDATES_API_URL() {
                let url = '';
                switch (this.userEappRole.codename) {
                    case "eAppDistrictRep":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/GetUpdateListForSalesRep?userId=` + this.userId;
                        break;
                    case "eApp-RegionalManager":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/GetUpdateListForRegionalManager?userId=` + this.userId;
                        break;
                    case "eApp-CentralLicensingManager":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/GetUpdateListForLicensing?userId=` + this.userId;
                        break;
                    case "eApp-EFTReviewer":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/GetUpdateListForEFT?userId=` + this.userId;
                        break;
                    case "eApp-Security":
                        url = this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/GetUpdateListForSecurity?userId=` + this.userId;
                        break;
                    default:
                        break;
                }
                return url;
            },
            GET_DISTRICTS_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Dashboard/GetDistricts?userId=` + this.userId;
            },
        }
    };
</script>