<template>
        <div class="eAppModule eAppBusinessNameEdit">
            <h3>Business Name</h3>

            <div class="EditingFormError" style="display: none;">

            </div>

            <div class="eAppForm grid-margin-x grid-x grid-margin-y">

                <div class="formRow cell">
                    <span class="req">*</span>
                    <label title="This is the name that you file your business taxes under and must match the information entered under the W-9 section" class="helpTxt" for="legalName">Legal Business Name:</label>
                    <input name="" type="text" :disabled="isDisabled" v-model="businessInfo.businessLegalName" maxlength="200" id="legalName" class="" />
                    <!-- <span class="toolTip">
                        <span class="toolTipIcon" title="This is the name that you file your business taxes under and must match the information entered under the W-9 section"></span>
                        <span class="toolTipContent" style="display: none;">This is the name that you file your business taxes under and must match the information entered under the W-9 section.</span>
                    </span> -->
                    <div class='error error-label' v-if="nameError" id="legalNameError">Business Legal Name is required.</div>
                </div>
                <div class="formRow cell">
                    <span class="req">*</span>
                    <label title="DBA-this is the name of your business" class="helpTxt" for="doingBusinessAs">Doing Business As:</label>
                    <input name="" type="text" :disabled="isDisabled" v-model="businessInfo.businessDoingBusinessAs" maxlength="200" id="doingBusinessAs" class="form-control">
                    <!-- <span class="toolTip">
                        <span class="toolTipIcon" title="DBA-this is the name of your business"></span>
                        <span class="toolTipContent" style="display: none;">DBA-this is the name of your business</span>
                    </span> -->
                    <div class='error' v-if="nameAsError" id="doingBusinessAsError">Doing Business As is required.</div>
                </div>
                <div class="formRow cell">
                    <input type="checkbox" id="sobCheck" name="saleOfBusiness" value="" @click="sobCheck">
                    <span>&nbsp;Is this a purchase of an existing Lottry retail location? If yes, please click the box to the left.</span>
                </div>
                <div class="formRow phoneField cell medium-6 large-4">
                    <span class="req">*</span>
                    <label class="control-label editing-form-label" for="">Business Phone:</label>
                    <!-- <input v-model="primaryPhone" type="text" :disabled="isDisabled" placeholder="xxx-xxx-xxxx" pattern="^\d{3}-\d{3}-\d{4}$" maxlength="12" id="businessPhone" @input="phoneFormat" /> -->
                    <input v-model="primaryPhone" type="text" :disabled="isDisabled" placeholder="xxx-xxx-xxxx" maxlength="12" id="businessPhone" />
                    <div class='error' id="businessPhoneError" v-if="phoneError">Business Phone is required.</div>
                    <span class="error" v-if="formatErrorPrimary">Please provide a valid phone number format. ex: 555-555-5555</span>
                </div>
                <div class="formRow phoneField cell medium-6 large-4">
                    <label class="control-label editing-form-label" for="">Alternate Business Phone:</label>
                    <input v-model="altPhone" id="altBusinessPhone" :disabled="isDisabled" name="" type="text" maxlength="12" placeholder="xxx-xxx-xxxx" />
                    <span class="error" v-if="formatErrorAlt">Please provide a valid phone number format. ex: 555-555-5555</span>
                </div>
                <div class="formRow phoneField cell medium-6 large-4">
                    <label class="control-label editing-form-label" for="">Business Fax:</label>
                    <input v-model="faxPhone" id="businessFax" name="" :disabled="isDisabled" type="text" maxlength="12" placeholder="xxx-xxx-xxxx" />
                    <span class="error" v-if="formatErrorFax">Please provide a valid fax number format. ex: 555-555-5555</span>
                </div>
            </div>
            <div class="btn-actions">
                <input type="button" name="" @click="onSaveClick('back')" value="Save &amp; Back" class=" btn-primary btnBack" title="Save the document and return to previous page">
                <input type="button" name="" @click="onSaveClick('next')" value="Save &amp; Next" class=" btn-primary btnNext" title="Save the document and proceed to next page">
            </div>
        </div>
       
</template>
<script>
    import $ from 'jquery';
    export default {
        props: {
            businessInfoJson: String,
            readOnly: Boolean
        },
        data() {
            return {
                businessInfo: {},
                isDisabled: false,
                primaryPhone: '',
                altPhone: '',
                faxPhone: '',
                phoneError: false,
                formatErrorPrimary: false,
                formatErrorAlt: false,
                formatErrorFax: false,
                error: false,
                nameError: false,
                nameAsError: false,
                sob: false
            }
        },
        mounted: function () {
            $('#legalNameError').hide();
            $('#doingBusinessAsError').hide();
            $('#businessPhoneError').hide();
            this.businessInfo = JSON.parse(this.businessInfoJson);
            // if (this.businessInfoJson.businessPhoneNumber != undefined) {
            //     document.getElementById('businessPhone').value = this.phoneNumberFormat(this.businessInfoJson.businessPhoneNumber);
            // }
            // if (this.businessInfoJson.businessPhoneNumber != undefined) {
            //     document.getElementById('businessPhone').value = this.phoneNumberFormat(this.businessInfoJson.businessPhoneNumberSecondary);
            // }
            // if (this.businessInfoJson.businessPhoneNumber != undefined) {
            // document.getElementById('businessPhone').value = this.phoneNumberFormat(this.businessInfoJson.businessFaxNumber);
            // }            
            
            this.primaryPhone = this.businessInfo.businessPhoneNumber;
            this.altPhone = this.businessInfo.businessPhoneNumberSecondary;
            this.faxPhone = this.businessInfo.businessFaxNumber;
            this.isDisabled = this.readOnly;
            this.sob = this.businessInfo.saleOfBusiness;

            let sobCheck = $('#sobCheck');

            console.log(this.sob)

            if (this.sob === true) {
                sobCheck.click();
            } else {
                sobCheck.checked = false;
            }

            // HELP TEXT BUBBLES =======================
            this.jqTooltip();
            
        },
        methods: {
            sobCheck(e) {
                if (e.target.checked === true) {
                    this.businessInfo.saleOfBusiness = true;
                } else {
                    this.businessInfo.saleOfBusiness = false;
                }
            },
            // formatPhone(d) {
            //     // console.log(d)
            //     if (d === 0) {
            //         let phone = this.primaryPhone,
            //             format = this.phoneNumberFormatModel(phone);
            //         console.log(format)
            //     } else if (d === 1) {
            //         if (this.altPhone.length === 3 || this.altPhone.length === 7) {
            //             this.altPhone += '-';
            //         }
            //     } else if (d === 2) {
            //         if (this.faxPhone.length === 3 || this.faxPhone.length === 7) {
            //             this.faxPhone += '-';
            //         }
            //     }
            // },
            phoneFormat() {
                // depricated 
                // let number = document.getElementById('businessPhone').value,
                //     altNumber = document.getElementById('altBusinessPhone').value,
                //     faxNumber = document.getElementById('businessFax').value;

                // if (number != '') {
                //     this.businessInfo.businessPhoneNumber = number;
                //     $('#businessPhone').value = this.phoneNumberFormat(document.getElementById('businessPhone'));
                // }

                // if (altNumber != '') {
                //     this.businessInfo.businessPhoneNumberSecondary = altNumber;
                //     $('#altBusinessPhone').value = this.phoneNumberFormat(document.getElementById('altBusinessPhone'));
                // }

                // if (faxNumber != '') {
                //     this.businessInfo.businessFaxNumber = faxNumber;
                //     $('#businessFax').value = this.phoneNumberFormat(document.getElementById('businessFax'));
                // }
            },
            phoneFormatValid() {
                let isValidPhone = true;
                let isValidAlt = true;
                let isValidFax = true;
                if (!this.phoneValidation(this.primaryPhone)) {
                    isValidPhone = false;
                    this.formatErrorPrimary = true;
                } else {
                    this.formatErrorPrimary = false;
                    this.businessInfo.businessPhoneNumber = this.primaryPhone;
                }
                if (this.altPhone !== null) {
                    isValidAlt = this.phoneValidation(this.altPhone);
                    this.formatErrorAlt = !this.phoneValidation(this.altPhone);
                    this.businessInfo.businessPhoneNumberSecondary = this.altPhone;
                }
                if (this.faxPhone !== null) {
                    isValidFax = this.phoneValidation(this.faxPhone);
                    this.formatErrorFax = !this.phoneValidation(this.faxPhone);
                    this.businessInfo.businessFaxNumber = this.faxPhone;
                }
                // console.log(isValidPhone, isValidAlt, isValidFax);
                if (isValidPhone && isValidAlt && isValidFax) {
                    return true;
                } else {
                    return false;
                }
            },
            onSaveClick(dir) {
                let request = {};
                request.dir = dir;
                request.businessInformation = this.businessInfo;
                // // if (this.phoneValidation(this.businessInfo.businessPhoneNumber) === true && this.businessInfo.businessPhoneNumber.length === 12) {
                if (this.validate() === true) {
                    this.$emit('onSaveClick', request);
                }
            },
            validate() {
                this.nameError = false;
                this.nameAsError = false;

                let error = false;

                if (this.businessInfo.businessLegalName && this.businessInfo.businessDoingBusinessAs) {
                    let nameLength = this.businessInfo.businessLegalName.length,
                        businessAsLength = this.businessInfo.businessDoingBusinessAs.length,
                        error = false;

                    if (nameLength < 1) {
                        this.nameError = true;
                        error = true;
                    }

                    if (businessAsLength < 1) {
                        this.nameAsError = true;
                        error = true;
                    }

                    if (this.phoneFormatValid() === false) {
                        error = true;
                    }

                    if (error === true) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    if (!this.businessInfo.businessLegalName) {
                        this.nameError = true;
                        error = true;
                    }

                    if (!this.businessInfo.businessDoingBusinessAs) {
                        this.nameAsError = true;
                        error = true;
                    }

                    if (this.phoneFormatValid() === false) {
                        error = true;
                    }

                    if (error === true) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }
        }
    }
</script>