<template>
    <div>
        <hollow-dots-spinner :animation-duration="1000"
                             :dot-size="15"
                             :dots-num="3"
                             color="#0FD8C9"
                             v-if="isLoading" />
        <div v-if="pageContent" v-html="pageContent.updatePrincipalsIntro"></div>

        <div class="eAppList cf" v-if="!isLoading">
            <div class="eAppListCount"></div>
            <!--<table class="eAppListTbl updatePrincipalsTbl">-->
                
            <div v-if="showError" class="ErrorLabel alert-error">{{genericErrorMessage}}</div>
            <table class="updatePrincipalsTbl">
                <tr>
                    <th colspan="3">Principal Information</th>
                    <th>Actions</th>
                </tr>

                <!-- if all principals are deleted -->
                <tr v-if="existingMergedPrincipals != null && existingMergedPrincipals.length === 0">
                    <td colspan="4">
                        <div class="error" style="text-align: center; padding: 30px 0;">
                            There needs to be at least one principal.
                        </div>
                    </td>
                </tr>
                <!-- BEGIN PRINCIPAL LOOP -->
                <tr  v-for="(item, index) in existingMergedPrincipals" :index="index" :key="index" class="principalRow" :data-form="'form-' + index" data-abide novalidate data-validate-on-blur="true" style="display:table-row-group">
                    <td width="20%">
                        <strong>{{ item.principal_salutation }} {{ item.principal_name }}</strong>
                        <br>
                        {{ item.principal_title }}
                    </td>
                    <td width="50%">
                        <div class="formRow cf">
                            <label>Email:</label>
                            <input type="text" id="principal_email" pattern="email" required :disabled="item.emailDisabled"
                                v-model="item.principal_email"
                                :class="['email', { 'is-invalid-input': !item.principal_email || !error.emailRegex.test(item.principal_email) }]" />
                            <div v-if="!item.principal_email" class="error">Please Provide an Email</div>
                            <div v-if="!error.emailRegex.test(item.principal_email)" class="error">
                                Please enter valid
                                email address. Eg. Something@domain.com
                            </div>
                        </div>
                        <div class="formRow cf">
                            <label>Ownership:</label>
                            <input type="text" :id="item.principal_share"
                                v-model="item.principal_share"
                                :class="['ownership', { 'is-invalid-input': item.principal_share.length < 1 || !checkOwnership(item.principal_share) }]"
                                pattern="(^100([.]\d{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$" minlength="1" maxlength="4"
                                required :disabled="readOnly" @input="checkOwnership(item.principal_share)"/>
                            <span style="margin-left: .5em;">%</span>
                            <div v-if="item.principal_share.length < 1" class="error">
                                Provide a percentage for ownership
                            </div>
                            <div v-if="!checkOwnership(item.principal_share)" class="error">
                                Please
                                Provide a valid percentage for ownership. Eg. 30.5
                            </div>

                            <!-- must be a whole number > 0 and < 101 -->
                            <!-- if multiple principals then total % should = 100% -->
                            <!-- see error message below table -->
                        </div>
                        <!-- if % less than 100% -->
                    </td>
                    <td>
                        <div>
                            Background check:
                            <span style="display: block;" v-if="item.principalAuthorizedBackgroundCheck && item.principalOperation === 0">Authorized.</span>
                            <span style="display: block;" v-else-if="!item.principalAuthorizedBackgroundCheck && item.principalOperation === 0">Waiting for authorization.</span>
                            <span style="display: block;" v-else>Not required.</span>
                        </div>
                    </td>
                    <td class="operations">
                        <button class="link" type="submit" :data-open="'changePrincipalInfo'+index">
                            Save Changes
                        </button>
                        <button class="link" @click="removePrincipal(item.principal_email, item.principal_IAD_RowID)">Delete Principal</button>
                        
                        <div class="reveal small alert-box alert-message eAppModal" :id="'changePrincipalInfo'+index" data-reveal>
                            <div class="message-content">
                                If you update the email for the principal associated with the user currently logged in, you will be logged out after the update is successfully submitted.<br><br>
                                <!-- <button class="btn-secondary button" @click="saveEditPrincipal(index, item.principal_email, item.principal_share, item.principal_IAD_RowID, item.principal_LicensePermitRevoked, item.principal_LicensePermitRevokedExplanation)">Continue</button> -->
                                <button class="btn-secondary button" data-close>Continue</button>
                            </div>
                        </div>
                    </td>
                    <td v-if="hasRenewal === true" style="display:table-row">
                        <div class="princiRevokeRadios">
                            <label>Has the applicant had any state license or permit revoked or suspended? {{ item.principal_LicensePermitRevoked }}</label>
                        
                            <label class="radio" style="display:inline-block">
                                <input type="radio" :name="'existingRevoked' + index" :id="'existingRevokedYes' + index" value="true"  @click="setRenewType(1, item)" required :disabled="readOnly" style="height: auto;" /> Yes
                            </label>
                            <label class="radio" style="display:inline-block;margin-left:10px">
                                <input type="radio" :name="'existingRevoked' + index" :id="'existingRevokedNo' + index" value="false"  @click="setRenewType(0, item)" required :disabled="readOnly" style="height: auto;" /> No
                            </label>
                        </div>
                        <div class="princiRevokeExplain">
                            <div class="error" v-if="item.errorLicense == true">Please select one option.</div>
                            <div class="formRow cf" v-if="item.principal_LicensePermitRevoked == true" style="padding-top: 0;">
                                <label class="helpTxt" for="addTxbxQuestionExplanation">Please explain:</label>
                                <textarea v-model="item.principal_LicensePermitRevokedExplanation" type="text" name="addTxbxQuestionExplanation" :id="'addTxbxQuestionExplanation' + index" maxlength="200" required :disabled="readOnly" style="min-height: 100px;"></textarea>
                                <div class="form-error error" data-form-error-on="required" v-if="item.errorLicenseExplination">Please Provide an explanation.</div>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
            
            <div v-if="showError" class="ErrorLabel alert-error">{{genericErrorMessage}}</div>

            <div class="reveal" id="removePrincipalsModal" data-reveal>
                <div class="ErrorLabel alert-error">
                    If You want to remove the item, please click on Continue button.
                </div>
                <div class="eAppModalBtns">
                    <button class="button" @click="removePrincipal(itemIndex)">Continue</button>
                    <button class="button" @click="closeModal()">Cancel</button>
                </div>
            </div>
            <!-- this show the next form below -->
            <div v-if="ownershipInvalid" class="alert-message MessageLabel">
                The total ownership of all principals must not exceed 100%
            </div>
            <div class="alert-message MessageLabel" v-if="backgroundCheckCompleted === false">All principals must have a completed background check before this update may be submitted.</div>
            <button class="button" @click="toggleNewPrincipal()" v-if="!toggleAddPrincipal">Add Principal</button>
        </div>

        <div class="addPrincipalForm" v-if="toggleAddPrincipal" data-abide novalidate data-validate-on-blur="true"
            id="addPrincipalsForm">
            <h4>New Principal Information</h4>

            <div class="grid-x grid-margin-x grid-margin-y addPrincipalFormGrid">
                <div class="formRow cell cf">
                    <span class="req">*</span>
                    <label for="addTxbxTitle">Title:</label>
                    <input name="addTxbxTitle" type="text" autocomplete="off" required :disabled="readOnly">
                    <span class="form-error error">Please add a Title</span>
                </div>

                <div class="formRow cell cell-no-y-bottom cf">
                    <span class="req">*</span>
                    <label for="addlPrefix">Name:</label>
                </div>
                <div class="formRow nameTitleRow cell cell-no-y medium-2">
                    <select name="addlPrefix" id="salutationSelect" :disabled="readOnly">
                        <option selected="selected" value="MR">Mr.</option>
                        <option value="MRS">Mrs.</option>
                        <option value="MS">Ms.</option>
                        <option value="MSS">Mss.</option>
                        <option value="DR">Dr.</option>
                    </select>
                    <span class="form-error error">Please select a salutation</span>
                </div>

                <div class="formRow nameTitleRow cell cell-no-y medium-5">
                    <input type="text" name="txbxFirstName" placeholder="First Name" required
                        :disabled="readOnly" />
                    <span class="form-error error">Please add a first name</span>
                </div>
                <div class="formRow nameTitleRow cell cell-no-y medium-5">
                    <input type="text" name="txbxLastName" placeholder="Last Name" required
                        :disabled="readOnly" />
                    <span class="form-error error">Please add a last name</span>
                </div>

                <div class="formRow emailField cell medium-6 cf">
                    <span class="req">*</span>
                    <label for="addStreetAddress_txtText">Home Address:</label>
                    <input type="text" name="addStreetAddress_txtText" maxlength="600" id="addStreetAddress_txtText"
                        autocomplete="off" required :disabled="readOnly">
                    <span class="form-error error">Please provide a valid Ohio street address.</span>
                </div>

                <div class="formRow emailField cell medium-6 cf">
                    <span class="req">*</span>
                    <label for="addCity_txtText">City:</label>
                    <input type="text" maxlength="300" name="addCity_txtText" id="addCity_txtText" autocomplete="off" required
                        :disabled="readOnly">
                    <span class="form-error error">Please provide a valid Ohio city.</span>
                </div>

                <!-- <div class="formRow validateZip cf"> -->
                <!-- <div style="display: flex; align-items: center;"> -->
                <div class="formRow cell medium-2">
                    <span class="req">*</span>
                    <label for="addLegalZip">Zip:</label>
                    <input type="text" name="addLegalZip" id="addLegalZip" class="zipFive infoChangeCheckInput"
                        pattern="^4[3-5]\d{3}$" minlength="5" maxlength="5" required :disabled="readOnly" />
                    <div class="error" v-if="error.zipCode">Please provide a Zip Code</div>
                </div>

                <div class="formRow cell medium-2">
                    <span class="req">*</span>
                    <label for="addLegalZipPlus4">Zip +4:</label>
                    <input type="text" name="addLegalZipPlus4" id="addLegalZipPlus4" class="zipFour" pattern="\b\d{4}\b"
                        minlength="4" maxlength="4" required :disabled="readOnly" />
                    <div class="error" v-if="error.zipCode4">Please provide a Zip +4</div>
                    <a href="https://tools.usps.com/go/ZipLookupAction!input.action" target="_blank"
                    class="eAppZip4Link cell medium-2">Zip +4 Lookup</a>
                </div>
                
                <!-- </div> -->
                
                
                <div class="error" v-if="error.zipCode">Please provide a valid Ohio Zip Code</div>
                <div class="error" v-if="error.zipCode || error.zipCode4">Please make sure to also update the W9 information
                    to match the Legal Zip Code</div>
                <!-- </div> -->

                <div class="formRow cell medium-8 cf">
                    <span class="req">*</span>
                    <label for="addTxbxEmail">E-mail Address:</label>
                    <input type="email" name="addTxbxEmail" id="addTxbxEmail" autocomplete="off" pattern="email" required
                        :disabled="readOnly" />
                    <div class="form-error error" data-form-error-on="required">Please enter email</div>
                    <div class="form-error error" data-form-error-on="pattern">Please enter valid email address. Eg.
                        Something@domain.com</div>
                    <span class="inputNote">Used for account log in and official Ohio Lottery communications</span>
                </div>

                <div class="formRow cell medium-3 cf">
                    <label>Percent Ownership:</label>
                    <div style="display: flex; width: 30%;">
                        <input type="text" name="addPrincipalPercentOwnership" id="addPrincipalPercentOwnership"
                            pattern="(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$" minlength="1" maxlength="4" required
                            :disabled="readOnly" />
                        <span style="margin-left: .5em;">%</span>
                    </div>
                    <div class="form-error error" data-form-error-on="required"
                        data-form-error-for="addPrincipalPercentOwnership">Provide a percentage for ownership
                    </div>
                    <div class="form-error error" data-form-error-on="pattern"
                        data-form-error-for="addPrincipalPercentOwnership">Please Provide a valid percentage
                        for ownership</div>
                </div>

                <div class="formRow phoneField cell medium-3 cf">
                    <span class="req">*</span>
                    <label for="addHomePhone">Home Phone Number:</label>
                    <input type="phone" name="addHomePhone" id="addHomePhone" placeholder="###-###-####"
                        pattern="^\d{3}-\d{3}-\d{4}$" maxlength="12" autocomplete="off" required
                        :disabled="readOnly" />
                    <span class="form-error error" data-form-error-on="required">Please enter a Home Phone Number</span>
                    <span class="form-error error" data-form-error-on="pattern">Please enter valid phone number. Ex.
                        ###-###-####</span>
                </div>

                <div class="formRow phoneField cell medium-3 cf">
                    <label for="addCellPhone">Cell Phone Number:</label>
                    <input type="phone" name="addCellPhone" id="addCellPhone" placeholder="###-###-####"
                        pattern="^\d{3}-\d{3}-\d{4}$" maxlength="12" autocomplete="off"
                        :disabled="readOnly" />
                    <span class="form-error error" data-form-error-on="pattern">Please enter valid phone number. Ex.
                        ###-###-####</span>
                </div>

                <!-- if part of renewal -->
                <div v-if="hasRenewal === true" class="formRow cell medium-12 cf">
                    <span class="req">*</span>
                    <label>Has the applicant had any state license or permit revoked or suspended?</label>
                    <label class="radio">
                        <input type="radio" name="addRevoked" id="addrevokedYes" :value="null" v-model="propPrincipalConvictions" required
                            :disabled="readOnly" style="height: auto;" /> Yes
                    </label>
                    <div class="formRow cf" v-if="principalConvictions === true" style="padding-top: 0;">
                        <label class="helpTxt" for="addTxbxQuestionExplanation">Please explain:</label>
                        <textarea v-model="principalConvictionExplanations" type="text" name="addTxbxQuestionExplanation" id="addTxbxQuestionExplanation" maxlength="200"
                            required :disabled="principalConvictions === false || readOnly" style="min-height: 100px;"></textarea>
                        <div class="form-error error" data-form-error-on="required">Please Provide an explanation.</div>
                    </div>

                    <label class="radio">
                        <input type="radio" name="addRevoked" id="addrevokedNo" :value="null" v-model="propPrincipalConvictions" required
                            :disabled="readOnly" style="height: auto;" /> No
                    </label>
                    <div class="form-error error" data-form-error-on="required" data-form-error-for="addrevoked">Please select
                        one option.</div>
                </div>                
            </div>

            <!-- save should add the data into the array of principals -->
            <!-- the list should update to reflect the addition -->
            <button type="submit" class="button btnSave">Save Principal</button>
            <button class="button btnSave" @click="toggleNewPrincipal()">Cancel</button>
        </div>

        
    </div>
</template>

<script>
    import $ from 'jquery';
    import { HollowDotsSpinner } from 'epic-spinners';
export default {
    props: {
        principalGroupUpdate: Object,
        existingPrincipals: Array,
        userId: Number,
        updateGuid: String,
        hasRenewal: Boolean,
        pageContent: Object,
        principalConvictionExplanations:String,
        principalConvictions: Boolean,
        readOnly: Boolean,
        apiToken: String
    },
        components: {
            HollowDotsSpinner
    },
    data() {
        return {
            updatePrincipalsForm: false,
            ownershipPecentAlert: false,
            toggleAddPrincipal: false,
            principalLicensePermit: [],
            existingMergedPrincipals: [],
            addRevoked: false,
            revokedExp: '',
            itemIndex: null,
            error: {
                zipCode: false,
                zipCode4: false,
                emailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
                ownershipRegex: /(^100([.]\d{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/
            },
            isLoading: false,
            showError: false,
            propPrincipalConvictions: this.principalConvictions,
            salutation: '',
            backgroundCheckCompleted: true,
            ownershipInvalid: false,
            genericErrorMessage: 'There was an error processing your request.'
            
        }
    },
    mounted: function () {

        this.init();
        setTimeout(() => {
            $(document).foundation();
        }, 500);
    },
    computed: {
        POST_UPDATE_PRINCIPAL_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/UpdatePrincipal';
        },
        POST_DELETE_PRINCIPAL_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/DeletePrincipal';
        },
        POST_ADD_PRINCIPAL_API_URL() {
            return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + '/1.0/Update/AddPrincipal';
        }
    },
    methods: {
        init() {
            this.isLoading = true;

            // loop through principals within the updateWrapper
            // these will be changes made after starting the update
            for (var i = 0; i < this.principalGroupUpdate.principalUpdates.length; i++) {
                // loop through the list of pre-existing principals 
                let alreadyProcessed = false;
                for (var j = 0; j < this.existingPrincipals.length; j++) {
                    var upiad = this.principalGroupUpdate.principalUpdates[i].principal_IAD_RowID;
                    var eiad = this.existingPrincipals[j].principal_IAD_RowID;
                    if (upiad.toString() === eiad.toString()) {
                        // process those updated or deleted from original list
                        switch (this.principalGroupUpdate.principalUpdates[i].principalOperation) {
                            
                            case 1:
                                //updated
                                this.existingPrincipals[j].principal_email = this.principalGroupUpdate.principalUpdates[i].principalEmail;
                                this.existingPrincipals[j].principal_share = this.principalGroupUpdate.principalUpdates[i].principalPercentOwnership;
                                this.existingPrincipals[j].emailDisabled = false;
                                this.existingPrincipals[j].principalAuthorizedBackgroundCheck = true; 
                                break;
                            case 2:
                                //deleted
                                this.existingPrincipals.splice(j, 1);
                                break;
                            case 0:
                                // added = processed already
                                alreadyProcessed = true;
                                // update the Background Check status as it may have changed since last load
                                // set the background check authorization value
                                this.existingPrincipals[j].principalAuthorizedBackgroundCheck = this.principalGroupUpdate.principalUpdates[i].principalAuthorizedBackgroundCheck; 

                            default:
                                break;
                        }
                    }
                }
                // process added principals               
                if (this.principalGroupUpdate.principalUpdates[i].principalOperation == 0 && !alreadyProcessed) {
                    //added

                    var newPrincipal = {};
                    newPrincipal.principalOperation = this.principalGroupUpdate.principalUpdates[i].principalOperation; // set the operation value
                    newPrincipal.principalAuthorizedBackgroundCheck = this.principalGroupUpdate.principalUpdates[i].principalAuthorizedBackgroundCheck; // likely false since just added
                    newPrincipal.principal_salutation = this.principalGroupUpdate.principalUpdates[i].principalSalutation;
                    newPrincipal.principal_name = this.principalGroupUpdate.principalUpdates[i].principalFullName;
                    newPrincipal.principal_title = this.principalGroupUpdate.principalUpdates[i].principalTitle;
                    newPrincipal.principal_email = this.principalGroupUpdate.principalUpdates[i].principalEmail;
                    newPrincipal.principal_share = this.principalGroupUpdate.principalUpdates[i].principalPercentOwnership;
                    newPrincipal.principal_IAD_RowID = this.principalGroupUpdate.principalUpdates[i].principal_IAD_RowID;
                    newPrincipal.emailDisabled = true;
                    this.existingPrincipals.push(newPrincipal);
                }
            }
            for (let w = 0; w < this.existingPrincipals.length; w++) {
                this.existingPrincipals[w].errorLicense = false;
                this.existingPrincipals[w].errorLicenseReason = false;
                // if (this.existingPrincipals[w].emailDisabled === true) {
                //     this.backgroundCheckCompleted = false; // not sure about this one
                // }
            }
            this.existingMergedPrincipals = this.existingPrincipals;
            
            this.isLoading = false;
            $(document).foundation();

            this.initModal();
        },
        checkOwnership(item) {
            let ownership = $('.ownership'),
                percent = 0;

            for (let i = 0; i < ownership.length; i++) {
                percent = percent + parseInt(ownership[i].value);
            }

            if (percent > 100) {
                this.ownershipInvalid = true;
            } else {
                this.ownershipInvalid = false;
            }

            if (item > 100 || item < 0) {
                return false;
            } else {
                return true;
            }
        },
        setRenewType(val, item) {
            // this.formReset();
            if (val === 0) {
                item.principal_LicensePermitRevoked = false;
            } else if (val === 1) {
                item.principal_LicensePermitRevoked = true;
            } else {
                console.log("Nothing is working")
            }
        },
        validateLicense(index, item) {
            // let item = this.existingMergedPrincipals[index];\
            item.errorLicense = true;
            // $('#changePrincipalInfo'+index).foundation('open');
            // :data-open="'changePrincipalInfo'+index"
        },
        saveEditPrincipal: function (index, email, share, iadrow) {
            //call to update principal
            // let target = $('#changePrincipalInfo'+index);
            let item = this.existingMergedPrincipals[index];
            if (item.principal_LicensePermitRevoked === null) {
                item.errorLicense = true;
            } else {
                this.existingMergedPrincipals[index].errorLicense = false;
            }
            
            this.updatePrincipal(email, share, iadrow);
        },
        addNewPrincipal: function (objForm) {
            objForm.principalOperation = 0;
            this.addPrincipal(objForm);
        },
        async addPrincipal(principal) {
            this.showError = false;
            let body = {};
            body.userId = this.userId;
            body.updateWrapperGuid = this.updateGuid;
            body.name = principal.principalFullName;
            body.email = principal.principalEmail;
            body.ownership = principal.principalPercentOwnership;
            body.principal = principal;
            body.principal.principalState = 'OH';
            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.apiToken,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    body
                )
            };
            this.isLoading = true;
            let response = await (await fetch(this.POST_ADD_PRINCIPAL_API_URL, appsRequestOptions)).json();
            if (response.statusCode == 200) {
                //added
                var newPrincipal = {};
               
                newPrincipal.principal_salutation = principal.principalSalutation;
                newPrincipal.principal_name = principal.principalFullName;
                newPrincipal.principal_title = principal.principalTitle;
                newPrincipal.principal_email = principal.principalEmail;
                newPrincipal.principal_share = principal.principalPercentOwnership;
                newPrincipal.emailDisabled = true;
                newPrincipal.principal_IAD_RowID = response.data;
                newPrincipal.principalAuthorizedBackgroundCheck = false;
                newPrincipal.principalOperation = 0;
                this.existingMergedPrincipals.push(newPrincipal);
            }
            else {
                //show error
                this.genericErrorMessage = response.data;
                this.showError = true;
            }
            // $(document).foundation();
            this.isLoading = false;
            this.checkPrincipalEmail();
        },
        checkPrincipalEmail() {
            let email = true;
            for (let w = 0; w < this.existingPrincipals.length; w++) {
                if (this.existingPrincipals[w].emailDisabled === true) {
                    email = false;
                }
            }

            email === false ? this.backgroundCheckCompleted = false : this.backgroundCheckCompleted = true;
        },
        async updatePrincipal(email, share, iadrow) {
            this.showError = false;
            let body = {};
            body.userId = this.userId;
            body.updateWrapperGuid = this.updateGuid;
            body.email = email;
            body.ownership = parseInt(share);
            body.iadrow = iadrow;
            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.apiToken,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    body
                )
            };
            this.isLoading = true;
            this.checkPrincipalEmail();

            let response = await (await fetch(this.POST_UPDATE_PRINCIPAL_API_URL, appsRequestOptions)).json();
            if (response.statusCode == 200) {
                // /1.0/Update/UpdatePrincipal
                //todo - what do we do here? anything? the data is already in the inputs?
                setTimeout(() => {
                    $(document).foundation();
                    location.reload();
                }, 500);
            }
            else {
                this.showError = true;
                setTimeout(() => {
                    $(document).foundation();
                }, 500);
            }
            
            this.isLoading = false;
        },
        initModal() {
            $(document).foundation();

            let _this = this;

            for (let r = 0; r < this.existingMergedPrincipals.length; r++) {
                let item = this.existingMergedPrincipals[r];

                $(document).on('closed.zf.reveal', '#changePrincipalInfo'+r+'[data-reveal]', function () {
                    _this.saveEditPrincipal(r, item.principal_email, item.principal_share, item.principal_IAD_RowID, item.principal_LicensePermitRevoked, item.principal_LicensePermitRevokedExplanation);
                });
            }
        },
        openModal: function (index) {
            this.itemIndex = index;
            // alert();
        },
        closeModal: function () {
            $('#removePrincipalsModal').foundation('close');
        },
        removePrincipal: function (email, iad) {
            this.deletePrincipal(email, iad);
        },
        async deletePrincipal(email, iadrow) {
            this.showError = false;
            let body = {};
            body.userId = this.userId;
            body.updateWrapperGuid = this.updateGuid;
            body.email = email;
            body.iadrow = iadrow;
            const appsRequestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.apiToken,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(
                    body
                )
            };

            this.isLoading = true;
            let response = await (await fetch(this.POST_DELETE_PRINCIPAL_API_URL, appsRequestOptions)).json();
            if (response.statusCode == 200) {
                //todo remove from principal from grid
                for (var j = 0; j < this.existingPrincipals.length; j++) {
                    var eiad = this.existingPrincipals[j].principal_IAD_RowID;
                    if (iadrow.toString() === eiad.toString()) {
                        //deleted
                        this.existingPrincipals.splice(j, 1);
                    }
                }
                this.existingMergedPrincipals = this.existingPrincipals;
                location.reload();
            }
            else {
                this.showError = true;
            }
            // $(document).foundation();
            this.isLoading = false;
            this.checkPrincipalEmail();
        },
        toggleNewPrincipal: function () {
            let _this = this;
            this.toggleAddPrincipal = !this.toggleAddPrincipal;
            setTimeout(() => {
                $(document).foundation();
                $('#addPrincipalsForm').on("invalid.zf.abide", function (ev, elem) {
                    let field = ev.target;
                    field.id === 'LegalZip' && (_this.error.zipCode = true);
                    field.id === 'LegalZipPlus4' && (_this.error.zipCode4 = true);
                }).on("valid.zf.abide", function (ev, elem) {
                    let field = ev.target;
                    field.id === 'LegalZip' && (_this.error.zipCode = false);
                    field.id === 'LegalZipPlus4' && (_this.error.zipCode4 = false);
                }).on("formvalid.zf.abide", function (ev, frm) {
                    let form = frm.attr('id');

                    
                    let objForm = {

                        principalFirstName: $('#' + form + ' input[name="txbxFirstName"]').val(),
                        principalLastName: $('#' + form + ' input[name="txbxLastName"]').val(),
                        principalFullName: $('#' + form + ' input[name="txbxFirstName"]').val() + " " + $('#' + form + ' input[name="txbxLastName"]').val(),
                        principalSalutation:  $('#salutationSelect').val(),
                        principalTitle: $('#' + form + ' input[name="addTxbxTitle"]').val(),
                        principalEmail: $('#' + form + ' input[name="addTxbxEmail"]').val(),
                        principalStreetAddress: $('#' + form + ' input[name="addStreetAddress_txtText"]').val(),
                        principalCity: $('#' + form + ' input[name="addCity_txtText"]').val(),
                        principalState: 'OH',
                        principalZip: $('#' + form + ' input[name="addLegalZip"]').val(),
                        principalZipPlus4: $('#' + form + ' input[name="addLegalZipPlus4"]').val(),
                        principalPercentOwnership: $('#' + form + ' input[name="addPrincipalPercentOwnership"]').val(),
                        principalHomePhoneNumber: $('#' + form + ' input[name="addHomePhone"]').val(),
                        principalCellPhoneNumber: $('#' + form + ' input[name="addCellPhone"]').val(),
                    }
                    _this.addNewPrincipal(objForm);
                    _this.toggleNewPrincipal();
                    $('#' + form + ' :input').val('');
                    $('#' + form + ' :radio').prop('checked',false);
                });
            }, 400)
        }
    }
}
</script>