<template>
    <div>
        <div class="rcWelcomeContent module">
            <!-- header content -->
            <div v-if="!isUserLogged">
                <p v-html="pageContent.rcCommonIntro"></p>
                <div class="grid-x grid-margin-x grid-margin-y module module_callOut">
                    <div class=" columns small-12 medium-8 cell">
                        <h3>Sign In</h3>
                        <div v-html="pageContent.rcSignIn"></div>
                        <a class="button btn-secondary btn_popupLogIn" href="#">Sign In</a>
                    </div>
                    <div class=" columns small-12 medium-4 cell">
                        <h3>Register</h3>
                        <div v-html="pageContent.rcRegister"></div>
                    </div>
                </div>

                <div v-html="pageContent.rcBecomeRetailer"></div>
            </div>

            <p v-if="isUserLogged && (isUserRetailer || isUserPrincipal || isUserPrincipalUpdates || isUserApplicant || isUserChainApplicant)"
                v-html="pageContent.rcAuthedIntro"></p>

            <div v-if="isUserLogged && !isUserRetailer && !isUserPrincipal && !isUserPrincipalUpdates && !isUserApplicant && !isUserChainApplicant"
                v-html="pageContent.rcCommonIntro">
            </div>
        </div>


        <div v-if="isUserLogged && (isUserRetailer || isUserPrincipal || isUserPrincipalUpdates || isUserApplicant || isUserChainApplicant)" class="module">
            <h3>eApplication</h3>
            <div v-html="pageContent.rcEappIntro"></div>
        </div>
        <div v-if="isUserLogged && !isUserRetailer && !isUserPrincipal && !isUserPrincipalUpdates && !isUserApplicant && !isUserChainApplicant" class="module">
            <h3>eApplication</h3>
            <div v-html="pageContent.rcEappInfo"></div>
        </div>
        <MyApplications v-if="isUserLogged"></MyApplications>

        <h3>License Renewals &amp; Updates</h3>
        <div class="module" v-if="!isUserLogged" v-html="pageContent.rcUpdatesPublic"></div>
        <div class="module" v-if="isUserLogged">
            <div v-if="!isUserRetailer && !isUserPrincipalUpdates && !isUserPrincipal" v-html="pageContent.rcUpdatesAuthedRegular"></div>

            <div v-if="isUserRetailer || isUserPrincipalUpdates" v-html="pageContent.rcUpdatesLink"></div>

            <div v-if="(isUserPrincipal || isUserRetailer) && !isUserPrincipalUpdates" v-html="pageContent.rcUpdatesAssociate"></div>

            <div v-if="(isUserPrincipal || isUserRetailer) && !isUserPrincipalUpdates" v-html="pageContent.rcUpdatesAuthedPrincipal"></div>

        </div>

        <h3 id="PayMyBill">Pay My Bill</h3>
        <div v-if="!isUserLogged || (isUserLogged && !isUserRetailer && !isUserPrincipal && !isUserPrincipalUpdates && !isUserApplicant && !isUserChainApplicant)">
            <div v-html="pageContent.rcPayPublic"></div>
            <div class="cta-button" v-if="!isUserLogged">
                <a class="button btn-secondary btn_popupLogIn" href="#">Sign In</a>
                <a class="button btn-secondary" href="/eApplications/Registration">Create Your Account</a>
            </div>
        </div>

        <p v-if="isUserLogged && (isUserPrincipal || isUserRetailer)" v-html="pageContent.rcPayAuthed"></p>
        
        <PayMyBill v-if="isUserLogged && (isUserPrincipal || isUserPrincipalUpdates || isUserRetailer)"></PayMyBill>

    </div>
</template>
<script>
import $ from 'jquery';
import PayMyBill from '../../ViewComponents/Eapplications/Listings/my-bills.vue';
import MyApplications from '../../ViewComponents/Eapplications/Listings/my-applications-status.vue';

export default {
    components: {
        PayMyBill,
        MyApplications
    },
    data: function () {
        return {
            isUserLogged: true,
            isUserRetailer: true,
            isUserPrincipal: false,
            isUserPrincipalUpdates: false,
            isUserApplicant: false,
            isUserChainApplicant: false,
            pageContent: {},
            contentKey: 'retailer-central-content',
            introCommon: ''
        }
    },
    mounted: function () {
        this.getContent();
    },
    methods: {
        getContent() {
            let cookieContent = JSON.parse(localStorage.getItem('rc_content'));

            if (cookieContent !== undefined && cookieContent !== "" && cookieContent !== null) {
                this.pageContent = JSON.parse(cookieContent);
                this.setTheStage();
            } else {
                this.getToken();
            }
        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
        },
        fetchData(apiToken) {
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + '/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=' + this.contentKey, {
                headers: {
                    'Authorization': 'Bearer ' + apiToken
                }
            })
                .then(response => response.json())
                .then(json => {
                    let element = json.data[0].centralContent,
                        elementParse = JSON.parse(element);

                    localStorage.setItem('rc_content', JSON.stringify(element));
                    this.pageContent = elementParse;
                    this.setTheStage();
                })
        },
        setTheStage() {
            // console.log(this.pageContent);
            // determine user info and roles
            this.isUserLogged = this.isUserAuthed();
            this.isUserRetailer = this.isUserInRole('eApp-Retailer');
            this.isUserPrincipal = this.isUserInRole('eAppPrincipal');
            this.isUserPrincipalUpdates = this.isUserInRole('eAppPrincipalUpdates');
            this.isUserApplicant = this.isUserInRole('eAppApplicant');
            this.isUserChainApplicant = this.isUserInRole('eApp-ChainApplicant');

            // this.isUserLogged = false;
            // this.isUserRetailer = false;
            // this.isUserPrincipal = false;
            // this.isUserPrincipalUpdates = false;
            // this.isUserApplicant = false;
            // this.isUserChainApplicant = false;
            
            $("#globalLogin, .btn_popupLogIn").on('click', function () {
                event.preventDefault();
                $("#globalLogInModal").show();
                $("#globalLoginBlocker").show();
            })
        }
    }
};
</script>
<style lang="scss" scoped>
</style>