<template>
    <div class="full holidayMultiplierProgess drawingList drawingListResponsive">
        <h2>"Holiday Bonus" entry progress</h2>
        <div class="holidayMultiplierTickets">
            <img v-for="ticket in tickets" 
                 :src="ticket.progressCount > 0 ? ticket.activeUrl : ticket.inactiveUrl" 
                 :alt="ticket.ticketName + ' - Holiday Multiplier Promotion'"
            />
        </div>
        <div class="holidayMultiplierProgress">
            <!-- Show entry progress for each game or a total... something -->
        </div>
    </div>
</template>
<script>
    export default {
        data: function() {
            return {
                tickets: [
                {
                    ticketName: '',
                    activeUrl: '',
                    inactiveUrl: '',
                    progressCount: 0
                },
                {
                    ticketName: '',
                    activeUrl: '',
                    inactiveUrl: '',
                    progressCount: 0
                },
                {
                    ticketName: '',
                    activeUrl: '',
                    inactiveUrl: '',
                    progressCount: 0
                },
                {
                    ticketName: '',
                    activeUrl: '',
                    inactiveUrl: '',
                    progressCount: 0
                },
                {
                    ticketName: '',
                    activeUrl: '',
                    inactiveUrl: '',
                    progressCount: 0
                },
                {
                    ticketName: '',
                    activeUrl: '',
                    inactiveUrl: '',
                    progressCount: 0
                },
            ]
            }
        },
        mounted: function() {
            console.log("Component loaded");
        },
        methods: {
            getHolidayMultiplierProgress() {
                //call the API endpoint for retrieving Holiday Multiplier Progress
                //endpoint - GetPromotionNotificationsForUser
            },
            updateTicketsArray() {
                //after getting the Holiday Multiplier Progress from the API
                //update the tickets array data property
            }

        }
    }
</script>
<style>

</style>