<template>
    <div class="progressive-jackpot-value" v-if="showWinners">
        <h3>Recent Winners</h3>

			<ul class="pjList" >
				<li class="pjWinner" v-for="winner in games" :key="winner.id">
                     <strong>{{ winner.retailer.businessName }}, ${{  numberWithCommas(winner.jackpotAmount) }}</strong>
					{{ winner.retailer.address }}, {{ winner.retailer.city }}, {{ winner.retailer.state }}
					<span class="pjTime">on {{ customFormatter(winner.date) }}</span>

				</li>
			</ul>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        components: {
        },
        data: function() {
            return {
                familyId: document.getElementById('familyId').value,
                games: [],
                recentWinners: [],
                showWinners: true
            }
        },
        mounted: function() {

            console.log(this.familyId)

            this.getAPItoken()
            .then((token) => {
                this.fetchData(token);
            });
        },
        methods: {
            customFormatter(date) {
                return moment(date).format('MM/DD/yyyy HH:mm:ss');
            },
            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            fetchData(token) {
                let apiToken = token,
                    id       = this.familyId,
                    records  = 10

                return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/Games/OnlineGames/OnlineGame/GetOnlineGameProgressiveFamilyWinnerData/"+id+"/"+records, {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    let response = json.data;
                    console.log(response.games)
                    if(!response.games[0].winners.length){
                        this.showWinners = false;

                    }else{
                        this.showWinners = true;
                        let winnersArr = [];
                        response.games.forEach((element) => {
                            winnersArr = [...winnersArr, ...element.winners];
                            
                        });

                        console.log(winnersArr);
                        let orderWinnersByDate = winnersArr.sort(function(a,b){
                            return new Date(b.date) - new Date(a.date);
                        });

                        this.games = orderWinnersByDate.slice(0, 10)
                    }

                    
                    

                    


                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;

                });
            }
        }
    };
</script>


<style lang="scss" scoped>
</style>