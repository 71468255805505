<template>
    <div>

        <div class="poll-widget">
            <h2>Poll</h2>

            <div class="poll-wrapper">

                <div>

                    <span v-if="errorMsg">{{ errorMsg }}</span>

                    <span class="poll-question">{{ pollQuestion }}</span>

                    <div class="poll-answers">
                        <div class="poll-list" :key="updatePoll">
                            <div class="poll-answer" v-for="answer in pollAnswers" :key="answer.id"
                                :id="answer.answerQuestionID" :answer-id="answer.answerID">

                                <label :class="!isUserLogged && !isUserCanVote || isUserLogged && !isUserCanVote? 'disabled' : ''">
                                    <input type="radio" :value="answer.answerText" :id="answer.answerID" name="radio"
                                        @change="selectedRadioAnswer($event)" v-if="isUserLogged && isUserCanVote" />
                                    {{ answer.answerText }}
                                </label>
                            </div>

                        </div>
                    </div>

                    <div class="poll-footer">
                        <div v-if="showVoteBtn">
                            <button type="button" class="button btn-default" @click="submitPoll"
                                v-if="isUserLogged && isUserCanVote">{{ submitText }}</button>
                            <p class="pollAlert" v-if="!isUserLogged">
                                <a href="#" class="btn_signIn btn_popupLogIn" id="globalSignIn">Sign In</a> or <a href="/Account/Register">Register</a> to
                                Vote.
                            </p>
                        </div>



                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'

export default {
    components: {

    },
    data: function () {
        return {
            isUserLogged: this.isUserAuthed(),
            isUserCanVote: false,
            isUserVoted: false,
            widgetSurveyId: document.getElementById('survey-id').value,
            widgetSubmitText: document.getElementById('submit-text').value,
            widgetRecentPostsDisplays: document.getElementById('recent-posts-display').value,
            updatePoll: 0,
            surveyId: '',
            questionId: '',
            answerID: '',
            responseText: '',
            pollQuestion: '',
            pollAnswers: [],
            answerData: [],
            submitText: 'Submit',
            errorMsg: '',
            globalToken: '',
            showVoteBtn: true,
            isDisabled: false
        }
    },
    mounted: function () {
        this.getToken()

        let apiToken = this.$cookies.get('apiToken');
        if (apiToken.user.username == "mobilepublic@mtllc.com") {
            this.isDisabled = true;
        }

        if (this.widgetSubmitText === '') {
            this.submitText = 'Submit'
        } else {
            this.submitText = this.widgetSubmitText;
        }
    },
    methods: {
        signInClk() {
            $("#globalLogInModal").show();
        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    // console.log('----->>>> ', this.isUserLogged);
                    this.fetchData(token);
                    this.getActiveSurveys(token);
                    this.loadComponent();
                });
        },
        loadComponent() {
            $(document).ready(function () {
                $('.close-button').on('click', function () {
                    $("#globalLogInModal").hide();
                    $("#globalLoginBlocker").hide();
                })

                $(".btn_popupLogIn").on('click', function () {
                    $("#globalLogInModal").show();
                    $("#globalLoginBlocker").show();
                });
            })
        },
        fetchData(token) {
            let apiToken = token;
            this.globalToken = token;
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Survey/Survey/GetSurveyByIdentifier?surveyIdentifier=" + this.widgetSurveyId, {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {

                    console.log('------>>>> ', json);

                    if (json.error) {
                        this.errorMsg = json.error.message
                        document.getElementsByClassName('poll-question-widget')[0].style.display = "none";
                    }
                    let results = json.data;
                    for (var i = 0; i < results.questions.length; i++) {

                        if (results.questions[i].questionType !== 0) {
                            this.errorMsg = "Invalid selected survey, Please contact an administrator"
                            return
                        }
                    }
                    if (results.questions.length >= 2) {
                        this.errorMsg = "Invalid selected survey, Please contact an administrator"
                        return
                    }

                    this.pollQuestion = results.questions[0].text;
                    this.pollAnswers = results.questions[0].answers;
                    this.surveyId = results.surveyID;

                    if (this.widgetRecentPostsDisplays === 'Always') {
                        this.fetchStats();
                    }
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;

                });
        },
        fetchStats() {
            // Get survey stats
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Survey/Survey/GetSurveyStats?surveyId=" + this.surveyId, {
                headers: {
                    'Authorization': 'Bearer ' + this.globalToken,
                },
            })
                .then(response => response.json())
                .then(json => {

                    let statsResults = json.data;
                    let total = 0,
                        arrOfQuantity = [],
                        percentNumbers = [];


                    let totalQuantity = statsResults.map(a => a.quantity);

                    for (var j = 0; j < totalQuantity.length; j++) {
                        total += totalQuantity[j];
                        arrOfQuantity.push(totalQuantity[j])
                    }

                    arrOfQuantity.forEach((sum) => {
                        let numPercent = Math.round((sum / total) * 100);
                        percentNumbers.push(numPercent);
                    });


                    for (var i = 0; i < statsResults.length; i++) {
                        // console.log( statsResults[i].answerID);
                        // console.log( percentNumbers[i]);
                        document.querySelector('[answer-id="' + statsResults[i].answerID + '"]').insertAdjacentHTML("beforeend",
                            "<span class='width-percent' style='width:" + percentNumbers[i] + "%'></span><span class='text-percent'>" + percentNumbers[i] + "%</span>");
                    }


                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;

                });
        },
        getActiveSurveys(token) {
            let apiToken = token;
            this.globalToken = token;
            let getCurrentUser = this.$cookies.get('apiToken');
            let getCurrentUserID = getCurrentUser.user.userId;
            
            // 225
            return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + "/1.0/Survey/Survey/GetActiveSurveys?userID=" + getCurrentUserID, {
                headers: {
                    'Authorization': 'Bearer ' + apiToken,
                },
            })
                .then(response => response.json())
                .then(json => {
                    const data = json.data;
                    // console.log('getActiveSurveys ---', data);
                    const surveyIDNumb = data.find(survey => survey.surveyID === this.surveyId);
                    surveyIDNumb ? this.isUserCanVote = true : this.isUserCanVote = false;

                    //  console.log('surveyIDNumb--------', surveyIDNumb);
                    //      console.log('getCurrentUserID >>>>', getCurrentUserID);
                    //  if (surveyIDNumb) {
                    //      console.log("SurveyID: 10 exists!");
                    //  } else {
                    //      console.log("SurveyID: 10 does not exist.");
                    //  }

                })
                .catch(error => {
                    return this.errorMessage = error;

                });
        },


        selectedRadioAnswer(event) {
            let getIdOfQuestionWrapper = event.currentTarget.parentElement.parentElement.id;
            let getAnswerId = event.currentTarget.id;
            let getQuestionText = event.currentTarget.value;



            this.questionId = getIdOfQuestionWrapper;
            this.answerID = getAnswerId;
            this.responseText = getQuestionText;
        },
        submitPoll() {
            let currentuserID;
            if (this.globalUser == undefined || this.globalUser == null) {
                var getCurrentUser = this.$cookies.get('apiToken');
                currentuserID = getCurrentUser.user.userId;
            } else {
                //coming from mobile app / no cookies
                currentuserID = this.globalUser;
            }

            let submitJson = {
                surveyID: parseInt(this.surveyId),
                userId: parseInt(currentuserID),
                questionId: parseInt(this.questionId),
                answerId: parseInt(this.answerID),
                responseText: this.responseText
            }


            const urlProtocol = 'https:';
            const apiToken = this.globalToken;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + apiToken
                },
                body: JSON.stringify(submitJson)
            };
            fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Survey/Survey/SubmitAnswer", requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.statusCode === 200) {
                        this.showSurvey = false
                        this.displaySuccessMsg = true
                        this.showBack = true;
                        this.isUserCanVote = false;
                    }
                    this.showVoteBtn = false;
                    if (this.widgetRecentPostsDisplays === 'After-Participation') {
                        this.fetchStats();
                    }

                    if (this.widgetRecentPostsDisplays === 'Always') {
                        this.updatePoll += 1;
                        this.fetchStats();
                    }
                })
        }
    },
    computed: {

    },
};
</script>

<style lang="scss" scoped></style>