<template>
    <div class="full">
        <div v-if="!isUserLogged"><SignInRegister></SignInRegister></div>
    </div>
</template>
<script>
    import SignInRegister from '../../ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        components: {
            SignInRegister
        },
        data: function() {
            return {
                isUserLogged: false,
                token: {},
                user: {},
                id: ''
            }
        },
        mounted: function() {
            this.isUserLogged = this.isUserAuthed();
            this.getToken();
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;

                    if (this.isUserLogged) {

                        this.user = this.$cookies.get('apiToken');
                        this.id = this.user.user.userId;

                        //hardcoded fieldObj
                        // let fieldObj = {};
                        // fieldObj.firstName = "Matthew";
                        // fieldObj.lastName = "Ewing";
                        // fieldObj.birthdate = "01/16/1990"

                        this.launchPersonaEmbed(this.id);
                    }
                })
            }
        }
    }
</script>