<template>
    <div>
        <business-name v-if="visiblePanel == 'business-name' && !loading" :businessInfoJson="businessInfoJson"
            @onSaveClick="onSaveClick" :readOnly="readOnly"></business-name>
        <business-address v-if="visiblePanel == 'business-address' && !loading" :businessInfoJson="businessInfoJson"
            :readOnly="readOnly" @onSaveClick="onSaveClick"></business-address>

        <business-sale v-if="visiblePanel == 'business-sale' && !loading" :businessInfoJson="businessInfoJson" :appIsASoB="application.applicationIsASoB"
            :checkBox="validateBusinessSaleCheckBox()" :readOnly="readOnly" @onSaveClick="onSaveClick"></business-sale>

        <business-hours v-if="visiblePanel == 'business-hours' && !loading" :businessInfoJson="businessInfoJson"
            :readOnly="readOnly" @onSaveClick="onSaveClick"></business-hours>
        <principals-list v-if="visiblePanel == 'principals' && !loading" :appJson="appJson" :principalsJson="principalsJson"
            :businessInfoJson="businessInfoJson" :readOnly="readOnly" @onPrincipalSaveClick="onPrincipalSaveClick"
            @onSaveClick="onSaveClick" @deletePrincipal="deletePrincipal"></principals-list>
        <contact v-if="visiblePanel == 'contact' && !loading" :businessInfoJson="businessInfoJson"
            :contactInfoJson="contactInfoJson" :readOnly="readOnly" @onSaveClick="onSaveClick"></contact>
    </div>
</template>
<script>

import $ from 'jquery';
import BusinessName from './sections/business-name';
import BusinessAddress from './sections/business-address';
import BusinessHours from './sections/business-hours';
import BusinessSale from './sections/business-sale';
import Contact from './sections/contact/contact';
import PrincipalsList from './sections/principals/principals-list'
export default {
    components: {
        BusinessName,
        BusinessAddress,
        BusinessHours,
        BusinessSale,
        Contact,
        PrincipalsList
    },
    props: {
        applicationInfoJson: String,
        businessJson: String,
        contactJson: String,
        principalsJson: String,
        visiblePanel: String,
    },
    data() {
        return {
            appJson: '',
            businessInfoJson: '',
            contactInfoJson: '',
            businessLegalName: '',
            doingBusinessAs: '',
            businessPhone: '',
            alternateBusinessPhone: '',
            businessFax: '',
            businessInformation: {},
            contactInformation: {},
            principals: {},
            application: {},
            loading: true,
            readOnly: false,
        }
    },
    mounted: function () {
        this.deserializeBusinessName();
        this.loading = false;
    },
    methods: {
        deserializeBusinessName() {
            this.appJson = this.applicationInfoJson;
            this.businessInfoJson = this.businessJson;
            this.contactInfoJson = this.contactJson;
            this.businessInformation = JSON.parse(this.businessJson);
            this.contactInformation = JSON.parse(this.contactJson);
            this.principals = JSON.parse(this.principalsJson);

            this.application = JSON.parse(this.appJson);
            this.readOnly = this.application.applicationStatus > 1 && this.application.applicationStatus != 8;
        },
        onSaveClick(request) {
            // console.log(this.validate(request))
            
            if (this.validate(request)) {
                console.log(request)
                // eapplication-form.vue
                this.$emit('onSaveClick',
                    // {
                    //     dir: request.dir,
                    //     businessInformation: request.businessInformation,
                    //     contactInformation: request.contactInformation,
                    //     principals: request.principals,
                    //     principalOwnershipCheck: request.principalOwnershipCheck,
                    //     principalPercentOwnershipMessage: request.principalPercentOwnershipMessage
                    // });
                    request);
            }

        },
        onPrincipalSaveClick(pr) {
            this.$emit('onPrincipalSaveClick', pr);
        },
        deletePrincipal(id) {
            this.$emit('deletePrincipal', id);
        },
        validate(request) {
            let valid = false;
            switch (this.visiblePanel) {
                case "business-name":
                    this.businessInformation = request.businessInformation;
                    valid = this.validateBusinessName();
                    break;
                case "business-address":
                    this.businessInformation = request.businessInformation;
                    valid = this.validateBusinessAddress();
                    break;
                case "business-sale":
                    this.businessInformation = request.businessInformation;
                    valid = this.validateSaleOfBusinessStart();
                    break;
                case "business-sale-agreement":
                    this.businessInformation = request.businessInformation;
                    //this gets validated in its own control so it is valid by the time it gets here
                    valid = true;
                    break;
                case "business-hours":
                    this.businessInformation = request.businessInformation;
                    valid = this.validateBusinessHours();
                    break;
                case "contact":
                    this.contactInformation = request.contactInformation;
                    valid = this.validateContact();
                    break;
                case "principals":
                    //this gets called from the list view so there is nothing to validate?
                    valid = true;
                    break;
                default:
                    break;
            }
            return valid;
            // return false;
        },
        validateBusinessName() {
            $('#legalNameError').hide();
            $('#doingBusinessAsError').hide();
            $('#businessPhoneError').hide();

            if (this.businessInformation.businessLegalName
                && this.businessInformation.businessDoingBusinessAs
                && this.businessInformation.businessPhoneNumber) {
                return true;
            }

            if (!this.businessInformation.businessLegalName) {
                $('#legalNameError').show();
            }
            if (!this.businessInformation.businessDoingBusinessAs) {
                $('#doingBusinessAsError').show();
            }
            if (!this.businessInformation.businessPhoneNumber) {
                $('#businessPhoneError').show();
            }

            // console.log("Business Name")
            return false;
        },
        validateBusinessAddress() {
            $('#businessStreetAddressError').hide();
            $('#businessZipCodeError').hide();
            $('#businessCityError').hide();
            $('#businessCountyError').hide();
            $('#businessStateError').hide();
            $('#mailingNameError').hide();
            $('#mailingStreetAddressError').hide();
            $('#mailingZipError').hide();
            $('#mailingCityError').hide();
            $('#mailingStateError').hide();

            if (this.businessInformation.businessStreetAddress
                && this.businessInformation.businessCity
                // && this.businessInformation.businessCounty
                && this.businessInformation.businessState
                && this.businessInformation.businessZip
                && this.businessInformation.businessZipPlus4) {

                if (!this.businessInformation.mailingSameAsBusiness) {
                    if (this.businessInformation.mailingName
                        && this.businessInformation.mailingStreetAddress
                        && this.businessInformation.mailingZip
                        && this.businessInformation.mailingZipPlus4
                        && this.businessInformation.mailingCity
                        && this.businessInformation.mailingState) {
                        return true;
                    }
                    else {
                        // console.log(this.businessInformation)
                        if (!this.businessInformation.mailingName) {
                            // console.log("MailingName")
                            // $('#mailingNameError').show();
                        }
                        if (!this.businessInformation.mailingZip || !this.businessInformation.mailingZipPlus4) {
                            // console.log("MailingZip")
                            // $('#mailingZipError').show();
                        }
                        if (!this.businessInformation.mailingCity) {
                            // console.log("MailingCity")
                            // $('#mailingCityError').show();
                        }
                        if (!this.businessInformation.mailingState) {
                            // console.log("MailingState")
                            // $('#mailingStateError').show();
                        }
                        if (!this.businessInformation.mailingStreetAddress) {
                            // console.log("MailingAdd")
                            // $('#mailingStreetAddressError').show();
                        }
                    }
                }
                else {
                    return true;
                }
            }

            if (this.businessInformation.businessStreetAddress === null || !this.businessInformation.businessStreetAddress) {
                $('#businessStreetAddressError').show();
            }
            if (this.businessInformation.businessCity === null || !this.businessInformation.businessCity) {
                $('#businessCityError').show();
            }
            if (this.businessInformation.businessCounty === null && this.businessInformation.businessState === 'OH' || !this.businessInformation.businessCounty && this.businessInformation.businessState === 'OH') {
                $('#businessCountyError').show();
            }
            if (this.businessInformation.businessState === null || !this.businessInformation.businessState) {
                $('#businessStateError').show();
            }
            if (this.businessInformation.businessZip === null || !this.businessInformation.businessZip || this.businessInformation.businessZipPlus4 === null ||!this.businessInformation.businessZipPlus4) {
                $('#businessZipCodeError').show();
            }

            // console.log("Business Address")
            return false;
        },
        validateBusinessHours() {
            $('#businessHours1Error').hide();
            $("#businessHours2Error").hide();
            $("#businessHours3Error").hide();
            $("#businessHours4Error").hide();
            $("#businessHours5Error").hide();
            $("#businessHours6Error").hide();
            $("#businessHours7Error").hide();

            let oneDayHasHours = false;
            let allDaysValid = true;

            function validateAndShowError(businessHours, errorMessage) {
                const b = JSON.parse(businessHours);

                if (b.Open24hours) {
                    oneDayHasHours = true;
                    return true;
                }
                if (b.Closed) {
                    return true;
                }

                if (!b.Open24hours && !b.Closed && (b.OpenHour === '' || b.OpenHour === '--' || b.CloseHour === '' || b.CloseHour === '--')) {
                    // oneDayHasHours = false;
                    $(errorMessage).show();
                    return false;
                } else {
                    // if (b.OpenHour < b.CloseHour) {
                        oneDayHasHours = true;
                    // }
                }

                return true;
            }

            for (let i = 1; i <= 7; i++) {
                const businessHours = this.businessInformation['businessHours' + i];
                const errorMessage = '#businessHours' + i + 'Error';

                if (!validateAndShowError(businessHours, errorMessage)) {
                    allDaysValid = false;
                }
            }

            if (allDaysValid && oneDayHasHours) {
                return true;
            }

            if (!oneDayHasHours) {
                $("#oneDayNeedsHoursError").show();
            }

            console.log(oneDayHasHours);
            console.log(allDaysValid)

            return false;
        },
        validateSaleOfBusinessStart() {
            $('#emailError').hide();
            if (this.businessInformation.saleOfBusiness) {
                if (this.businessInformation.businessOriginalOwnerEmail && this.emailValidation(this.businessInformation.businessOriginalOwnerEmail)) {
                    return true;
                }
                else {
                    $('#emailError').show();
                    // console.log("Business Start")
                    return false;
                }
            }
            return true;
        },
        validateContact() {
            $('#primaryContactNameError').hide();
            $('#primaryContactTitleError').hide();
            $('#primaryContactEmailError').hide();
            $('#primaryContactPhoneError').hide();
            $('#secondaryContactNameError').hide();
            $('#secondaryContactTitleError').hide();
            $('#secondaryContactEmailError').hide();
            $('#secondaryContactPhoneError').hide();


            let valid = true;
            if (!this.contactInformation.primaryContactName) {
                valid = false;
            }

            if (!this.contactInformation.primaryContactTitle) {
                valid = false;
                $('#primaryContactTitleError').show();
            }

            if (!this.contactInformation.primaryContactEmail || !this.emailValidation(this.contactInformation.primaryContactEmail)) {
                valid = false;
                $('#primaryContactEmailError').show();
            }

            if (!this.contactInformation.primaryContactPhone || !this.phoneValidation(this.contactInformation.primaryContactPhone)) {
                valid = false;
                $('#primaryContactPhoneError').show();
            }

            if (this.contactInformation.secondaryContactInfo) {
                if (!this.contactInformation.secondaryContactName) {
                    valid = false;
                    $('#secondaryContactNameError').show();
                }

                if (!this.contactInformation.secondaryContactTitle) {
                    valid = false;
                    $('#secondaryContactTitleError').show();
                }

                if (!this.contactInformation.secondaryContactEmail || !this.emailValidation(this.contactInformation.secondaryContactEmail)) {
                    valid = false;
                    $('#secondaryContactEmailError').show();
                }

                if (!this.contactInformation.secondaryContactPhone || !this.phoneValidation(this.contactInformation.secondaryContactPhone)) {
                    valid = false;
                    $('#secondaryContactPhoneError').show();
                }
            }

            // console.log("Business Contact")
            return valid;
        },
        validateBusinessSaleCheckBox() {
            if (this.application.applicationIsMasterChain || this.application.applicationIspartOfChain || this.application.applicationChainIsFranchise) {
                return false;
            } else {
                return true;
            }
        }

    }
}
</script>