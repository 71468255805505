<template>
    <div class="full">
        <input type="hidden" id="personaInquiryStatus" v-model="inquiryStatus">
        <input type="hidden" id="personaInquiryId" v-model="inquiryId">
        <input type="hidden" id="personaReferenceId" v-model="referenceId">
        <input type="hidden" id="personaFirstName" v-model="firstName">
        <input type="hidden" id="personaLastName" v-model="lastName">
        <input type="hidden" id="personaBirthDate" v-model="birthdate">
        <input type="hidden" id="personaAddress" v-model="addressOne">  
        <input type="hidden" id="personaCity" v-model="addressCity">
        <input type="hidden" id="personaState" v-model="addressSubDiv">
        <input type="hidden" id="personaZip" v-model="addressPostalCode">
        <input type="hidden" id="personaCountryCode" v-model="addressCountryCode">
    </div>
</template>
<script>

    var personaData;

    export default {
        data: function() {
            return {
                personaData: {},
                inquiryId: '',
                referenceId: '',
                firstName: '',
                lastName: '',
                addressOne: '',
                inquiryStatus: '',
                birthdate: '',
                addressCity: '',
                addressSubDiv: '',
                addressPostalCode: '',
                addressCountryCode: ''
            }
        },
        mounted: function() {
        //    console.log("Component is mounted");
           personaData = this.getParams();
        },
        methods: {
            getParams() {
                const data = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(data);
                // personaData = params;
                console.log(params);
                
                if (params["status"] === undefined || params["status"] === "undefined") {
                    this.inquiryStatus = "workflow cancelled";
                    console.log(this.inquiryStatus);
                } else {
                    this.inquiryId = params["inquiry-id"];
                    this.referenceId = params["reference-id"];
                    this.inquiryStatus = params["status"];
                    this.firstName = params["fields[name-first][value]"];
                    this.lastName = params["fields[name-last][value]"];
                    this.addressCity = params["fields[address-city][value]"];
                    this.addressCountryCode = params["fields[address-country-code][value]"];
                    this.addressOne = params["fields[address-street-1][value]"];
                    this.addressSubDiv = params["fields[address-subdivision][value]"];
                    this.birthdate = params["fields[birthdate][value]"];
                    this.addressPostalCode = this.processZip(params["fields[address-postal-code][value]"]);
                    //adjust the params field
                    params["fields[address-postal-code][value]"] = this.processZip(params["fields[address-postal-code][value]"]);
                }

                document.cookie = "personaDataCookie=" + JSON.stringify(params);

                return params;
            },
            processZip(zip) {
                var newZip,
                    dashLocation = zip.indexOf('-');

                    if (dashLocation !== -1) {
                        newZip = zip.slice(0, dashLocation);
                        console.log(newZip);
                    }


                    return newZip;
                
            }
        }
    }
</script>
