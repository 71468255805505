<template>
    <div class="full holidayMultiplierProgess drawingList drawingListResponsive" v-if="holiday === true">

        <div class="holidayBanner">
            <div class="bannerContent">
                <div class="bannerTextContent">
                    <div class="bannerText">Your Entries for {{ DrawingName }}</div>
                    <img :src="isMobile ? bannerTextMobile : bannerTextDesktop" class="bannerImage" />
                    <div class="bannerClosing">Closes {{ formatCloseDate }}</div>
                </div>
                <div class="entriesContainer">
                    <div class="entriesEarned">{{ entriesEarned }}</div>
                    <div class="myEntries">My Entries</div>
                </div>
            </div>
            <img :src="isMobile ? bannerBackgroundMobile : bannerBackgroundDesktop" class="bannerBackground">
        </div>
        
        <h2>Your Holiday Bonus entry status</h2>
        <p><em>Enter one of each of the eligible Holiday Bonus Scratch-Off tickets to earn one entry into this month’s Holiday Bonus Draw.</em></p>
        
        <div class="holidayMultiplierTickets">
            <div v-for="ticket in tickets" 
                :key="ticket.ticketName"
                class="holidayTicketContainer">
                <img
                    :src="ticket.Url" 
                    :alt="ticket.ticketName + ' - Holiday Multiplier Promotion'"
                    class="holidayTicketImage"
                />
                <img v-if="ticket.snowflake"
                    :src="snowflakeURL" 
                    alt="Snowflake"
                    class="holidaySnowflakeImage"
                />
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data: function() {
            return {
                snowflakeURL: '/getattachment/37b9d260-1c48-436b-9c95-ce66c7e4b7f2/snowflake-badge.png',
                bannerBackgroundDesktop: '/getattachment/ee8657f1-d598-427d-b78e-29635746b8cd/2024HolidayMultiplier_DesktopBanner.png',
                bannerBackgroundMobile: '/getattachment/5faab633-84e3-48f5-8ce9-cdd6bf437837/2024HolidayMultiplier_MobileBanner.png',
                bannerTextDesktop: '/getattachment/b625324b-894a-4b99-8bea-a388bd6a818e/2024HolidayMultiplier_title-DesktopBanner.png', 
                bannerTextMobile: '/getattachment/893ed52f-d022-4ecd-be24-a9a9548f98a5/2024HolidayMultiplier_title-MobileBanner.png',
                
                isMobile: true,
                
                entryData: [],
                promotionID: '',
                promotionName: '',
                message: '',
                entriesEarned: 0,
                DrawingName: 'Drawing 1',
                closeDate: '11/06/24',

                tickets: [
                    {
                        ticketName: '$1 Holiday Cash',
                        Url: '/getattachment/3448e4ee-0622-4dd9-a4c3-07bd4fd9343b/HolidayCash_TicketArt_BW.png',
                        snowflake: false
                    },
                    {
                        ticketName: '$2 Lucky and I Gnome It',
                        Url: '/getattachment/6a887680-4bb9-465d-ba91-ef9b8e51656a/LuckyGnomb_TicketArt_BW.png',
                        snowflake: false
                    },
                    {
                        ticketName: '$5 Holiday Lucky Times 10',
                        Url: '/getattachment/5284cabb-418e-4220-a6c5-3fd97d8ce920/LuckyTimes_TicketArt_BW.png', 
                        snowflake: false
                    },
                    {
                        ticketName: '$10 Home for the Holidays',
                        Url: '/getattachment/b856678d-629d-49ba-82c3-efccb8e3b4eb/HomeHolidays_TicketArt_BW.png', 
                        snowflake: false
                    },
                    {
                        ticketName: '$20 Merry Money',
                        Url: '/getattachment/27ba293d-900f-40ac-b974-38a02000574d/MerryMoney_TicketArt_BW.png',
                        snowflake: false
                    },
                    {
                        ticketName: '$30 Holiday Bonus Millions',
                        Url: '/getattachment/57c282f6-3231-4e80-9ea4-ef0ad33dbf2c/HolidayBonus_TicketArt_BW.png', 
                        snowflake: false
                    },
                ],
                holiday: true
            }
        },
        mounted: function() {
            //console.log("Component loaded");
            this.getToken();
            this.checkIfMobile();
            window.addEventListener('resize', this.checkIfMobile);
            this.showHoliday();
        },
        computed: {
            formatCloseDate() {
                const date = new Date(this.closeDate);
                
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const year = date.getFullYear().toString().slice(-2);
                
                const formattedDate = `${month}/${day}/${year}`;
                
                return formattedDate;
            }
        },
        methods: {
            showHoliday() {
                let query = window.location.origin;

                const currentDate = new Date(),
                    holidayStart = new Date('10/8/2024'),
                    holidayEnd = new Date('1/6/2025');

                if (query.indexOf('www') > -1 && (currentDate < holidayStart || currentDate > holidayEnd)) {
                    this.holiday = false;
                }
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

            },
            fetchData(token) {
                let apiToken = token;
                let userID = this.getLoggedInUserId();

                let params = new URLSearchParams(document.location.search);
                let promotionId;
                promotionId = params.get("id");

                if (promotionId == null) {
                    // promotionId = document.getElementById('PromotionID').value
                    let env = this.getCurrentEnvironment()
                    
                    promotionId = 43;
                    if (env == 'uat' || env == 'local'){
                        promotionId = 40;
                    } 
                }

                return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/SecondChance/Promotion/GetSecondChanceNotifications/"+userID+"/"+promotionId, {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var results = json.data;
                    this.entryData = results[0];
                    this.updateTicketsArray();
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            },
            updateTicketsArray() {
                this.promotionID = this.entryData.promotionID;
                this.promotionName = this.entryData.promotionName;
                this.message = this.entryData.message;

                this.tickets[0].Url = this.entryData.secondaryData.imgUrl1;
                this.tickets[1].Url = this.entryData.secondaryData.imgUrl2;
                this.tickets[2].Url = this.entryData.secondaryData.imgUrl5;
                this.tickets[3].Url = this.entryData.secondaryData.imgUrl10;
                this.tickets[4].Url = this.entryData.secondaryData.imgUrl20;
                this.tickets[5].Url = this.entryData.secondaryData.imgUrl30;

                this.tickets[0].snowflake = this.entryData.secondaryData.snowflake1;
                this.tickets[1].snowflake = this.entryData.secondaryData.snowflake2;
                this.tickets[2].snowflake = this.entryData.secondaryData.snowflake5;
                this.tickets[3].snowflake = this.entryData.secondaryData.snowflake10;
                this.tickets[4].snowflake = this.entryData.secondaryData.snowflake20;
                this.tickets[5].snowflake = this.entryData.secondaryData.snowflake30;
                
                this.entriesEarned = this.entryData.secondaryData.bonusEntriesThisDraw;
                this.DrawingName = this.entryData.secondaryData.bonusDrawingName;
                this.closeDate = this.entryData.secondaryData.bonusDrawingCloseDate;
            },
            checkIfMobile() {
                this.isMobile = window.matchMedia("(max-width: 640px)").matches;
            }    
        },
        beforeUnmount() {
            window.removeEventListener('resize', this.checkIfMobile);
        }
    }
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
/*general styles*/
    .full.holidayMultiplierProgess{
        margin:10px;
    }

    .full.holidayMultiplierProgess h2{
        font-family: "Source Sans 3";
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        margin: 20px 0px 0px 5px;
        color: black;
    }

    .full.holidayMultiplierProgess p{
        font-family: "Source Sans 3";
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 20px;
        margin: 5px 5px;
    }
    .full.holidayMultiplierProgess.drawingList{
        background-color: #fff;
        margin:0px;
        padding:0px;
    }
/*end general*/

/*Banner styles*/
/*Mobile/General */
    .holidayBanner { /*entire Banner*/
        width: 100%;
        display: flex;
        height: auto;
        align-items: center;
    }

    .bannerContent { /*All Banner content (not background)*/
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 90%;
        height: auto;
        align-items: center;
        margin: 3px 0px 0px 5px
    }

    .bannerTextContent { /*All Banner Text Content*/
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-left: 0px;
        margin-top:10px;
    }

    .bannerText{ /*Banner Top Text*/
        color: rgba(14, 63, 117, 1);
        font-family: "Source Sans 3";
        font-size: clamp(10px, 4vw, 24px);
        font-weight: 700;
        text-align: center;
        margin-bottom: 11vw;
    }

    .bannerImage { /*Banner Text Image*/
        width: 60vw;
    } 

    .bannerClosing { /*Banner Bottom Text*/
        color: rgba(14, 63, 117, 1);
        font-family: "Source Sans 3";
        font-size: clamp(6px, 4.5vw, 16px); 
        font-weight: 600;
        text-align: center;
        position: relative;
        bottom: 15px;
    }

    .entriesContainer { /*entire Entries Earned Circle*/
        position: absolute;
        top: 20%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        width: clamp(20px, 9vw, 85px); 
        height: clamp(2px, 9vw, 85px); 
        background: radial-gradient(rgba(221, 70, 38, 1) 0%, rgba(102, 26, 12, 1) 100%);
        box-shadow: 0px 4px 10px rgba(64, 64, 64, 1), 0px 0px 0px 2px rgba(255, 255, 255, 1);

        border: 1px solid #fff;
        border-radius: 50%;
        padding: clamp(2px, 1vw, 10px);
        text-align: center;
        overflow: hidden;
    }

    .entriesEarned{ /*dynamic entries number*/
        color: rgba(255, 255, 255, 1);
        font-family: "Source Sans 3";
        font-size: clamp(20px, 6vw, 63px);
        font-weight: 700;
    }

    .myEntries{ /*'my entries' text*/
        display: none;
    }

    .bannerBackground { /*Background Banner*/
        width: 100%;
        height: auto;
    }

    @media (max-width: 400px) {
        .bannerContent { /*All Banner content (not background)*/
            margin: 0px;
        }
        .bannerText{ /*Banner Top Text*/
            margin-bottom: 10vw;
        }
    }

/*Desktop*/
    @media (min-width: 641px) {
        .bannerContent { /*All Banner content (not background)*/
            flex-direction: row;
            width: 75vw;
            margin: 0px;
            max-width:880px;
            padding-bottom: 10px;

        }

        .bannerTextContent { /*All Banner Text Content*/
            margin-left: 50px;
        }

        .bannerText{ /*Banner Top Text*/
            font-size: clamp(20px, 2.5vw, 24px);
            text-align: left;
            margin-bottom: 0px;
        }

        .bannerImage { /*Banner Text Image*/
            width: 50vw;
            max-width: 550px;
        } 

        .bannerClosing { /*Banner Bottom Text*/
            font-size: clamp(12px, 1.2vw, 14px); 
            text-align: left;
            position: absolute;
            bottom: 0px;
        }

        .entriesContainer { /*entire Entries Earned Circle*/
            right: 80px;
            top: initial;

            width: clamp(40px, 8vw, 85px); 
            height: clamp(40px, 8vw, 85px); 
            box-shadow: 0px 4px 10px rgba(64, 64, 64, 1), 0px 0px 0px 4px rgba(255, 255, 255, 1);

            border: 2px solid #fff;
            padding: clamp(3px, 1vw, 10px);
        }

        .entriesEarned{ /*dynamic entries number*/
            font-size: clamp(20px, 3.9vw, 55px);
            line-height: clamp(20px, 3.9vw, 47px);
        }

        .myEntries{ /*'my entries' text*/
            display: block;
            color: rgba(255, 255, 255, 1);
            font-family: "Source Sans 3";
            font-size: clamp(5px, 1vw, 12px);
            font-weight: 600;
        }
    }

    @media (min-width: 641px) and (max-width: 960px) {
        .bannerContent { /*All Banner content (not background)*/
            width: 90vw;
            max-width:880px;
            padding-bottom:13px;
        }

        .bannerImage { /*Banner Text Image*/
            width: 60vw;
            max-width: 550px;
        } 

        .bannerClosing{
            bottom: 8px;
        }

        .entriesContainer { /*entire Entries Earned Circle*/
            right: 50px;
            width: clamp(40px, 9vw, 85px); 
            height: clamp(40px, 9vw, 85px); 
        }

        .myEntries{ /*'my entries' text*/
            font-size: clamp(5px, 1.3vw, 12px);
        }

    }
/*End Desktop*/
/* End Banner styles*/
    
/*Ticket status styling*/
   .holidayMultiplierTickets { /*entire tickets container*/
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .holidayTicketContainer { /*individual ticket container*/
        position: relative;
        display: inline-block;
        width:33.33%;
        height:33.33%;
    }

    .holidaySnowflakeImage { /*snowflake image individual*/
        position: absolute;
        bottom: 5px;
        left: 14px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: white;
    }
    
    .full.holidayMultiplierProgess p, .full.holidayMultiplierProgess h2{
        text-align: center;
    }

    .holidayTicketImage{ /*individual ticket images*/
        width: 90%;
        height: 90%;
        object-fit: contain;
        object-position: top left;
        border-radius: 5px;
        margin: 6px 6px 15px 6px;
    }

    @media (min-width: 641px) {
        .holidayMultiplierTickets { /*entire tickets container*/
            flex-wrap: nowrap;
            justify-content: space-between;
        }
        
        .holidayTicketContainer { /*individual ticket container*/
            width:16.66%;
            height:16.66%;
        }

        .full.holidayMultiplierProgess p, .full.holidayMultiplierProgess h2{
            text-align: left;
        }
    }

/*end ticket status*/
</style>