<template>
    <div class="eAppModule">
        <div class="moduleContent cf chainLocations saveForm cf">
            <div v-if="showLocationsList">
                <h2 class="eAppTitle">Chain Locations</h2>
                <a class="button eAppDashBtn" @click="createNewChainLocation">Create Chain Location</a>
                <div v-if="showSuccessMessage" class="ErrorLabel alert-error">
                    Location successfully created!
                </div>
                <table v-if="locations && locations.length" class="eAppListTbl">
                    <thead>
                        <tr>
                            <th v-for="(header, index) in headers" :key="index">
                                <span @click="sortListBy(header)">
                                    {{ header }}
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(row, index) in locations">
                            <tr>
                                <td>
                                    {{row['applicationNumber']}}
                                </td>
                                <td>
                                    {{ hasBusinessName(row['businessName']) }}

                                    <div v-if="showQuickview">
                                        <!-- quickview only for SR, RM, & CLM Roles -->
                                        <a :href="'#qv'+index" @click="openQuickview(index)" class="quickViewOpen">Quickview</a> |
                                        <a :href="'/eapplications/eapplication?id='+row['businessGuid']">Review</a>

                                        <!-- create modal for Quickview -->
                                        <div :id="'qv'+index" data-reveal class="quickView reveal">
                                            <button class="close-button" data-close aria-label="Close modal" type="button">
                                                <span aria-hidden="true">&times;</span>
                                            </button>

                                            <strong>
                                                {{row['businessLegalName']}} -
                                                {{row['applicationNumber']}}
                                            </strong>

                                            <div class="quickViewAddress">
                                                {{row['businessStreetAddress']}}<br />
                                                {{row['businessCity']}}, {{row['businessZip']}}
                                            </div>

                                            <!-- for Sales Rep -->
                                            <div v-if="userEappRole.codename == 'eAppDistrictRep' || userEappRole.codename == 'eApp-RegionalManager'">
                                                <span class="alertSOB" v-if="row['saleOfBusiness'] && userEappRole.codename == 'eAppDistrictRep'">
                                                    <br /><span class="icon-Status statusSOB"></span>
                                                    Sale of Business<br />
                                                </span>
                                                Status: {{ getStatusName(row['applicationStatus']) }}
                                            </div>
                                            <!-- for Regional Mngr -->
                                            <div v-if="userEappRole.codename == 'eApp-RegionalManager'">
                                                District: {{row['applicationDistrict']}}<br />
                                                Sales Rep: {{row['associatedSalesRep']}}<br />
                                                Status: {{ getStatusName(row['applicationStatus']) }}
                                            </div>
                                            <!-- for Licensing -->
                                            <div v-if="userEappRole.displayname == 'Licensing'" class="quickViewStatus quickViewLicensingStatus">
                                                <span class="icon-Status statusSecurity" :class="{ needsData: (row['securityReview'] == 0) }"></span> Principals Approved<br />
                                                <span class="icon-Status statusEFT" :class="{ needsData: (row['eFTReview'] == 0) }"></span> EFT Approved<br />
                                                <span class="bold">District:</span> {{row['applicationDistrict']}}
                                            </div>
                                            <!-- action(s) -->
                                            <div class="quickViewActions">
                                                <a :href="'/eapplications/eapplication?id='+row['businessGuid']">Review</a>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {{row['businessCity']}}
                                </td>

                                <td>
                                    {{dateFormatter(row['createdOn'])}}
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <p v-else>There are no applications to display.</p>
            </div>
            <div v-if="showNewLocationForm">
                <h3 class="eAppTitle">Chain Location Creation</h3>
                <div v-if="errorMessageShow" class="ErrorLabel alert-error">
                    There was an error processing your request.
                    <span>{{ additionalErrorInfo }}</span>
                </div>
               
                <h4>Business Information</h4>
                <div class="formRow cell">
                    <span class="req">*</span>
                    <label title="This is the name that you file your business taxes under and must match the information entered under the W-9 section" class="helpTxt" for="legalName">Legal Business Name:</label>
                    <input name="" type="text" v-model="application.businessInformation.businessLegalName" maxlength="200" id="legalName" class="" />
                    <div class='error error-label' v-if="nameError" id="legalNameError">Business Legal Name is required.</div>
                </div>
                <div class="formRow cell">
                    <span class="req">*</span>
                    <label title="DBA-this is the name of your business" class="helpTxt" for="doingBusinessAs">Doing Business As:</label>
                    <input name="" type="text" v-model="application.businessInformation.businessDoingBusinessAs" maxlength="200" id="doingBusinessAs" class="form-control">
                    <div class='error' v-if="nameAsError" id="doingBusinessAsError">Doing Business As is required.</div>
                </div>

                <div class="eAppForm">
                    <div class="formRow checkboxRow">
                        <input id="chkIsSellOfBusiness" :disabled="isDisabled" v-model="application.applicationIsASoB" type="checkbox" name="chkIsSellOfBusiness">
                        <label for="chkIsSellOfBusiness" id="lblchkIsSellOfBusiness" class="labelSOB">Is this a purchase of an existing Lottery chain location? If yes, please click the box to the left.</label>
                    </div>
                    <div id="pnlSubmission" v-if="application.applicationIsASoB">

                        <div class="formRow">
                            <span class="req">*</span>
                            <label for="txbxEmail" id="lbltxbxEmail">Owner Email:</label>
                            <input name="txbxEmail" :disabled="isDisabled" v-model="application.businessInformation.businessOriginalOwnerEmail" type="text" id="txbxEmail">
                            <div id="emailError" class="fieldError" style="display:none;">Please enter email</div>
                            <div v-if="!validEmail" class="error">Please enter valid email address. Eg. Something@domain.com</div>
                            <br><span>The current business owner will receive a notification and be required to log-on to the website and complete the Sale of Business Agreement prior to your application being approved.</span>

                        </div>

                    </div>
                </div>
                <hr />
                <div class="formRow phoneField cell medium-6 large-4">
                    <span class="req">*</span>
                    <label class="control-label editing-form-label" for="">Business Phone:</label>
                    <!-- <input v-model="primaryPhone" type="text" :disabled="isDisabled" placeholder="xxx-xxx-xxxx" pattern="^\d{3}-\d{3}-\d{4}$" maxlength="12" id="businessPhone" @input="phoneFormat" /> -->
                    <input v-model="primaryPhone" type="text" :disabled="isDisabled" placeholder="xxx-xxx-xxxx" maxlength="12" id="businessPhone" />
                    <div class='error' id="businessPhoneError" v-if="phoneError">Business Phone is required.</div>
                    <span class="error" v-if="formatErrorPrimary">Please provide a valid phone number format. ex: 555-555-5555</span>
                </div>
                <div class="formRow phoneField cell medium-6 large-4">
                    <label class="control-label editing-form-label" for="">Alternate Business Phone:</label>
                    <input v-model="altPhone" id="altBusinessPhone" :disabled="isDisabled" name="" type="text" maxlength="12" placeholder="xxx-xxx-xxxx" />
                    <span class="error" v-if="formatErrorAlt">Please provide a valid phone number format. ex: 555-555-5555</span>
                </div>
                <div class="formRow phoneField cell medium-6 large-4">
                    <label class="control-label editing-form-label" for="">Business Fax:</label>
                    <input v-model="faxPhone" id="businessFax" name="" :disabled="isDisabled" type="text" maxlength="12" placeholder="xxx-xxx-xxxx" />
                    <span class="error" v-if="formatErrorFax">Please provide a valid fax number format. ex: 555-555-5555</span>
                </div>

                <hr />
                <h4>Contact Information</h4>
                <div class="eAppForm primarycontactForm grid-x grid-margin-x grid-margin-y">
                    <div class="formRow cell medium-6">
                        <span class="req">*</span>
                        <label for="PrimaryContactName">Primary Contact Name:</label>
                        <input v-model="application.contactInformation.primaryContactName" :disabled="isDisabled" name="PrimaryContactName" type="text" maxlength="200" id="PrimaryContactName" required>
                        <div id="primaryContactNameError" v-if="primaryContactNameError" class="form-error">Please provide a Primary Contact Name</div>
                    </div>
                    <div class="formRow cell medium-6">
                        <span class="req">*</span>
                        <label for="PrimaryContactTitle">Primary Contact Title:</label>
                        <input v-model="application.contactInformation.primaryContactTitle" :disabled="isDisabled" name="PrimaryContactTitle" type="text" maxlength="200" id="PrimaryContactTitle" required>
                        <div id="primaryContactTitleError" v-if="primaryContactTitleError" class="form-error">Please provide a Primary Contact Title</div>
                    </div>
                    <div class="formRow cell medium-6">
                        <span class="req">*</span>
                        <label for="PrimaryContactEmail">Primary Contact E-mail:</label>
                        <input v-model="application.contactInformation.primaryContactEmail" :disabled="isDisabled" name="PrimaryContactEmail" type="text" maxlength="254" id="PrimaryContactEmail" required pattern="email">
                        <div id="primaryContactEmailError" v-if="primaryContactEmailError" class="form-error">Please provide a valid Primary Contact Email</div>
                    </div>
                    <div class="formRow phoneField cell medium-6">
                        <span class="req">*</span>
                        <label for="PrimaryContactPhone">Primary Contact Phone:</label>
                        <input v-model="application.contactInformation.primaryContactPhone" :disabled="isDisabled" name="PrimaryContactPhone" type="tel" maxlength="12" id="PrimaryContactPhone" placeholder="xxx-xxx-xxxx" required>
                        <div id="primaryContactPhoneError" v-if="primaryContactPhoneError" class="form-error">Please provide a valid Primary Contact Phone</div>
                        <!-- pattern="^\(?([0-9]{3})\)?[\-. ]?([0-9]{3})[\-. ]?([0-9]{4})$"  -->
                    </div>
                </div>
                <div class="eAppForm secondaryContactForm grid-x grid-margin-x grid-margin-y">
                    <h4 class="cell">Secondary Contact</h4>
                    <div class="formRow cell medium-6">
                        <label for="SecondaryContactName">Secondary Contact Name:</label>
                        <input v-model="application.contactInformation.secondaryContactName" :disabled="isDisabled" name="SecondaryContactName" type="text" maxlength="200" id="SecondaryContactName">
                        <div id="secondaryContactNameError" v-if="secondaryContactNameError" class="form-error">Please provide a Secondary Contact Name</div>
                    </div>
                    <div class="formRow cell medium-6">
                        <label for="SecondaryContactTitle">Secondary Contact Title:</label>
                        <input v-model="application.contactInformation.secondaryContactTitle" :disabled="isDisabled" name="SecondaryContactTitle" type="text" maxlength="200" id="SecondaryContactTitle">
                        <div id="secondaryContactTitleError" v-if="secondaryContactTitleError" class="form-error">Please provide a Secondary Contact Title</div>
                    </div>
                    <div class="formRow cell medium-6">
                        <label for="SecondaryContactEmail">Secondary Contact E-mail:</label>
                        <input v-model="application.contactInformation.secondaryContactEmail" :disabled="isDisabled" name="SecondaryContactEmail" type="text" maxlength="254" id="SecondaryContactEmail" required pattern="email">
                        <div id="secondaryContactEmailError" v-if="secondaryContactEmailError" class="form-error">Please provide a valid Secondary Contact Email</div>
                    </div>
                    <div class="formRow cell medium-6 phoneField">
                        <label for="SecondaryContactPhone">Secondary Contact Phone:</label>
                        <input v-model="application.contactInformation.secondaryContactPhone" :disabled="isDisabled" name="SecondaryContactPhone" type="tel" maxlength="12" id="SecondaryContactPhone" placeholder="xxx-xxx-xxxx" pattern="^\(?([0-9]{3})\)?[\-. ]?([0-9]{3})[\-. ]?([0-9]{4})$" required>
                        <div id="secondaryContactPhoneError" v-if="secondaryContactPhoneError" class="form-error">Please provide a valid Secondary Contact Phone</div>
                    </div>
                </div>
                <hr />
                <h4>Business Address</h4>
                <div class="eAppForm grid-margin-x grid-x grid-margin-y">
                    <div class="formRow cell">
                        <span class="req">*</span>
                        <label id="BusinessStreetAddress_lb" for="BusinessStreetAddress_txtText">Street Address:</label>
                        <input name="BusinessStreetAddress_txtText" v-model="application.businessInformation.businessStreetAddress" :disabled="isDisabled" type="text" maxlength="600" id="BusinessStreetAddress_txtText" class="form-control" autocomplete="off">
                        <div id="businessStreetAddressError" v-if="businessStreetAddressError" class="error">Please provide a valid Ohio street address.</div>
                    </div>
                    <div class="formRow cell medium-8">
                        <span class="req">*</span>
                        <label id="BusinessCity_lb" for="BusinessCity_txtText">City:</label>
                        <input name="BusinessCity_txtText" v-model="application.businessInformation.businessCity" type="text" maxlength="300" :disabled="isDisabled" id="BusinessCity_txtText" autocomplete="off">
                        <div id="businessCityError" v-if="businessCityError" class="error">Please provide a valid Ohio city.</div>
                    </div>
                    <div class="formRow cell medium-4">
                        <span class="req">*</span>
                        <label id="BusinessState_lb" for="BusinessState_dropDownList">State:</label>
                        <select name="BusinessState_dropDownList" v-model="application.businessInformation.businessState" id="BusinessState_dropDownList" :disabled="isDisabled" class="DropDownField form-control">
                            <option value="null">Select...</option>
                            <option selected value="OH"> OH</option>
                        </select>
                        <div id="businessStateError" v-if="businessStateError" class="error">Please provide a valid state.</div>
                    </div>
                    <div class="formRow zipFields validateZip cell medium-8">
                        <span class="req">*</span>
                        <label id="BusinessZip_lb" for="BusinessZip_txtText">Zip:</label>
                        <input name="BusinessZip_txtText" v-model="application.businessInformation.businessZip" type="text" maxlength="5" :disabled="isDisabled" id="BusinessZip_txtText" class="zipFive" autocomplete="off">
                        <input name="$BusinessZipPlus4_txtText" v-model="application.businessInformation.businessZipPlus4" type="text" :disabled="isDisabled" maxlength="4" id="BusinessZipPlus4_txtText" class="zipFour" autocomplete="off">
                        <a href="https://tools.usps.com/go/ZipLookupAction!input.action" target="_blank" class="eAppZip4Link">Zip +4 Lookup</a>
                        <div id="businessZipCodeError" v-if="!validZip" class="zipError error">Please provide a valid Ohio Zip Code.</div>
                    </div>
                    <div class="formRow cell medium-4">
                        <span class="req">*</span>
                        <label id="BusinessCounty_lb" for="BusinessCounty_dropDownList">County:</label>
                        <select name="BusinessCounty_dropDownList" v-model="application.businessInformation.businessCounty" :disabled="isDisabled" id="BusinessCounty_dropDownList" class="DropDownField">
                            <option value="null">Select...</option>
                            <option value="39">ADAMS</option>
                            <option value="11">ALLEN</option>
                            <option value="01">ASHLAND</option>
                            <option value="02">ASHTABULA</option>
                            <option value="65">ATHENS</option>
                            <option value="28">AUGLAIZE</option>
                            <option value="66">BELMONT</option>
                            <option value="40">BROWN</option>
                            <option value="41">BUTLER</option>
                            <option value="77">CARROLL</option>
                            <option value="29">CHAMPAIGN</option>
                            <option value="30">CLARK</option>
                            <option value="42">CLERMONT</option>
                            <option value="43">CLINTON</option>
                            <option value="78">COLUMBIANA</option>
                            <option value="49">COSHOCTON</option>
                            <option value="12">CRAWFORD</option>
                            <option value="03">CUYAHOGA</option>
                            <option value="31">DARKE</option>
                            <option value="13">DEFIANCE</option>
                            <option value="50">DELAWARE</option>
                            <option value="04">ERIE</option>
                            <option value="51">FAIRFIELD</option>
                            <option value="52">FAYETTE</option>
                            <option value="53">FRANKLIN</option>
                            <option value="14">FULTON</option>
                            <option value="67">GALLIA</option>
                            <option value="05">GEAUGA</option>
                            <option value="32">GREENE</option>
                            <option value="68">GUERNSEY</option>
                            <option value="44">HAMILTON</option>
                            <option value="15">HANCOCK</option>
                            <option value="16">HARDIN</option>
                            <option value="79">HARRISON</option>
                            <option value="17">HENRY</option>
                            <option value="45">HIGHLAND</option>
                            <option value="54">HOCKING</option>
                            <option value="80">HOLMES</option>
                            <option value="06">HURON</option>
                            <option value="69">JACKSON</option>
                            <option value="81">JEFFERSON</option>
                            <option value="55">KNOX</option>
                            <option value="07">LAKE</option>
                            <option value="70">LAWRENCE</option>
                            <option value="56">LICKING</option>
                            <option value="33">LOGAN</option>
                            <option value="08">LORAIN</option>
                            <option value="18">LUCAS</option>
                            <option value="57">MADISON</option>
                            <option value="82">MAHONING</option>
                            <option value="58">MARION</option>
                            <option value="09">MEDINA</option>
                            <option value="71">MEIGS</option>
                            <option value="34">MERCER</option>
                            <option value="35">MIAMI</option>
                            <option value="72">MONROE</option>
                            <option value="36">MONTGOMERY</option>
                            <option value="73">MORGAN</option>
                            <option value="59">MORROW</option>
                            <option value="60">MUSKINGUM</option>
                            <option value="74">NOBLE</option>
                            <option value="19">OTTAWA</option>
                            <option value="20">PAULDING</option>
                            <option value="61">PERRY</option>
                            <option value="62">PICKAWAY</option>
                            <option value="47">PIKE</option>
                            <option value="83">PORTAGE</option>
                            <option value="37">PREBLE</option>
                            <option value="21">PUTNAM</option>
                            <option value="10">RICHLAND</option>
                            <option value="63">ROSS</option>
                            <option value="22">SANDUSKY</option>
                            <option value="48">SCIOTO</option>
                            <option value="23">SENECA</option>
                            <option value="38">SHELBY</option>
                            <option value="84">STARK</option>
                            <option value="85">SUMMIT</option>
                            <option value="86">TRUMBULL</option>
                            <option value="87">TUSCARAWAS</option>
                            <option value="64">UNION</option>
                            <option value="24">VAN WERT</option>
                            <option value="75">VINTON</option>
                            <option value="46">WARREN</option>
                            <option value="76">WASHINGTON</option>
                            <option value="88">WAYNE</option>
                            <option value="25">WILLIAMS</option>
                            <option value="26">WOOD</option>
                            <option value="27">WYANDOT</option>
                        </select>
                        <div id="businessCountyError" v-if="businessCountyError" class="error">Please provide a valid Ohio county.</div>
                    </div>
                    <div class="formRow cell dividerRow"> </div>


                    <div class="formRow checkboxRow">
                        <h5>Mailing Address</h5>
                        <div class="CheckBoxField checkbox">
                            <input id="MailingSameAsBusiness_checkbox" :disabled="isDisabled" v-model="application.businessInformation.mailingSameAsBusiness" type="checkbox" name="MailingSameAsBusiness_checkbox">
                            <label id="MailingSameAsBusiness_lb" for="MailingSameAsBusiness_checkbox">Mailing address is the same as the street address</label>
                        </div>
                    </div>

                    <div class="mailingAddressForm grid-x grid-margin-x grid-margin-y" v-if="!application.businessInformation.mailingSameAsBusiness">

                        <div class="formRow cell">
                            <label id="MailingName_lb" for="MailingName_txtText">Mailing Name:</label>
                            <input name="MailingName_txtText" :disabled="isDisabled" v-model="application.businessInformation.mailingName" type="text" maxlength="200" id="MailingName_txtText" autocomplete="off">
                            <div id="mailingNameError" class="error" v-if="errorName">Please provide a mailing name.</div>
                        </div>
                        <div class="formRow cell">
                            <label id="MailingStreetAddress_lb" for="MailingStreetAddress_txtText">Mailing Address:</label>
                            <input name="MailingStreetAddress_txtText" :disabled="isDisabled" v-model="application.businessInformation.mailingStreetAddress" type="text" maxlength="300" id="MailingStreetAddress_txtText" autocomplete="off">
                            <div id="mailingStreetAddressError" class="error" v-if="errorAddress">Please provide a valid street address.</div>
                        </div>
                        <div class="formRow cell medium-8">
                            <label id="MailingCity_lb" for="MailingCity_txtText">City:</label>
                            <input name="MailingCity_txtText" :disabled="isDisabled" v-model="application.businessInformation.mailingCity" type="text" maxlength="200" id="MailingCity_txtText" autocomplete="off">
                            <div id="mailingCityError" class="error" v-if="errorCity">Please provide a valid city.</div>
                        </div>
                        <div class="formRow cell medium-4">
                            <label id="MailingState_lb" for="MailingState_dropDownList">State:</label>
                            <select name="MailingState_dropDownList" :disabled="isDisabled" v-model="application.businessInformation.mailingState" id="MailingState_dropDownList" class="DropDownField">
                                <option value="null">Select...</option>
                                <option value="AL"> AL</option>
                                <option value="AK"> AK</option>
                                <option value="AZ"> AZ</option>
                                <option value="AR"> AR</option>
                                <option value="CA"> CA</option>
                                <option value="CO"> CO</option>
                                <option value="CT"> CT</option>
                                <option value="DE"> DE</option>
                                <option value="FL"> FL</option>
                                <option value="GA"> GA</option>
                                <option value="HI"> HI</option>
                                <option value="ID"> ID</option>
                                <option value="IL"> IL</option>
                                <option value="IN"> IN</option>
                                <option value="IA"> IA</option>
                                <option value="KS"> KS</option>
                                <option value="KY"> KY</option>
                                <option value="LA"> LA</option>
                                <option value="ME"> ME</option>
                                <option value="MD"> MD</option>
                                <option value="MA"> MA</option>
                                <option value="MI"> MI</option>
                                <option value="MN"> MN</option>
                                <option value="MS"> MS</option>
                                <option value="MO"> MO</option>
                                <option value="MT"> MT</option>
                                <option value="NE"> NE</option>
                                <option value="NV"> NV</option>
                                <option value="NH"> NH</option>
                                <option value="NJ"> NJ</option>
                                <option value="NM"> NM</option>
                                <option value="NY"> NY</option>
                                <option value="NC"> NC</option>
                                <option value="ND"> ND</option>
                                <option value="OH"> OH</option>
                                <option value="OK"> OK</option>
                                <option value="OR"> OR</option>
                                <option value="PA"> PA</option>
                                <option value="RI"> RI</option>
                                <option value="SC"> SC</option>
                                <option value="SD"> SD</option>
                                <option value="TN"> TN</option>
                                <option value="TX"> TX</option>
                                <option value="UT"> UT</option>
                                <option value="VT"> VT</option>
                                <option value="VA"> VA</option>

                                <option value="WA"> WA</option>
                                <option value="DC"> DC</option>
                                <option value="WV"> WV</option>
                                <option value="WI"> WI</option>
                                <option value="WY"> WY</option>

                            </select>
                            <div id="mailingStateError" class="error" v-if="errorState">Please provide a valid state.</div>
                        </div>

                        <div class="formRow zipFields cell">
                            <label id="MailingZip_lb" for="MailingZip_txtText">Zip:</label>
                            <input name="MailingZip_txtText" :disabled="isDisabled" v-model="application.businessInformation.mailingZip" type="text" maxlength="5" id="MailingZip_txtText" autocomplete="off">
                            <input name="MailingZipPlus4_txtText" :disabled="isDisabled" v-model="application.businessInformation.mailingZipPlus4" type="text" maxlength="4" id="MailingZipPlus4_txtText" autocomplete="off">
                            <div id="mailingZipError" v-if="!validMailZip" class="error">Please provide a valid zip code.</div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="businessHours">
                    <h4>Business Hours</h4>
                    <div class="eAppForm">
                        <div id="oneDayNeedsHoursError" class="error hoursError" style="display:none;">Please select open hours for at least one day of the week.</div>
                        <div class="hoursDay cf">
                            <span class="req">*</span> <label>Monday</label>
                            <div class="businessHoursInput cf">
                                <div class="hoursHours cf">
                                    <legend>Hours of operation</legend>
                                    <select v-model="hoursOpen1" :disabled="isDisabled" class="hoursSelect hoursHour"
                                            @change="dropDownCahnge('1')">
                                        <option value="" disabled>--</option>
                                        <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                            {{ option.display }}
                                        </option>
                                    </select>
                                    <span class="hoursTo">to</span>
                                    <select v-model="hoursClose1" :disabled="isDisabled" class="hoursSelect hoursHour"
                                            @change="dropDownCahnge('1')">
                                        <option value="" disabled>--</option>
                                        <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                            {{ option.display }}
                                        </option>
                                    </select>
                                    <span class="hoursCheckboxes cf">
                                        <span class="checkboxSet cf">
                                            <span class="hoursCheck hoursOpen24">
                                                <input id="BusinessHours1_open24hours"
                                                       :disabled="isDisabled" @change="openChange('1')" v-model="open2471" type="checkbox"
                                                       name="BusinessHours1_open24hours">
                                            </span>
                                            <label for="BusinessHours1_open24hours" id="BusinessHours1_open24hoursLbl"
                                                   class="hoursCheckLbl hoursOpen24lbl">Open 24 hrs</label>
                                        </span>

                                        <span class="checkboxSet cf">
                                            <span class="hoursCheck hoursClosed">
                                                <input id="BusinessHours1_closed"
                                                       :disabled="isDisabled" @change="closeChange('1')" v-model="closed1" type="checkbox"
                                                       name="BusinessHours1_closed">
                                            </span>
                                            <label for="BusinessHours1_closed" id="BusinessHours1_closedLbl"
                                                   class="hoursCheckLbl hoursClosedLbl">Closed</label>
                                        </span>
                                    </span>
                                    <div id="businessHours1Error" class="error hoursError" style="display:none;">Please select hours for this day.</div>
                                </div>
                            </div>

                        </div>

                        <div class="hoursDay cf">
                            <span class="req">*</span> <label>Tuesday</label>
                            <div class="businessHoursInput cf">

                                <div class="hoursHours cf">
                                    <legend>Hours of operation</legend>
                                    <select v-model="hoursOpen2" :disabled="isDisabled" class="hoursSelect hoursHour"
                                            @change="dropDownCahnge('2')">
                                        <option value="" disabled>--</option>
                                        <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                            {{ option.display }}
                                        </option>
                                    </select>
                                    <span class="hoursTo">to</span>
                                    <select v-model="hoursClose2" :disabled="isDisabled" class="hoursSelect hoursHour"
                                            @change="dropDownCahnge('2')">
                                        <option value="" disabled>--</option>
                                        <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                            {{ option.display }}
                                        </option>
                                    </select>
                                    <span class="hoursCheckboxes cf">
                                        <span class="checkboxSet cf">
                                            <span class="hoursCheck hoursOpen24">
                                                <input id="BusinessHours2_open24hours"
                                                       :disabled="isDisabled" v-model="open2472" @change="openChange('2')" type="checkbox"
                                                       name="BusinessHours2_open24hours">
                                            </span>
                                            <label for="BusinessHours2_open24hours" id="BusinessHours2_open24hoursLbl"
                                                   class="hoursCheckLbl hoursOpen24lbl">Open 24 hrs</label>
                                        </span>

                                        <span class="checkboxSet cf">
                                            <span class="hoursCheck hoursClosed">
                                                <input id="BusinessHours2_closed"
                                                       :disabled="isDisabled" @change="closeChange('2')" v-model="closed2" type="checkbox"
                                                       name="BusinessHours2_closed">
                                            </span>
                                            <label for="BusinessHours2_closed" id="BusinessHours2_closedLbl"
                                                   class="hoursCheckLbl hoursClosedLbl">Closed</label>
                                        </span>
                                    </span>
                                    <div id="businessHours2Error" class="error hoursError" style="display:none;">
                                        Please select hours
                                        for this day.
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="hoursDay cf">
                            <span class="req">*</span> <label>Wednesday</label>

                            <div class="businessHoursInput cf">

                                <div class="hoursHours cf">
                                    <legend>Hours of operation</legend>
                                    <select v-model="hoursOpen3" :disabled="isDisabled" class="hoursSelect hoursHour"
                                            @change="dropDownCahnge('3')">
                                        <option value="" disabled>--</option>
                                        <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                            {{ option.display }}
                                        </option>
                                    </select>
                                    <span class="hoursTo">to</span>
                                    <select v-model="hoursClose3" :disabled="isDisabled" class="hoursSelect hoursHour"
                                            @change="dropDownCahnge('3')">
                                        <option value="" disabled>--</option>
                                        <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                            {{ option.display }}
                                        </option>
                                    </select>
                                    <span class="hoursCheckboxes cf">
                                        <span class="checkboxSet cf">
                                            <span class="hoursCheck hoursOpen24">
                                                <input id="BusinessHours3_open24hours"
                                                       :disabled="isDisabled" type="checkbox" @change="openChange('3')" v-model="open2473"
                                                       name="BusinessHours3_open24hours">
                                            </span>
                                            <label for="BusinessHours3_open24hours" id="BusinessHours3_open24hoursLbl"
                                                   class="hoursCheckLbl hoursOpen24lbl">Open 24 hrs</label>
                                        </span>

                                        <span class="checkboxSet cf">
                                            <span class="hoursCheck hoursClosed">
                                                <input id="BusinessHours3_closed"
                                                       :disabled="isDisabled" @change="closeChange('3')" v-model="closed3" type="checkbox"
                                                       name="BusinessHours3_closed">
                                            </span>
                                            <label for="BusinessHours3_closed" id="BusinessHours3_closedLbl"
                                                   class="hoursCheckLbl hoursClosedLbl">Closed</label>
                                        </span>
                                    </span>
                                    <div id="businessHours3Error" class="error hoursError" style="display:none;">
                                        Please select hours for this day.
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="hoursDay cf">
                            <span class="req">*</span> <label>Thursday</label>

                            <div class="businessHoursInput cf">
                                <div class="hoursHours cf">
                                    <legend>Hours of operation</legend>
                                    <select v-model="hoursOpen4" :disabled="isDisabled" class="hoursSelect hoursHour"
                                            @change="dropDownCahnge('4')">
                                        <option value="" disabled>--</option>
                                        <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                            {{ option.display }}
                                        </option>
                                    </select>
                                    <span class="hoursTo">to</span>
                                    <select v-model="hoursClose4" :disabled="isDisabled" class="hoursSelect hoursHour"
                                            @change="dropDownCahnge('4')">
                                        <option value="" disabled>--</option>
                                        <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                            {{ option.display }}
                                        </option>
                                    </select>
                                    <span class="hoursCheckboxes cf">
                                        <span class="checkboxSet cf">
                                            <span class="hoursCheck hoursOpen24">
                                                <input id="BusinessHours4_open24hours"
                                                       :disabled="isDisabled" type="checkbox" @change="openChange('4')" v-model="open2474"
                                                       name="BusinessHours4_open24hours">
                                            </span>
                                            <label for="BusinessHours4_open24hours" id="BusinessHours4_open24hoursLbl"
                                                   class="hoursCheckLbl hoursOpen24lbl">Open 24 hrs</label>
                                        </span>

                                        <span class="checkboxSet cf">
                                            <span class="hoursCheck hoursClosed">
                                                <input id="BusinessHours4_closed"
                                                       :disabled="isDisabled" @change="closeChange('4')" v-model="closed4" type="checkbox"
                                                       name="BusinessHours4_closed">
                                            </span>
                                            <label for="BusinessHours4_closed" id="BusinessHours4_closedLbl"
                                                   class="hoursCheckLbl hoursClosedLbl">Closed</label>
                                        </span>
                                    </span>
                                    <div id="businessHours4Error" class="error hoursError" style="display:none;">
                                        Please select hours for this day.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="hoursDay cf">
                            <span class="req">*</span> <label>Friday</label>
                            <div class="businessHoursInput cf">
                                <div class="hoursHours cf">
                                    <legend>Hours of operation</legend>
                                    <select v-model="hoursOpen5" :disabled="isDisabled" class="hoursSelect hoursHour"
                                            @change="dropDownCahnge('5')">
                                        <option value="" disabled>--</option>
                                        <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                            {{ option.display }}
                                        </option>
                                    </select>
                                    <span class="hoursTo">to</span>
                                    <select v-model="hoursClose5" :disabled="isDisabled" class="hoursSelect hoursHour"
                                            @change="dropDownCahnge('5')">
                                        <option value="" disabled>--</option>
                                        <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                            {{ option.display }}
                                        </option>
                                    </select>
                                    <span class="hoursCheckboxes cf">
                                        <span class="checkboxSet cf">
                                            <span class="hoursCheck hoursOpen24">
                                                <input id="BusinessHours5_open24hours"
                                                       :disabled="isDisabled" type="checkbox" @change="openChange('5')" v-model="open2475"
                                                       name="BusinessHours5_open24hours">
                                            </span>
                                            <label for="BusinessHours5_open24hours" id="BusinessHours5_open24hoursLbl"
                                                   class="hoursCheckLbl hoursOpen24lbl">Open 24 hrs</label>
                                        </span>

                                        <span class="checkboxSet cf">
                                            <span class="hoursCheck hoursClosed">
                                                <input id="BusinessHours5_closed"
                                                       :disabled="isDisabled" @change="closeChange('5')" v-model="closed5" type="checkbox"
                                                       name="BusinessHours5_closed">
                                            </span>
                                            <label for="BusinessHours5_closed" id="BusinessHours5_closedLbl"
                                                   class="hoursCheckLbl hoursClosedLbl">Closed</label>
                                        </span>
                                    </span>
                                    <div id="businessHours5Error" class="error hoursError" style="display:none;">
                                        Please select hours for this day.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="hoursDay cf">
                            <span class="req">*</span> <label>Saturday</label>

                            <div class="businessHoursInput cf">

                                <div class="hoursHours cf">
                                    <legend>Hours of operation</legend>
                                    <select v-model="hoursOpen6" :disabled="isDisabled" class="hoursSelect hoursHour"
                                            @change="dropDownCahnge('6')">
                                        <option value="" disabled>--</option>
                                        <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                            {{ option.display }}
                                        </option>
                                    </select>
                                    <span class="hoursTo">to</span>
                                    <select v-model="hoursClose6" :disabled="isDisabled" class="hoursSelect hoursHour"
                                            @change="dropDownCahnge('6')">
                                        <option value="" disabled>--</option>
                                        <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                            {{ option.display }}
                                        </option>
                                    </select>
                                    <span class="hoursCheckboxes cf">
                                        <span class="checkboxSet cf">
                                            <span class="hoursCheck hoursOpen24">
                                                <input :disabled="isDisabled"
                                                       id="BusinessHours6_open24hours" type="checkbox" @change="openChange('6')"
                                                       v-model="open2476" name="BusinessHours6_open24hours">
                                            </span>
                                            <label for="BusinessHours6_open24hours" id="BusinessHours6_open24hoursLbl"
                                                   class="hoursCheckLbl hoursOpen24lbl">Open 24 hrs</label>
                                        </span>

                                        <span class="checkboxSet cf">
                                            <span class="hoursCheck hoursClosed">
                                                <input :disabled="isDisabled"
                                                       id="BusinessHours6_closed" @change="closeChange('6')" v-model="closed6"
                                                       type="checkbox" name="BusinessHours6_closed">
                                            </span>
                                            <label for="BusinessHours6_closed" id="BusinessHours6_closedLbl"
                                                   class="hoursCheckLbl hoursClosedLbl">Closed</label>
                                        </span>
                                    </span>
                                    <div id="businessHours6Error" class="error hoursError" style="display:none;">
                                        Please select hours for this day.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="hoursDay cf">
                            <span class="req">*</span> <label>Sunday</label>
                            <div class="businessHoursInput cf">
                                <div class="hoursHours cf">
                                    <legend>Hours of operation</legend>
                                    <select v-model="hoursOpen7" :disabled="isDisabled" class="hoursSelect hoursHour"
                                            @change="dropDownCahnge('7')">
                                        <option value="" disabled>--</option>
                                        <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                            {{ option.display }}
                                        </option>
                                    </select>
                                    <span class="hoursTo">to</span>
                                    <select v-model="hoursClose7" :disabled="isDisabled" class="hoursSelect hoursHour"
                                            @change="dropDownCahnge('7')">
                                        <option value="" disabled>--</option>
                                        <option v-for="(option, index) in hoursOptions" :value="option.hr" :key="index">
                                            {{ option.display }}
                                        </option>
                                    </select>
                                    <span class="hoursCheckboxes cf">
                                        <span class="checkboxSet cf">
                                            <span class="hoursCheck hoursOpen24">
                                                <input :disabled="isDisabled"
                                                       id="BusinessHours7_open24hours" type="checkbox" @change="openChange('7')"
                                                       v-model="open2477" name="BusinessHours7_open24hours">
                                            </span>
                                            <label for="BusinessHours7_open24hours" id="BusinessHours7_open24hoursLbl"
                                                   class="hoursCheckLbl hoursOpen24lbl">Open 24 hrs</label>
                                        </span>

                                        <span class="checkboxSet cf">
                                            <span class="hoursCheck hoursClosed">
                                                <input :disabled="isDisabled"
                                                       id="BusinessHours7_closed" @change="closeChange('7')" v-model="closed7"
                                                       type="checkbox" name="BusinessHours7_closed">
                                            </span>
                                            <label for="BusinessHours7_closed" id="BusinessHours7_closedLbl"
                                                   class="hoursCheckLbl hoursClosedLbl">Closed</label>
                                        </span>
                                    </span>
                                    <div id="businessHours7Error" class="error hoursError" style="display:none;">
                                        Please select hours for this day.
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <hr />

                <h3>Lottery Equipment</h3>
                <div class="equipForm form-horizontal eapp_equipmentinformation">
                    <h4>Monitors</h4>

                    <p>Indicate the size and number of units needed for this location. Leave blank if unit is not required.</p>

                    <table class="equipMonitorTbl">
                        <tbody>
                            <tr>
                                <th>Monitor Size</th>
                                <th>Wall-mount</th>
                                <th>Ceiling-mount</th>
                            </tr>
                            <tr>
                                <td>Small (up to 32")</td>
                                <td>
                                    <input :disabled="isDisabled" v-model="application.equipment.smallMonitorWall"
                                           @change="validateMonitor($event)" name="SmallMonitorWall" type="text" maxlength="3"
                                           id="SmallMonitorWall">
                                </td>
                                <td>
                                    <input :disabled="isDisabled" v-model="application.equipment.smallMonitorCeiling"
                                           @change="validateMonitor($event)" name="SmallMonitorCeiling" type="text" maxlength="3"
                                           id="SmallMonitorCeiling">
                                </td>
                            </tr>
                            <tr>
                                <td>Medium (33" to 42")</td>
                                <td>
                                    <input :disabled="isDisabled" v-model="application.equipment.mediumMonitorWall"
                                           @change="validateMonitor($event)" name="MediumMonitorWall" type="text" maxlength="3"
                                           id="MediumMonitorWall">
                                </td>
                                <td>
                                    <input :disabled="isDisabled" v-model="application.equipment.mediumMonitorCeiling"
                                           @change="validateMonitor($event)" name="MediumMonitorCeiling" type="text" maxlength="3"
                                           id="MediumMonitorCeiling">
                                </td>
                            </tr>
                            <tr>
                                <td>Large (43" to 50")</td>
                                <td>
                                    <input :disabled="isDisabled" v-model="application.equipment.largeMonitorWall"
                                           @change="validateMonitor($event)" name="LargeMonitorWall" type="text" maxlength="3"
                                           id="LargeMonitorWall">
                                </td>
                                <td>
                                    <input :disabled="isDisabled" v-model="application.equipment.largeMonitorCeiling"
                                           @change="validateMonitor($event)" name="LargeMonitorCeiling" type="text" maxlength="3"
                                           id="LargeMonitorCeiling">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="error" v-if="monitorError">Quantities must be in numeric format only.</div>

                    <div class="formRow radiosRight cf">
                        <span>Type of ceiling:</span>
                        <div class="formRowRight radios cf">
                            <span id="EquipTypeCeiling_list" class="radio radio-list-vertical">
                                <input :disabled="isDisabled" v-model="application.equipment.equipTypeCeiling"
                                       id="EquipTypeCeiling_list_0" type="radio" name="EquipTypeCeiling" value="Drywall">
                                <label for="EquipTypeCeiling_list_0">Drywall</label><br>
                                <input :disabled="isDisabled" v-model="application.equipment.equipTypeCeiling"
                                       id="EquipTypeCeiling_list_1" type="radio" name="EquipTypeCeiling" value="Open">
                                <label for="EquipTypeCeiling_list_1">Open</label><br>
                                <input :disabled="isDisabled" v-model="application.equipment.equipTypeCeiling"
                                       id="EquipTypeCeiling_list_2" type="radio" name="EquipTypeCeiling" value="Drop">
                                <label for="EquipTypeCeiling_list_2">Drop</label>
                            </span>
                        </div>
                    </div>

                    <div class="formRow field10wide dividerRow cf" id="estimatedCeilingHeight">
                        <label for="EquipEstimatedCeilingHeight">Estimated ceiling height:</label>
                        <input :disabled="isDisabled" v-model="application.equipment.equipEstimatedCeilingHeight"
                               name="EquipEstimatedCeilingHeight" type="text" maxlength="20" id="EquipEstimatedCeilingHeight">
                    </div>

                    <div class="formRow cf businessType">
                        <label for="EquipBusinessType_dropDownList">Business type:</label>

                        <select :disabled="isDisabled" v-model="application.equipment.equipBusinessType" name="EquipBusinessType"
                                id="EquipBusinessType" class="">
                            <option value="null">Select...</option>
                            <option value="53110">53110 - Department-Discount Store</option>
                            <option value="54111">54111 - Supermarkets</option>
                            <option value="54112">54112 - Small Food-Deli-Specialty Food Store</option>
                            <option value="54113">54113 - Convenience Food Store</option>
                            <option value="55411">55411 - Gasoline Stations</option>
                            <option value="55412">55412 - Gasoline Station W-Convenience Store</option>
                            <option value="58120">58120 - Eating Place</option>
                            <option value="58130">58130 - Bars-Taverns</option>
                            <option value="59120">59120 - Drug Stores</option>
                            <option value="59210">59210 - Liquor Carry Out Stores &amp; Drive-Thrus</option>
                            <option value="59940">59940 - Newsstands &amp; Tobacco Shops</option>
                            <option value="59991">59991 - Small Retail Sales Stores</option>
                            <option value="59992">59992 - Small Retail Service Stores</option>
                            <option value="59993">59993 - Agencies &amp; Clubs</option>
                            <option value="59994">59994 - General Service Stores</option>
                            <option value="59995">59995 - Video Stores</option>
                            <option value="59996">59996 - Money Exchange-Check Cashing Stores</option>
                            <option value="59997">59997 - Bowling Lanes</option>
                            <option value="59998">59998 - Variety Stores</option>
                            <option value="59999">59999 - Hardware Stores</option>
                        </select>
                        <br><br>
                    </div>
                    <div class="formRow cf businessType">
                        Security:<br />
                        <div class="formRowRight checkboxWithExplan cf CheckBoxField checkbox">
                            <input :disabled="isDisabled" v-model="application.equipment.equipSecurityAcceptance"
                                   id="EquipSecurityAcceptance" type="checkbox" name="EquipSecurityAcceptance">
                            <label class="explanation-text" for="EquipSecurityAcceptance">
                                Applicant can provide reasonable security
                                for equipment, tickets and proceeds
                            </label>
                        </div>
                    </div>
                    <div class="formRow fileUpload cf businessType">
                        <label for="EquipSiteLayoutFile">
                            Site layout: <a v-if="fileLayout != '' || fileLayout != null || fileLayout != undefined"
                                            @click="downloadEquipmentFile()">
                                Click Here to View
                                {{ application.equipment.equipSiteLayoutFileName }}
                            </a>
                        </label>
                        <!-- <input :disabled="isDisabled" type="file" name="EquipSiteLayoutFile" id="EquipSiteLayoutFile" @input="event => handleFile(event)"> -->

                        <VueFileAgent ref="vueFileAgent" :multiple="false" :deletable="true" :meta="true" :linkable="true"
                                      :accept="'.pdf,.doc,.docx,.ppt,.pptx,.gif,.jpg,.jpeg,.png,.zip'" :theme="'list'" :maxSize="'5MB'"
                                      :maxFiles="1" :helpText="'Choose file'" :errorText="{
                type: 'Invalid file type. Allowed extensions: pdf, doc, docx, ppt, pptx, gif, jpg, jpeg, png, zip.',
                size: 'Files should not exceed 5mb in size'}" @select="handleEquipmentFile($event)"
                                      id="equipment-file" @beforedelete="onBeforeEquipmentDelete($event)" @delete="onEquipmentDelete($event)">
                        </VueFileAgent>
                        <span class="explanation-text">Upload a sketch of the retail location's floor plan.</span>
                    </div>
                    <div class="formRow dividerRow cf checkboxWithExplan businessType">
                        <label for="EquipOnPremiseConsumption">On premise consumption:</label>
                        <span class="CheckBoxField checkbox">
                            <input :disabled="isDisabled" v-model="application.equipment.equipOnPremiseConsumption"
                                   id="EquipOnPremiseConsumption" type="checkbox" name="EquipOnPremiseConsumption">
                            <label for="EquipOnPremiseConsumption" class="explanation-text">
                                Check this box for on premise
                                consumption
                            </label>
                        </span>
                    </div>
                    <br /><br />
                    <h4>ADA Compliance Information</h4>
                    <div class="formRow radiosRight cf" id="adaCompliance">
                        <span style="display: block; float: left; width: 23%;">ADA Compliance:</span>
                        <div class="formRowRight radios cf">
                            <span id="EquipADAInCompliance_list" class="radio radio-list-vertical">
                                <input :disabled="isDisabled" v-model="application.equipment.equipADAInCompliance"
                                       id="EquipADAInCompliance_list_0" type="radio" name="EquipADAInCompliance" value="true">
                                <label for="EquipADAInCompliance_list_0">In Compliance</label><br>
                                <input :disabled="isDisabled" v-model="application.equipment.equipADAInCompliance"
                                       id="EquipADAInCompliance_list_1" type="radio" name="EquipADAInCompliance" value="false">
                                <label for="EquipADAInCompliance_list_1">Not Achievable</label>
                            </span>
                        </div>
                    </div>
                    <div class="formRow datePicker cf">
                        <label for="EquipADAInspectionDate">Inspection Date:</label>
                        <datepicker :disabled="isDisabled" placeholder="Select Date" name="EquipADAInspectionDate" :disabledDates="disabledDates"
                                    :format="customFormatter" v-model="application.equipment.equipADAInspectionDate" style="width: 58%;">
                        </datepicker><!--:input-class="input-width-58"-->
                    </div>
                    <div class="formRow datePicker cf">
                        <label for="EquipADANoticeSentDate">Notice Sent Date:</label>
                        <datepicker :disabled="isDisabled" placeholder="Select Date" name="EquipADANoticeSentDate"
                                    :format="customFormatter" v-model="application.equipment.equipADANoticeSentDate" style="width: 58%;">
                        </datepicker> <!--:input-class="input-width-58"-->
                    </div>
                    <div class="formRow notCompliantReasons cf">
                        <div>Not Compliant Reasons:</div>
                        <div class="grid-x grid-margin-x grid-margin-y">
                            <div class="cell small-6 medium-3 checkboxes-wrapper">
                                <input :disabled="isDisabled" v-model="application.equipment.equipADANotCompliantParking"
                                       id="EquipADANotCompliantParking" type="checkbox" name="EquipADANotCompliantParking">
                                <label for="EquipADANotCompliantParking" class="explanation-text">Parking</label>
                            </div>
                            <div class="cell small-6 medium-3 checkboxes-wrapper">
                                <input :disabled="isDisabled" v-model="application.equipment.equipADANotCompliantExteriorTravel"
                                       id="EquipADANotCompliantExteriorTravel" type="checkbox"
                                       name="EquipADANotCompliantExteriorTravel">
                                <label for="EquipADANotCompliantExteriorTravel" class="explanation-text">Exterior Travel</label>
                            </div>
                            <div class="cell small-6 medium-3 checkboxes-wrapper">
                                <input :disabled="isDisabled" v-model="application.equipment.equipADANotCompliantEntrance"
                                       id="EquipADANotCompliantEntrance" type="checkbox" name="EquipADANotCompliantEntrance">
                                <label for="EquipADANotCompliantEntrance" class="explanation-text">Entrance</label>
                            </div>
                            <div class="cell small-6 medium-3 checkboxes-wrapper">
                                <input :disabled="isDisabled" v-model="application.equipment.equipADANotCompliantInteriorTravel"
                                       id="EquipADANotCompliantInteriorTravel" type="checkbox"
                                       name="EquipADANotCompliantInteriorTravel">
                                <label for="EquipADANotCompliantInteriorTravel" class="explanation-text">Interior Travel</label>
                            </div>
                            <div class="cell small-6 medium-3 checkboxes-wrapper">
                                <input :disabled="isDisabled" v-model="application.equipment.equipADASpacing" id="EquipADASpacing"
                                       type="checkbox" name="EquipADASpacing">
                                <label for="EquipADASpacing" class="explanation-text">Spacing</label>
                            </div>
                            <div class="cell small-6 medium-3 checkboxes-wrapper">
                                <input :disabled="isDisabled" v-model="application.equipment.equipADASignage" id="EquipADASignage"
                                       type="checkbox" name="EquipADASignage">
                                <label for="EquipADASignage" class="explanation-text">Signage</label>
                            </div>
                        </div>
                    </div>
                    <h4>Requested Equipment</h4>
                    <div class="formRow cf grid-x grid-margin-x grid-margin-y equip-grid">
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipCDU" @change="validateEqiup($event)"
                                   name="EquipCDU" type="text" maxlength="3" id="EquipCDU">
                            <label class="explanation-text">CDU</label>
                        </div>
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipPhoton" @change="validateEqiup($event)"
                                   name="EquipPhoton" type="text" maxlength="3" id="EquipPhoton">
                            <label class="explanation-text">Photon</label>
                        </div>
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipTouchWin"
                                   @change="validateEqiup($event)" name="EquipTouchWin" type="text" maxlength="3" id="EquipTouchWin">
                            <label class="explanation-text">Touch &amp; Win</label>
                        </div>
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipKenoBacklight"
                                   @change="validateEqiup($event)" name="EquipKenoBacklight" type="text" maxlength="3"
                                   id="EquipKenoBacklight">
                            <label class="explanation-text">Keno Backlight</label>
                        </div>
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipPlayStation"
                                   @change="validateEqiup($event)" name="EquipPlayStation" type="text" maxlength="3"
                                   id="EquipPlayStation">
                            <label class="explanation-text">PlayStation</label>
                        </div>
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipWinStation"
                                   @change="validateEqiup($event)" name="EquipWinStation" type="text" maxlength="3"
                                   id="EquipWinStation">
                            <label class="explanation-text">WinStation</label>
                        </div>
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipMP" @change="validateEqiup($event)"
                                   name="EquipMP" type="text" maxlength="3" id="EquipMP">
                            <label class="explanation-text">MP</label>
                        </div>
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipPole" @change="validateEqiup($event)"
                                   name="EquipPole" type="text" maxlength="3" id="EquipPole">
                            <label class="explanation-text">Pole Mount</label>
                        </div>
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipWiredTicketChecker"
                                   @change="validateEqiup($event)" name="EquipWiredTicketChecker" type="text" maxlength="3"
                                   id="EquipWiredTicketChecker">
                            <label class="explanation-text">Wired Ticket Checker</label>
                        </div>
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipMPNG" @change="validateEqiup($event)"
                                   name="EquipMPNG" type="text" maxlength="3" id="EquipMPNG">
                            <label class="explanation-text">MPNG</label>
                        </div>
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipSATSign" @change="validateEqiup($event)"
                                   name="EquipSATSign" type="text" maxlength="3" id="EquipSATSign">
                            <label class="explanation-text">SAT Sign</label>
                        </div>
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipWirelessReader"
                                   @change="validateEqiup($event)" name="EquipWirelessReader" type="text" maxlength="3"
                                   id="EquipWirelessReader">
                            <label class="explanation-text">Wireless Reader</label>
                        </div>
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipPAD" @change="validateEqiup($event)"
                                   name="EquipPAD" type="text" maxlength="3" id="EquipPAD">
                            <label class="explanation-text">Pad</label>
                        </div>
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipStarlight"
                                   @change="validateEqiup($event)" name="EquipStarlight" type="text" maxlength="3" id="EquipStarlight">
                            <label class="explanation-text">Starlight</label>
                        </div>
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipWirelessTicketChecker"
                                   @change="validateEqiup($event)" name="EquipWirelessTicketChecker" type="text" maxlength="3"
                                   id="EquipWirelessTicketChecker">
                            <label class="explanation-text">Wireless Ticket Checker</label>
                        </div>
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipDreamTouchSmart"
                                   @change="validateEqiup($event)" name="EquipDreamTouchSmart" type="text" maxlength="3"
                                   id="EquipDreamTouchSmart">
                            <label class="explanation-text">Dream Touch Smart</label>
                        </div>
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipSelect4Unit"
                                   @change="validateEqiup($event)" name="EquipSelect4Unit" type="text" maxlength="3"
                                   id="EquipSelect4Unit">
                            <label class="explanation-text">Select 4 Unit</label>
                        </div>
                        <div class="cell small-6 medium-4 large-3 xlarge-2">
                            <input :disabled="isDisabled" v-model="application.equipment.equipSciTrackUnit"
                                   @change="validateEqiup($event)" name="EquipSciTrackUnit" type="text" maxlength="3"
                                   id="EquipSciTrackUnit">
                            <label class="explanation-text">Sci Track Unit</label>
                        </div>
                    </div>
                    <div class="error" v-if="equipRequestError">Quantities must be in numeric format only.</div>
                    <div class="formRow cf">
                        <label for="">Other:</label>
                        <input :disabled="isDisabled" v-model="application.equipment.equipOtherDesc" name="EquipOtherDesc" type="text"
                               maxlength="600" id="EquipOtherDesc" class="otherField">
                    </div>
                </div>
                <hr />

                <h3>Liability Deposit</h3>
                <div class="liabilityForm EditForm eAppFormEdit eapp_info grid-x grid-margin-x grid-margin-y">
                    <div class="cell medium-5 large-3">
                        <label>Liability Multiplier:</label>
                    </div>
                    <div class="cell medium-7 large-9">
                        <span class="radio depositMultiplier radio-list-horizontal">
                            <input v-model="application.keno.kenoDepositMultiplier" id="DepositMultiplier_0" type="radio"
                                   name="DepositMultiplier" value="7">
                            <label for="DepositMultiplier_0">7x</label>
                            <input v-model="application.keno.kenoDepositMultiplier" id="DepositMultiplier_1" type="radio"
                                   name="DepositMultiplier" value="10">
                            <label for="DepositMultiplier_1">10x</label>
                        </span>
                        <div v-if="errorDepositMultiplier" class="error">Please select a multiplier.</div>
                    </div>
                    <div class="cell medium-5 large-3">
                        <label for="DepositAmount">Current deposit amount:</label>
                    </div>
                    <div class="cell medium-7 large-9 field10wide">
                        $ <input v-model="application.keno.kenoDepositAmount" name="DepositAmount" type="number"
                                 pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==11) return false;" maxlength="11"
                                 id="DepositAmount" class="depositInput">
                        <div v-if="errorDepositAmount" class="error">Please provide a valid number.</div>
                    </div>
                    <div class="cell medium-5 large-3">
                        <label for="MaxLiability">Maximum Liability Limit:</label>
                    </div>
                    <div class="cell medium-7 large-9">
                        $ <span class="maxLiability">{{ maxLiability }}</span>
                        <div class="explanation-text">
                            Maximum liablility limit is calculated by multiplying the Liability
                            Multiplier times the deposit amount.
                        </div>
                    </div>
                    <div class="cell medium-5 large-3">
                        <label for="DepositCheckNumber">Check Number:</label>
                    </div>
                    <div class="cell medium-7 large-9 field10wide">
                        <input v-model="application.keno.kenoDepositCheckNumber" name="DepositCheckNumber" type="text"
                               maxlength="10" id="DepositCheckNumber" class="field30wide">
                        <div v-if="errorDepositCheckNumber" class="error">Please provide a valid check number.</div>
                    </div>
                    <div class="cell medium-5 large-3">
                        <label for="DepositCurrentDueAmount">Current Due Amount:</label>
                    </div>
                    <div class="cell medium-7 large-9 field10wide">
                        $ <input v-model="application.keno.kenoDepositCurrentDueAmount" name="DepositCurrentDueAmount"
                                 type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==11) return false;"
                                 maxlength="11" id="DepositCurrentDueAmount" class="currentDueAmount">
                        <div v-if="errorDepositCurrentDueAmount" class="error">Please provide a valid number.</div>
                    </div>
                    <div class="cell medium-5 large-3">
                        <label for="LiquorLicenseID">Liquor license ID:</label>
                    </div>
                    <div class="cell medium-7 large-9">
                        <input v-model="application.keno.kenoLiquorLicenseID" name="LiquorLicenseID" type="text" maxlength="11"
                               id="LiquorLicenseID" class="field30wide">
                        <div v-if="errorLiquorLicenseID" class="error">Please provide a valid number.</div>
                    </div>
                    <div class="cell medium-5 large-3">
                        <label for="">Liquor License Type:</label>
                    </div>
                    <div class="cell medium-7 large-9 radiosRight">
                        <div class="checkbox checkbox-list-horizontal">
                            <input v-model="d1" id="D1" type="checkbox" name="D1" value="1"><label for="1">D1</label>
                            <input v-model="d2" id="D2" type="checkbox" name="D2" value="2"><label for="2">D2</label>
                            <input v-model="d3" id="D3" type="checkbox" name="D3" value="3"><label for="3">D3</label>
                            <input v-model="d4" id="D4" type="checkbox" name="D4" value="4"><label for="4">D4</label>
                            <input v-model="d5" id="D5" type="checkbox" name="D5" value="5"><label for="5">D5</label>
                            <input v-model="d7" id="D7" type="checkbox" name="D7" value="6"><label for="6">D7</label>
                            <input v-model="a1a" id="A1A" type="checkbox" name="A1A" value="7"><label for="7">A1A</label>
                            <input v-model="a1c" id="A1c" type="checkbox" name="A1c" value="8"><label for="8">A1c</label>
                        </div>
                        <div class="explanation-text">(Check all the apply)</div>
                    </div>
                    <div class="cell medium-5 large-3 dividerRow ">
                        <label for="">Uploaded File:</label>
                    </div>
                    <div class="cell medium-7 large-9 fileUpload">
                        <label>
                            File: <a v-if="application.keno.kenoUploadedFile != '' || application.keno.kenoUploadedFile != null || application.keno.kenoUploadedFile != undefined" @click="downloadLiabilityFile()">
                                Click Here to
                                View {{ application.keno.kenoUploadedFileName }}
                            </a>
                        </label>
                        <!-- <input type="file" id="KenoUploadedFile" name="KenoUploadedFile" @input="event => handleFile(event)" /> -->
                        <VueFileAgent ref="vueFileAgent" :multiple="false" :deletable="true" :meta="true" :linkable="true"
                                      :accept="'.pdf,.doc,.docx,.ppt,.pptx,.gif,.jpg,.jpeg,.png,.zip'" :theme="'list'" :maxSize="'5MB'"
                                      :maxFiles="1" :helpText="'Choose file'" :errorText="{
                type: 'Invalid file type. Allowed extensions: pdf, doc, docx, ppt, pptx, gif, jpg, jpeg, png, zip.',
                size: 'Files should not exceed 5mb in size'
            }" @select="handleLiabilityFile($event)" id="liability-file"
                                      @beforedelete="onBeforeLiabilityDelete($event)" @delete="onLiabilityDelete($event)">
                        </VueFileAgent>
                        <br />
                        <div class="explanation-text">
                            Upload a copy of the retailer's liquor license or a photograph of the
                            posted "18 or over" sign.
                        </div>
                        <div v-if="errorUploadedFile" class="error">Please upload a photograph.</div>
                    </div>
                </div>


                <div class="btn-actions">
                    <a class="button eAppDashBtn" title="Save" @click="save">Save</a>
                    <a class="button eAppDashBtn" title="Save and create another location" @click="saveAndNew">Save and Create New</a>
                </div>
            </div>

        </div>
    </div>
</template>


<script>

    // https://www.npmjs.com/package/vue-moment-datepicker
    import Datepicker from "vue-moment-datepicker";
    import Foundation from "@/foundation-sites/js/foundation.js";

    import moment from "moment";
    export default {

        components: {
            Datepicker
},
        props: {
            masterApplicationGuid: String,
            userId: Number,
            chainToken: String,
            userRole: String
        },
        data: function () {
            return {
                application :
                {
                    businessInformation :{},
                    contactInformation : {},
                    equipment : {},
                    keno: {}
                },
                headers: ["Application", "Business Name", "City", "Application Date"],
                submitting: false,
                masterAppGuid: '',
                showLocationsList: true,
                showNewLocationForm: false,
                businessInfo: {},
                primaryPhone: '',
                altPhone: '',
                businessFax: '',
                contactInfo: {},
                faxPhone: '',
                formatErrorFax: false,
                isLoading: false,
                monitorError: false,

                validEmail: true,

                disabledDates: {
                    from: new Date()
                },

                isDisabled: false,
                nameError: false,
                nameAsError: false,
                formatErrorPrimary: false,
                phoneError: false,

                primaryContactNameError: false,
                primaryContactTitleError: false,
                primaryContactEmailError: false,
                primaryContactPhoneError: false,

                secondaryContactNameError: false,
                secondaryContactTitleError: false,
                secondaryContactEmailError: false,
                secondaryContactPhoneError: false,

                businessStreetAddressError: false,
                businessStateError: false,
                businessCityError: false,
                validZip: true,
                businessCountyError: false,

                errorName: false,
                errorAddress: false,
                errorCity: false,
                errorState: false,
                validMailZip: true,

                hoursOpen1: '',
                hoursClose1: '',
                closed1: false,
                open2471: false,
                hoursOpen2: '',
                hoursClose2: '',
                closed2: false,
                open2472: false,
                hoursOpen3: '',
                hoursClose3: '',
                closed3: false,
                open2473: false,
                hoursOpen4: '',
                hoursClose4: '',
                closed4: false,
                open2474: false,
                hoursOpen5: '',
                hoursClose5: '',
                closed5: false,
                open2475: false,
                hoursOpen6: '',
                hoursClose6: '',
                closed6: false,
                open2476: false,
                hoursOpen7: '',
                hoursClose7: '',
                closed7: false,
                open2477: false,
                hoursOptions:
                    [
                        { hr: "00:00", display: "12:00 AM" },
                        { hr: "00:30", display: "12:30 AM" },
                        { hr: "01:00", display: "1:00 AM" },
                        { hr: "01:30", display: "1:30 AM" },
                        { hr: "02:00", display: "2:00 AM" },
                        { hr: "02:30", display: "2:30 AM" },
                        { hr: "03:00", display: "3:00 AM" },
                        { hr: "03:30", display: "3:30 AM" },
                        { hr: "04:00", display: "4:00 AM" },
                        { hr: "04:30", display: "4:30 AM" },
                        { hr: "05:00", display: "5:00 AM" },
                        { hr: "05:30", display: "5:30 AM" },
                        { hr: "06:00", display: "6:00 AM" },
                        { hr: "06:30", display: "6:30 AM" },
                        { hr: "07:00", display: "7:00 AM" },
                        { hr: "07:30", display: "7:30 AM" },
                        { hr: "08:00", display: "8:00 AM" },
                        { hr: "08:30", display: "8:30 AM" },
                        { hr: "09:00", display: "9:00 AM" },
                        { hr: "09:30", display: "9:30 AM" },
                        { hr: "10:00", display: "10:00 AM" },
                        { hr: "10:30", display: "10:30 AM" },
                        { hr: "11:00", display: "11:00 AM" },
                        { hr: "11:30", display: "11:30 AM" },
                        { hr: "12:00", display: "12:00 PM" },
                        { hr: "12:30", display: "12:30 PM" },
                        { hr: "13:00", display: "1:00 PM" },
                        { hr: "13:30", display: "1:30 PM" },
                        { hr: "14:00", display: "2:00 PM" },
                        { hr: "14:30", display: "2:30 PM" },
                        { hr: "15:00", display: "3:00 PM" },
                        { hr: "15:30", display: "3:30 PM" },
                        { hr: "16:00", display: "4:00 PM" },
                        { hr: "16:30", display: "4:30 PM" },
                        { hr: "17:00", display: "5:00 PM" },
                        { hr: "17:30", display: "5:30 PM" },
                        { hr: "18:00", display: "6:00 PM" },
                        { hr: "18:30", display: "6:30 PM" },
                        { hr: "19:00", display: "7:00 PM" },
                        { hr: "19:30", display: "7:30 PM" },
                        { hr: "20:00", display: "8:00 PM" },
                        { hr: "20:30", display: "8:30 PM" },
                        { hr: "21:00", display: "9:00 PM" },
                        { hr: "21:30", display: "9:30 PM" },
                        { hr: "22:00", display: "10:00 PM" },
                        { hr: "22:30", display: "10:30 PM" },
                        { hr: "23:00", display: "11:00 PM" },
                        { hr: "23:30", display: "11:30 PM" },
                    ],
                showSuccessMessage: false,
                errorMessageShow: false,

                
                d1: false,
                d2: false,
                d3: false,
                d4: false,
                d5: false,
                d7: false,
                a1a: false,
                a1c: false,
                errorDepositMultiplier: false,
                errorDepositAmount: false,
                errorDepositCheckNumber: false,
                errorDepositCurrentDueAmount: false,
                errorLiquorLicenseID: false,
                errorUploadedFile: false,

                formatErrorAlt: false,
                equipRequestError: false,
                fileLayout: false,
                locations: [],
                showQuickview: false,
                userEappRole: ''
            }
        },
        mounted: function () {
            this.init();
        },
        methods: {
            init() {
                this.tryGetFromQueryString().then(() => {
                    this.fetchData();
                });
                this.setRole();
            },
            setRole() {
                this.userEappRole = this.getEappRole();

                if (this.userEappRole !== false) {
                        // is Sales Rep?
                    if (this.userEappRole.codename == 'eApp-ChainSalesRep' || this.userEappRole.codename == 'eApp-RegionalManager') {
                            this.showQuickview = true;
                        }

                        if (this.userEappRole.codename == 'eApp-CentralLicensingManager') {
                            this.showQuickview = true;
                        }
                    } else {
                        // no eApp Role found
                        console.log('no eApp Role found');
                    }
            },
            openQuickview(i) {
                let popup = new Foundation.Reveal($('#qv' + i));
                popup.open();
            },
            async fetchData() {

                this.isLoading = true;
                let request = {
                    "masterAppGuid": this.masterAppGuid
                }
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + this.chainToken,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(request)
                };
                let response = await (await fetch(this.GET_LOCATION_API_URL, appsRequestOptions)).json();

                if (response != null && response.statusCode == 200) {
                    this.locations = response.data.results;
                }
                else {
                    console.log('error!');
                    this.errorMessageShow = true;
                    this.isLoading = false;
                }
            },
            dateFormatter(date) {
                return moment(date).format('M/D/yyyy');
            },
            hasBusinessName(bizName) {
                return (bizName ? bizName : '- No business name available -');
            },
            customFormatter(date) {
                return moment(date).format('MM/DD/yyyy');
            },
            createNewChainLocation() {
                this.showNewLocationForm = true;
                this.showLocationsList = false;
            },
            tryGetFromQueryString() {

                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                if (urlParams.has('id')) {
                    this.masterAppGuid = urlParams.get('id');
                }
                
                return Promise.resolve();
            },
            dropDownCahnge(hoursNum) {
                switch (hoursNum) {
                    case "1":
                        this.open2471 = false;
                        this.closed1 = false;

                        break;
                    case "2":
                        this.open2472 = false;
                        this.closed2 = false;
                        break;
                    case "3":
                        this.open2473 = false;
                        this.closed3 = false;
                        break;
                    case "4":
                        this.open2474 = false;
                        this.closed4 = false;
                        break;
                    case "5":
                        this.open2475 = false;
                        this.closed5 = false;
                        break;
                    case "6":
                        this.open2476 = false;
                        this.closed6 = false;
                        break;
                    case "7":
                        this.open2477 = false;
                        this.closed7 = false;
                        break;
                    default:
                        break;
                }

            },
            openChange(hoursNum) {
                switch (hoursNum) {
                    case "1":
                        if (this.open2471) {
                            this.hoursOpen1 = ''
                            this.hoursClose1 = '';
                            this.closed1 = false;
                        }
                        break;
                    case "2":
                        if (this.open2472) {
                            this.hoursOpen2 = ''
                            this.hoursClose2 = '';
                            this.closed2 = false;
                        }
                        break;
                    case "3":
                        if (this.open2473) {
                            this.hoursOpen3 = ''
                            this.hoursClose3 = '';
                            this.closed3 = false;
                        }
                        break;
                    case "4":
                        if (this.open2474) {
                            this.hoursOpen4 = ''
                            this.hoursClose4 = '';
                            this.closed4 = false;
                        }
                        break;
                    case "5":
                        if (this.open2475) {
                            this.hoursOpen5 = ''
                            this.hoursClose5 = '';
                            this.closed5 = false;
                        }
                        break;
                    case "6":
                        if (this.open2476) {
                            this.hoursOpen6 = ''
                            this.hoursClose6 = '';
                            this.closed6 = false;
                        }
                        break;
                    case "7":
                        if (this.open2477) {
                            this.hoursOpen7 = ''
                            this.hoursClose7 = '';
                            this.closed7 = false;
                        }
                        break;
                    default:
                        break;
                }

            },
            closeChange(hoursNum) {
                switch (hoursNum) {
                    case "1":
                        if (this.closed1) {
                            this.hoursOpen1 = ''
                            this.hoursClose1 = '';
                            this.open2471 = false;
                        }
                        break;
                    case "2":
                        if (this.closed2) {
                            this.hoursOpen2 = ''
                            this.hoursClose2 = '';
                            this.open2472 = false;
                        }
                        break;
                    case "3":
                        if (this.closed3) {
                            this.hoursOpen3 = ''
                            this.hoursClose3 = '';
                            this.open2473 = false;
                        }
                        break;
                    case "4":
                        if (this.closed4) {
                            this.hoursOpen4 = ''
                            this.hoursClose4 = '';
                            this.open2474 = false;
                        }
                        break;
                    case "5":
                        if (this.closed5) {
                            this.hoursOpen5 = ''
                            this.hoursClose5 = '';
                            this.open2475 = false;
                        }
                        break;
                    case "6":
                        if (this.closed6) {
                            this.hoursOpen6 = ''
                            this.hoursClose6 = '';
                            this.open2476 = false;
                        }
                        break;
                    case "7":
                        if (this.closed7) {
                            this.hoursOpen7 = ''
                            this.hoursClose7 = '';
                            this.open2477 = false;
                        }
                        break;
                    default:
                        break;
                }
            },
            updateBusinessHours() {
                let b1 = {};
                b1.OpenHour = this.hoursOpen1;
                b1.CloseHour = this.hoursClose1;
                b1.Open24hours = this.open2471;
                b1.Closed = this.closed1;
                this.application.businessInformation.businessHours1 = JSON.stringify(b1);


                let b2 = {};
                b2.OpenHour = this.hoursOpen2;
                b2.CloseHour = this.hoursClose2;
                b2.Open24hours = this.open2472;
                b2.Closed = this.closed2;
                this.application.businessInformation.businessHours2 = JSON.stringify(b2);


                let b3 = {};
                b3.OpenHour = this.hoursOpen3;
                b3.CloseHour = this.hoursClose3;
                b3.Open24hours = this.open2473;
                b3.Closed = this.closed3;
                this.application.businessInformation.businessHours3 = JSON.stringify(b3);


                let b4 = {};
                b4.OpenHour = this.hoursOpen4;
                b4.CloseHour = this.hoursClose4;
                b4.Open24hours = this.open2474;
                b4.Closed = this.closed4;
                this.application.businessInformation.businessHours4 = JSON.stringify(b4);

                let b5 = {};
                b5.OpenHour = this.hoursOpen5;
                b5.CloseHour = this.hoursClose5;
                b5.Open24hours = this.open2475;
                b5.Closed = this.closed5;
                this.application.businessInformation.businessHours5 = JSON.stringify(b5);

                let b6 = {};
                b6.OpenHour = this.hoursOpen6;
                b6.CloseHour = this.hoursClose6;
                b6.Open24hours = this.open2476;
                b6.Closed = this.closed6;
                this.application.businessInformation.businessHours6 = JSON.stringify(b6);

                let b7 = {};
                b7.OpenHour = this.hoursOpen7;
                b7.CloseHour = this.hoursClose7;
                b7.Open24hours = this.open2477;
                b7.Closed = this.closed7;
                this.application.businessInformation.businessHours7 = JSON.stringify(b7);
            },
            parseHours() {
                if (this.application.businessInformation.businessHours1) {
                    let hours1 = JSON.parse(this.application.businessInformation.businessHours1);
                    this.hoursOpen1 = hours1.OpenHour;
                    this.hoursClose1 = hours1.CloseHour;
                    this.open2471 = hours1.Open24hours;
                    this.closed1 = hours1.Closed;
                }
                if (this.application.businessInformation.businessHours2) {
                    let hours2 = JSON.parse(this.application.businessInformation.businessHours2);
                    this.hoursOpen2 = hours2.OpenHour;
                    this.hoursClose2 = hours2.CloseHour;
                    this.open2472 = hours2.Open24hours;
                    this.closed2 = hours2.Closed;

                }
                if (this.application.businessInformation.businessHours3) {
                    let hours3 = JSON.parse(this.application.businessInformation.businessHours3);
                    this.hoursOpen3 = hours3.OpenHour;
                    this.hoursClose3 = hours3.CloseHour;
                    this.open2473 = hours3.Open24hours;
                    this.closed3 = hours3.Closed;

                }
                if (this.application.businessInformation.businessHours4) {
                    let hours4 = JSON.parse(this.application.businessInformation.businessHours4);
                    this.hoursOpen4 = hours4.OpenHour;
                    this.hoursClose4 = hours4.CloseHour;
                    this.open2474 = hours4.Open24hours;
                    this.closed4 = hours4.Closed;

                }
                if (this.application.businessInformation.businessHours5) {
                    let hours5 = JSON.parse(this.application.businessInformation.businessHours5);
                    this.hoursOpen5 = hours5.OpenHour;
                    this.hoursClose5 = hours5.CloseHour;
                    this.open2475 = hours5.Open24hours;
                    this.closed5 = hours5.Closed;

                }
                if (this.application.businessInformation.businessHours6) {
                    let hours6 = JSON.parse(this.application.businessInformation.businessHours6);
                    this.hoursOpen6 = hours6.OpenHour;
                    this.hoursClose6 = hours6.CloseHour;
                    this.open2476 = hours6.Open24hours;
                    this.closed6 = hours6.Closed;

                }
                if (this.application.businessInformation.businessHours7) {
                    let hours7 = JSON.parse(this.application.businessInformation.businessHours7);
                    this.hoursOpen7 = hours7.OpenHour;
                    this.hoursClose7 = hours7.CloseHour;
                    this.open2477 = hours7.Open24hours;
                    this.closed7 = hours7.Closed;
                }
            },
            validate() {
                //business name
                let validName = this.validateName();

                //business sob
                let validSob = this.validateSob();

                //contact
                let validContact = this.validateContact();

                //address
                let validAddress = this.validateAddress();

                //hours
                let validHours = this.validateHours();

                if (validName && validSob && validContact && validAddress && validHours) {
                    return true;
                }
                else {
                    console.log(validName)
                    console.log(validSob)
                    console.log(validContact)
                    console.log(validAddress)
                    console.log(validHours)
                    return false;
                }
            },
            phoneFormatValid() {
                let isValidPhone = true;
                let isValidAlt = true;
                let isValidFax = true;
                if (!this.phoneValidation(this.primaryPhone)) {
                    isValidPhone = false;
                    this.formatErrorPrimary = true;
                } else {
                    this.formatErrorPrimary = false;
                    this.application.businessInformation.businessPhoneNumber = this.primaryPhone;
                }
                if (this.altPhone != '') {
                    isValidAlt = this.phoneValidation(this.altPhone);
                    this.formatErrorAlt = !this.phoneValidation(this.altPhone);
                    this.application.businessInformation.businessPhoneNumberSecondary = this.altPhone;
                }
                if (this.faxPhone != '') {
                    isValidFax = this.phoneValidation(this.faxPhone);
                    this.formatErrorFax = !this.phoneValidation(this.faxPhone);
                    this.application.businessInformation.businessFaxNumber = this.faxPhone;
                }

                if (isValidPhone && isValidAlt && isValidFax) {
                    return true;
                } else {
                    return false;
                }
            },
            validateEmail(thisEmail) {
                this.validEmail = this.emailValidation(thisEmail);
                return this.validEmail;
            },
            validateName() {
                this.nameError = false;
                this.nameAsError = false;

                let nameLength = this.application.businessInformation.businessLegalName ? this.application.businessInformation.businessLegalName.length : 0,
                    businessAsLength = this.application.businessInformation.businessDoingBusinessAs ? this.application.businessInformation.businessDoingBusinessAs.length : 0,
                    error = false;


                if (nameLength < 1) {
                    this.nameError = true;
                    error = true;
                }

                if (businessAsLength < 1) {
                    this.nameAsError = true;
                    error = true;
                }

                if (this.phoneFormatValid() === false) {
                    error = true;
                }

                if (error === true) {
                    return false;
                } else {
                    return true;
                }
            },
            validateSob() {
                let valid = true;
                if (this.applicationIsASoB) {
                    valid = this.validateEmail(this.application.businessInformation.businessOriginalOwnerEmail);
                }
                return valid;
            },
            validateContact() {
                let nameLength = this.application.contactInformation.primaryContactName ? this.application.contactInformation.primaryContactName.length :0,
                    titleLength = this.application.contactInformation.primaryContactTitle ? this.application.contactInformation.primaryContactTitle.length : 0,
                    emailLength = this.application.contactInformation.primaryContactEmail ? this.application.contactInformation.primaryContactEmail.length : 0,
                    phoneLength = this.application.contactInformation.primaryContactPhone ? this.application.contactInformation.primaryContactPhone.length : 0,
                    secondPhoneLength = this.application.contactInformation.secondaryContactPhone ? this.application.contactInformation.secondaryContactPhone.length : 0,
                    secondEmailLength = this.application.contactInformation.secondaryContactEmail ? this.application.contactInformation.secondaryContactEmail.length : 0,
                    error = false;

                if (nameLength < 1) {
                    this.primaryContactNameError = true;
                    error = true;
                }

                if (titleLength < 1) {
                    this.primaryContactTitleError = true;
                    error = true;
                }
                if (emailLength < 1) {
                    this.primaryContactEmailError = true;
                    error = true;
                }

                if (phoneLength < 1) {
                    this.primaryContactPhoneError = true;
                    error = true;
                }
                if (!this.phoneValidation(this.application.contactInformation.primaryContactPhone)) {
                    error = true;
                    this.primaryContactPhoneError = true;
                } else {
                    this.primaryContactPhoneError = false;
                }
                if (!this.emailValidation(this.application.contactInformation.primaryContactEmail)) {
                    error = true;
                    this.primaryContactEmailError = true;
                } else {
                    this.primaryContactEmailError = false;
                }
                 
                if (secondPhoneLength > 0) {
                    if (!this.phoneValidation(this.application.contactInformation.secondaryContactPhone)) {
                        error = true;
                        this.secondaryContactPhoneError = true;
                    } else {
                        this.secondaryContactPhoneError = false;
                    }
                }
                
                if (secondEmailLength > 0) {
                    if (!this.emailValidation(this.application.contactInformation.secondaryContactEmail)) {
                        error = true;
                        this.secondaryContactEmailError = true;
                    } else {
                        this.secondaryContactEmailError = false;
                    }
                }
                
                if (error === true) {
                    return false;
                } else {
                    return true;
                }
            },
            validateAddress() {
                let addressLength = this.application.businessInformation.businessStreetAddress ? this.application.businessInformation.businessStreetAddress.length : 0,
                    cityLength = this.application.businessInformation.businessCity ? this.application.businessInformation.businessCity.length : 0,
                    stateLength = this.application.businessInformation.businessState ? this.application.businessInformation.businessState.length : 0,
                    countyLength = this.application.businessInformation.businessCounty ? this.application.businessInformation.businessCounty.length : 0,
                    error = false;

                if (addressLength < 1) {
                    this.businessStreetAddressError = true;
                    error = true;
                }

                if (cityLength < 1) {
                    this.businessCityError = true;
                    error = true;
                }
                if (stateLength < 1) {
                    this.businessStateError = true;
                    error = true;
                }

                if (countyLength < 1) {
                    this.businessCountyError = true;
                    error = true;
                }

                if (!this.zipValidation(this.application.businessInformation.businessZip) || !this.zipPlusFourValidation(this.application.businessInformation.businessZipPlus4)) {
                    this.validZip = false;
                    error = true;
                }
                else {
                    this.validZip = true;
                }
                if (!this.application.businessInformation.mailingSameAsBusiness)
                {
                    if (!this.zipValidation(this.application.businessInformation.mailingZip) || !this.application.businessInformation.mailingSameAsBusiness
                        && !this.zipPlusFourValidation(this.application.businessInformation.mailingZipPlus4)) {
                        this.validMailZip = false;
                        error = true;
                    }
                    else {
                        this.validMailZip = true;
                    }

                    let maddressLength = this.application.businessInformation.mailingssStreetAddress ? this.application.businessInformation.mailingssStreetAddress.length : 0,
                        mcityLength = this.application.businessInformation.mailingCity ? this.application.businessInformation.mailingCity.length : 0,
                        mstateLength = this.application.businessInformation.mailingState ? this.application.businessInformation.mailingState.length : 0;

                    if (maddressLength < 1) {
                        this.mailingStreetAddressError = true;
                        error = true;
                    }

                    if (mcityLength < 1) {
                        this.mailingCityError = true;
                        error = true;
                    }
                    if (mstateLength < 1) {
                        this.mailingStateError = true;
                        error = true;
                    }
                }
                

                if (error === true) {
                    return false;
                } else {
                    return true;
                }
            },
            validateEqiup(event) {
                let thisVal = event.target.value;
                this.equipRequestError = this.isValNumber(thisVal);
            },
            validateMonitor(event) {
                let thisVal = event.target.value;
                this.monitorError = this.isValNumber(thisVal);
            },
            isValNumber(num) {
                return isNaN(num);
            },
            validateHours() {
                $('#invalidDaysError').hide();
                $('#oneDayNeedsHoursError').hide();
                $('#businessHours1Error').hide();
                $("#businessHours2Error").hide();
                $("#businessHours3Error").hide();
                $("#businessHours4Error").hide();
                $("#businessHours5Error").hide();
                $("#businessHours6Error").hide();
                $("#businessHours7Error").hide();

                let oneDayHasHours = false;
                let allDaysValid = true;

                function validateAndShowError(businessHours, errorMessage, i) {
                    const b = JSON.parse(businessHours);

                    if (b.Open24hours) {
                        oneDayHasHours = true;
                        return true;
                    }

                    if (b.Closed) {
                        return true;
                    }

                    if (!b.Open24hours && !b.Closed && (b.OpenHour === '' || b.OpenHour === '--' || b.CloseHour === '' || b.CloseHour === '--')) {
                        oneDayHasHours = false;
                        $(errorMessage).show();
                        return false;
                    }

                    if (b.OpenHour < b.CloseHour) {
                        oneDayHasHours = true;
                        return true;
                    } else {
                        $("#businessHours" + (i) + "Error").show();
                        return false;
                    }
                }

                for (let i = 1; i <= 7; i++) {
                    const businessHours = this.application.businessInformation['businessHours' + i];
                    const errorMessage = '#businessHours' + i + 'Error';

                    if (!validateAndShowError(businessHours, errorMessage, i)) {
                        allDaysValid = false;
                    }
                }

                console.log(allDaysValid)
                console.log(oneDayHasHours)

                if (allDaysValid && oneDayHasHours) {
                    return true;
                }

                if (!allDaysValid) {
                    $('#invalidDaysError').show();
                }

                if (!oneDayHasHours) {
                    $("#oneDayNeedsHoursError").show();
                }

                return false;
            },
            clearForm() {
                this.application = {};
                this.application.businessInformation = {};
                this.application.contactInformation = {};
                this.application.equipment = {};
                this.application.keno = {};
                this.primaryPhone = '';
                this.altPhone = '';
                this.faxPhone = '';
                this.hoursOpen1= '';
                this.hoursClose1= '';
                this.closed1= false;
                this.open2471= false;
                this.hoursOpen2= '';
                this.hoursClose2= '';
                this.closed2= false;
                this.open2472= false;
                this.hoursOpen3= '';
                this.hoursClose3= '';
                this.closed3= false;
                this.open2473= false;
                this.hoursOpen4= '';
                this.hoursClose4= '';
                this.closed4= false;
                this.open2474= false;
                this.hoursOpen5= '';
                this.hoursClose5= '';
                this.closed5= false;
                this.open2475= false;
                this.hoursOpen6= '';
                this.hoursClose6= '';
                this.closed6= false;
                this.open2476= false;
                this.hoursOpen7= '';
                this.hoursClose7= '';
                this.closed7= false;
                this.open2477 = false;
                this.d1 = false;
                this.d2 =false;
                this.d3 =false;
                this.d4 =false;
                this.d5 =false;
                this.d7 =false;
                this.a1a = false;
                this.a1c = false;

            },
            async save() {
                //save
                this.updateBusinessHours();
                this.getLiquorLicenseType();
                if (this.validate()) {
                    let response = await this.postSaveData()
                        .then(
                            (response) => {
                                if (response != null && response.statusCode == 200) {
                                    this.clearForm();
                                    this.showNewLocationForm = false;
                                    this.showLocationsList = true;
                                    this.showSuccessMessage = true;
                                    window.scrollTo(0, 0);
                                    setTimeout(() => {
                                        let currentPage = window.location.origin + window.location.pathname;
                                        let queryString = window.location.search;
                                        let urlParams = new URLSearchParams(queryString);
                                        currentPage += '?' + urlParams.toString();
                                        window.location.href = currentPage;
                                    }, 2000);
                                   
                                }
                                else {
                                    this.isLoading = false;
                                    this.errorMessageShow = true;
                                }

                            }
                        );

                    response();
                }
                else {

                }
            },
            async saveAndNew() {
                //save
               this.updateBusinessHours();
               this.getLiquorLicenseType();
                if (this.validate()) {
                    let response = await this.postSaveData()
                        .then(
                            (response) => {
                                if (response != null && response.statusCode == 200) {
                                    this.clearForm();
                                    this.showSuccessMessage = true;
                                }
                                else {
                                    this.isLoading = false;
                                    this.errorMessageShow = true;
                                }

                            }
                        );
                }
                else {

                }

            },
            async postSaveData() {
                let request = {
                    "application": this.application,
                    "masterAppGuid": this.masterAppGuid
                }
                this.isLoading = true;
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + this.chainToken,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(
                        request
                    )
                };

                let response = await (await fetch(this.SAVE_LOCATION_API_URL, appsRequestOptions)).json();
                return Promise.resolve(response);
            },
            downloadLiabilityFile() {
                var binaryString = window.atob(this.application.keno.kenoUploadedFile);
                var binaryLen = binaryString.length;
                var bytes = new Uint8Array(binaryLen);
                for (var i = 0; i < binaryLen; i++) {
                    var ascii = binaryString.charCodeAt(i);
                    bytes[i] = ascii;
                }

                var blob = new Blob([bytes], { type: this.application.keno.kenoUploadedFileExtension });
                var objectUrl = URL.createObjectURL(blob);
                window.open(objectUrl);
            },
            handleLiabilityFile(event) {
                // restrict to: pdf,doc,docx,gif,jpg,jpeg,png
                var _this = this;
                var file = event[0].file;
                var fileName = event[0].file.name;
                var fileType = event[0].file.type

                const reader = new FileReader();
                reader.onload = function (e) {
                    _this.application.keno.kenoUploadedFile = e.target.result.split(',')[1];
                    _this.application.keno.kenoUploadedFileExtension = fileType;
                    _this.application.keno.kenoUploadedFileName = fileName;
                };

                reader.readAsDataURL(file);
            },
            onBeforeLiabilityDelete(event) {
                // optionally, you can confirm and call the deleteFileRecord method
                this.$refs.vueFileAgent.deleteFileRecord(event);
            },
            onLiabilityDelete(event) {
                this.application.keno.kenoUploadedFile = null;
                this.application.keno.kenoUploadedFileExtension = null;
                this.application.keno.kenoUploadedFileName = null;
            },
            getLiquorLicenseType() {
                let licTypes = [];
                if (this.d1 === true) {
                    licTypes.push(1);
                }
                if (this.d2 === true) {
                    licTypes.push(2);
                }
                if (this.d3 === true) {
                    licTypes.push(3);
                }
                if (this.d4 === true) {
                    licTypes.push(4);
                }
                if (this.d5 === true) {
                    licTypes.push(5);
                }
                if (this.d7 === true) {
                    licTypes.push(6);
                }
                if (this.a1a === true) {
                    licTypes.push(7);
                }
                if (this.a1c === true) {
                    licTypes.push(8);
                }
                this.application.keno.kenoLiquorLicenseClass = licTypes.toString();
            },
            downloadEquipmentFile() {
                var binaryString = window.atob(this.application.equipment.equipSiteLayoutFile);
                var binaryLen = binaryString.length;
                var bytes = new Uint8Array(binaryLen);
                for (var i = 0; i < binaryLen; i++) {
                    var ascii = binaryString.charCodeAt(i);
                    bytes[i] = ascii;
                }
                var blob = new Blob([bytes], { type: this.application.equipment.equipSiteLayoutFileExtension });
                var objectUrl = URL.createObjectURL(blob);
                window.open(objectUrl);
            },
            handleEquipmentFile(event) {
                // restrict to: pdf,doc,docx,gif,jpg,jpeg,png
                var _this = this;
                var file = event[0].file;
                var fileName = event[0].file.name;
                var fileType = event[0].file.type

                const reader = new FileReader();
                reader.onload = function (e) {
                    _this.application.equipment.equipSiteLayoutFile = e.target.result.split(',')[1];
                    _this.application.equipment.equipSiteLayoutFileExtension = fileType;
                    _this.application.equipment.equipSiteLayoutFileName = fileName;
                };

                reader.readAsDataURL(file);
            },
            onBeforeEquipmentDelete(event) {
                // optionally, you can confirm and call the deleteFileRecord method
                this.$refs.vueFileAgent.deleteFileRecord(event);
            },
            onEquipmentDelete(event) {
                this.application.equipment.equipSiteLayoutFile = null;
                this.application.equipment.equipSiteLayoutFileExtension = null;
                this.application.equipment.equipSiteLayoutFileName = null;
            }

        },
        computed: {
            SAVE_LOCATION_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/ChainApplication/SaveFCodeLocation/` + this.userId;
            },
            GET_LOCATION_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/ChainApplication/GetFCodeLocations/` + this.userId;
                // return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/ChainApplication/GetFCodeLocations/` + 129;
            },
            maxLiability() {

                let maxL = 0;
                if (this.application.keno.kenoDepositMultiplier != null && !Number.isNaN(this.application.keno.kenoDepositMultiplier) && this.application.keno.kenoDepositAmount !== '' && !Number.isNaN(this.application.keno.kenoDepositAmount)) {
                    maxL = this.application.keno.kenoDepositMultiplier * this.application.keno.kenoDepositAmount;
                }

                return maxL;
            }
        }
    };
</script>

<style lang="scss" scoped>
.checkboxes-wrapper {
    display: flex;
    align-items: baseline;

    input {
        margin: 0;
    }
}

.notCompliantReasons {
    padding-top: 20px;

    hr {
        margin: 1.5rem auto;
    }

    .cell {
        margin: 0;
    }
}
.liabilityForm input[type=number].depositInput {
    width: auto;
    max-width: 90px;
    display: inline-block;
}
</style>
