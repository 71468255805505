<template>
    <section class="tabbed_content fluid_tabs cf">
        <div v-html="instantWin"></div>
    </section>
</template>

<script>
    import $ from 'jquery';
    import moment from "moment";
    import { inject } from 'vue';

    export default {
        data: function () {
            return {
                mobileCashing: false,
                token: '',
                instantWin: '',
                message: '',
                image: ''
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.getTerms();
                });
            },
            getTerms() {
                let apiToken = this.token,
                    sku = this.getURLQuery('orderId'),
                    url = process.env.VUE_APP_APIURL + '/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=mlr-instant-win';

                return fetch(url, {
                        method: 'GET',
                        // body: JSON.stringify(datCreds),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    this.instantWin = json.data[0].centralContent;
                    let button = document.getElementById('closeTC');
                })
                .then(() => {
                    this.getMessage();
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
            },
            getMessage() {
                let winner = this.$cookies.get('TokenInfo'),
                    message = winner.Notifications[0].Message,
                    image = this.processImageURL(winner.Notifications[0].ImageURL);

                let injectMessage = document.getElementById('iwMessage'),
                    injectImage = document.getElementById('iwImage');

                injectMessage.innerText = message;
                injectImage.src = image;

            },
            processImageURL(imgURL) {
                var newURL;
                if (imgURL[0] === '~') {
                    newURL = imgURL.substring(1);
                } else {
                    newURL = imgURL;
                }

                return newURL;
            }
		}
    };
</script>

<style lang="scss" scoped>
    /* #TransactionLogPage {
        height: 240px;
        margin: 15px 0;
        overflow-y: scroll;
    } */
</style>