<template>
    <div class="productDetailWrap cf">
        <SignInRegister v-if="!isUserLogged"></SignInRegister>
        <!-- <Drawings v-if="isUserLogged && id != 0 && drawings != '' && completedDrawHist === false && secondHist === false && completedSecondHist === false && cashHist === false && completedCashHist === false" :debug="false" :tokenKey="token" title="Active Drawing Entries" :resultsReturned="qty" :data="drawings" userId="id" @history="showDrawHist" :view="drawHist"></Drawings>

        <CompletedDrawings v-if="isUserLogged && id != 0 && completedDrawings != '' && drawHist === false && secondHist === false && completedSecondHist === false && cashHist === false && completedCashHist === false" :debug="false" :tokenKey="token" title="Recent Completed Drawings" :resultsReturned="10" :data="completedDrawings" userId="id" @history="showDrawHist" :view="completedDrawHist"></CompletedDrawings>

        <SecondChance v-if="isUserLogged && id != 0 && secondChance != '' && drawHist === false && completedDrawHist === false && completedSecondHist === false && cashHist === false && completedCashHist === false" :debug="false" :tokenKey="token" title="Active Second Chance Entries" :resultsReturned="10" :data="secondChance" userId="id" @history="showDrawHist" :view="secondHist"></SecondChance>

        <CashExplosion v-if="isUserLogged && id != 0 && completedCashExplosion != '' && drawHist === false && completedDrawHist === false && secondHist === false && completedSecondHist === false && completedCashHist === false" :debug="false" :tokenKey="token" title="Cash Explosion" :resultsReturned="10" :data="cashExplosion" userId="id" @history="showDrawHist" :view="cashHist"></CashExplosion> -->

        <Drawings v-if="isUserLogged && id != 0 && drawings != ''" :debug="false" :tokenKey="token" title="Active Drawing Entries" :resultsReturned="10" :data="drawings" :userId="id" @history="showDrawHist" :view="drawHist"></Drawings>

        <CompletedDrawings v-if="isUserLogged && id != 0 && completedDrawings != ''" :debug="false" :tokenKey="token" title="Recent Completed Drawings" :resultsReturned="10" :data="completedDrawings" :userId="id" @history="showDrawHist" :view="completedDrawHist"></CompletedDrawings>

        <SecondChance v-if="isUserLogged && id != 0 && secondChance != ''" :debug="false" :tokenKey="token" title="Active Second Chance Entries" :resultsReturned="10" :data="secondChance" :userId="id" @history="showDrawHist" :view="secondHist"></SecondChance>

        <CompletedSecondChance v-if="isUserLogged && id != 0 && completedSecondChance != ''" :debug="false" :tokenKey="token" title="Completed Second Chance Entries" :resultsReturned="10" :data="completedSecondChance" :userId="id" @history="showDrawHist" :view="completedSecondHist"></CompletedSecondChance>

        <CashExplosion v-if="isUserLogged && id != 0 && cashExplosion != ''" :debug="false" :tokenKey="token" title="Cash Explosion" :resultsReturned="10" :data="cashExplosion" :userId="id" @history="showDrawHist" :view="cashHist"></CashExplosion>

        <!--Second Chance Notifications - FTF -->
        <div class="secondChanceNotifications" v-if="hasSecondChanceNotificationEntries">
            <ul class="secondChanceNotificationsList">
                <li class="secondChanceNotificationsLI" v-for="(item, key) in SecondChanceNotificationData" :key="key">
                    <h3>{{ item.promotionName }}</h3>
                    <!-- <span>You have {{ item.message }}</span> -->
                    <div v-if="secondaryData !== ''" class="progress-bar">
                        <div class="progress-bar-inner" :style="{width: progress + '%'}"></div>
                        <span class="progress-bar-txt">You have <strong>${{ item.secondaryData.currentProgress }}</strong> of <strong>${{ item.secondaryData.totalValue }}</strong> towards your next entry</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import SignInRegister from '../../../ViewComponents/SignInRegister/SignInRegister.vue';
    import Drawings from './EntryList_Drawings.vue';
    import CompletedDrawings from './EntryList_CompletedDrawings.vue';
    import SecondChance from './EntryList_SecondChance.vue';
    import CompletedSecondChance from './EntryList_CompletedSecondChance.vue';
    import CashExplosion from './EntryList_CashExplosion.vue';
    import moment from 'moment';

    export default {
        components: {
            SignInRegister,
            Drawings,
            CompletedDrawings,
            SecondChance,
            CompletedSecondChance,
            CashExplosion
        },
        data: function () {
            return {
                id: 0,
                isUserLogged: false,
                drawings: '',
                completedDrawings: '',
                secondChance: '',
                completedSecondChance: '',
                cashExplosion: '',
                completedCashExplosion: '',
                qty: 1,
                history: false,
                drawHist: false,
                completedDrawHist: false,
                secondHist: false,
                completedSecondHist: false,
                cashHist: false,
                completedCashHist: false,
                hasSecondChanceNotificationEntries: false,
                SecondChanceNotificationData: '',
                progress: 0,
                secondaryData: '',
                currentProgress: 0,
                totalValue: 0
            }
        },
        mounted: function () {
            this.isUserLogged = this.isUserAuthed();
            this.getToken();
        },
        methods: {
            getToken() {
                // then hit API with token
                this.getAPItoken().then((token) => {
                    this.token = token;
                }).then(() => {
                    this.id = this.getLoggedInUserId();
                }).then(() => {
                    this.getDrawingEntries();
                    this.getCompletedEntries();
                }).then(() => {
                    this.getSecondChanceEntries();
                    // this.getDrawingEntriesByDate();
                    this.getCompletedSecondChanceEntries();
                }).then(() => {
                    this.getCashExplosionEntries();
                }).then(() => {
                    this.getSecondChanceNotificationsByUserId();
                });
            },
            convertDates(d, type) {
                let entries = d,
                    newDates = [];

                if (entries === null) {
                    false;
                } else {
                    for (let i = 0; i < entries.length; i++) {
                        let entry = entries[i];

                        if (type === 'secondChance') {
                            entry.drawingClosingDate = moment(entry.closeDate).format("MM/DD/YYYY");
                        } else {
                            entry.drawingClosingDate = moment(entry.drawingClosingDate).format("MM/DD/YYYY");
                        }

                        entry.drawingDate = moment(entry.drawingDate).format("MM/DD/YYYY");
                        entry.drawingStartDate = moment(entry.drawingStartDate).format("MM/DD/YYYY");

                        newDates.push(entry);
                    }
                }

                if (entries === null) {
                    false;
                } else {
                    return newDates;
                }
            },
            getDrawingEntries() {
                let url = process.env.VUE_APP_APIURL + '/1.0/PlainDraw/PlainDraw/GetPlainDrawingUserEntries?userID=' + this.id + '&open=true';
                // let url = process.env.VUE_APP_APIURL + '/1.0/PlainDraw/PlainDraw/Promotion/Get-User-Participation-Entries-Detail?userID=' + this.id;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    },
                })
                    .then((response) => response.json())
                    .then((json) => {
                        // console.log('PlainDrawingUserEntries')
                        // console.log(json)
                        if (json.error === null) {
                            json.data.sort((a, b) => {
                                return new Date(b.drawingClosingDate) - new Date(a.drawingClosingDate);
                            });
                            this.drawings = this.convertDates(json.data);
                        } else { false };
                    })
                    .catch(error => {
                        console.log(this.errorMessage = error);
                    })
            },
            getCompletedEntries() {
                let url = process.env.VUE_APP_APIURL + '/1.0/PlainDraw/PlainDraw/GetPlainDrawingUserEntries?userID=' + this.id + '&open=false';

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    },
                })
                    .then((response) => response.json())
                    .then((json) => {
                        if (json.error === null) {
                            json.data.sort((a, b) => {
                                return new Date(b.drawingClosingDate) - new Date(a.drawingClosingDate);
                            });
                            this.completedDrawings = this.convertDates(json.data);
                        } else { false };
                    })
                    .catch(error => {
                        console.log(this.errorMessage = error);
                    })
            },
            getSecondChanceEntries() {
                let url = process.env.VUE_APP_APIURL + '/1.0/SecondChance/DrawingEntries/GetActiveDrawingEntriesByUserId/' + this.id;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    },
                })
                    .then((response) => response.json())
                    .then((json) => {
                        if (json.error === null) {
                            json.data.sort((a, b) => {
                                return new Date(b.closeDate) - new Date(a.closeDate);
                            });
                            this.secondChance = this.convertDates(json.data, 'secondChance');
                        } else { false };
                    })
                    .catch(error => {
                        console.log(this.errorMessage = error);
                    })
            },
            getCompletedSecondChanceEntries() {
                let url = process.env.VUE_APP_APIURL + '/1.0/SecondChance/DrawingEntries/GetClosedDrawingEntriesByUserId/' + this.id;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    },
                })
                    .then((response) => response.json())
                    .then((json) => {
                        if (json.error === null) {
                            json.data.sort((a, b) => {
                                return new Date(b.closeDate) - new Date(a.closeDate);
                            });
                            this.completedSecondChance = this.convertDates(json.data, 'secondChance');
                        } else { false };
                    })
                    .catch(error => {
                        console.log(this.errorMessage = error);
                    })
            },
            getCashExplosionEntries() {
                let url = process.env.VUE_APP_APIURL + '/1.0/CashExplosion/CashExplosion/GetCashExplosionEntries?userID=' + this.id;
                // console.log(this.id)
                // let url = process.env.VUE_APP_APIURL + '/1.0/CashExplosion/CashExplosion/GetCashExplosionEntries?userID=' + 114; // FOR TESTING

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    },
                })
                    .then((response) => response.json())
                    .then((json) => {
                        if (json.error === null) {
                            // this.cashExplosion = [{"ticketSerialNumber":"932-002003-022-2-0675111783","submitDate":"2023-07-24T22:17:18.04","drawId":543,"drawDate":"2023-07-29T00:00:00","showAirDate":"2023-07-29T00:00:00"},{"ticketSerialNumber":"992-277527-003-0-0150132337","submitDate":"2023-07-12T19:48:28.297","drawId":541,"drawDate":"2023-07-15T00:00:00","showAirDate":"2023-07-15T00:00:00"}];
                            json.data.sort((a, b) => {
                                return new Date(b.drawDate) - new Date(a.drawDate);
                            });
                            this.cashExplosion = json.data;
                        } else { false };
                    })
                    .catch(error => {
                        console.log(this.errorMessage = error);
                    })
            },
            getCompletedCashExplosionEntries() {
                let url = process.env.VUE_APP_APIURL + '/1.0/CashExplosion/CashExplosion/GetCashExplosionEntries?userID=' + this.id;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    },
                })
                    .then((response) => response.json())
                    .then((json) => {
                        if (json.error === null) {
                            json.data.sort((a, b) => {
                                return new Date(b.drawDate) - new Date(a.drawDate);
                            });
                            this.completedCashExplosion = this.convertDates(json.data);
                        } else { false };
                    })
                    .catch(error => {
                        console.log(this.errorMessage = error);
                    })
            },
            showDrawHist(type) {
                // this.history = !this.history;

                switch (type) {
                    case 1:
                        this.drawHist = !this.drawHist;
                        break;
                    case 2:
                        this.completedDrawHist = !this.completedDrawHist;
                        break;
                    case 3:
                        this.secondHist = !this.secondHist;
                        break;
                    case 4:
                        this.completedSecondHist = !this.completedSecondHist;
                        break;
                    case 5:
                        this.cashHist = !this.cashHist;
                        break;
                    case 6:
                        this.completedCashHist = !this.completedCashHist;
                        break;
                }

                // this.history === false ? this.qty = 1 : this.qty = 100;
            },
            getSecondChanceNotificationsByUserId() {
                let url = process.env.VUE_APP_APIURL + '/1.0/SecondChance/Promotion/GetSecondChanceNotificationsByUserId/' + this.id;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    }
                })
                    .then((response) => response.json())
                    .then((json) => {
                        console.log("=== FTF Progress ===");
                        console.log(json.data);
                        if (json.data.length) {
                            this.hasSecondChanceNotificationEntries = true;
                            this.SecondChanceNotificationData = json.data;
                            this.secondaryData = json.data[0].secondaryData;
                            this.currentProgress = json.data[0].secondaryData.currentProgress;
                            this.totalValue = json.data[0].secondaryData.totalValue;
                            console.log(this.currentProgress);
                            console.log(this.totalValue);
                            this.computeProgressPercent(this.currentProgress, this.totalValue);
                            // this.SecondChanceNotificationData = [  //hardcoded example from what Leandro sent to test with
                            //     {
                            //         "promotionID": "10",
                            //         "promotionName": '"Fun Turns 50" entry progress',
                            //         "message": "$0 of $50 towards your next entry"
                            //     }
                            // ];
                            console.log(this.SecondChanceNotificationData);
                        } else {
                            console.log('No data');
                        }
                    })
            },
            computeProgressPercent(current, total) {
                this.progress = Math.floor((current / total) * 100);
                console.log(this.progress);
            }
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>
    .secondChanceNotifications {
        background-color: #F1EFEE;
        padding: 20px;
    }

    .secondChanceNotificationsList {
        list-style-type: none;
        margin-left: 0;

        li {
            margin-bottom: 12px;
        }

        h3 {
            color: #000;
            font-weight: 400;
            line-height: 1.1;
            margin-bottom: 12px;
        }

        span {
            display: inline-block;
            background-color: #fff;
            border-radius: 16px;
            padding: 8px 20px;
            text-align: center;
            line-height: 1.1;
            font-weight: 400;
        }
    }

    .progress-bar {
        width: 100%;
        height: 35px;
        background-color: #fff;
        margin-bottom: 10px;
        border-radius: 16px;
        display: grid;
        align-items: center;
        max-width: 375px;
        overflow: hidden;
    }

    .progress-bar-inner {
        border-radius: 16px;
        height: 100%;
        max-width: 100%;
        background-color: #0FD8C9;
        grid-area: 1/1;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    span.progress-bar-txt {
        background-color: transparent;
        text-align: center;
        padding: 0;
        line-height: 1.1;
        font-size: 1.2rem;
        grid-area: 1/1;
    }

    @media only screen and (min-width: 641px) {
        span.progress-bar-txt {
            font-size: 1.6rem;
        }
    }
</style>