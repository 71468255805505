<template>
    <div class="myApplicationsWrap" v-if="isUserRetailer">
        <hollow-dots-spinner :animation-duration="1000"
                             :dot-size="15"
                             :dots-num="3"
                             color="#0FD8C9"
                             v-if="isLoading" />

        <table class="billPayLocations" v-if="gridData.length > 0 && !isLoading">
            <tr v-for="(row, index) in gridData" :key="index">
                <td>
                    <span>{{row['AgentNumber']}}</span> -
                    <span>{{row['BusinessName']}}</span><br />
                    <span>{{row['Address']}}</span>,
                    <span>{{row['City']}}</span>, <span>{{row['State']}}</span>
                    <span>{{row['Zip']}}</span>-<span>{{row['Zip4']}}</span>
                </td>
                <td>
                    <button type="button" class="button btn-primary" @click="payBill(row['AgentNumber'])">Pay here!</button>
                </td>
            </tr>
        </table>

        <div class="InfoLabel alert-message cf" v-if="error === true">There was an error proccessing your request at this time. Please try again later.</div>
        <div class="InfoLabel alert-message cf" v-if="gridData.length == 0 && !isLoading">You do not appear to have any current invoices.</div>
    </div>
</template>


<script>
    import { HollowDotsSpinner } from 'epic-spinners';

    export default {
        components: {
            HollowDotsSpinner
        },
        data() {
            return {
                isUserRetailer: false,
                isLoading: false,
                userId: 0,
                principalName: '',
                principalSSN: '',
                userEmail: '',
                token: '',
                validRoles: ['eAppPrincipal', 'eAppPrincipalUpdates', 'eApp-Retailer'],
                gridData: "",
                agentNumber: 0,
                userId: 0,
                error: false
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            init() {
                this.validRoles.forEach(element => {
                    if (this.isUserInRole(element)) {
                        // console.log('User is in a valid role')
                        this.isUserRetailer = true;
                    }
                });

                // console.log(this.isUserRetailer)
                // get the this.agentNumber
                // but how??

                if (this.isUserRetailer) {
                    this.isLoading = true;
                    let grid = [];
                    // this.getToken();
                    this.applications.forEach(element => {
                        if (element.agentNumber !== 0) {
                            grid.push({
                                "AgentNumber": element.agentNumber,
                                "BusinessName": element.businessName,
                                "Address": element.address,
                                "City": element.city,
                                "Zip": element.zip
                            })
                            // this.agentNumber = element.agentNumber;
                            // this.fetchData(this.token);
                        }
                    });

                    this.gridData = grid;
                    this.isLoading = false;
                    console.log(this.gridData)
                }
            },
            getToken() {
                let uId = this.getLoggedInUserId();
                if (uId) {
                    this.userId = uId;
                    this.getAPItoken()
                        .then((token) => {
                            this.token = token;
                            this.getRetailers(token);
                        });
                }
            },
            getRetailers(token) {
                console.log(token)
                const appsRequestOptions = {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        "Content-Type": "application/json"
                    },

                };

                return fetch(this.GET_RETAILER_LIST, appsRequestOptions)
                    .then(response => response.json())
                    .then(json => {
                        this.applications = json.value;
                        this.init();
                    })

                // let retailers = fetch(this.GET_RETAILER_LIST).json();
                console.log(retailers);
            },
            async fetchData(agentNumber) {
                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    }
                };

                return fetch(this.GET_BILLS_API_URL, appsRequestOptions)
                    .then(response => response.json())
                    .then(json => {
                        console.log(json)
                        // this.gridData = applicationsRequest.results;
                        // this.isLoading = false;
                    });
            },
            payBill(agentNumber) {
                this.agentNumber = agentNumber;

                const appsRequestOptions = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type": "application/json"
                    }
                };

                return fetch(this.GET_BILLS_API_URL, appsRequestOptions)
                    .then(response => response.json())
                    .then(json => {
                        console.log(json);
                        let data = json;
                        if (data.HasErrors === false && data.ErrorCount < 1) {
                            this.error = false;
                            let win = window.open(data.Url, '_blank');
                            // window.location.href = data.Url;
                            win.focus();
                        } else {
                            this.error = true;
                        }
                    });
            }
        },
        computed: {
            GET_RETAILER_LIST() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/Retailer/GetRetailers?userID=` + this.userId;
            },
            GET_BILLS_API_URL() {
                return this.getProtocol() + process.env.VUE_APP_EAPPAPIURL + `/1.0/RegularApplication/PayerExpress?userID=` + this.userId + '&agentNumber=' + this.agentNumber;
            }
        }
    }
</script>