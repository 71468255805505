import { render, staticRenderFns } from "./User-Message.vue?vue&type=template&id=60082640&scoped=true"
import script from "./User-Message.vue?vue&type=script&lang=js"
export * from "./User-Message.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60082640",
  null
  
)

export default component.exports