<template>
    <div>
        <div class="sf-menu">
            <div><img src="/getattachment/00363fd3-701d-4cf2-a8cd-36b54b530c03/sf-olc-logo.png" alt="OH Logo"></div>
            <div>
                <span style="font-size:30px;cursor:pointer" @click="openMenu()">&#9776;</span>
            </div>
        </div>
        <div class="sf-mobile-menu" id="sf-mobile-menu">
            <div class="sf-close"><span @click="closeMenu()" style="font-size:30px;cursor:pointer">&times;</span></div>
            <nav>
                <ul>
                    <li><a href="/$1000-Scratch-Offs-Statue-Search">My Statues</a></li>
                    <li><a href="/$1000-Scratch-Offs-Statue-Search/How-To-Collect">How To Play</a></li>
                    <li><a href="/$1000-Scratch-Offs-Statue-Search/Rules">Drawing Rules</a></li>
                </ul>
            </nav>
            <div class="sf-menu-sub">
                <nav>
                    <ul>
                        <!-- <li><a href="#">Promotion Terms & Conditions</a></li> -->
                        <li><a href="/about/about-the-ohio-lottery/legal/privacy-policy">Privacy Policy</a></li>
                        <!-- <li><a href="#">Disclaimers</a></li> -->
                        <li><a href="/$1000-Scratch-Offs-Statue-Search/Contact">Contact</a></li>
                    </ul>
                </nav>
            </div>
            <div class="sf-menu-disclaimer">
                <img src="/getattachment/b3c87314-a1a5-4b4b-913b-0f4279cf9e0c/sf-menu-logo.png" alt="OH Logo">
                <p>Ohio Problem Gambling Helpline</p>
                <p>800-589-9966</p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data: function () {
            return {

            }
        },
        methods: {
            openMenu() {
                document.getElementById("sf-mobile-menu").style.width = "80%";
            },
            closeMenu() {
                document.getElementById("sf-mobile-menu").style.width = "0%";
            }
        }
    }
</script>
<style>
    .sf-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .sf-mobile-menu {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        background-color: #4A4587;
        overflow-x: hidden;
        transition: 0.5s;
        box-shadow: 1px 2px 8px #000;
    }

    .sf-close {
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        justify-content: flex-end;
    }

        .sf-close span {
            font-size: 2.4rem;
            color: #fff;
        }

    .sf-mobile-menu nav ul li a {
        color: #fff;
        font-size: 2.4rem;
        text-decoration: none;
        font-weight: 500;
        padding: 0.6em 10px;
        display: block;
    }

    .sf-menu-sub {
        background-color: #5B58A5;
        padding-bottom: 20px;
        padding-top: 20px;
        nav ul li a

    {
        font-size: 1.6rem;
        font-weight: 400;
    }

    }

    .sf-menu-disclaimer {
        background-color: #b3b3b3;
        height: 100%;
        padding-left: 2rem;
        padding-top: 2rem;
    }

        .sf-menu-disclaimer img {
            margin-bottom: 20px;
        }

        .sf-menu-disclaimer p {
            color: #5b5b5b;
            font-weight: 500;
            margin-bottom: 0px;
        }
</style>