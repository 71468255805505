<template>
    <div class="eAppModule eAppPrincipalDetail">
        <h4>Principals</h4>
        <p>{{ PrincipalCreateContent }}</p>


        <div class="EditingFormError" style="display: none;">

            <span class="fieldError" style="visibility:hidden;">The principal is already associated to this application.</span>

        </div>
        <div class="grid-x grid-margin-x grid-margin-y">
            <div class="formRow cell">
                <span class="req">*</span>
                <label for="txbxTitle">Title:</label>
                <input v-model="principal.principalTitle" :disabled="isDisabled" name="txbxTitle" type="text" id="txbxTitle" autocomplete="off">
                <span id="titleError" class="fieldError" v-if="errorTitle">Please add a Title</span>
            </div>
            <div class="formRow cell cell-no-y-bottom">
                <span class="req">*</span>
                <label>Name:</label>
            </div>
            <div class="formRow nameTitleRow cell cell-no-y medium-2">
                <select v-model="principal.principalSalutation" :disabled="isDisabled" name="ddlPrefix" id="ddlPrefix" class="selectTitle">
                    <option value="0">Mr.</option>
                    <option value="1">Mrs.</option>
                    <option value="2">Ms.</option>
                    <option value="3">Mss.</option>
                    <option value="4">Dr.</option>
                </select>
            </div>
            <div class="formRow nameTitleRow cell cell-no-y medium-5">
                <input v-model="principal.principalFirstName" :disabled="isDisabled" name="txbxFirstName" type="text" id="txbxFirstName" placeholder="First Name">
            </div>
            <div class="formRow nameTitleRow cell cell-no-y medium-5">
                <input v-model="principal.principalLastName" :disabled="isDisabled" name="txbxLastName" type="text" id="txbxLastName" placeholder="Last Name" autocomplete="off">
            </div>
            <div class="formRow cell cell-no-y-top">
                <span id="salutationError" class="fieldError" v-if="errorSalutation">Please select a salutation</span>
                <span id="firstNameError" class="fieldError" v-if="errorFirst">Please add a first name</span>
                <span id="lastNameError" class="fieldError" v-if="errorLast">Please add a last name</span>
            </div>
            <div class="formRow emailField cell medium-6">
                <span class="req">*</span>
                <label for="txbxEmail">E-mail Address:</label>
                <input v-model="principal.principalEmail" :disabled="isDisabled" name="txbxEmail" type="text" id="txbxEmail" autocomplete="off">
                <span id="emailError" class="fieldError" v-if="errorEmail">Please enter email</span>
                <span id="emailValidError" class="fieldError" v-if="errorEmailValid">Please enter valid email address. Eg. Something@domain.com</span>
                <span class="inputNote">Used for account log in and official Ohio Lottery communications</span>
            </div>
            <div class="formRow emailField cell medium-6">
                <span class="req">*</span>
                <label for="txbxEmailConfirmation">Confirm E-mail Address:</label>
                <input name="txbxEmailConfirmation" type="text" :disabled="isDisabled" id="txbxEmailConfirmation" class="preventPaste" onpaste="return false;" autocomplete="off">
                <span id="emailConfirmError" class="fieldError" v-if="errorEmailConfirm">Please confirm your email address.</span>
                <span id="emailMatchError" class="fieldError" v-if="errorMatch">Please make sure that the emails are the same.</span>
            </div>
            <div class="formRow cell">
                <span class="req">*</span>
                <label for="txbxPercentOwnership">Percent Ownership:</label>
                <input v-model="principal.principalPercentOwnership" :disabled="isDisabled" name="txbxPercentOwnership" type="text" maxlength="3" id="txbxPercentOwnership" class="inputPercent" autocomplete="off" @input="restrictValue"> %
                <span id="percentError" class="fieldError" v-if="errorPercent">Please add a percentage.</span>
                <span id="percentValidError" class="fieldError" v-if="errorPercentValid">Value must be a whole number between 0 and 100</span>
                <span id="percentMaxError" class="fieldError" v-if="errorPercentMax">The total ownership should not exceed 100.</span>
            </div>
            <div class="formRow checkboxRow cell">
                <span class="setPrimary">
                    <input id="chkPrimaryContact" type="checkbox" :disabled="isDisabled" v-model="makePrimaryContact" @change="toggleContactChecks('primary')">
                    <label for="chkPrimaryContact">Set this principal as the primary contact</label>
                </span>
            </div>
            <div class="formRow checkboxRow cell">
                <span class="setSecondary">
                    <input id="chkSecondaryContact" type="checkbox" :disabled="isDisabled" v-model="makeSecondaryContact" @change="toggleContactChecks('secondary')">
                    <label for="chkSecondaryContact">Set this principal as the secondary contact</label>
                </span>
            </div>
        </div>

        <span class="error" v-if="specialError === true">Please provide standard English text characters only.</span>

        <div class="btn-actions">
            <input type="button" name="" @click="onSaveClick()" value="Save &amp; Next" class=" btn-primary btnNext" title="Save the document and proceed to next page">
            <button type="button" class="button btnCancel btn-secondary" @click="stepClick('principals')">Cancel</button>
        </div>

    </div>
</template>


<script>
    import $ from 'jquery';

    export default {
        props: {
            principalJson: String,
            readOnly: Boolean,
            totalPrincipalPercent: Number,
        },
        components: {

        },
        data: function () {
            return {
                PrincipalCreateContent: document.getElementById('PrincipalCreateContent').value,
                makePrimaryContact: false, // set this dyanmically
                makeSecondaryContact: false, // set this dyanmically
                isEdit: Boolean,
                isAdd: Boolean,
                principal: {},
                isDisabled: false,
                pastValue: 0,
                errorTitle: false,
                errorSalutation: false,
                errorFirst: false,
                errorLast: false,
                errorEmail: false,
                errorEmailValid: false,
                errorEmailConfirm: false,
                errorMatch: false,
                errorPercent: false,
                errorPercentValid: false,
                errorPercentMax: false,
                specialError: false
            }
        },
        mounted: function () {
            this.tryGetFromQueryString().then(() => {
                if (this.isEdit) {
                    this.deserializeJson();
                } else {
                    this.principal.principalInformationGuid = '00000000-0000-0000-0000-000000000000';
                }
            });

            if (this.principal.principalSalutation === undefined || this.principal.principalSalutation === null || this.principal.principalSalutation === "null") {
                this.principal.principalSalutation = 0;
            }

            this.isDisabled = this.readOnly;
        },
        methods: {
            restrictValue() {
                let value = this.principal.principalPercentOwnership,
                    rule = /^[0-9]*$/,
                    result = rule.test(value);

                if (result === true) {
                    if (value > 100) {
                        // this.pastValue = 100;
                    } else if (value < 0) {
                        this.pastValue = 0;
                    } else {
                        this.pastValue = value;
                    }
                }

                this.principal.principalPercentOwnership = this.pastValue;
            },
            toggleContactChecks: function (contactPref) {
                if (contactPref == 'primary') {
                    this.makeSecondaryContact = false;
                    this.principal.principalContactSecondary = false;
                    this.makePrimaryContact = true;
                    this.principal.principalContactPrimary = true;
                }
                else if (contactPref == 'secondary') {
                    this.makeSecondaryContact = true;
                    this.principal.principalContactSecondary = true;
                    this.makePrimaryContact = false;
                    this.principal.principalContactPrimary = false;
                }
            },
            deserializeJson() {
                this.principal = JSON.parse(this.principalJson);
                this.makePrimaryContact = this.principal.principalContactPrimary;
                this.makeSecondaryContact = this.principal.principalContactSecondary;
            },
            tryGetFromQueryString() {
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                if (urlParams.has('p')) {
                    this.isAdd = urlParams.get('p') == 0;
                    this.isEdit = urlParams.get('p') > 0;
                }
                return Promise.resolve();
            },
            validatePrincipal() {
                // $('#titleError').hide();
                // $('#salutationError').hide();
                // $('#firstNameError').hide();
                // $('#lastNameError').hide();
                // $('#emailError').hide();
                // $('#emailValidError').hide();
                // $('#emailConfirmError').hide();
                // $('#emailMatchError').hide();
                // $('#percentError').hide();
                // $('#percentValidError').hide();
                // $('#percentMaxError').hide();
                if(this.principal.principalSalutation === null || this.principal.principalSalutation === undefined || this.principal.principalSalutation === 'null') {
                    this.principal.principalSalutation = "-1";
                }

                if (!this.principal.principalTitle) {
                    this.errorTitle = true;
                } else {
                    this.errorTitle = false;
                }
                if (this.principal.principalSalutation < 0) {
                    this.errorSalutation = true;
                } else {
                    this.errorSalutation = false;
                }
                if (!this.principal.principalFirstName) {
                    this.errorFirst = true;
                } else {
                    this.errorFirst = false;
                }
                if (!this.principal.principalLastName) {
                    this.errorLast = true;
                } else {
                    this.errorLast = false;
                }

                let validPercent = true;
                if (!this.principal.principalPercentOwnership) {
                    validPercent = false;
                    this.errorPercent = true;
                } else {
                    this.errorPercent = false;
                }
                if (isNaN(this.principal.principalPercentOwnership)) {
                    this.errorPercentValid = true;
                    validPercent = false;
                } else {
                    let thisPercent = parseInt(this.principal.principalPercentOwnership);
                    let totalPercent = thisPercent + parseInt(this.totalPrincipalPercent);

                    // console.log(totalPercent > 100);
                    if (thisPercent > 100) {
                        validPercent = false;
                        this.errorPercentValid = true;
                    } 
                    else if(thisPercent === 0){
                        validPercent = false;
                        this.errorPercentValid = true;
                    }
                    else if (totalPercent > 100 && !this.isEdit) {
                        // if edit, then allow for total > 100
                        // otherwise you cannot even reduce the % for the user
                        // if add, then block that malarky
                        validPercent = false;
                        this.errorPercentMax = true;
                    } else {
                        this.errorPercentValid = false;
                        this.errorPercentMax = false;
                    }
                }
                

                let validEmail = true;
                let confirmEmailValue = $('#txbxEmailConfirmation').val();
                if (this.principal.principalEmail !== confirmEmailValue && !this.isDisabled ) {
                    validEmail = false;
                    this.errorEmailConfirm = true;
                } else {
                    this.errorEmailConfirm = false;
                }

                if (!this.emailValidation(this.principal.principalEmail)) {
                    validEmail = false;
                    this.errorEmailValid = true;
                } else {
                    this.errorEmailValid = false;
                }

                if (!this.principal.principalEmail) {
                    validEmail = false;
                    this.errorEmail = true;
                } else {
                    this.errorEmail = false;
                }

                if (this.principal.principalTitle
                    && this.principal.principalSalutation
                    && this.principal.principalFirstName
                    && this.principal.principalLastName
                    && validEmail
                    && validPercent) {
                    return true;
                }
                else {
                    return false;
                }

            },
            onSaveClick() {
                if (this.validateSpecial($('input[type=text]')) === false) {
                    this.specialError = true;
                    error = true;
                } else {
                    this.specialError = false;
                }
                // principals-list.vue
                // console.log(this.principal);
                if (this.validatePrincipal() && this.specialError === false) {
                    this.$emit('onPrincipalSaveClick', this.principal);
                }
            },
            stepClick(step) {
                let currentPage = window.location.origin + window.location.pathname;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                if (urlParams.has('p')) {
                    urlParams.delete('p');
                }
                urlParams.set('step', step);
                currentPage += '?' + urlParams.toString();
                window.location.href = currentPage;
            },
            // noPaste(event) {
            //     // keypress
            //     if (!ValidatedTextBoxOnKeyPress(event)) {
            //         event.cancelBubble = true;
            //         if (event.stopPropagation) event.stopPropagation();
            //         return false;
            //     }
            //     // change, paste
            //     function onpaste(event) {
            //         return false;
            //     }
            // }
        }
    };
</script>

<style lang="scss" scoped>
.eApp .formRow{
    padding: 0;
}
</style>
