<template>
    <div class="check_your_numbers_panel" :class="gameName">
        <h3>Have My Numbers Been Drawn?</h3>
        <div class="panel_content">


            <div class="grid-x amIaWinner_form haveMyNumbersBeenDrawn">
                <div class="grid-x cell large-12">
                    <div class="cell small-3 dates">
                        <span class="from-date-label small-12 large-5">From Date:</span>
                        <datepicker :disabledDates="disabledDates" placeholder="Select Date" :name="'from_' + gameName + '_inputDate'"
                                    :format="customFormatter"></datepicker>
                    </div>
                    <div class="cell small-3 dates">
                        <span class="to-date-label small-12 large-5">To Date:</span>
                        <datepicker :disabledDates="disabledDates" placeholder="Select Date" :name="'to_' + gameName + '_inputDate'"
                                    :format="customFormatter"></datepicker>
                    </div>
                </div>
                <div class="cell medium-10 enter-numbers grid-x">
                    <span class="enter-numbers-label small-12 medium-5">Enter Your Numbers:</span>
                    <div class="enter-numbers-wrapper">
                        <div class="numbers small-12 large-11">
                            <div v-for="(number, idx) in numbers" v-bind:key="idx" class="number_input_wrapper">
                                <input type="text" pattern="\d*" :name="gameName + '_value'" v-model="number.value"
                                    maxlength="2" class="number_input">
                            </div>
                        </div> 
                    </div>
                </div>

                <div class="cell medium-2 btn-go">
                    <button class="button" @click="go">Go</button>
                </div>
            </div>

            <span class="error-message" v-if="showErrorMessage"> {{ messageErrorTxt }} </span>


            <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#0FD8C9" v-if="isLoading" />

            <!-- 
            <div class="solid-top-border amIaWinner_form align-bottom amIaWinner_winningNumber"
                v-if="showMatchNumbers && !isLoading"> -->
            <div class="solid-top-border align-bottom "
                v-if="showMatchNumbers && !isLoading">
                <div class="hmnbd_header grid-x desktopOnly">
                    <div class="cell medium-4">
                        Draw Date
                    </div>
                    <div class="cell medium-5">
                        Results
                    </div>
                    <div class="cell medium-3 amount">
                        Prize Amount
                    </div>
                </div>
                <div class="hmnbd_cell grid-x" v-for="(match, index) in matchingDraws" :key="index">
                    <div class="cell medium-4 drawDate">
                        {{ match.drawDate }}
                    </div>
                    <div class="cell medium-5 numbers winning-numbers grid-x">
                        <div class="numbers small-12 large-7">
                            <div v-for="(wNumber, index) in match.numbers" v-bind:key="index"
                                class="number_input_wrapper winningNumbersInputs"
                                :class="[{ 'matched_number': wNumber.match }, `winningNumbersInputs-${index}`]">

                                <input type="text" name="number" :value="wNumber.value" :data-value="wNumber.value"
                                    :class="[`numberPos-${index}`, `input-${index}`]" class="number_input" readonly />

                            </div>
                        </div>
                        <div class="cell medium-2">
                            <div class="special-number text-center" v-if="gameName == 'megaMillions'">
                                <span class="extraVal">{{ megaSpecialValue }}</span>
                            </div>

                            <div class="special-number text-center" v-if="gameName == 'powerBall'">
                                <span class="extraVal">{{ powerSpecialValue }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="cell medium-3 amount">
                        <span v-html="formatPrize(match.prize.Prize, match.prizePool)"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HollowDotsSpinner } from 'epic-spinners'
import Datepicker from "vue-moment-datepicker";
import moment from 'moment';

export default {
    components: {
        Datepicker,
        HollowDotsSpinner
    },
    props: ['gameName', 'inputsQuantity'],
    data: function () {
        return {
            showMatchNumbers: false,
            showErrorMessage: false,
            winnerMessage: false,
            notWinnerMessage: false,
            showAmPm: false,
            isLoading: false,
            modifier: 2,
            messageErrorTxt: '',
            game: this.gameName,
            megaSpecialValue: '',
            powerSpecialValue: '',
            disabledDates: {
                from: new Date(),
                to: new Date('2000-1-1')
            },
            startDate: new Date(),
            amountInputs: this.inputsQuantity,
            numbers: [],
            winningNumbers: [],
            winnerPrize: '',
            prizeName: '',
            matchingDraws: ''
        }
    },
    mounted: function () {

        if (this.game.indexOf('pick') >= 0) {
            this.showAmPm = true;
        }

        let number = []

        let amountInputs = this.amountInputs;

        for (var i = 0; i < amountInputs; i++) {
            number.push({
                "value": undefined,
                "index": i
            })
        }

        this.numbers = number;
    },
    methods: {
        pickAmPm(event) {
            let data = event.target.value;

            let inputCalendar = document.querySelector("input[name=" + this.game + "_inputDate]");

            if (inputCalendar.value.length !== 0) {
                if (data == 'Midday') {
                    this.modifier = 1
                } else if (data == 'Evening') {
                    this.modifier = 2
                }
            }
        },
        customFormatter(date) {
            return moment(date).format('yyyy-MM-DD');
        },
        go() {
            let gameSelected = '';
            gameSelected = this.game;

            this.isLoading = true;
            //let inputPickAmPm = document.querySelectorAll("input[name="+ gameSelected +"_pick_am_pm]");

            if (this.game.indexOf('pick') >= 0) {

                if (document.querySelector("input[name=from_" + gameSelected + "_inputDate]").value.length === 0
                    || document.querySelector("input[name=to_" + gameSelected + "_inputDate]").value.length === 0) {
                    this.isLoading = false;
                    this.showMatchNumbers = false;
                    this.showErrorMessage = true;
                    this.winnerMessage = false;
                    this.notWinnerMessage = false;
                    this.messageErrorTxt = "Invalid Inputs. Please try again"
                } else {
                    this.showErrorMessage = false;
                    this.getToken();
                }

            } else {
                if (document.querySelector("input[name=from_" + gameSelected + "_inputDate]").value.length === 0
                    || document.querySelector("input[name=to_" + gameSelected + "_inputDate]").value.length === 0) {
                    this.isLoading = false;
                    this.showMatchNumbers = false;
                    this.showErrorMessage = true;
                    this.messageErrorTxt = "Invalid Inputs. Please try again"
                } else {
                    this.showErrorMessage = false;
                    this.getToken();
                }
            }



        },
        getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

        },
        fetchData(token) {
            let gameSelected = '';
            gameSelected = this.game;

            const apiToken = token;
            const urlProtocol = this.getProtocol();
            let gameNameApi = this.game;


            var sinceDate = document.querySelector("input[name=from_" + gameSelected + "_inputDate]").value;
            var toDate = document.querySelector("input[name=to_" + gameSelected + "_inputDate]").value;

            console.log(sinceDate);
            console.log(toDate);

            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + apiToken
                }
            };

            fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/" + gameNameApi + "/SearchDrawDateRange?sinceDate=" + sinceDate + "&toDate=" + toDate, requestOptions)
                .then(response => response.json())
                .then(data => {
                    let resultsArray = data.data;

                    if (resultsArray.draws.length < 1) {
                        this.showErrorMessage = true;
                        this.showMatchNumbers = false;
                        this.isLoading = false;
                        if (resultsArray.errorMessage === '' || resultsArray.errorMessage === null || resultsArray.errorMessage === undefined) {
                            this.messageErrorTxt = "There are no results for this date range. Please select another.";
                        }
                        else {
                            this.messageErrorTxt = resultsArray.errorMessage;
                        }

                        return false
                    } else {
                        this.showErrorMessage = false;
                        // this.isLoading = false;
                        this.winningNumbers = resultsArray.winningNumbers;
                    }

                    this.showMatchNumbers = true;

                    let userNumbers = []
                    let resultOfMatch = [] //For Pick games this is your key to what places match

                    this.numbers.forEach(function (item) {
                        userNumbers.push(parseInt(item.value))
                    });

                    if (this.game.indexOf('pick') >= 0) {
                        let draws = resultsArray.draws,
                            matchingDraws = [],
                            userNumbers = [...this.numbers];

                        for (let i = 0; i < draws.length; i++) {
                            let drawNumbers = draws[i].numbers;

                            let match = false,
                                matches = 0;

                            for (let w = 0; w < userNumbers.length; w++) {
                                let uNumber = userNumbers[w];

                                for (let q = 0; q < drawNumbers.length; q++) {

                                    if (drawNumbers[q].value == parseInt(uNumber.value) && drawNumbers[q].match === undefined) {
                                        drawNumbers[q].match = true;
                                        q == w ? drawNumbers[q].order = true : drawNumbers[q].order = false;
                                        match = true;
                                        matches++;
                                        draws[i].drawDate = moment(draws[i].drawDate).format('dddd') + ' ' + moment(draws[i].drawDate).format('l');
                                        break;
                                    }
                                }
                            }

                            draws[i].prize = { Prize: 0 };
                            draws[i].matches = matches;

                            match === true ? matchingDraws.push(draws[i]) : false;
                        }




                        this.matchingDraws = matchingDraws




                    } else {
                        let draws = resultsArray.draws,
                            matchingDraws = [],
                            numbers = [...this.numbers];

                        if (this.game === 'megaMillions' || this.game === 'powerBall' || this.game === 'luckyForLife') {
                            let userLastBall = numbers.pop();

                            for (let i = 0; i < draws.length; i++) {
                                let removeXBall;

                                this.game === 'megaMillions' || this.game === 'powerBall' ? removeXBall = draws[i].numbers.pop() : false;

                                let winningNumbers = [...draws[i].numbers],
                                    apiLastBall = winningNumbers.pop();

                                draws[i].prize = {
                                    WB: 0,
                                    CB: 0,
                                    Prize: 0,
                                };

                                this.game === 'megaMillions' || this.game === 'powerBall' ? draws[i].multi = removeXBall.value : false;

                                let match = false;

                                if (apiLastBall.value == userLastBall.value) {
                                    draws[i].numbers[draws[i].numbers.length - 1].match = true;
                                    draws[i].drawDate = moment(draws[i].drawDate).format('dddd') + ' ' + moment(draws[i].drawDate).format('l');

                                    match = true;
                                }

                                for (let w = 0; w < numbers.length; w++) {
                                    let userNumber = numbers[w];

                                    for (let q = 0; q < winningNumbers.length; q++) {
                                        if (userNumber.value == winningNumbers[q].value) {
                                            draws[i].numbers[q].match = true;
                                            draws[i].drawDate = moment(draws[i].drawDate).format('dddd') + ' ' + moment(draws[i].drawDate).format('l');

                                            match = true;
                                        }
                                    }
                                }

                                match === true ? matchingDraws.push(draws[i]) : false;
                            }
                        } else {
                            // Classic Lotto & Rolling Cash 5
                            for (let i = 0; i < draws.length; i++) {
                                let winningNumbers = [...draws[i].numbers];

                                draws[i].prize = {
                                    WB: 0,
                                    CB: 0,
                                    Prize: 0
                                };

                                let match = false,
                                    matches = 0;

                                for (let w = 0; w < numbers.length; w++) {
                                    let userNumber = numbers[w];

                                    for (let q = 0; q < winningNumbers.length; q++) {
                                        if (userNumber.value == winningNumbers[q].value) {
                                            if (draws[i].numbers[q].match === null || draws[i].numbers[q].match === undefined) {
                                                matches++;
                                            }
                                            
                                            draws[i].numbers[q].match = true;

                                            draws[i].drawDate = moment(draws[i].drawDate).format('dddd') + ' ' + moment(draws[i].drawDate).format('l');

                                            match = true;
                                        }
                                    }
                                }

                                draws[i].match = match;
                                draws[i].matches = matches;

                                match === true ? matchingDraws.push(draws[i]) : false;
                            }
                        }

                        this.matchingDraws = matchingDraws;
                    }

                    fetch(urlProtocol + process.env.VUE_APP_APIURL + "/1.0/Games/DrawGames/" + gameNameApi + "/GetGameInformation", {
                        headers: {
                            'Authorization': 'Bearer ' + apiToken,
                        },
                    })
                        .then(response => response.json())
                        .then(data => {
                            let res = data;
                            let prizes = {};

                            prizes = res.data.prizeStructureJSON;
                            this.parsedPrizes = res.data.prizeStructureJSON;

                            // console.log( this.parsedPrizes )

                            let toJson = {};
                            let gameRules = {};

                            if (prizes.length !== 0) {
                                toJson = eval('(' + prizes + ')');
                                if (this.game == 'powerBall') {
                                    gameRules = toJson.Matches
                                } else {
                                    gameRules = toJson.Rules
                                }
                            }

                            let checkDuplicates = (arr) => {
                                let numbers = arr;
                                let numbersArray = [];

                                for (let i = 0; i < numbers.length; i++) {
                                    let numberA = numbers[i].value;
                                    let number = {
                                        value: numberA,
                                        times: 0
                                    };

                                    for (let w = 0; w < numbers.length; w++) {
                                        let numberB = numbers[w].value;

                                        if (numberA === numberB) {
                                            number.times++;
                                        }
                                    }

                                    numbersArray.push(number);
                                }

                                for (let i = 0; i < numbersArray.length; i++) {
                                    let mainNumber = numbersArray[i].value,
                                        mainTimes = numbersArray[i].times;

                                    for (let w = 0; w < numbersArray.length; w++) {
                                        let compareNumber = numbersArray[w].value,
                                            compareTimes = numbersArray[w].times;

                                        if (mainNumber === compareNumber && i != w) {
                                            numbersArray.splice(w, 1);
                                        }
                                    }
                                }

                                return numbersArray;
                            }

                            //console.log(gameRules)
                            let draws = [...this.matchingDraws];

                            if (this.game.indexOf('pick') >= 0) {
                                let matchedDraws = [];

                                for (let i = 0; i < draws.length; i++) {
                                    let draw = draws[i];

                                    // PICK 3 //
                                    if (this.game == 'pick3') {
                                        if (draw.matches == 3) {
                                            let occurance = checkDuplicates(draw.numbers),
                                                order = false,
                                                duplicate = false;

                                            // Check for order
                                            for (let w = 0; w < draw.numbers.length; w++) {
                                                let number = draw.numbers[w];

                                                if (number.match != null && number.match != undefined) {
                                                    if (number.order === false) {
                                                        order = false;
                                                        break;
                                                    } else {
                                                        order = true;
                                                    }
                                                }
                                            }

                                            // Set Prizes based on order and duplicates
                                            if (order === true) {
                                                draw.prize.Prize = this.getPrize('Straight', draw);
                                            } else {
                                                for (let w = 0; w < occurance.lenght; w++) {
                                                    let number = occurance[w];

                                                    if (number.times > 1) {
                                                        duplicate = true;
                                                    }
                                                }

                                                if (duplicate === true) {
                                                    draw.prize.Prize = this.getPrize('ThreeWayBox', draw);
                                                } else {
                                                    draw.prize.Prize = this.getPrize('SixWayBox', draw);
                                                }
                                            }
                                        } else if (draw.matches == 2) {
                                            // Must be 3-way, front pair, back pair, split, pair
                                            let ordered = 0;

                                            for (let w = 0; w < draw.numbers.length; w++) {
                                                let number = draw.numbers[w];

                                                if (number.match != null && number.match != undefined) {
                                                    if (number.order === true) {
                                                        ordered++;
                                                    }
                                                }
                                            }

                                            if (ordered === 2) {
                                                draw.prize.Prize = '$50';
                                            }
                                        }
                                    } else if (this.game === 'pick4') {
                                        if (draw.matches == 4) {
                                            let check = checkDuplicates(draw.numbers),
                                                order = false,
                                                duplicate = false,
                                                doubleDuplicate = false,
                                                triplicate = false;

                                            //console.log(check);

                                            // Determine if numbers are in order or not.
                                            for (let w = 0; w < draw.numbers.length; w++) {
                                                let number = draw.numbers[w];

                                                if (number.match != null && number.match != undefined) {
                                                    if (number.order === false) {
                                                        order = false;
                                                        break;
                                                    } else {
                                                        order = true;
                                                    }
                                                }
                                            }

                                            if (order === true) {
                                                draw.prize.Prize = this.getPrize('Straight', draw);
                                            } else {
                                                for (let w = 0; w < check.length; w++) {
                                                    let number = check[w];

                                                    if (number.times > 1) {
                                                        if (number.times === 2 && duplicate === true) {
                                                            doubleDuplicate = true;
                                                        } else if (number.times === 2 && duplicate === false) {
                                                            duplicate = true;
                                                        } else if (number.times === 3) {
                                                            triplicate = true;
                                                        }
                                                    }
                                                }

                                                if (doubleDuplicate === true) {
                                                    draw.prize.Prize = this.getPrize('SixWayBox', draw);
                                                } else if (duplicate === true) {
                                                    draw.prize.Prize = this.getPrize('TwelveWayBox', draw);
                                                } else if (triplicate === true) {
                                                    draw.prize.Prize = this.getPrize('FourWayBox', draw);
                                                } else if (duplicate === false && doubleDuplicate === false && triplicate === false) {
                                                    draw.prize.Prize = this.getPrize('TwentyFourWayBox', draw);
                                                }
                                            }
                                        }
                                    } else if (this.game === 'pick5') {
                                        if (draw.matches == 5) {
                                            let check = checkDuplicates(draw.numbers),
                                                order = false,
                                                duplicate = false,
                                                doubleDuplicate = false,
                                                triplicate = false,
                                                fourOfaKind = false;

                                            //console.log(check);

                                            // Determine if numbers are in order or not.
                                            for (let w = 0; w < draw.numbers.length; w++) {
                                                let number = draw.numbers[w];

                                                if (number.match != null && number.match != undefined) {
                                                    if (number.order === false) {
                                                        order = false;
                                                        break;
                                                    } else {
                                                        order = true;
                                                    }
                                                }
                                            }

                                            if (order === true) {
                                                // Straight
                                                let parsedPrize = JSON.parse(this.parsedPrizes),
                                                    prize = parsedPrize.Rules,
                                                    prizeArray = [];

                                                console.log(prize)

                                                for (let w = 0; w < prize.length; w++) {
                                                    if (prize[w].Name === 'Straight') {
                                                        prizeArray.push(prize[w]);
                                                    }
                                                }

                                                console.log(prizeArray)

                                                draw.prize.Prize = '$' + prizeArray[prizeArray.length - 1].Prize.toLocaleString();
                                            } else {
                                                for (let w = 0; w < check.length; w++) {
                                                    let number = check[w];

                                                    if (number.times > 1) {
                                                        if (number.times === 2 && duplicate === true) {
                                                            doubleDuplicate = true;
                                                        } else if (number.times === 2 && duplicate === false) {
                                                            duplicate = true;
                                                        } else if (number.times === 3) {
                                                            triplicate = true;
                                                        } else if (number.times === 4) {
                                                            fourOfaKind = true;
                                                        }
                                                    }
                                                }

                                                if (fourOfaKind === true) {
                                                    draw.prize.Prize = this.getPrize('FiveWayBox', draw);
                                                } else if (doubleDuplicate === true) {
                                                    draw.prize.Prize = this.getPrize('ThirtyWayBox', draw);
                                                } else if (duplicate === true && triplicate === true) {
                                                    draw.prize.Prize = this.getPrize('TenWayBox', draw);
                                                } else if (duplicate === true && triplicate === false) {
                                                    draw.prize.Prize = this.getPrize('SixtyWayBox', draw);
                                                } else if (duplicate === false && triplicate === true) {
                                                    draw.prize.Prize = this.getPrize('TwentryWayBox', draw);
                                                } else if (duplicate === false && doubleDuplicate === false && triplicate === false && fourOfaKind === false) {
                                                    draw.prize.Prize = this.getPrize('OneTwentryWayBox', draw);
                                                }
                                            }

                                        } else {
                                            // Logic for Front/Back Four
                                            let num1 = draw.numbers[0].value,
                                                num2 = draw.numbers[1].value,
                                                num3 = draw.numbers[2].value,
                                                num4 = draw.numbers[3].value,
                                                num5 = draw.numbers[4].value;

                                            let uNum1 = this.numbers[0].value,
                                                uNum2 = this.numbers[1].value,
                                                uNum3 = this.numbers[2].value,
                                                uNum4 = this.numbers[3].value,
                                                uNum5 = this.numbers[4].value;

                                            if (num1 == uNum1 && num2 == uNum2 && num3 == uNum3 && num4 == uNum4) {
                                                // console.log('Front Four');
                                                draw.prize.Prize = '$5,000';
                                            } else if (num2 == uNum2 && num3 == uNum3 && num4 == uNum4 && num5 == uNum5) {
                                                // console.log('Back Four');
                                                draw.prize.Prize = '$5,000';
                                                draw.numbers[4].match = true;
                                            } else if (num1 == uNum1 && num2 == uNum2 && num3 == uNum3) {
                                                // console.log('Front Three');
                                                draw.prize.Prize = '$500';
                                                draw.numbers[3].match = false;
                                                draw.numbers[4].match = false;
                                            } else if (num3 == uNum3 && num4 == uNum4 && num5 == uNum5) {
                                                // console.log('Back Three');
                                                draw.prize.Prize = '$500';
                                                draw.numbers[0].match = false;
                                                draw.numbers[1].match = false;
                                                draw.numbers[2].match = true;
                                                draw.numbers[3].match = true;
                                                draw.numbers[4].match = true;
                                            } else if (num1 == uNum1 && num2 == uNum2) {
                                                // console.log('Front Pair');
                                                draw.prize.Prize = '$50';
                                                draw.numbers[2].match = false;
                                                draw.numbers[3].match = false;
                                                draw.numbers[4].match = false;
                                            } else if (num4 == uNum4 && num5 == uNum5) {
                                                // console.log('Back Pair');
                                                draw.prize.Prize = '$50';
                                                draw.numbers[0].match = false;
                                                draw.numbers[1].match = false;
                                                draw.numbers[2].match = false;
                                                draw.numbers[3].match = true;
                                                draw.numbers[4].match = true;
                                            }

                                        }

                                    }
                                }

                                for (let i = draws.length - 1; i >= 0; --i) {
                                    if (draws[i].prize.Prize === 0) {
                                        draws.splice(i, 1);
                                    }
                                }
                            } else if (this.game == 'powerBall' || this.game == 'megaMillions' || this.game == 'luckyForLife') {
                                for (let i = 0; i < draws.length; i++) {
                                    let draw = draws[i],
                                        numbers = draw.numbers,
                                        matches = 0,
                                        special = 0;

                                    numbers[numbers.length - 1].match === true ? special = 1 : false;

                                    for (let w = 0; w < numbers.length - 1; w++) {
                                        let number = numbers[w];

                                        if (number.match === true) {
                                            matches++;
                                        }
                                    }

                                    for (let q = 0; q < gameRules.length; q++) {
                                        let tier = gameRules[q];

                                        if (tier.WB == matches && tier.CB == special) {
                                            if ( Number(tier.Prize) > 999) {
                                                tier.Prize = tier.Prize.toLocaleString();
                                            } 
                                            draws[i].prize = tier;
                                        }
                                    }
                                }

                                for (let i = 0; i < draws.length; i++) {
                                    
                                    if (draws[i].prize.Prize !== 0 && draws[i].prize.Prize[0] !== "$") {
                                        if (draws[i].prize.Prize === "Jackpot" || draws[i].prize.Prize === "Grand Prize") {
                                            draws[i].prize.Prize = '$' + draws[i].prizePool
                                            
                                        } else {
                                            if (this.game == 'powerBall') {
                                                draws[i].prize.Prize = '$' + Number(draws[i].prize.Prize).toLocaleString() + '<br> with Power Play x' + draws[i].multi + ': $' + (draws[i].prize.Prize * draws[i].multi).toLocaleString();
                                                // console.log(draws[i].multi)
                                            } else {
                                                draws[i].prize.Prize = "$" + draws[i].prize.Prize
                                            }
                                        }
                                    }
                                }

                                for (let i = draws.length - 1; i >= 0; --i) {
                                    if (draws[i].prize.Prize === 0) {
                                        draws.splice(i, 1);
                                    }
                                }
                            } else {
                                console.log(gameRules)
                                // Meaning it's Classic Lotto or Rolling Cash 5
                                for (let i = 0; i < draws.length; i++) {
                                    let drawing = draws[i];

                                    if (drawing.match === true) {
                                        for (let w = 0; w < gameRules.length; w++) {
                                            let tier = gameRules[w].WB,
                                                prize = gameRules[w].Prize;

                                            if (drawing.matches === tier) {
                                                if (prize === 'Jackpot') {
                                                    drawing.prize.Prize = prize;
                                                } else {
                                                    if (prize.length > 3) {
                                                        prize = Number(prize).toLocaleString();
                                                    }
                                                    drawing.prize.Prize = '$' + prize;
                                                    console.log(drawing.prize.Prize)
                                                }
                                            }
                                        }
                                    }
                                }

                                for (let i = 0; i < draws.length; i++) {
                                    if (draws[i].prize.Prize !== 0 && draws[i].prize.Prize[0] !== "$") {
                                        draws[i].prize.Prize !== "Jackpot" ? draws[i].prize.Prize = "$" + draws[i].prize.Prize : false;
                                    }
                                }

                                for (let i = draws.length - 1; i >= 0; --i) {
                                    if (draws[i].prize.Prize === 0) {
                                        draws.splice(i, 1);
                                    }
                                }

                                for (let i = 0; i < draws.length; i++) {
                                    if (draws[i].prize.Prize === 0) {
                                        draws.splice(i, 1);
                                    }
                                }
                            }


                            if (draws.length === 0) {
                                this.showErrorMessage = true;
                                this.showMatchNumbers = false;
                                this.isLoading = false;
                                this.messageErrorTxt = "There are no results for this date. Please select another."
                                return false
                            } else {
                                this.matchingDraws = draws;
                                this.isLoading = false;
                            }

                            console.log(draws)
                        })
                });
        },
        changeRange(event) {
            this.dateRangeValue = event.target.value;
            console.log(this.dateRangeValue)
        },
        getPrize(game, draw) {
            let prize = this.parsedPrizes.Rules,
                prizeArray = [],
                name = game;

            if (typeof this.parsedPrizes === typeof "") {
                prize = JSON.parse(this.parsedPrizes);
                prize = prize.Rules;
            }

            for (let w = 0; w < prize.length; w++) {
                if (prize[w].Name === name) {
                    prizeArray.push(prize[w]);
                }
            }

            console.log(prizeArray)

            let finalPrize = '$' + prizeArray[prizeArray.length - 1].Prize.toLocaleString();
            return finalPrize;
        },
        formatPrize(prize, jackpot) {
            const inNumber = /^\$?\d+(,\d{3})*$/.test(prize);

            if (inNumber) {
                const prizeNumerico = parseFloat(prize.replace(/[^\d.]/g, ''));
                return prizeNumerico.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });
            } else {
                if (prize === 'Jackpot') {
                    // Formatear el valor del jackpot numérico
                    const jackpotNumerico = parseFloat(jackpot);
                    return jackpotNumerico.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    });
                } else {
                    return prize;
                }
            }
        }
    },
    computed: {
    },
    watch: {
        game: function () {
            if (this.game.indexOf('pick') >= 0) {
                this.showAmPm = true;
            }
        }
    }

};

</script>

<style lang="scss" scoped>
.hmnbd_header {
    padding: 0 20px 20px;

    .amount {
        text-align: right;
    }
}

.hmnbd_cell {
    padding: 20px 0;
    border-top: 1px solid #b9bdc3;

    @include breakpoint(medium) {
        padding: 20px;
    }

    .medium-5 {
        &.winning-numbers {
            text-align: left;
            display: block;
            .grid-x{
                width:fit-content;
            }
            .numbers {
                justify-content: space-evenly;
                margin: 0;

                @include breakpoint(medium) {
                   // justify-content: space-evenly;
                }

                .number_input_wrapper {
                    margin-right: 5px;
                    @media only screen and (min-width: 401px) {
                        margin-right: 10px;
                    }

                    @include breakpoint(medium) {
                        margin-right: 0px;
                    }
                }
            }
        }
    }

    .drawDate {
        font-size: 2rem;
        margin-bottom: 10px;

        @include breakpoint(medium) {
            font-size: 1.6rem;
            margin-bottom: 0px;
            width: 25%;
        }
    }

    .amount {
        text-align: right;
        color: #be0f34;

        @media screen and (min-width: 40em) {
            width: 20%;
        }
    }

    .matched_number {
        .number_input {
            background-color: #fff;
        }
    }
}
</style>