import {mapActions, mapGetters} from 'vuex';
import Vue from 'vue';

import VueSanitize from 'vue-sanitize';
import sanitizeHtml from 'sanitize-html';
// import $ from 'jquery';
import moment from "moment";
import Persona from 'persona';
Vue.use(VueSanitize);

export default {
    data: function() {
        return {
            token: ''
        }
    },
    methods: {
        isUserAuthed() {
            let userData = this.$cookies.get('apiToken');
            let userName = userData.user.username;
            
            if (userName === 'mobilepublic@mtllc.com') {
                // console.log('public user');
                return false;
            } else if (userName !== "" && userName !== undefined) {
                // console.log('authed user');
                return true;
            } else {
                // console.log('no user, no token?');
                return false;
            }
        },
        getRefereshToken(apiToken) {
            let username = apiToken.user.username;
            let password = "string";
            let refreshToken = apiToken.refreshToken.token;
            let protocol = this.getProtocol();

            

            let loginCreds = {
                "userName": username,
                "password": password,
                "refreshToken": refreshToken
            }

            // console.log('refreshToken: '+refreshToken);

            fetch(protocol+process.env.VUE_APP_AUTHURL+'/1.0/Authentication/RefreshToken', {
                method: 'POST',
                body: JSON.stringify(loginCreds),
                headers: {
                    'Content-Type': 'application/json-patch+json'
                }
            })
            .then(response => response.json())
            .then((response) => {
                // var resStatus = response;
                // console.log('response: '+response.ok+' resStatus: '+resStatus);
                // if (response.ok) {
                    let res = response;
                    console.log(res);
                    this.submitRefresh(res);
                // } else {
                    // console.log('token error: '+response+' resStatus: '+resStatus);
                    // throw new Error('Data API access temporarily down. Please try again later.');
                // }
            })
            .catch(error => {
                console.log('token refresh error: '+error);
                // reset and try again
                this.$cookies.remove('apiToken');
                return this.getNewAPItoken();
                // return error;
            });
        },
        submitRefresh(response) {
            var tokenObj = {};

            console.log(response.data);

            tokenObj = response.data;
            // console.log(tokenObj);

            // check if token exists
            // console.log('refreshed token: '+tokenObj.token);
            if (tokenObj.token === null && tokenObj.token === undefined && tokenObj.token === "") {
                // reset and try again
                // console.log(response);
                // console.log('remove token cookie');
                // this.$cookies.remove('apiToken');
                // return this.getNewAPItoken();
            } else {
                // var tokenExpires = new Date(tokenObj.token.expires);
                // tokenExpires = tokenExpires;
                // this.$cookies.set('apiToken', JSON.stringify(tokenObj), tokenExpires, '/', null, true, 'Strict');
                this.$cookies.remove('apiToken');
                this.$cookies.set('apiToken', JSON.stringify(tokenObj), '/', null, true, 'Strict');
                console.log("= New Token Set in Cookie =");
                // return tokenObj.token;
                // let tokenInfo = this.$cookies.get('TokenInfo');
                // if (tokenInfo) {
                //     tokenInfo.Token.Token = tokenObj.token.token;
                //     tokenInfo.Message = tokenObj.message;
                //     tokenInfo.Expires = tokenObj.expires;
                //     tokenInfo.IsExpires = tokenObj.isExpired;
                //     tokenInfo.MinutesToExpiration = tokenObj.minutesToExpiration;
                //     tokenInfo.IsAuthenticated = tokenObj.isAuthenticated;
                //     tokenInfo.RefreshToken.Token = tokenObj.refreshToken.token;
                //     tokenInfo.RefreshToken.CurrentDatetime = tokenObj.refreshToken.currentDateTime;
                //     tokenInfo.RefreshToken.Expires = tokenObj.refreshToken.expires;
                //     tokenInfo.RefreshToken.IsExpired = tokenObj.refreshToken.isExpired;
                //     tokenInfo.RefreshToken.MinutesToExpiration = tokenObj.refreshToken.minutesToExpiration;
                //     tokenInfo.RefreshToken.Created = tokenObj.refreshToken.created;
                //     tokenInfo.RefreshToken.Revoked = tokenObj.refreshToken.revoked;
                //     tokenInfo.RefreshToken.IsActive = tokenObj.refreshToken.isActive;
                //     tokenInfo.RefreshToken.LastUpdated = tokenObj.refreshToken.lastupdated;

                //     this.$cookies.set('TokenInfo', JSON.stringify(tokenInfo), "/", tokenInfo.RefreshToken.Expires, false);
                // }
                // this.token = tokenObj;
                // return tokenObj.token;                   
            }
        },
        getNewAPItoken() {
            // console.log('== get new token with Vue ==');
            let username = process.env.VUE_APP_APIUSER;
            let password = process.env.VUE_APP_APIPASS;
            let protocol = this.getProtocol();

            let loginCreds = {
                "userName": username,
                "password": password
            }
            return fetch(protocol+process.env.VUE_APP_AUTHURL+'/1.0/Authentication/Login', {
                method: 'POST',
                body: JSON.stringify(loginCreds),
                headers: {
                    'Content-Type': 'application/json-patch+json'
                }
            })
            .then(response => {
                var resStatus = response.status;
                // console.log('response: '+response.ok+' resStatus: '+resStatus);
                if (response.ok) {
                    // console.log(response.json());
                    return response.json();
                } else {
                    // console.log('token error: '+response+' resStatus: '+resStatus);
                    throw new Error('Data API access temporarily down. Please try again later.');
                }
            })
            .then(response => {
                var tokenObj = {};
                tokenObj = response.data;
                // check if token exists
                if (tokenObj.token !== null && tokenObj.token !== undefined && tokenObj.token !== "") {
                    var tokenExpires = new Date(tokenObj.token.expires);
                    tokenExpires = tokenExpires.toUTCString();
                    this.$cookies.set('apiToken', tokenObj, tokenExpires, '/', null, true, 'Strict');
                    this.token = tokenObj;
                    return tokenObj.token;
                } else {
                    // console.log('== get token error ==');
                    this.$cookies.remove('apiToken');
                    // nothing to do if this fails, the site is down.
                    return "error";
                }
            })
            .catch(error => {
                // console.log('get token error: '+error);
                // nothing to do if this fails, the site is down.
                // return error;
            });

        },
        returnToken() {
            return this.token;
        },
        getAPItoken() {
            // check if a token is still valid
            let apiToken = this.$cookies.get('apiToken');
            let tokenExpires = 0;
            let refreshTokenExpires = 0;
            let currentTime = moment(new Date());
            
            // return token if still valid
            if (apiToken !== null && apiToken !== undefined) {
                if (apiToken.expires !== null) {
                    tokenExpires =  moment(apiToken.expires);
                    tokenExpires = tokenExpires.diff(currentTime, 'seconds');
                    refreshTokenExpires = moment(apiToken.refreshToken.expires);
                    refreshTokenExpires = refreshTokenExpires.diff(currentTime, 'seconds');
                }
            }

            // console.log("- Token Date: " + apiToken.expires)
            console.log("- Token Expires in " + tokenExpires + " seconds.")
            // console.log("- Refresh Date: " + apiToken.refreshToken.expires)
            console.log("- Refresh Expires in " + refreshTokenExpires + " seconds.")

            if (tokenExpires > 0) {
                // token is still current
                console.log("= Returning Normal Token =");
                return Promise.resolve(apiToken.token);

            } else if (refreshTokenExpires > 0) {
                console.log("= Refreshing Token =");

                let username = apiToken.user.username;
                let password = "string";
                let refreshToken = apiToken.refreshToken.token;
                let protocol = this.getProtocol();

                let loginCreds = {
                    "userName": username,
                    "password": password,
                    "refreshToken": refreshToken
                }

                return fetch(protocol+process.env.VUE_APP_AUTHURL+'/1.0/Authentication/RefreshToken', {
                    method: 'POST',
                    body: JSON.stringify(loginCreds),
                    headers: {
                        'Content-Type': 'application/json-patch+json'
                    }
                })
                .then(response => response.json())
                .then((response) => {
                        this.$cookies.remove('apiToken');
                        let res = response;

                        console.log("= Refresh Token Returned =");
                        // console.log("user: ");
                        // console.log(res.data.user.email);


                        if (res.data.user.email === 'mobilepublic@mtllc.com' || res.data.user === null) {
                            this.reloadThings();

                        } else {
                            if (refreshToken.data.minutesToExpiration > -1) {
                                this.$cookies.set('apiToken', JSON.stringify(res.data), '/', null, true, 'Strict');

                            } else {
                                
                                let button = document.getElementById('forceLogout');
                                button.click();
                            }
                        }

                        // if (res.data.isExpired == true && res.data.isAuthenticated == false) {
                            
                        //     location.reload();
                        // } else {
                        
                        //     this.$cookies.set('apiToken', JSON.stringify(tokenObj), '/', null, true, 'Strict');
                        //     this.submitRefresh(res);
                        //     return res.data;
                        // }
                })
                .then((res) => {
                    console.log(res);
                    return res.data;
                })
                .catch(error => {
                    this.$cookies.remove('apiToken');
                    return this.getNewAPItoken();
                });
            } else if (apiToken === undefined) {
                if (apiToken.error == null || apiToken.error == undefined) {
                    // refresh token
                    console.log('try here')
                    // this.getRefereshToken(apiToken);
                }
            } else {
                if (apiToken.user.email === 'mobilepublic@mtllc.com') {
                    this.$cookies.remove('apiToken');
                    location.reload();
                } else {
                    // $('#forceLogout').trigger("click");
                    let button = document.getElementById('forceLogout');
                    button.click();
                }
            }
        },
        reloadThings() {
            console.log("we should reload");
            let thisWindow = window.location.href;
            window.location.href = thisWindow;
            window.location.reload();
            console.log("you going to reload this or what?");

        },
        getProtocol() {
            // hard-coded for HTTPS since it's mandatory for CMS, Site and API.
            return process.env.VUE_APP_APIPROTOCOL; //'https:'; //window.location.protocol;
        },
        nameNoSpecialFormat(targetInputID) {
            const nameInput = targetInputID;
            if (nameInput) {
                nameInput.addEventListener('input', (event) => {
                    const inputStripped = nameInput.value.replace(/[^a-zA-Z0-9 ]/, '');
                    nameInput.value = inputStripped;                    
                });
            }
            else {
                // console.log('unable to find name input ');
            }
        },
        phoneNumberFormat(targetInputID){
            const phoneInput = targetInputID; //document.querySelector('#phoneInput');
            const phoneFormat = '{0}{1}{2}-{3}{4}{5}-{6}{7}{8}{9}';
            if (phoneInput) {
                phoneInput.addEventListener('input', (event) => {
                    const inputStripped = phoneInput.value.replace(/\D/g, '');
                    const inputIsValid = !isNaN(parseInt(event.data));

                    if (event.inputType.includes('deleteContent')) {
                        /*
                        TODO Create input inequality when values are deleted
                        that are NOT at the end of the input
        
                        '(012) 34' -> '(012) 3' FINE
                        '(012) 34' -> '(01x) 34' INEQUALITY TO FIX
                        */
                        return;
                    }

                    if (event.inputType == 'insertText' && (inputStripped.length > 10 || !inputIsValid)) {
                        phoneInput.value = phoneInput.value.substring(0, phoneInput.value.length - 1);
                        return;
                    }

                    if (inputStripped)
                        phoneInput.value = formatPhoneInput(inputStripped);
                });

                const formatPhoneInput = (inputNumber) => {
                    let inputNumArr = inputNumber.split('');
                    let formatVar = inputNumArr.length - 1;

                    // indexOf() + 3, so we can replace the entire '{x}' variable in phoneFormat
                    let replaceIndex = phoneFormat.indexOf(`{${formatVar}}`) + 3;

                    // Autocompletion to next input value
                    switch (formatVar) {
                        case 2:
                            replaceIndex += 2;
                            break;
                        case 5:
                            replaceIndex += 1;
                            break;
                        default:
                            break;
                    }

                    let formattedInput = phoneFormat.substring(0, replaceIndex);

                    for (let i = 0; i < inputNumArr.length; i++) {
                        formattedInput = formattedInput.replace(`{${i}}`, inputNumArr[i]);
                    }

                    return formattedInput;
                }
            }
            else {
                // console.log('unable to find phone input ');
            }
            
        },
        pastedPhoneFormat(num) {
            let phone = num;

            if (phone !== null) {
                let area = phone.slice(0, 3),
                    county = phone.slice(3, 6),
                    local = phone.slice(6, phone.length),
                    formatted = area + '-' + county + '-' + local;

                return formatted;
            }
        },
        phoneNumberFormatModel(input){
            let phoneInput = input; //document.querySelector('#phoneInput');
            let phoneFormat = '{0}{1}{2}-{3}{4}{5}-{6}{7}{8}{9}';
            if (phoneInput) {
                // phoneInput.addEventListener('input', (event) => {
                    let inputStripped = phoneInput.replace(/\D/g, '');
                    let inputIsValid = !isNaN(parseInt(phoneInput));

                    let formatPhoneInput = (inputNumber) => {
                        let inputNumArr = inputNumber.split('');
                        let formatVar = inputNumArr.length - 1;
    
                        // indexOf() + 3, so we can replace the entire '{x}' variable in phoneFormat
                        let replaceIndex = phoneFormat.indexOf(`{${formatVar}}`) + 3;
    
                        // Autocompletion to next input value
                        switch (formatVar) {
                            case 2:
                                replaceIndex += 2;
                                break;
                            case 5:
                                replaceIndex += 1;
                                break;
                            default:
                                break;
                        }
    
                        let formattedInput = phoneFormat.substring(0, replaceIndex);
    
                        for (let i = 0; i < inputNumArr.length; i++) {
                            formattedInput = formattedInput.replace(`{${i}}`, inputNumArr[i]);
                        }
                        console.log(formattedInput)
                        return formattedInput;
                    }

                    if (inputStripped.length > 10 || !inputIsValid) {
                        phoneInput = phoneInput.substring(0, phoneInput.length - 1);
                        return 'Nothing';
                    }

                    if (inputStripped)
                        phoneInput = formatPhoneInput(inputStripped);
                    }
                    else {
                        console.log('unable to find phone input ');
                    }
        },
        dateValidation(dateVal) {
            const dateRegex = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;
            if (dateRegex.test(dateVal)) {
                return true;
            }
            else {
                return false;
            }
        },
        phoneValidation(phoneVal) {
            // test for phone format
            const phoneRegExA = /[A-z]/g; // any letter
            const phoneRegExN = /[^0-9,\-,(,),\s]/g;    // any non-number besides "( - )" and spaces
            let phoneTestAv = phoneRegExA.test(phoneVal);
            let phoneTestNv = phoneRegExN.test(phoneVal);
            if (phoneTestAv == true || phoneVal.length < 7) {
                return false;
            } else if (phoneTestNv == false) {
                return true;
            } else {
                return false;
            }
        },
        ssnValidation(ssnVal) {
            // Validate SSN
            const ssnRegex = new RegExp("^\\d{9}?$");
            if (ssnRegex.test(ssnVal)) {
                return true;
            } else {
                return false;
            }
        },
        cleanseImagePath(path) {
            let image = path;

            if (image.indexOf('~') === 0) {
                let dirtyPath = image.split('~'),
                    cleanPath = dirtyPath[1];

                return cleanPath;
            } else {
                return image;
            }
        },
        decimalFormat(decVal) {
            // Decimal "number"
            const decRegex = /^\d+(\.\d{1,2})?$/; // only numbers and possible point with up to 2 places
            const pointRegex = /^\d+\.\d{2}$/; // has ".xx" format?
            const placeRegex = /^\d+\.\d{1}$/; // has ".x" format?
            if (!decRegex.test(decVal)) {
                return false;
            } else {
                // test if has decimal & add if not
                if(placeRegex.test(decVal)) {
                    decVal = decVal + '0';
                } else if (!pointRegex.test(decVal)) {
                    decVal = decVal + '.00';
                }
                return decVal;
            }
        },
        accountNumberValidation(n) {
            // bank account must be a minimum of 8 characters, but only numbers
            // return !isNaN(parseFloat(n)) && isFinite(n);
            const accntRegex = /^\d+$/;
            if (accntRegex.test(n)) {
                if (n.length < 6) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
        routingNumberValidation(routingVal) {
            // const routeRegex = new RegExp("^\\d{8,}?$");
            console.log(routingVal)
            const routeRegex = new RegExp("[0-9]{9}");
            if (routeRegex.test(routingVal)) {
                return true;
            } else {
                return false;
            }
        },
        zipValidation(zipVal) {
            // Validate zip code
            const zipRegex = /^\d{5}?$/;
            if (zipRegex.test(zipVal)) {
                return true;
            } else {
                return false;
            }
        },
        zipPlusFourValidation(zipVal) {
            // Validate zip code
            const zipRegex = /^\d{4}?$/;
            if (zipRegex.test(zipVal)) {
                return true;
            } else {
                return false;
            }
        },
        textValidation(txtVal, noNumbers) {
            // noNumbers is booleon
            // test for text format
            const textRegExA = /[A-z]/g; // any letter?
            const textRegExN = /\d/g;    // any number?
            const textTestAv = textRegExA.test(txtVal);
            const textTestNv = textRegExN.test(txtVal);
            if (textTestAv == false) {
                return false;
            } else if (textTestNv == true && noNumbers) {
                return false;
            } else {
                return true;
            }
        },
        emailValidation(emailVal) {
            const emailRegExS = /\s/;
            const emailRegExa = /@/g;
            const emailTestS = emailRegExS.test(emailVal);
            const emailTesta = emailRegExa.test(emailVal);
            if (emailVal == undefined || emailVal.length < 5) {
                return false;
            } else if (emailTestS == true) {
                return false;
            } else if (emailTesta == false) {
                return false;
            } else {
                return true;
            }
        },
        passwordValidation(passwordVal) {
            // console.log(passwordVal)
            const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
            const passwordTest = passwordRegex.test(passwordVal);
            return passwordTest;
        },
        getUserPoints() {
            // maybe check user is not public first?
            let userData = this.$cookies.get('apiToken');
            let apiToken = userData.token;
            let userId = userData.user.userId;
            let url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetUserAvailableCredit?userId=' + userId;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + apiToken,
                },
            })
            .then(response => response.json())
            .then((json) => {
                let d = json;
                let points = 0;
                if (d.data !== null && d.data !== undefined) {
                    // catch for users created in CMS withour Registering
                    // Swagger will create the customer, but not before we get null
                    points = d.data.pointBalance.balance;
                }
                userData.user.pointBalance.balance = points;
                document.getElementById('currentBalance').innerHTML = points;

                this.$cookies.set('apiToken', JSON.stringify(userData));
            });
        },
        getLoggedInUserId() {
            let apiToken = this.$cookies.get('apiToken');
            // return token if already set
            if (apiToken !== null && apiToken !== undefined) {
                if (apiToken.token !== null) {
                    let tokenExpires = new Date(apiToken.expires)
                    let rightNow = new Date()
                    // console.log(rightNow + ' ?< ' + tokenExpires);

                    if (rightNow < tokenExpires) {
                        // console.log(apiToken.user.userId);
                        return apiToken.user.userId;
                        //return Promise.resolve(apiToken.user.userId);
                    } else {
                        // need to get a token or fallback response
                        // this.getRefereshToken(apiToken);
                        //return Promise.resolve(0);
                        return 0;
                    }
                }
            } 
            // return Promise.resolve(0);
            return 0;
        },
        getURLQuery(q) {
            let prop = q;

            let url = window.location.search.substring(1),
                urlVar = url.split('&'),
                param,
                i;

            for (i = 0; i < urlVar.length; i++) {
                param = urlVar[i].split('=');

                if (param[0] === prop) {
                    return param[0] === undefined ? true : param[1];
                }
            }

            return false;
        },
        getContentByKey(contentKey, contentID) {
            
            let sessionContent = sessionStorage.getItem(contentID);
            // console.log(sessionContent);

            if (sessionContent !== undefined && sessionContent !== "" && sessionContent !== null) {
                if (sessionContent.startsWith('"{')) {
                    // content is in JSON string format
                    sessionContent = JSON.parse(sessionContent);
                    sessionContent = JSON.parse(sessionContent);
                }
                return sessionContent;

            } else {
                return this.fetchContentFromAPI(contentKey, contentID);
            }
        },
        fetchContentFromAPI(contentKey, contentID) {
            return this.getAPItoken()
            .then((token) => {
                return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + '/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=' + contentKey, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                .then(response => response.json())
                .then(json => {
                    let element = json.data[0].centralContent;
                    
                    if (element !== undefined && element !== "" && element !== null) {

                        sessionStorage.setItem(contentID, JSON.stringify(element));
                        
                        if (element.startsWith('{')) {
                            // content is in JSON string format
                            element = JSON.parse(element);
                        } else {
                            element = JSON.stringify(element);
                            // element = element.toString();
                        }
                        // this isn't working for plain string content from API, only JSON
                        // console.log(element);
                        return Promise.resolve(element);
                    } else {
                        console.log('no content found for the key key: '+contentKey);
                        return Promise.resolve({});
                    }

                })
            });
        },
        getCookie(cookie) {
            return this.$cookies.get(cookie);
        },
        appendCookie(cName, keyObj, key, value) {
            console.log(keyObj)
            let cookie = $cookies.get(cName);
            if (keyObj) {
                cookie[keyObj][key] = value;
            } else {
                cookie[key] = value;
            }
            
            $cookies.set(cName, cookie);
        },
        sanitizeMessage: (string) => {
            const html = sanitizeHtml(string);

            return html;
        },
        sortDates(a, b) {
            
            if ( a.date > b.date ){
                return -1;
            }
            if ( a.date < b.date ){
                return 1;
            }
            return 0;
        },
        isUserInRole(role) {
            // must be sent the role "codename" to return true
            let theCookie = this.$cookies.get('apiToken'),
                userRoles = theCookie.user.listOfRoles,
                i;
                // console.log(userRoles);

            for (i = 0; i < userRoles.length; i++) {
                // console.log(userRoles[i],role);
                if (userRoles[i] === role) {
                    return true;
                }
            }
            return false;
        },
        xmlBusinessHours(text) {
            if (text === null) {
                return "Not Provided";
            } else {
                let parser = new DOMParser(),
                    xmlDoc = parser.parseFromString(text, "text/xml"),
                    hoursAttributes = xmlDoc.getElementsByTagName("businessHours")[0].childNodes[0];

                let hours = {
                    closed: hoursAttributes.getAttribute('closed'),
                    open24hours: hoursAttributes.getAttribute('open24hours'),
                    openHour: hoursAttributes.getAttribute('openHour'),
                    closeHour: hoursAttributes.getAttribute('closeHour'),

                };

                if (hours.closed == "True") {
                    return "Closed";
                } else if (hours.open24hours == "True") {
                    return "Open 24 Hours";
                } else if (hours.closed == "False" && hours.open24hours == "False") {
                    
                    hours.openHourHr = hours.openHour.slice(0,2);
                    hours.openHourMin = hours.openHour.slice(2,4);
                    hours.closeHourHr = hours.closeHour.slice(0,2);
                    hours.closeHourMin = hours.closeHour.slice(2,4);

                    hours.openHour = moment({hour: hours.openHourHr, minute: hours.openHourMin}).format("hh:mm");
                    hours.closeHour = moment({hour: hours.closeHourHr, minute: hours.closeHourMin}).format("hh:mm");
                    return hours.openHour + " - " + hours.closeHour;
                }
            }
        },
        getEappRole() {
            // returns an object with the codename and display name of role
            // if user has more than one role we return the first one found, sorry
            let theCookie = this.$cookies.get('apiToken'),
                userRoles = theCookie.user.listOfRoles,
                i;
            // console.log(userRoles);

            for (i = 0; i < userRoles.length; i++) {
                switch (userRoles[i]) {
                    case 'eAppDistrictRep':
                        return {'codename':'eAppDistrictRep','displayname':'Sales Representative'};
                    case 'eApp-RegionalManager':
                        return {'codename':'eApp-RegionalManager','displayname':'Regional Manager'};
                    case 'eApp-Security':
                        return {'codename':'eApp-Security','displayname':'Security'};
                    case 'eApp-RegionalSecurity':
                        return {'codename':'eApp-RegionalSecurity','displayname':'Regional Security'};
                    case 'eApp-CentralLicensingManager':
                        return {'codename':'eApp-CentralLicensingManager','displayname':'Licensing'};
                    case 'eApp-EFTReviewer':
                        return {'codename':'eApp-EFTReviewer','displayname':'EFT'};
                    case 'eApp-CentralSalesManager':
                        return {'codename':'eApp-CentralSalesManager','displayname':'Central Sales'};
                    case 'eApp-ChainSalesRep':
                        return {'codename':'eApp-ChainSalesRep','displayname':'Chain Sales Rep'};
                    case 'eApp-ChainManager':
                        return {'codename':'eApp-ChainManager','displayname':'Chain Manager'};
                    case 'eApp-ChainApplicant':
                        return {'codename':'eApp-ChainApplicant','displayname':'Chain Applicant'};
                    case 'eAppApplicant':
                        return {'codename':'eAppApplicant','displayname':'Applicant'};
                    case 'eApp-Retailer':
                        return {'codename':'eApp-Retailer','displayname':'Retailer'};
                    case 'eAppPrincipal':
                        return {'codename':'eAppPrincipal','displayname':'Principal'};
                    case 'eAppPrincipalUpdates':
                        return {'codename':'eAppPrincipalUpdates','displayname':'Principal Updates'};
                }
            }
            return false;
        },
        // Validates that the input string is a valid date formatted as "mm/dd/yyyy"
        isValidDate(dateString){
            // First check for the pattern
            if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
                return false;

            // Parse the date parts to integers
            var parts = dateString.split("/");
            var day = parseInt(parts[1], 10);
            var month = parseInt(parts[0], 10);
            var year = parseInt(parts[2], 10);

            // Check the ranges of month and year
            if (year < 1000 || year > 3000 || month == 0 || month > 12)
                return false;

            var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

            // Adjust for leap years
            if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
                monthLength[1] = 29;

            // Check the range of the day
            return day > 0 && day <= monthLength[month - 1];
        },
        getEappStatus(statusCode) {
            switch (statusCode) {
                case 1: return "Open"
                case 2: return "Submitted"
                case 3: return "Reviewed by Sales Rep"
                case 4: return "Reviewed by Regional Manager"
                case 5: return "Reviewed by Central Staff"
                case 6: return "Approved by Central Licensing Manager"
                case 7: return "Rejected to Regional Manager by Central Licensing Manager"
                case 8: return "Rejected to Applicant by Central Licensing Manager"
                case 9: return "Rejected by Central Sales Manager"
                case 10: return "Processed"
                case 11: return "Denied"
                case 12: return "Rejected to Chain Sales Manager by Central Licensing Manager"
                case 13: return "Rejected to Regional Manager by Central Sales Manager"
                case 14: return "Rejected to Central Licensing Manager by Central Sales Manager"
                default: return ""
            }
        },
        eAppLimitChars() {
            // placeholderfor now
			//event listener for fields, removing illegal characters
			// $('.eAppLimitChars').each(function(){
			// 	var field = $(this);
			// 	field.on("blur", function(){
			// 		var val = field.val();
			// 		console.log(val);
			// 		val = removeIllegalChars(/[^a-zA-Z0-9 \-&]/g, val);
			// 		console.log(val);
			// 		field.val(val);
			// 	});
			// });
        },
        tildeKiller(thisString) {
            // console.log(thisString);
            // return thisString = thisString.replace('src="~','src="');
            return thisString.split('src="~').join('src="');
        },
        mapSelect(d, id) {
            let countyCode = 0,
                select = document.getElementById(id),
                options = select.getElementsByTagName('option');

            for (let i = 0; i < options.length; i++) {
                let option = options[i],
                    text = option.innerHTML,
                    value = option.value;

                if (text === d) {
                    countyCode = value;
                    break;
                }
            }

            select.value = countyCode;

            return countyCode;
        },
        launchPersonaEmbed(email, callback) {
            //generic function to hit with userId, started with POC
            const client = new Persona.Client({
                templateId: "itmpl_X4q68YhFJXKGUgwZHHBubuXqEbXX",
                referenceId: email,
                // environmentId: "env_vsjtCWY7BGXyRHJhZ8bMcDuH1Y8R", //Sandbox
                // environmentId: "env_m3rAXC4BQpP9QmHnfKfvqjWa8RY2", // Production
                environmentId: "env_s37KeDFnWuS2REpZFJkT8oNfgs3k", // UAT
                onReady: () => client.open(),
                onComplete: ({ inquiryId, status, fields}) => {
                    //Inquiry completed. Optionally tell your server about it.
                    console.log(`Sending finished inquiry ${inquiryId} to backend`);
                    console.log("Status : " + status);
                    console.log("Fields: " + JSON.stringify(fields));

                    if(callback) {
                        callback(fields, inquiryId, status, email);
                    }
                },
                onCancel: ({ inquiryId, sessionToken }) => {
                    console.log("User cancelled");
                    console.log(inquiryId);
                    console.log(sessionToken);
                    //do something with inquiryId and sessionToken?
                    var cancelLocation = window.location.pathname;

                    if (cancelLocation.includes("register")) {
                        window.location.reload();
                    }

                },
                onError: (error) => console.log(error),
            });
        },
        launchPersonaEmbedApp(emailAddress) {
            //created for mobile App component (NOT CURRENTLY USED DUE TO WEBVIEW FLOW needed)x

            // https://inquiry.withpersona.com/verify?is-webview=true&environment-id=env_s37KeDFnWuS2REpZFJkT8oNfgs3k&template-id=itmpl_X4q68YhFJXKGUgwZHHBubuXqEbXX&reference-id=ewingm16888@gmail.com&redirect-uri=https://solutions-uat.ohiolottery.com/mylotto-rewards/my-account/verify-my-identity/verification-confirmation

            console.log('In launch function');
            const client = new Persona.Client({
                templateId: "itmpl_X4q68YhFJXKGUgwZHHBubuXqEbXX",
                referenceId: emailAddress,
                environmentId: "env_vsjtCWY7BGXyRHJhZ8bMcDuH1Y8R",
                onReady: () => client.open(),
                onComplete: ({ inquiryId, status, fields}) => {
                    //Inquiry completed. Optionally tell your server about it.
                    console.log(`Sending finished inquiry ${inquiryId} to backend`);
                    console.log("Status : " + status);
                    console.log("Fields: " + JSON.stringify(fields));
                },
                onCancel: ({ inquiryId, sessionToken }) => {
                    console.log("User cancelled");
                    console.log(inquiryId);
                    console.log(sessionToken);
                    //do something with inquiryId and sessionToken
                },
                onError: (error) => console.log(error),
            });
        },
        jqTooltip() {
            // HELP TEXT BUBBLES =======================
            $('.helpTxt').each(function(index, el) {
                var helpLbl = $(this),
                    helpTxt = helpLbl.attr('title');
                helpLbl.append('<span class="toolTip"><span class="toolTipIcon" title="'+helpTxt+'"></span><span class="toolTipContent">'+helpTxt+'</span></span>');
            });
            
            function accountTooltips() {
                var icon = $('.toolTipIcon'),
                    tooltip = $('.toolTipContent');

                icon.on('click', function(e) {
                    e.stopPropagation();
                });
            
                $( function(){
                    icon.on('click', function() {
                        tooltip.hide();
                        var tooltipContent = $(this).closest('.toolTip').find('.toolTipContent');
                        tooltipContent.toggle();
                    });
                    $('.toolTipContent').on('click', function(){
                        $(this).hide();
                    });
                });
            }
            accountTooltips();
        },
        filterSpecial(data) {
            let input = data;

            input.replaceAll(/“|”/g, '"');
            input.replaceAll(/‘|’/g, "'");
            input.replaceAll(/‘|’/g, "'");

            return input;
        },
        validateSpecial(data) {
            let inputs = data;
            let characters = ['Š','Ž','š','ž','Ÿ','À','Á','Â','Ã','Ä','Å','Ç','È','É','Ê','Ë','Ì','Í','Î','Ï','Ð','Ñ','Ò','Ó','Ô','Õ','Ö','Ù','Ú','Û','Ü','Ý','à','á','â','ã','ä','å','ç','è','é','ê','ë','ì','í','î','ï','ð','ñ','ò','ó','ô','õ','ö','ù','ú','û','ü','ý','ÿ','’','‘','`','Æ','æ'];
            let foundCharacters = [];

            for (let w = 0; w < inputs.length; w++) {
                for (let i = 0; i < characters.length; i++) {
                    if (inputs[w].value.indexOf(characters[i]) > -1) {
                        // if (foundCharacters.length > 0) {
                        //     foundCharacters = foundCharacters + ', ';
                        // }
                        // foundCharacters = foundCharacters + characters[i];
                        foundCharacters.push(characters[i]);
                    }
                }
            }
            
            return foundCharacters.length < 1 ? true : false;
        },
        ...mapActions ({
            setToken: 'tokenManagement/setToken'
        }),
        ...mapGetters ({
            getToken: 'tokenManagement/getToken'
        })
    },
    watch: {
        token: function () {
            this.returnToken();
        }
    }
}