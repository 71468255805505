<template>
    <section id="account-profile">
        <p v-if="!isUserLogged">
            Sign in to enter ticket numbers, see your profile and awards information, and redeem points. If you don’t have an account, create one for free by clicking the REGISTER button below.
        </p>
        <UserLoginVue v-if="!isUserLogged"></UserLoginVue>
        <form class="content_wrap" v-if="isUserLogged">
            <div class="modulePrpl pointsOverview">
                <div class="content">
                    <h2>
                        Points Overview
                    </h2>
                    <span class="current">Current balance: </span>
                    <span class="currentBalance">{{ points.user.pointBalance.balance.toLocaleString() }}</span>
                    <br>
                    <a href="/myLotto/My-Account#Transactions" class="history">View Points History</a>
                </div>
            </div>
            <div class="cf intro">
                <p>Click the UPDATE PROFILE button to save any changes to your profile.</p>
            </div>
            <div class="moduleContent cf userProfileFormWrap userRegFormWrap">
                <div class="my-profile-panel">
                    <div class="form-horizontal">
                        <div>
                            <input type="text" class="ErrorLabel" name="" value="There was an error submitting. Please see the fields below for details." :disabled="lockError" style="border:none;padding:20px;" v-if="error" ref="error">
                            <input type="text" class="InfoLabel" value="The changes were saved." style="border:none;padding:20px;" v-if="success" :disabled="successLock" ref="success">
                        </div>
                        <fieldset class="regForm clearFix" id="userProfileInfo">
                            <p class="reqNote">*Required field</p>
                            <h3>Contact Information</h3>
                            <div class="halfForm">
                                <label for="">Legal Name</label>
                                <div class="EditingFormControlNestedControl editing-form-control-nested-control">
                                    {{ firstName }} {{ lastName }}
                                </div>
                            </div>
                            <div class="halfForm halfFormRight">
                                <label for="">Date of birth</label>
                                <div class="EditingFormControlNestedControl editing-form-control-nested-control">
                                    <span v-if="!editBirthdate">
                                        {{ birthdate }}
                                    </span>
                                    <span v-if="editBirthdate">
                                        <input type="date" id="inputBirthdate" v-model="birthdate">
                                        <p v-if="errorBirthdate">Please enter a valid birthdate.</p>
                                    </span>
                                </div>
                            </div>
                            <div class="halfForm">
                                <label for="">Email address</label>
                                <div class="EditingFormControlNestedControl editing-form-control-nested-control">
                                    {{ email }}
                                </div>
                            </div>
                            <p class="lblNote">
                                If you would like to update your name, birthdate or email address, please contact the Ohio Lottery at <strong>1-800-686-4208</strong>
                            </p>
                        </fieldset>
                        
                        <fieldset class="regForm clearfix" id="userProfileForm">
                            <div class="halfForm">
                                <label for="">Preferred Name</label>
                                <div class="EditingFormControlNestedControl editing-form-control-nested-control">
                                    <input type="text" class="form-control" v-model="nickname">
                                </div>
                            </div>
                            <div class="halfForm halfFormRight nicknameInfo">
                                <span class="fieldInfo ">
                                    Go by a name other than your legal name? Great, enter it here and we'll greet you with it when you log in.
                                </span>
                            </div>
                            <div class="halfForm clear passwordSet">
                                <label for="">Password</label>
                                <input type="text" value="*********" :disabled="disabledPass">
                                <a href="/mylotto-rewards/my-account/my-profile/Change-Password" class="fieldLink">Change Password</a>
                            </div>
                            <div class="halfForm">
                                <label for="">Mailing Address<span class="req">*</span></label>
                                <input type="text" class="form-control regAddress" v-model="address1" required>
                                <span class="EditingFormErrorLabel" v-if="errorAddress">Please enter your Mailing Address.</span>
                            </div>
                            <div class="halfForm halfFormRight">
                                <label for="">Address (continued)</label>
                                <input type="text" class="form-control regAddress2 not_required" v-model="address2">
                            </div>
                            <div class="halfForm">
                                <label for="city">City<span class="req">*</span></label>
                                <input type="text" class="form-control regCity" v-model="city" required>
                                <span class="EditingFormErrorLabel" v-if="errorCity">Please enter your City.</span>
                            </div>
                            <div class="halfForm halfFormRight stateField">
                                <label for="state">
                                    State/Province
                                    <span class="req">*</span>
                                </label>
                                <select name="account-state" id="" class="DropDownField form-control regState" v-model="state" required>
                                    <option value="null">State</option>
                                    <option value="AL"> AL</option>
                                    <option value="AK"> AK</option>
                                    <option value="AZ"> AZ</option>
                                    <option value="AR"> AR</option>
                                    <option value="CA"> CA</option>
                                    <option value="CO"> CO</option>
                                    <option value="CT"> CT</option>
                                    <option value="DE"> DE</option>
                                    <option value="FL"> FL</option>
                                    <option value="GA"> GA</option>
                                    <option value="HI"> HI</option>
                                    <option value="ID"> ID</option>
                                    <option value="IL"> IL</option>
                                    <option value="IN"> IN</option>
                                    <option value="IA"> IA</option>
                                    <option value="KS"> KS</option>
                                    <option value="KY"> KY</option>
                                    <option value="LA"> LA</option>
                                    <option value="ME"> ME</option>
                                    <option value="MD"> MD</option>
                                    <option value="MA"> MA</option>
                                    <option value="MI"> MI</option>
                                    <option value="MN"> MN</option>
                                    <option value="MS"> MS</option>
                                    <option value="MO"> MO</option>
                                    <option value="MT"> MT</option>
                                    <option value="NE"> NE</option>
                                    <option value="NV"> NV</option>
                                    <option value="NH"> NH</option>
                                    <option value="NJ"> NJ</option>
                                    <option value="NM"> NM</option>
                                    <option value="NY"> NY</option>
                                    <option value="NC"> NC</option>
                                    <option value="ND"> ND</option>
                                    <option value="OH"> OH</option>
                                    <option value="OK"> OK</option>
                                    <option value="OR"> OR</option>
                                    <option value="PA"> PA</option>
                                    <option value="RI"> RI</option>
                                    <option value="SC"> SC</option>
                                    <option value="SD"> SD</option>
                                    <option value="TN"> TN</option>
                                    <option value="TX"> TX</option>
                                    <option value="UT"> UT</option>
                                    <option value="VT"> VT</option>
                                    <option value="VA"> VA</option>
                                    <option value="WA"> WA</option>
                                    <option value="DC"> DC</option>
                                    <option value="WV"> WV</option>
                                    <option value="WI"> WI</option>
                                    <option value="WY"> WY</option>
                                    <option value="">Province</option>
                                    <option value="AB"> AB</option>
                                    <option value="BC"> BC</option>
                                    <option value="MB"> MB</option>
                                    <option value="NB"> NB</option>
                                    <option value="NL"> NL</option>
                                    <option value="NS"> NS</option>
                                    <option value="NT"> NT</option>
                                    <option value="NU"> NU</option>
                                    <option value="ON"> ON</option>
                                    <option value="PE"> PE</option>
                                    <option value="QC"> QC</option>
                                    <option value="SK"> SK</option>
                                    <option value="YT"> YT</option>
                                </select>
                                <span class="EditingFormErrorLabel" v-if="errorState">Please choose your State.</span>
                            </div>
                            <div class="qtrForm">
                                <label for="">
                                    Zip/Postal Code
                                    <span class="req">*</span>
                                </label>
                                <input type="text" name="" value="" class="form-control regZipCode" maxlength="100" v-model="zip" required>
                                <span class="EditingFormErrorLabel" v-if="errorZip">Please enter your Zip Code.</span>
                            </div>
                            <div class="qtrForm">
                                <label for="">
                                    Country
                                    <span class="req">*</span>
                                </label>
                                <select name="" id="" class="DropDownField form-control regCountry" v-model="country" required>
                                    <option value="null">Country</option>
                                    <option value="USA">United States</option>
                                    <option value="CAN">Canada</option>
                                </select>
                                <span class="EditingFormErrorLabel" v-if="errorCountry">Please choose your Country.</span>
                            </div>
                            <div class="phoneEntry" v-if="!cashlessUser">
                                <div class="halfForm clear phoneWrap">
                                    <label for="">
                                        Primary phone number
                                        <span class="req">*</span>
                                    </label>
                                    <input type="tel" class="form-control regPhone" id="primaryPhone" max-length="200" v-model="primaryPhone" required>
                                    <span class="EditingFormErrorLabel" v-if="errorPhone">Please enter your primary phone number.</span>
                                </div>
                                <!-- <div class="halfForm halfFormRight phoneForTxt">
                                    <div class="EditingFormControlNestedControl editing-form-control-nested-control">
                                        <input type="checkbox" v-model="usePrimaryPhone">
                                    </div>
                                    <label for="" class="control-label editing-form-label">Use primary number for text message alerts:</label>
                                </div> -->
                                <!-- <div class="halfForm clear phoneWrap">
                                    <label for="">
                                        Alternate phone number
                                    </label>
                                    <input type="tel" max="200" class="form-control regAltPhone not_required" id="altPhone" v-model="altPhone">
                                </div> -->
                                <div class="halfForm halfFormRight phoneForTxt">
                                    <!-- <input type="checkbox" v-model="useAltPhone">
                                    <label for="" class="control-label editing-form-label">Use alternate number for text message alerts:</label> -->
                                    <div class="clear" style="font-size:1.2rem;line-height: 1.3em;margin-bottom:20px;">
                                        You’ll receive recurring messages. Message &amp; data rates may apply. Reply STOP to cancel, HELP for help. Visit Mobile Terms and Privacy Policy visit:
                                        <a href="https://tnc.mobi/91011" target="_blank">https://tnc.mobi/91011</a>
                                    </div>
                                </div>
                            </div>
                            <div class="phoneCashless" v-if="cashlessUser">
                                <div class="halfForm clear phoneWrap">
                                    <label for="">
                                        Primary phone number
                                        <span class="req">*</span>
                                    </label>
                                    <p>
                                        {{ primaryPhone }}
                                    </p>
                                    <p>Please follow this link (<a href="/mylotto-rewards/my-account/mobile-cashing-profile/edit-verified-phone">here</a>) to change your phone number.</p>

                                    <div class="clear" style="font-size:1.2rem;line-height: 1.3em;margin-bottom:20px;">
                                        You’ll receive recurring messages. Message &amp; data rates may apply. Reply STOP to cancel, HELP for help. Visit Mobile Terms and Privacy Policy visit:
                                        <a href="https://tnc.mobi/91011" target="_blank">https://tnc.mobi/91011</a>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <fieldset class="regForm regDisclaimers cf" id="regDisclaimers">
                    <h3>Terms of Use</h3>
                    <div class="disclaimerScroll cf" id="disclaimerScroll">
                        <div v-html="this.termsCash"></div>
                        <div v-html="this.termsText"></div>
                    </div>
                    <div class="fullForm discliamerBox cf">
                        <input type="checkbox" id="disclaimers" v-model="terms">
                        <label for="disclaimers" class="userRegCheckLbl">
                            I have read and agree to the above statements and Terms &amp; Conditions.
                            <span class="req">*</span>
                        </label>
                    </div>
                    <div class="formButtons">
                        <HollowDotsSpinner v-if="loadingOverlay" color="#0FD8C9" class="account-profile-spinner" />
                        <button id="profileSubmit" type="button" class="button blue-btn" @click="validate" :disabled="!terms" v-if="!loadingOverlay">
                            Update Profile
                        </button>
                        <a href="#" class="cancelProfileChanges" v-if="!loadingOverlay">
                            Cancel Changes
                        </a>
                    </div>
                </fieldset>
            </div>
        </form>
        <USPS :mailingAddress="address1" :addressContinued="address2" :city="city" :state="state" :zipcode="zip" :country="country" ref="uspsValidate"></USPS>
    </section>
</template>

<script>
    import $ from 'jquery';
    import moment from "moment";
    import UserLoginVue from '../../ViewComponents/SignInRegister/SignInRegister.vue';
    import USPS from "../Shared/USPS-AddressCheck.vue";
    import { HollowDotsSpinner } from 'epic-spinners';

    export default {
        components: {
            USPS,
            UserLoginVue,
            HollowDotsSpinner
        },
        data: function () {
            return {
                isLoading: true,
                loadingOverlay: false,
                isUserLogged: '',
                points: this.$cookies.get('apiToken'),
                user: '',
                disabledPass: true,
                email: '',
                birthdate: '',
                editBirthdate: false,
                cashlessUser: false,
                nickname: '',
                username: '',
                firstName: '',
                lastName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                primaryPhone: '',
                altPhone: '',
                usePrimaryPhone: true,
                useAltPhone: false,
                terms: false,
                validated: false,
                errorBirthdate: false,
                errorAddress: false,
                errorCity: false,
                errorState: false,
                errorZip: false,
                errorCountry: false,
                errorPhone: false,
                errorTerms: false,
                lockError: false,
                error: false,
                success: false,
                successLock: false,
                termsText: '',
                termsCash: '',
                mainCurrency: '',
                listOfRoles: []
            }
        },
        mounted: function () {
            this.getToken();
            $(document).foundation();
            
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.isUserLogged = this.isUserAuthed();
                    console.log(this.isUserLogged)
                    if (this.isUserLogged === true) {
                        this.getUser();
                        this.getUserPoints();
                        this.getTerms();
                        this.getCash();
                        
                    }
                });
            },
            formatPhoneInput(inputNumber) {
                    const phoneFormat = '{0}{1}{2}-{3}{4}{5}-{6}{7}{8}{9}';
                    let inputNumArr = inputNumber.split('');
                    let formatVar = inputNumArr.length - 1;

                    // indexOf() + 3, so we can replace the entire '{x}' variable in phoneFormat
                    let replaceIndex = phoneFormat.indexOf(`{${formatVar}}`) + 3;

                    // Autocompletion to next input value
                    switch (formatVar) {
                        case 2:
                            replaceIndex += 2;
                            break;
                        case 5:
                            replaceIndex += 1;
                            break;
                        default:
                            break;
                    }

                    let formattedInput = phoneFormat.substring(0, replaceIndex);

                    for (let i = 0; i < inputNumArr.length; i++) {
                        formattedInput = formattedInput.replace(`{${i}}`, inputNumArr[i]);
                    }

                    return formattedInput;
            },
            getTerms() {
                let url = process.env.VUE_APP_APIURL + '/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=mlr-terms-conditions',
                    apiToken = this.token;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + apiToken
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    this.termsText = json.data[0].centralContent;
                    if (!this.cashlessUser)  {
                        this.phoneNumberFormat(document.querySelector('#primaryPhone'));
                        // this.phoneNumberFormat(document.querySelector('#altPhone'));
                    } else {
                        console.log(this.user)
                    }

                })
            },
            getCash() {
                let url = process.env.VUE_APP_APIURL + '/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=cash-explosion-disclaimer',
                    apiToken = this.token;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + apiToken
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    this.termsCash = json.data[0].centralContent;
                })
            },
            getUser() {
                let url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetUser/' + this.points.user.userId,
                    apiToken = this.token;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + apiToken
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    let data = json.data;

                    // data.userDto.birthdate = null;
                    // data.listOfRoles.push('CashlessUser');

                    if (data.userDto.birthdate === null) {
                        this.editBirthdate = true;
                    } else {
                        data.userDto.birthdate = moment(data.userDto.birthdate).format('MM/DD/YYYY');
                    }

                    for (let i = 0; i < data.listOfRoles.length; i++) {
                        if (data.listOfRoles[i] === 'CashlessUser') {
                            this.cashlessUser = true;
                        } 
                    }

                    this.user = data;
                    this.address1 = data.address.address;
                    this.address2 = data.address.address2;
                    this.city = data.address.city;
                    this.state = data.address.state;
                    this.zip = data.address.zipCode;
                    this.country = data.address.country;
                    this.email = data.email;
                    this.firstName = data.firstName;
                    this.lastName = data.lastName;
                    this.username = data.userDto.username;
                    this.nickname = data.nickname;
                    this.mainCurrency = data.mainCurrency;
                    this.listOfRoles = data.listOfRoles;
                    this.birthdate = data.userDto.birthdate;
                    // this.primaryPhone = this.formatPhoneInput(data.userDto.phoneNumber);

                    if (data.userDto.phoneNumber.includes("-")) {
                        this.primaryPhone = data.userDto.phoneNumber;
                    } else {
                        this.primaryPhone = this.formatPhoneInput(data.userDto.phoneNumber);
                    }
                    // this.altPhone = this.formatPhoneInput(data.userDto.altPhone);
                    // this.usePrimaryPhone = data.userDto.primaryPhoneForTxt;
                    // this.useAltPhone = data.userDto.altPhoneForTxt;

                    this.isLoading = false;
                })
            },
            validate() {
                this.loadingOverlay = true;
                this.error = false;
                this.lockError = false;
                this.success = false;
                this.successLock = false;

                let birthdate = this.birthdate,
                    address1 = this.address1,
                    city = this.city,
                    state = this.state,
                    zip = this.zip,
                    country = this.country,
                    phone = this.primaryPhone;

                if (
                    birthdate.length < 1 ||
                    address1.length < 1 ||
                    city.length < 1 ||
                    state < 1 ||
                    zip.length < 1 ||
                    country < 1 ||
                    phone.length < 1
                ) {
                    this.loadingOverlay = false;
                    this.error = true;
                    this.$nextTick(() => {
                        this.$refs.error.focus();
                        this.lockError = true;
                    });
                } else {
                    this.error = false;
                    this.lockError = false;
                    // this.isLoading = false;
                    this.$refs.uspsValidate.validate();
                }

                birthdate.length < 1 ? this.errorBirthdate = true : this.errorBirthdate = false;
                address1.length < 1 ? this.errorAddress = true : this.errorAddress = false;
                city.length < 1 ? this.errorCity = true : this.errorCity = false;
                state < 1 ? this.errorState = true : this.errorState = false;
                zip.length < 1 ? this.errorZip = true : this.errorZip = false;
                country < 1 ? this.errorCountry = true : this.errorCountry = false;
                phone.length < 1 ? this.errorPhone = true : this.errorPhone = false;
            },
            // *************** MAILING ADDREES
            uspsVerified(msg) {
                console.log(msg)
                this.uspsClose();
                // this.success = true;
                this.$nextTick(() => {
                    // this.$refs.success.focus();
                    // this.successLock = true;
                    this.update();
                })
            },
            uspsOpen() {
                this.loadingOverlay = false;
                $('#USPSmodal').foundation('open');
            },
            uspsClose() {
                $('#USPSmodal').foundation('close');
                // this.scrollToAnchor('section-mailing-address');
            },
            update() {
                let url = process.env.VUE_APP_APIURL + '/1.0/PAM/UpdateProfile/',
                    apiToken = this.token;

                let body = {
                    "userId": this.user.userId,
                    "email": this.email,
                    "address": {
                        "address": this.address1,
                        "address2": this.address2,
                        "city": this.city,
                        "state": this.state,
                        "zipCode": this.zip
                    },
                    "user": {
                        "firstName": this.firstName,
                        "lastName": this.lastName,
                        "email": this.email,
                        "userId": this.user.userId,
                        "nickname": this.nickname,
                        "mainCurrency": this.mainCurrency,
                        "listOfRoles": this.listOfRoles,
                        "address": {
                            "address1": this.address1,
                            "address2": this.address2,
                            "city": this.city,
                            "state": this.state,
                            "zip": this.zip
                        },
                        "phoneNumber": this.primaryPhone,
                        "altPhone": this.altPhone,
                        "termsAcceptance": this.terms,
                        "primaryPhoneForTxt": this.usePrimaryPhone,
                        "altPhoneForTxt": this.useAltPhone
                    },
                    "validateAddress": true
                }

                console.log(body)

                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + apiToken
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json);
                    this.getUser();
                    this.success = true;
                    this.$nextTick(() => {
                        this.$refs.success.focus();
                        this.successLock = true;
                        this.loadingOverlay = false;
                    });
                    
                });
            }
		}
    };
</script>