<template>
    <!-- <div class="EdjucationInput">
        TESTS
     <button class="button" @click="getString">Test URL</button> 
    </div> -->
</template>


<script>

import $ from 'jquery';

export default {
    data() {
        return {
            string: 'fromEmail',
            input: 'fromEmail_Value'
        };
    },

    mounted() {
        // console.log(showBullets);
        this.getString();
        this.disableState();
    },
    methods: {
        getString() {
            let string = this.getURLQuery(this.string),
                input = document.querySelector('[id*=' + this.input + ']');            

            if (string != false) {
                input.value = string;
            }
        },
        disableState(){
            let fields = document.querySelectorAll(".editing-form-control-nested-control input")
            for (let f = 0; f < fields.length; f++){
                if (fields[f].placeholder == "State"){
                    fields[f].disabled = true;
                };
            }
        }
    }
}
</script>