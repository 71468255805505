// This file is where we will gather all Vue "loader" modules, 
// each controlling the initialization of an app, 
// and call their load() function (aliased to avoid name conflicts) 
// within the loadComponents() function.

// list loader modules
// Home 
// import { load as loadToken } from "./Shared/token-loader";
import { load as loadStatic } from "./Shared/static-loader";
import { load as loadTokenTimeout } from "./Shared/token-timeout-loader";
import { load as loadHomeNumbers } from "./Home/home-numbers-loader";
import { load as loadHome } from "./Home/home-loader";

// Menu
import { load as loadContentChildrenMenu } from "./Menu/content-children-menu-loader";
import { load as loadSecondaryMenu } from "./Menu/secondary-menu-loader";
import { load as loadLeftNavMenu } from "./Menu/left-nav-menu-loader";
import { load as loadMobileMenu } from "./Menu/mobile-menu-loader";
import { load as loadSiteMenu } from "./Menu/site-menu-loader";
import { load as loadFooter } from "./Shared/footer-loader";
import { load as loadWinningNumbersMenu } from "./Menu/winningNumbers-menu-loader";
import { load as loadWinningNumbersMenuMobile } from "./Menu/winningNumbers-menu-mobile-loader";
// import { load as loadHeader } from "./Shared/header-loader";

// Shared
import { load as loadUserMessage } from "./Shared/user-message-loader";
import { load as loadUserLogin } from "./Shared/user-login-loader";
import { load as loadTerms } from "./Account/terms-and-conditions-loader";
import { load as loadWinner } from "./Account/instant-winner-loader";

// Games
import { load as loadPick3WN } from "./Games/Pick3/pick3-winningnumbers-loader";
import { load as loadPick4WN } from "./Games/Pick4/pick4-winningnumbers-loader";
import { load as loadPick5WN } from "./Games/Pick5/pick5-winningnumbers-loader";
import { load as loadRolling5WN } from "./Games/Rolling5/Rolling5-winningnumbers-loader";
import { load as loadPowerBallWN } from "./Games/PowerBall/PowerBall-winningnumbers-loader";
import { load as loadMegaWN } from "./Games/MegaMillions/MegaMillions-winningnumbers-loader";
import { load as loadClassicWN } from "./Games/ClassicLotto/Classic-winningnumbers-loader";
import { load as loadLuckyWN } from "./Games/LuckForLife/Lucky-winningnumbers-loader";
import { load as loadEasyPlay } from "./Games/EasyPlay/EasyPlay-landing-loader";
import { load as loadEasyPlayList } from "./Games/EasyPlay/EasyPlay-List-loader";
import { load as loadScratchOffsList } from "./Games/ScratchOffs/scratchoffs-list-loader";
import { load as loadScratchOffsGame } from "./Games/ScratchOffs/scratchoffs-game-loader";
import { load as loadScratchOffsSingleGame } from "./Games/ScratchOffs/scratchoffs-single-game-loader";
import { load as loadScratchOffsPrice } from "./Games/ScratchOffs/scratchoffs-price-loader";
import { load as loadScratchOffsLastDay } from "./Games/ScratchOffs/scratchoffs-last-day-loader";
import { load as LoadDrawDetail } from "./Games/Draw-Details/draw-detail-loader";
import { load as loadProgressiveJackpotHome } from "./Games/ProgressiveJackpot/progressive-jackpot-home-loader";
import { load as loadProgressiveJackpotLanding } from "./Games/ProgressiveJackpot/progressive-jackpot-landing-loader";
import { load as loadProgressiveJackpotValue } from "./Games/ProgressiveJackpot/ProgressiveJackpotDetail/progressive-jackpot-value-loader"
import { load as loadProgressiveJackpotRecentWinners } from "./Games/ProgressiveJackpot/ProgressiveJackpotDetail/progressive-jackpot-recent-winners-loader";
import { load as loadScratchOffsCarousel } from "./Games/PromoCarousel/scratchoff-carousel-loader";
import { load as loadKenoHotCold } from "./Games/KENO/keno-hot-cold-loader";
import { load as LoadCashExplosionLanding} from "./Games/CashExplosionShow/CashExplosionEntry/cash-explosion-landing-loader";
import { load as LoadCashExplosionEntry} from "./Games/CashExplosionShow/CashExplosionEntry/cash-explosion-entry-loader";
import { load as LoadKenoWinningNumbers } from "./Games/KENO/keno-winningnumbers-loader";
import { load as LoadLuckyOneWinningNumbers} from "./Games/TheLuckyOne/luckyone-winningnumbers-loader";
import { load as LoadWnAnnualExport} from "./WinningNumbers/NumbersAnnualArchive/winning-annual-export-loading";

// Draw Numbers
import { load as LoadDrawingLanding } from "./DrawNumbers/drawing-landing-loader";
import { load as LoadDrawingWinners } from "./DrawNumbers/drawing-winners-loader";

// Winning Numbers
import { load as LoadWinningLanding } from "./WinningNumbers/winning-landing-loader";
import { load as loadWNArchivePicker } from "./WinningNumbers/winning-numbers-archive-picker-loader";
import { load as loadWNLandingArchive  } from "./WinningNumbers/winning-landing-archive-loader";
import { load as loadKENODrawings } from "./WinningNumbers/KENO/keno-drawings-loader";
import { load as LoadKenoArchives} from "./WinningNumbers/KENO/keno-archives-loader";
import { load as loadDrawing  } from "./WinningNumbers/drawing-loader";
import { load as loadRandomizer } from "./WinningNumbers/randomizer-loader";
import { load as LoadCheckYourNumbers } from "./WinningNumbers/check-your-numbers-loader";
import { load as LoadCheckYourNumbersTabs } from "./WinningNumbers/check-your-numbers-tabs-loader";
import { load as LoadHighPayout} from "./WinningNumbers/HighPayout/high-payout-loader";
import { load as LoadLuckyOneDrawings } from "./WinningNumbers/LuckyOneDrawings/lucky-one-drawings-loader";
import { load as LoadLuckyOneArchives} from "./WinningNumbers/LuckyOneDrawings/luckyone-archives-loader";
// import { load as LoadNumbersArchiveModlue} from "./../ViewComponents/WinningNumbers/CheckNumbersPanels/numbers-archive-module-loader";

// Find retailer
import { load as LoadFindRetailer } from "./FindRetailer/find-retailer-loader";

// Survey
import { load as LoadSurveyWizard} from "./Survey/survey-wizard-loader";
import { load as LoadClubMemberSurvey} from "./Survey/club-member-survey-loader";

// Rewards
import { load as LoadRewardsGreeting } from "./Rewards/rewards-greeting-loader";
import { load as LoadRewardsGreetingNoTicket } from "./Rewards/rewards-greeting-no-ticket-loader";
import { load as LoadRewardsGreetingDynamic } from "./Rewards/rewards-greeting-dynamic-loader";
import { load as LoadSecondChanceLanding} from "./Rewards/SecondChance/second-chance--landing-loader";
import { load as LoadSecondChanceDetail} from "./Rewards/SecondChance/second-chance--detail-loader";
import { load as LoadGamesForPrizesDetail} from "./Rewards/GamesForPrizes/games-for-prizes--detail-loader";
import { load as LoadGamesForPrizesList } from "./Rewards/GamesForPrizes/games-for-prizes--list-loader";
import { load as LoadNewLottoRewardsHeader} from "./Rewards/NewMyLottoRewards/new-lotto-rewards-header-loader";
import { load as LoadNewLottoRewards} from "./Rewards/NewMyLottoRewards/new-lotto-rewards-loader";
import { load as LoadCatalogList} from "./Rewards/Catalog/catalog-list-loader";
import { load as LoadDigitalCatalogList} from "./Rewards/DigitalCatalog/digital-catalog-list-loader";
import { load as LoadDrawingsCatalogList} from "./Rewards/Drawings/drawingscatalog-list-loader";
import { load as LoadCouponsList} from "./Rewards/Coupons/coupons-list-loader";
import { load as LoadProductDetail} from "./Rewards/product-loader";
import { load as LoadProductDetailDrawings} from "./Rewards/product-detail-drawings-loader";
import { load as LoadProductDetailDigital} from "./Rewards/product-detail-digital-loader";
import { load as LoadEntriesList} from "./Rewards/DrawingEntries/drawing-entires-loader";
import { load as LoadRedemptionCentral} from "./Rewards/redemption-central-loader";
import { load as LoadStockAlert} from "./Rewards/stock-alert-loader";
import { load as LoadAdditionalRewards} from "./Rewards/additional-rewards-loader";
import { load as LoadAdditionalRewardsLanding} from "./Rewards/additional-rewards-landing-loader";
import { load as LoadAboutTheProgram} from "./Rewards/about-the-program-loader";
import {load as OLC_FunTurnsFiftyEvent} from "./Rewards/SecondChance/funturnsfiftyevent-loader";

//Custom Promos
import {load as OLC_GGROBAR} from "./Rewards/SocialPromos/olc-ggrobar-promo-loader";
import {load as OLC_Eclipse} from "./Rewards/SocialPromos/olc-eclipse-promo-loader";
import {load as StatueSearch} from "./Rewards/StateFair/statue-search-loader";
import {load as StatueSearchMenu} from "./Rewards/StateFair/statue-search-menu-loader";

//Birthday Offer
import {load as BirthdayOptIn} from "./Rewards/Birthday/olc-birthday-optin-loader";

// 8Bit
import { load as LoadEightBitatBat } from "./8Bit/atBat/eight-bit-at-bat-loader";

// Register
import { load as LoadRegister} from "./Register/register-loader";
import { load as loadSignInRegister} from "./../ViewComponents/SignInRegister/sign-in-register-loader";  

// Edit Profile
import { load as LoadEditProfile } from "./EditProfile/edit-profile-loader";

// About
import { load as LoadEducationInput } from "./Shared/educationforminput-loader";

// Account
import { load as LoadAccountProfile } from "./Account/account-profile-loader";
import { load as LoadChangePassword } from "./Account/change-password-loader";
import { load as LoadRetrievePassword } from "./Account/retrieve-password-loader";
import { load as LoadNotification} from "./Account/notification-settings-loader";
import { load as LoadDeleteAccount } from "./Account/delete-account-loader";
import { load as LoadConfirmDeleteAccount } from "./Account/confirm-delete-account-loader";
import { load as LoadTransactionLog } from "./Account/transaction-log-loader";
import { load as LoadPhysicalPurchases } from "./Account/physical-purchases-loader";
import { load as LoadPhysicalPurchasesDetail } from "./Account/physical-purchases-detail-loader";
import { load as LoadCashCoupons } from "./Account/cash-coupons-loader";
import { load as LoadCashCouponsDetail } from "./Account/cash-coupons-detail-loader";
import { load as LoadDigitalPurchases } from "./Account/digital-purchases-loader";
import { load as LoadDigitalPurchasesDetail } from "./Account/digital-purchases-detail-loader";
import { load as LoadMobileCashing } from "./Account/mobile-cashing-loader";
import { load as LoadVerifiedPhone } from "./Account/verified-phone-loader";
import { load as LoadAccountHeader } from "./Account/myaccount-header-loader";
import {load as LoadVerifyIdentity} from "./Account/verify-identity-loader";
import {load as LoadVerifyIdentityConfirmation} from "./Account/verify-identity-confirmation-loader";
import {load as LoadPersonaEmbed} from "./Account/persona-embed-loader";

// Ticker Checker & Ticket Entry
import { load as LoadTicketChecker} from "./TicketChecker/ticket-checker-loader";
import { load as LoadTicketEntry} from "./TicketEntry/ticket-entry-loader";
import { load as LoadPromotionCodeEntry} from "./TicketEntry/promotion-code-entry-loader";

// View Components
import { load as LoadBannerCarousel } from "./../ViewComponents/BannerCarousel/banner-carousel-loader";
import { load as LoadBlog } from "./../ViewComponents/Blog/blog-loader";
import { load as LoadCheckNumbersHome } from "./../ViewComponents/CheckNumbersHome/check-numbers-loader";
import { load as LoadSearchBox } from "./../ViewComponents/SearchBox/search-box-loader";
import { load as LoadPromotionLandingPage } from "./../ViewComponents/PromotionLandingPage/promotion-landing-page-loader";

//Eapplications
import { load as LoadMyApplications } from "./../ViewComponents/Eapplications/Listings/my-applications-loader";
import { load as LoadApplicationGrid } from "./Eapplication/dashboard/application-grid-loader";
import { load as LoadGridPaginate } from "./Pagination/pagination-loader";
import { load as LoadEapplicationForm } from "./Eapplication/eapplication-form-loader";
import { load as LoadDashboardsRegular } from "./Eapplication/dashboard/dashboard-regular-loader";
import { load as LoadMyApplicationsStatus } from "./../ViewComponents/Eapplications/Listings/my-applications-status-loader";
import { load as LoadRetailerRegistrationForm } from "./Eapplication/retailer-registration-form-loader";
import { load as LoadRetailerChainRegistrationForm } from "./Eapplication/retailer-chain-registration-form-loader";
//Eapplication Business
import { load as LoadBusinessLanding } from "./Eapplication/business/sections/business-landing-loader";
import { load as LoadBusinessSection } from "./Eapplication/business/business-section-loader";
import { load as LoadBusinessName } from "./Eapplication/business/sections/business-name-loader";
import { load as LoadBusinessAddress } from "./Eapplication/business/sections/business-address-loader";
import { load as LoadBusinessHours } from "./Eapplication/business/sections/business-hours-loader";
import { load as LoadSaleOfBusiness } from "./Eapplication/business/sections/business-sale-loader";
import { load as LoadSaleOfBusinessAgreement } from "./Eapplication/business/sections/business-sale-agreement-loader";
import { load as LoadContactInformation } from './Eapplication/business/sections/contact/contact-loader';
//Eapplication Principals
import { load as LoadPrincipalsList } from './Eapplication/business/sections/principals/principals-list-loader';
import { load as LoadBackgroundCheck } from './Eapplication/background/background-check-loader';
import { load as LoadPrincipalAuthorization } from './Eapplication/background/principal-authorization-loader';
import { load as LoadPrincipalAddEdit } from './Eapplication/business/sections/principals/principal-add-edit-loader';
import { load as LoadPrincipalRegistration } from './Eapplication/principal-registration-loader';
//Eapplication EFT
import { load as LoadEft } from './Eapplication/eft/electronic-funds-loader';
import { load as LoadEftEdit } from './Eapplication/eft/electronic-funds-edit-loader';
//Eapplication Tax
import { load as LoadTax } from './Eapplication/tax/tax-loader';
import { load as LoadTaxEdit1 } from './Eapplication/tax/tax-edit-1-loader';
import { load as LoadTaxEdit2 } from './Eapplication/tax/tax-edit-2-loader';
//Eapplication Review
import { load as LoadReview } from './Eapplication/review/review-loader';
//Eapplication Chains
import { load as LoadChainDashboard } from './Eapplication/dashboard/dashboard-chains-loader';
import { load as LoadChainAssignments } from "./Eapplication/dashboard/chain-assignments-loader";
import { load as LoadChainLocations } from './Eapplication/dashboard/chain-location-loader';

//Eapplication Updates & Renewals
import { load as LoadUpdatesRenewalsLanding } from "./Eapplication/updates/updates-renewals-landing-loader";
import { load as LoadLicenseDetail } from './Eapplication/updates/license-detail-loader';
import { load as LoadLicenseEdit } from './Eapplication/updates/license-edit-loader';
import { load as LoadBusinessUpdateReview } from './Eapplication/updates/review/business-review-loader';
import { load as LoadRenewalUpdateReview } from './Eapplication/updates/review/renewal-review-loader';
import { load as LoadTaxUpdateReview } from './Eapplication/updates/review/tax-review-loader';
import { load as LoadEftUpdateReview } from './Eapplication/updates/review/eft-review-loader';
import { load as LoadPrincipalUpdateReview } from './Eapplication/updates/review/principal-review-loader';
import { load as LoadUpdateBackgroundCheck } from './Eapplication/updates/update-background-check-loader';


// Featured Winners
import { load as LoadFeaturedWinners} from "./FeaturedWinners/featured-winners-loader";

// Press Releases
import { load as LoadPressReleases} from "./PressReleases/press-releases-loader";

// For Retailers
import { load as LoadFeaturedRetailer} from "./FeaturedRetailer/FeaturedRetailer/featured-retailer-loader";
import { load as LoadRetailerResources} from "./Retailers/RetailerResources/retailer-resources-loader";
import { load as LoadRetailerCentral} from "./Retailers/retailer-central-loader";

// Poll Question
import { load as LoadPollQuestion } from "./PollQuestion/poll-question-loader";

// Cash Explosion
import { load as LoadFutureContestants} from "./CashExplosion/Contestants/future-contestants-loader";
import { load as LoadAnnouncementSchedule} from "./CashExplosion/Contestants/announcement-schedule-loader";
import { load as LoadWeekContestants} from "./CashExplosion/Contestants/week-contestants-loader";
import { load as LoadWatchShow} from "./CashExplosion/Watch-the-show/watch-show-loader";

import { load as LoadScratchOffsPrizesRemaining } from "./Games/ScratchOffs/scratchOffs-prizes-remaining-loader";

import { load as LoadOnlineClaimForm} from "./Claiming/online-claim-form-loader";
import { load as LoadRulesAndRegulations} from "./Claiming/RulesAndRegulations/rulesandregulations-loader";
import { load as LoadCashOptionValues} from "./Claiming/CashOptionValues/cash-option-values-loader";

// Winners
import { load as LoadShareStory} from "./Winners/ShareStoryHeader/share-story-loader";
import { load as LoadWinnersStories} from "./Winners/WinnersStories/winners-stories-loader";
import { load as LoadShareStoriesForm} from "./Winners/ShareStoryForm/share-stories-form-loader";
import { load as LoadWinnersStoriesDetail} from "./Winners/WinnersStories/winners-stories-detail-loader";

// Claiming
import { load as LoadArchivedGameRules } from "./Claiming/RulesAndRegulations/archived-game-rules-loader";


// Survey Widget
import { load as LoadSurveyWidget } from "./SurveyWidget/survey-widget-loader";


// list loader calls
export function loadComponents(store) {
    // loadToken(store);
    
    // Global //////////////////
    loadStatic(store);
    loadTokenTimeout();
    // loadHeader();
    loadContentChildrenMenu(store);
    loadSecondaryMenu(store);
    loadLeftNavMenu(store);
    loadUserMessage(store);
    loadMobileMenu(store);
    loadSiteMenu(store); 
    loadFooter(store);
    LoadSearchBox();

    LoadApplicationGrid();
    LoadMyApplications();
    LoadGridPaginate();

    // Home
    loadHomeNumbers();
    loadHome(store);
    // Games Lists
    loadScratchOffsList(store);
    loadScratchOffsGame(store);
    loadScratchOffsSingleGame(store);
    loadScratchOffsPrice(store);
    loadScratchOffsLastDay(store)
    loadScratchOffsCarousel();
    LoadScratchOffsPrizesRemaining();
    loadKenoHotCold();

    // Winning Numbers //////////////////
    loadWinningNumbersMenu(store);
    loadWinningNumbersMenuMobile(store);
    loadPick3WN();
    loadPick4WN();
    loadPick5WN();
    loadRolling5WN();
    loadPowerBallWN();
    loadMegaWN();
    loadClassicWN();
    loadLuckyWN();
    loadEasyPlay();
    loadEasyPlayList(store);
    LoadDrawDetail();
    LoadDrawingLanding(store);
    LoadDrawingWinners();
    LoadWinningLanding(store);
    loadWNArchivePicker();
    loadWNLandingArchive();
    loadKENODrawings();
    loadDrawing();
    loadProgressiveJackpotHome();
    loadProgressiveJackpotLanding();
    loadProgressiveJackpotValue();
    loadProgressiveJackpotRecentWinners();
    loadRandomizer();
    LoadCheckYourNumbers(store);
    LoadCheckYourNumbersTabs(store);
    LoadHighPayout();
    LoadLuckyOneDrawings();
    LoadKenoWinningNumbers();
    LoadLuckyOneWinningNumbers();
    LoadWnAnnualExport();

    // Account
    LoadAccountProfile();
    loadUserLogin(store);
    loadTerms();
    loadWinner();
    LoadRegister();
    LoadChangePassword();
    LoadRetrievePassword();
    loadSignInRegister();
    LoadDeleteAccount();
    LoadConfirmDeleteAccount();
    LoadTransactionLog();
    LoadPhysicalPurchases();
    LoadPhysicalPurchasesDetail();
    LoadCashCoupons();
    LoadCashCouponsDetail();
    LoadDigitalPurchases();
    LoadDigitalPurchasesDetail();
    LoadMobileCashing();
    LoadVerifiedPhone();
    LoadAccountHeader();
    LoadVerifyIdentity();
    LoadVerifyIdentityConfirmation();
    LoadPersonaEmbed();

    // Retailer
    LoadFindRetailer();
    LoadRetailerResources();
    LoadRetailerCentral();
    // LoadFeaturedRetailerCalendar();

    LoadSurveyWizard(store);
    LoadClubMemberSurvey();
    // LoadFeaturedRetailerDetail();
    LoadSecondChanceLanding();
    LoadSecondChanceDetail();
    // LoadGamesForPrizesListing();
    
    // Rewards
    LoadCouponsList();
    LoadPollQuestion();
    LoadRewardsGreeting();
    LoadRewardsGreetingNoTicket();
    LoadRewardsGreetingDynamic();
    LoadNotification();
    LoadTicketChecker();
    LoadTicketEntry();
    LoadPromotionCodeEntry();
    LoadEditProfile();
    LoadEducationInput();
    LoadBannerCarousel();
    LoadGamesForPrizesDetail();
    LoadGamesForPrizesList();
    LoadFeaturedWinners();
    LoadCheckNumbersHome();
    LoadKenoArchives();
    LoadLuckyOneArchives();
    // LoadNumbersArchiveModlue();
    LoadPressReleases();
    LoadFeaturedRetailer();
    LoadNewLottoRewardsHeader();
    LoadNewLottoRewards();
    LoadCashExplosionLanding();
    LoadCashExplosionEntry();
    LoadProductDetail();
    LoadProductDetailDrawings();
    LoadProductDetailDigital();
    LoadEntriesList();
    LoadRedemptionCentral();
    LoadCatalogList();
    LoadDigitalCatalogList();
    LoadDrawingsCatalogList();
    LoadStockAlert();
    LoadAdditionalRewards();
    LoadAdditionalRewardsLanding();
    LoadAboutTheProgram();
    OLC_GGROBAR();
    OLC_Eclipse();
    OLC_FunTurnsFiftyEvent();
    StatueSearch();
    StatueSearchMenu();
    BirthdayOptIn();
    
    // 8Bit
    LoadEightBitatBat();

    //Eapplications
    LoadApplicationGrid();
    LoadMyApplications();
    LoadGridPaginate();
    LoadDashboardsRegular();
    LoadEapplicationForm();
    LoadMyApplicationsStatus();
    //Eapplications Business
    LoadBusinessLanding();
    LoadBusinessSection();
    LoadBusinessName();
    LoadBusinessAddress();
    LoadBusinessHours();
    LoadSaleOfBusiness();
    LoadSaleOfBusinessAgreement();
    LoadContactInformation();
    //Eapplications Principals
    LoadPrincipalsList();
    LoadPrincipalAddEdit();
    LoadBackgroundCheck();
    LoadPrincipalAuthorization();
    LoadPrincipalRegistration();
    //Eapplication EFT
    LoadEft();
    LoadEftEdit();
    //Eapplication Tax
    LoadTax();
    LoadTaxEdit1();
    LoadTaxEdit2();
    //Review
    LoadReview();
    //Updates & Renewals
    LoadUpdatesRenewalsLanding();
    LoadLicenseDetail();
    LoadLicenseEdit();
    LoadBusinessUpdateReview();
    LoadRenewalUpdateReview();
    LoadTaxUpdateReview();
    LoadEftUpdateReview();
    LoadPrincipalUpdateReview();
    LoadUpdateBackgroundCheck();
    //eApp Registration
    LoadRetailerRegistrationForm();
    LoadRetailerChainRegistrationForm();
    //EapplicationChains
    LoadChainDashboard();
    LoadChainAssignments();
    LoadChainLocations();


    // Cash Explosion
    LoadFutureContestants();
    LoadAnnouncementSchedule();
    LoadWeekContestants();

    // Claiming
    LoadOnlineClaimForm();
    LoadRulesAndRegulations();
    LoadCashOptionValues();
    LoadArchivedGameRules();

    LoadBlog();
    LoadWatchShow();

    // Winners 
    LoadShareStory();
    LoadWinnersStories();
    LoadShareStoriesForm();
    LoadWinnersStoriesDetail();

    // Promotion Code
    LoadPromotionCodeEntry();
// Promotion Landing Page
    LoadPromotionLandingPage();


    LoadSurveyWidget();

}
