<template>
    <div class="eftWContent cf ">
        <h3>Add Checking Account</h3>

        <div class="alert-error ErrorLabel" style="display: none;">The entered values cannot be saved. Please see the fields below for details.</div>

        <div class=" cf eAppFormEdit eapp_w9information grid-x grid-margin-x grid-margin-y">
            <div class="formRow cell large-6">
                <span class="req">*</span>
                <label for="BankName_txtText">Bank Name:</label>
                <input type="text" maxlength="200" :disabled="isDisabled" v-model="eft.dfiName" id="BankName_txtText" autocomplete="off" aria-describedby="BankName_error">
                <div class="error" role="alert" v-if="!dfiNameValid" id="BankName_error">Please enter a bank name above.</div>
            </div>
            <div class="formRow cell large-6">
                <span class="req">*</span>
                <label for="AccountName_txtText">Account Name:</label>
                <input name="AccountName_txtText" :disabled="isDisabled" v-model="eft.eftAccountName" type="text" maxlength="100" id="AccountName_txtText" autocomplete="off" aria-describedby="AccountName_error">
                <div class="explanation-text">(Enter the name listed on the bank account - NOT the type of account)</div>
                <div class="error" role="alert" v-if="!accountNameValid" id="AccountName_error">Please enter an account name.</div>
            </div>
            <div class="formRow cell large-6">
                <span class="req">*</span>
                <label for="AccountNumber_txtText">Checking Account Number:</label>
                <input name="AccountNumber_txtText" :disabled="isDisabled" v-model="eft.checkingAccountNumber" type="text" maxlength="30" id="AccountNumber_txtText" autocomplete="off" aria-describedby="AccountNumber_error">
                <div class="error" role="alert" v-if="!checkingAccountValid" id="AccountNumber_error">Please enter a valid checking account number.</div>
            </div>
            <div class="formRow cell large-6">
                <span class="req">*</span>
                <label for="AccountNumberConfirm_txtText">Re-enter Account Number:</label>
                <input name="AccountNumberConfirm_txtText" :disabled="isDisabled" v-model="eft.checkingAccountNumberVerification" type="text" maxlength="30" id="AccountNumberConfirm_txtText" onpaste="return false;" autocomplete="off" aria-describedby="AccountNumberConfirm_error">
                <div class="error" role="alert" v-if="!checkingAccountConfirmValid" id="AccountNumberConfirm_error">Please re-enter an account number.</div>
                <div class="error" role="alert" v-if="!checkingAccountConfirmMatch" id="AccountNumberMatch_error">Please make sure that the checking account numbers match.</div>
            </div>
            <div class="formRow cell large-6">
                <span class="req">*</span>
                <label for="RoutingNumber_txtText">Bank Routing Number:</label>
                <input name="RoutingNumber_txtText" :disabled="isDisabled" v-model="eft.checkingAccountRoutingNumber" type="text" maxlength="9" id="RoutingNumber_txtText" autocomplete="off" aria-describedby="RoutingNumber_error">
                <div class="error" role="alert" v-if="!checkingAccountRoutingValid" id="RoutingNumber_error">Please enter a valid bank routing number.</div>
            </div>
            <div class="formRow cell large-6">
                <span class="req">*</span>
                <label id="RoutingNumberConfirm_lb" for="RoutingNumberConfirm_txtText">Re-enter Bank Routing Number:</label>
                <input name="RoutingNumberConfirm_txtText" onpaste="return false;" :disabled="isDisabled" v-model="eft.checkingAccountRoutingNumberVerification" type="text" maxlength="9" id="RoutingNumberConfirm_txtText" autocomplete="off" aria-describedby="RoutingNumberConfirm_error">
                <div class="error" role="alert" v-if="!checkingAccountRoutingConfirmValid" id="RoutingNumberConfirm_error">Please re-enter a bank routing number.</div>
                <div class="error" role="alert" v-if="!checkingAccountRoutingConfirmMatch" id="RoutingNumberMatch_error">The routing numbers do not match.</div>
            </div>
            <div class="formRow dividerRow txtCenter cell">
                <img src="/getattachment/6ef3edb7-7890-4603-92ba-91cf4942ec1c/Check-Image.jpg" class="eftCheckImg img-responsive">
            </div>
            <div class="formRow checkboxRow cell">
                <input id="ConfirmCheckingAccount" :disabled="isDisabled" v-model="confirm" type="checkbox" @click="confirmClick()" name="ConfirmCheckingAccount" aria-describedby="Confirm_error">
                <label for="ConfirmCheckingAccount">
                    <span class="req">*</span> I confirm that the account listed above is a checking account.
                </label>
                <div class="error checkError" role="alert" v-if="!confirmCheckingAccountValid" id="Confirm_error">Please confirm your checking account.</div>
            </div>
            <div class="formRow checkboxRow cell">
                <input id="AuthorizeCheckingAccount" :disabled="isDisabled" v-model="auth" type="checkbox" @click="authClick()" name="AuthorizeCheckingAccount" aria-describedby="Authorize_error">
                <label for="AuthorizeCheckingAccount">
                    <span class="req">*</span> I authorize the Ohio Lottery Commission to make Automatic Clearing House (ACH) withdrawals or deposits each week into the account identified above. I also authorize the named Depository Financial Institution to charge such withdrawals or deposits to my listed account.
                </label>
                <div class="error checkError" role="alert" v-if="!dfiAuthorizationValid" id="Authorize_error">Please authorize OLC to make ACH withdrawals or deposits.</div>
            </div>
            <div class="formRow checkboxRow cell">
                <input id="AcknowledgeCheckingAccount" :disabled="isDisabled" v-model="ack" @click="ackClick()" type="checkbox" name="AcknowledgeCheckingAccount" aria-describedby="Acknowledge_error">
                <label for="AcknowledgeCheckingAccount">
                    <span class="req">*</span> I acknowledge my digital signature will be added to this document at time of submission
                </label>
                <div class="error checkError" role="alert" v-if="!ackValid" id="Acknowledge_error">Please acknowledge your digital signature addition.</div>
            </div>
        </div>

        <span class="error" v-if="specialError === true">Please provide standard English text characters only.</span>

        <div class="btn-actions">
            <button type="button" @click="saveBack()" class=" btn-primary btnBack" title="Save the document and return to previous page">Save &amp; Back</button>
            <button type="button" @click="saveNext()" class=" btn-primary btnNext" title="Save the document and proceed to next page">Save &amp; Next</button>
        </div>


    </div>
</template>


<script>

    export default {
        components: {},
        props: {
            eftJson: String,
            readOnly: Boolean
        },
        data: function () {
            return {
                eft: {},
                hasEft: false,
                dfiNameValid: true,
                accountNameValid: true,
                checkingAccountValid: true,
                checkingAccountConfirmValid: true,
                checkingAccountConfirmMatch: true,
                checkingAccountRoutingValid: true,
                checkingAccountRoutingConfirmValid: true,
                checkingAccountRoutingConfirmMatch: true,
                confirmCheckingAccountValid: true,
                dfiAuthorizationValid: true,
                ackValid: true,
                ack: false,
                auth: false,
                confirm: false,
                isDisabled: false,
                specialError: false
            };
        },
        mounted: function () {
            this.deserializeJson();
            this.isDisabled = this.readOnly;
        },
        methods: {
            deserializeJson() {
                this.eft = JSON.parse(this.eftJson);
                this.hasEft = this.eftJson !== '{}';
                // console.log(this.eft.confirmCheckingAccount);
                this.confirm = this.eft.confirmCheckingAccount;
                // alert('is checked: '+this.eft.confirmCheckingAccount);
                this.ack = this.eft.signatureAcknowledgement;
                this.auth = this.eft.dfIauthorization;
            },
            ackClick() {
                this.ack = !this.ack;
                if (this.ack) {
                    this.eft.signatureAcknowledgement = 1;
                }
                else {
                    this.eft.signatureAcknowledgement = 0;
                }
            },
            authClick() {
                this.auth = !this.auth;
                if (this.auth) {
                    this.eft.dfIauthorization = 1;
                }
                else {
                    this.eft.dfIauthorization = 0;
                }
            },
            confirmClick() {
                this.confirm = !this.confirm;
                if (this.confirm) {
                    this.eft.confirmCheckingAccount = 1;
                }
                else {
                    this.eft.confirmCheckingAccount = 0;
                }
            },
            saveNext() {
                if (this.validate()) {
                    this.$emit('onEftSaveClick', { eft: this.eft, dir: 'next' });
                    // to electronic-funds 
                }
            },
            saveBack() {
                if (this.validate()) {
                    this.$emit('onEftSaveClick', { eft: this.eft, dir: 'back' });
                    // to electronic-funds 
                }
            },
            validate() {
                if (!this.isDisabled) {
                    this.dfiNameValid = this.eft.dfiName;
                    this.accountNameValid = this.eft.eftAccountName;
                    this.checkingAccountValid = this.eft.checkingAccountNumber && this.accountNumberValidation(this.eft.checkingAccountNumber);
                    this.checkingAccountConfirmValid = this.eft.checkingAccountNumberVerification && this.accountNumberValidation(this.eft.checkingAccountNumberVerification);
                    this.checkingAccountConfirmMatch = this.eft.checkingAccountNumber == this.eft.checkingAccountNumberVerification;
                    // console.log('checkin account match')
                    // console.log(this.checkingAccountConfirmMatch);
                    this.checkingAccountRoutingValid = this.eft.checkingAccountRoutingNumber && this.routingNumberValidation(this.eft.checkingAccountRoutingNumber);
                    this.checkingAccountRoutingConfirmValid = this.eft.checkingAccountRoutingNumberVerification;
                    this.checkingAccountRoutingConfirmMatch = this.eft.checkingAccountRoutingNumber == this.eft.checkingAccountRoutingNumberVerification;

                    // console.log('routing match')
                    // console.log(this.checkingAccountRoutingConfirmMatch);
                    this.confirmCheckingAccountValid = this.eft.confirmCheckingAccount == 1;
                    this.dfiAuthorizationValid = this.eft.dfIauthorization == 1;
                    this.ackValid = this.eft.signatureAcknowledgement == 1;

                    if (this.validateSpecial($('input[type=text]')) === false) {
                        this.specialError = true;
                        error = true;
                    } else {
                        this.specialError = false;
                    }

                    if (this.dfiNameValid
                        && this.accountNameValid
                        && this.checkingAccountValid
                        && this.checkingAccountConfirmValid
                        && this.checkingAccountConfirmMatch
                        && this.checkingAccountRoutingValid
                        && this.checkingAccountRoutingConfirmValid
                        && this.checkingAccountRoutingConfirmMatch
                        && this.confirmCheckingAccountValid
                        && this.dfiAuthorizationValid
                        && this.ackValid
                        && this.specialError === false) {
                        return true;
                    }
                    return false;
                } else {
                    return true;
                }
            }
        },
        computed: {}
    };
</script>
