<template>
    <section class="content contentRow" v-if="!loading">
        <h3 class="insightName">
            {{ product.orderItems[0].name }}
        </h3>
        <span class="insightPurch">
            Purchased On: {{ product.orderDate }}
        </span>
        <p class="insightId">
            Order Id: {{ product.orderID }}
        </p>
        <img :src="product.orderItems[0].imagePath" alt="" class="img-responsive insightDetailImg">
        <div class="insightDesc">
            <h4>Product Information:</h4>
            <span v-html="productDetail.description"></span>
        </div>
        <div class="insightS">
            <h4>Status Information:</h4>
            Order Status: <span v-if="product.statusName">{{ product.statusName }}</span><span v-if="!product.statusName">Not yet available</span>
        </div>
        <div>
            Recipient: <span>{{ product.shippingAddress.personalName }}</span><span v-if="!product.shippingAddress.personalName">Not yet available</span>
        </div>
        <div v-if="product.fulfillmentInfo">
            Received: <span>{{ product.fulfillmentInfo.physicalFulfillmentInfo.receiptTime }}</span><span v-if="!product.fulfillmentInfo.physicalFulfillmentInfo.receiptTime">Not yet available</span>
        </div>
        <div v-if="product.fulfillmentInfo">
            Carrier: <span>{{ product.fulfillmentInfo.physicalFulfillmentInfo.carrier }}</span><span v-if="!product.fulfillmentInfo.physicalFulfillmentInfo.carrier">Not yet available</span>
        </div>
        <div v-if="product.fulfillmentInfo">
            Tracking Number: <span>{{ product.fulfillmentInfo.physicalFulfillmentInfo.trackingNumber }}</span><span v-if="!product.fulfillmentInfo.physicalFulfillmentInfo.trackingNumber">Not yet available</span>
        </div>
        <div class="insightLastUpdated" v-if="product.fulfillmentInfo">
            Last Updated: <span>{{ product.fulfillmentInfo.physicalFulfillmentInfo.lastUpdate }}</span><span v-if="!product.fulfillmentInfo.physicalFulfillmentInfo.lastUpdate">Not yet available</span>
        </div>
        <h3 v-if="error">{{ errorMessage }}</h3>
    </section>
</template>

<script>
    // import $ from 'jquery';
    import moment from "moment";
    import Pagination from '../Pagination/pagination.vue';
    import UserLoginVue from '../Shared/User-Login.vue';

    const modal = {
        mounted() {}
    }

    export default {
        // directives: {
        //     modal
        // },
        components: {
           Pagination,
           UserLoginVue
        },
        data: function () {
            return {
                isUserLogged: this.isUserAuthed(),
                token: '',
                user: '',
                product: '',
                productDetail: '',
                loading: true,
                errorMessage: '',
                error: true
            }
        },
        mounted: function () {
            this.getToken();

            this.startDate = moment().startOf('month').format('yyyy-MM-DD');
            this.endDate = moment().format('yyyy-MM-DD');

            console.log(this.startDate)
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.user = this.$cookies.get('apiToken');
                    this.getTLog();
                });
            },
            getTLog() {
                let apiToken = this.token,
                    url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetUserOrderDetail?userId=' + this.user.user.userId + '&orderId=' + this.getURLQuery('id');

                return fetch(url, {
                        method: 'GET',
                        // body: JSON.stringify(datCreds),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    let data = json.data;

                    console.log(data)

                    if(data.orderItems[0].imagePath.length > 0) {
                        if (data.orderItems[0].imagePath[0].includes('~')) {
                            let dataSplit = data.orderItems[0].imagePath.split('~');

                            data.orderItems[0].imagePath = dataSplit[1];
                        }
                    }
                    
                    // if (data.fulfillmentInfo) {
                    //     if (data.fulfillmentInfo.physicalFulfillmentInfo.receiptTime) {
                    //         let date = moment(data.fulfillmentInfo.physicalFulfillmentInfo.receiptTime).format("MM/DD/yyyy");

                    //         data.orderDate = date;
                    //     }
                    // }

                    data.orderDate = moment(data.orderDate).format("MM/DD/yyyy");
                    
                    this.product = data;
                    console.log(this.product)
                    this.error = false;
                    this.getProductDetail();
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    this.error = true;
                    return (this.errorMessage = error);
                });
            },
            getProductDetail() {
                console.log('woo hoo')
                let apiToken = this.token,
                    url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetProductDetail?productId=' + this.product.orderItems[0].skuid;

                return fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + apiToken
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    if (json.data !== null) {
                        console.log(json.data)
                        this.error = false;
                        this.productDetail = json.data;
                        this.loading = false;
                    } else if (json.data === null && json.error !== null) {
                        // this.error = true;
                        this.productDetail = {
                            description: "No product information available."
                        }
                        // this.errorMessage = json.error.message;
                        this.loading = false;
                    }
                    
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    this.error = true;
                    this.errorMessage = error;
                });
            },
            setPages() {
                this.itemsPerPage = 5;
                this.totalPages = Math.ceil(this.tLog.length / this.itemsPerPage);
            },
            changePage(p) {
                this.currentPage = p;
                this.setItems();
            },
            setItems() {
                let start = (this.itemsPerPage * this.currentPage) - this.itemsPerPage,
                    end = (this.itemsPerPage * this.currentPage),
                    currentItems = this.tLog.slice(start, end);

                this.currentItems = currentItems;
            },
            setStartDate(t) {
                let target = t;

                this.startDate = moment(target.target.value).format('yyyy-MM-DD');
            },
            setEndDate(t) {
                let target = t;

                this.endDate = moment(target.target.value).format('yyyy-MM-DD');
            }
		}
    };
</script>