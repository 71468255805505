<template>
    <div>
        <section v-if="products.length" class="catalogList">
            <span>
                <span v-for="product in products" :key="product.skuNumber">
                    <div class="productItem cf">
                        <a :href="product.nodeAliasPath" class="productImg" :style="'backgroundImage: url(' + product.image + ';'">
                            <!-- <img :src="product.image" alt="product.description"> -->
                        </a>
                        <article class="productInfo">
                            <a :href="product.nodeAliasPath" class="productTitle">
                                {{product.name}}
                            </a>
                            <span class="productPrice">
                                {{product.price}} Points
                            </span>
                        </article>
                    </div>
                </span>
                <br/>
                <AdditionalRewardsVue></AdditionalRewardsVue>
            </span>
        </section>
        <section v-if="!products.length" class="noProductsAvailable">
             We re-stock up to 3,000 new Lottery Cash and Coupons at random times every day—they do sell out quickly, so check in often to get them while they're hot!
        </section>
        <span class="note red">* Point costs are subject to change</span>
    </div>
</template>

<script>
    import AdditionalRewardsVue from '../AdditionalRewards.vue';

    export default {
        components: [
            AdditionalRewardsVue
        ],
        data: function() {
            return {
                token: '',
                products: [],
                games: [
                    {
                        name: 1,
                        id: 2
                    }
                ]
            }
        },
        mounted: function() {
            this.getToken()
        },
        methods: {
            getToken() {
                // console.log('getting token')
                this.getAPItoken()
                .then((token) => {
                    // console.log('got token')
                    this.getProducts(token);
                });
            },
            getProducts(token) {
                console.log("Getting Products")
                let apiToken = token,
                    url = process.env.VUE_APP_APIURL+'/1.0/Ecommerce/GetProductList?type=7&top=100';
                    // req = '{"type": 4, "offset": 0, "top": 10, "categoryId": 0}';

                return fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + apiToken
                    }
                    // body: JSON.stringify(req)
                })
                .then(response => response.json())
                .then(json => {
                    this.setStock(json.data);
                })
                .catch(error => {
                    console.log(this.errorMessage = error);
                })
            },
            setStock(json) {
                let data = json,
                    products = [];

                for (let i = 0; i < data.length; i++) {
                    data[i].stock > 0 ? products.push(data[i]) : false;
                }

                this.products = products;
            }
        }
    }
</script>