<template>
    <div class="full">
        <SignInRegister v-if="!isUserLogged"></SignInRegister>
        <div class="verifyIdActions" v-if="isUserLogged && !kyc">
            <div><a :href="verifyLink" class="button btn-primary verifyIdBtn" target="_blank">VERIFY MY ID NOW</a></div>
            <div>
                <h3>Send the verification link to your smartphone</h3>
                <p>The ID verification process is easier to complete on a smartphone. Use one of the options below to send the verification link to your mobile device.</p>
            </div>
            <div>
                <!-- <a href="#" class="button btn-tertiary" @click="initiateLinkSend('email')">Send Link via Email</a> -->
                <div class="emailFieldID">
                    <div class="userCreds">Send the verification link to the email address associated with my account: <br><strong>{{ userEmail }}</strong></div>
                    <!-- <input type="text" v-model="userEmail" placeholder="Email Address" disabled> -->
                    <!-- <span>{{ userEmail }}</span> -->
                    <button class="button btn-tertiary vIdBtn" @click="confirmLinkSend(true,verifyLink)">Send Email</button>
                </div>
            </div>
            <div>
                <!-- <a href="#" class="button btn-tertiary" @click="initiateLinkSend('sms')">Send Link via Text Message</a> -->
                <div class="phoneFieldID">
                    <div class="userCreds">Send the verification link to the mobile number associated with my account: <br><strong>{{ pastedPhoneFormat(userPhone) }}</strong></div>
                    <!-- <input type="text" v-model="userPhone" placeholder="Phone Number" disabled> -->
                    <button class="button btn-tertiary vIdBtn" @click="confirmLinkSend(false,verifyLink)">Send Text</button>
                </div>
            </div>
            <div v-if="attemptReasons"></div>
            <div v-if="successfulSend" class="linkSent">Your link has been sent.</div>
            <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
            <!-- <div v-if="attemptReasons.length > 0" class="module_callOut">
                <h3>Errors from your previous verification attempt.</h3>
                <p>Please visit your <a href="/mylotto-rewards/my-account/my-profile" target="_blank">MyLotto Rewards Profile</a> to correct the errors listed below before attempting to verify your ID again.</p>
                <ul>
                    <li v-for="reason in attemptReasons" class="error">{{ reason }}</li>
                </ul>
            </div> -->
        </div>
        <div v-if="kyc" class="alreadyIdverified module_callOut">You have been verified.</div>
    </div>
</template>
<script>
    import SignInRegister from '../../ViewComponents/SignInRegister/SignInRegister.vue';
    export default {
        components: {
            SignInRegister
        },
        data: function() {
            return {
                isUserLogged: false,
                token: {},
                user: {},
                id: '',
                kyc: true, //always true
                userEmail: '',
                userPhone: '',
                sendObj: {},
                verifyLink: '',
                sendType: '',
                showEmailField: false,
                showPhoneField: false,
                successfulSend: false,
                errorMessage: '',
                attemptReasons: []
            }
        },
        mounted: function() {
            this.isUserLogged = this.isUserAuthed();
            this.getToken();
        },
        // computed: {
        //     successfulSend() {
        //         return this.successfulSend;
        //     }
        // },
        methods: {
            getToken() {
                this.getAPItoken()
                    .then((token) => {
                        this.token = token;
                        // console.log(this.token);

                        if (this.isUserLogged) {
                            this.user = this.$cookies.get('apiToken');
                            this.id = this.user.user.userId;
                            // this.kyc = this.user.user.KYCVerified;
                            this.userPhone = this.user.user.phoneNumber;
                            this.userEmail = this.user.user.email;
                            // console.log(this.user);
                            // console.log(`KYC : ${this.kyc}`);
                            // console.log(this.userPhone);
                            // console.log(this.userEmail);
                        }
                    })
                    .then(() => {
                        this.getKYCVerified();
                    })
            },
            //post method to url with 0 or 1
            initiateLinkSend(type) {
                
                switch(type) {
                    case 'email':
                        console.log(type);
                        this.sendType = true;
                        this.showEmailField = true;
                    break;
                    case 'sms':
                        console.log(type);
                        this.sendType = false;
                        this.showPhoneField = true;
                    break;
                }

                // this.confirmLinkSend(this.sendType, this.verifyLink);
            },
            confirmLinkSend(sendType, linkUrl) {
                this.successfulSend = false;
                let url = 'https://' + window.location.hostname + '/KYC/NotifyKYCUser';

                console.log(url);

                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    },
                    body: JSON.stringify({ "URL":linkUrl, "Email":sendType})
                })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json);
                    let resp = json;
                    if (resp.error === false) {
                        console.log("No Error");
                        this.setSuccessfulSend();
                    } else {
                        this.errorMessage = resp.message;
                    }
                })
                .catch((error) => {
                    // return(this.errorMessage = error)
                    console.log(error);

                })
                
            },
            getKYCVerified() {
                let url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetUserKYCStatus/?userId=' + this.id;
                
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json);
                    console.log(json.data);
                    //always true
                    // this.kyc = json.data.userIsVerified;
                    this.verifyLink = json.data.kycVerificationLink;

                    if (json.data.errorMessage !== '') {
                        this.displayIDErrors(json.data.errorMessage);
                    }

                    // this.kyc = true;
                    console.log(`KYC : ${this.kyc}`);
                    console.log(`Link URL : ${this.verifyLink}`);
                })
                .catch((error) => {
                    // return (this.errorMessage = error)
                    console.log(error);
                })
                 
            },
            setSuccessfulSend() {
                this.errorMessage = '';
                this.successfulSend = true;
            },
            displayIDErrors(errors) {
                console.log(errors);
                let errorArr = errors.split(',');
                this.attemptReasons = errorArr;
            }
        },
    }
</script>
<style lang="scss" scoped>
    .verifyIdActions a {
        letter-spacing: 0.3px;
        font-size: 1.4rem;
        font-weight: 400;
    }
    .alreadyIdverified {
        font-weight: 700;
    }
    .emailFieldID, .phoneFieldID {
        margin-bottom: 1em;

        > div {
            margin-right: 0.8em;
        }

        .userCreds {
            margin-bottom: 0.8em;
        }

        button {
            margin-bottom: 0;
        }
    }
    .linkSent {
        color: green;
        margin-bottom: 1.5rem;
    }
    li.error {
        margin-bottom: 0;
    }

    .button.btn-tertiary.vIdBtn {
        background-color: #5161ac;
    }

    // @media only screen and (min-width: 641px) {
    //     .emailFieldID, .phoneFieldID {
    //         display: flex;
    //         align-items: center;
    //     }
    // }
</style>