import { render, staticRenderFns } from "./ScratchOffs-SingleGame.vue?vue&type=template&id=5fb1cb49&scoped=true"
import script from "./ScratchOffs-SingleGame.vue?vue&type=script&lang=js"
export * from "./ScratchOffs-SingleGame.vue?vue&type=script&lang=js"
import style0 from "./ScratchOffs-SingleGame.vue?vue&type=style&index=0&id=5fb1cb49&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb1cb49",
  null
  
)

export default component.exports