import Vue from "vue";

import OLC_holidayMultiplierProgress from "./OLC_holidayMultiplierProgress.vue";

export function load() {
    const selector = "holiday-multiplier-progress";

  // Is the custom Vue root element in the DOM?
  if (!document.querySelector(selector)) {
    return;
  }

  // Create a new Vue app with the imported Home component
  new Vue({
    render: createElement =>
      createElement(OLC_holidayMultiplierProgress, {
        props: {
          ...window[selector],
        },
      }),
  }).$mount(selector);
}
