<template>
    <div class="winner-stories-grid">

        <div class="storyListHeader" id="UserStoryListHeader">
            <div class="contentRow txtCenter">
                <h1 style="margin-top:0.5em; margin-bottom: 0.5em;">YOUR STORIES</h1>
                <span class="storyTotal" style="font-size:1.6rem;position:unset;">{{ items.length }} TOTAL STORIES</span>
            </div>
        </div>
            
        <masonry-wall :items="itemsLoaded" :ssr-columns="1" :column-width="300" :gap="30">
                <template #default="{ item, index }">
                    <div class="storyGridItemInner" :class="`storyGridItemInner-`+ index"  v-on:click="openModalItem($event)" :data-guid="item.winnerStoriesGuid">
                       
                        <img class="storyGridImg notClickSupport" :src="item.photoPathUrl" :alt="item.storyTitle" v-if="!item.videoUrl" />
                        <iframe class="storyGridVid" :src="item.videoUrl" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen="" width="290" height="164" v-if="item.videoUrl"></iframe>

                        <div class="storyGridShareLink">
                            Share using this link: 
                            <a :href="`/mylotto-rewards/winners/story-detail?guid=${item.winnerStoriesGuid}`">
                                {{ currentUrl }}/mylotto-rewards/winners/story-detail?guid={{item.winnerStoriesGuid}}
                            </a>
                        </div>
                        
                        <h1 class="storyGridTitle notClickSupport">{{ item.storyTitle}}</h1>
                        <h5 class="storyGridTitle notClickSupport">{{ item.storyTitle}}</h5>
                        <div class="storyGridUser notClickSupport">by <span class="userName">{{item.firstName}} {{ item.lastName }}</span></div>
                        <div class="storyGridText notClickSupport">{{ item.storyText }}</div>
                        <div class="storyGridLocation notClickSupport">
                            <span class="icon-map-marker icon"></span> <span class="location">{{ item.city }}, {{item.state}}</span>
                        </div>


                        <div class="story-modal-content">
                            <div class="grid-x grid-margin-x modal-content">
                                <div class="cell medium-6">
                                    <img :src="item.photoPathUrl" :alt="item.storyTitle" v-if="!item.videoUrl" />
                                    <iframe :src="item.videoUrl" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen="" v-if="item.videoUrl"></iframe>

                                    <div class="sharing-link">
                                        Share using this link: 
                                        <a :href="`/mylotto-rewards/winners/story-detail?guid=${item.winnerStoriesGuid}`">
                                            {{ currentUrl }}/mylotto-rewards/winners/story-detail?guid={{item.winnerStoriesGuid}}
                                        </a>
                                    </div>

                                </div>
                                <div class="cell medium-6">
                                    <h5 class="story-title">{{ item.storyTitle}}</h5>
                                    <div class="story-username">by <span class="userName">{{item.firstName}} {{ item.lastName }}</span></div>
                                    <div class="story-text">{{ item.storyText }}</div>
                                    <!-- <div>
                                        <span class="icon-map-marker icon"></span> <span class="location">{{ item.city }}, {{item.state}}</span>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
            </template>
        </masonry-wall>

        <div class="load-more-btn text-center" v-if="!hideLoadMore">
            <a @click="loadMore" class="button">{{itemsLeftText}}</a>
        </div>
        
        <template>
            <modal name="story-modal" classes="story-modal" :maxWidth="200" :height="'auto'">
                <button class="close-button" type="button" @click="closeModal()">
                    <span aria-hidden="true">×</span>
                </button>
                <span v-html="modalInnerContent"></span>
            </modal>
        </template>
    </div>
</template>

<script>


    export default {
        components: {
            
        },
        data: function () {
            return {
                items: [],
                modalInnerContent: '',
                maxPerPage: 20,
                hideLoadMore: false,
                itemsLeftText: "Load 20 More",
                currentUrl: window.location.hostname,
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            getToken() {
            // then hit API with token
            this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
            },
            fetchData(apiToken) {
                return fetch(process.env.VUE_APP_APIPROTOCOL + process.env.VUE_APP_APIURL + '/1.0/Games/WinnerStories/GetAllApprovedWinnerStories', {
                    headers: {
                        'Authorization': 'Bearer ' + apiToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    const results = json.data;
                    
                    this.items = results.reverse();
                    console.log("This.items");
                    console.log(this.items);
                })
            },
            loadMore() {

                

                if( this.itemsLeftText == "Load 20 More"){
                    this.itemsLeftText = "20 MORE INSPIRATIONS"
                }else if ( this.itemsLeftText == "20 MORE INSPIRATIONS" ){
                    this.itemsLeftText = "Show Me 20 More"
                }else if( this.itemsLeftText == "Show Me 20 More"){
                    this.itemsLeftText = "Load 20 More"
                }



                let itemsLeft = this.items.length - this.maxPerPage

                if ( itemsLeft < 20){
                    this.itemsLeftText = "Load 10 More"
                }

                if (this.maxPerPage > this.items.length){
                    this.hideLoadMore = true;
                    return;
                } 
                this.maxPerPage = this.maxPerPage + 20;
            },
            openModalItem(event){
                this.$modal.show('story-modal')
                this.modalInnerContent = event.target.lastElementChild.innerHTML
            },
            closeModal(){
                this.$modal.hide('story-modal');
            }
        },
        computed: {
            itemsLoaded() {
                console.log(this.items.length)
                return this.items.slice(0, this.maxPerPage);
            }
        }
    };
</script>

<style lang="scss">
.vm--container{
    overflow: auto;

}
.vm--overlay{
    background-color: rgba(98, 91, 196, 0.95) !important;
    box-shadow: inset 0px 0 200px rgba(20,20,20,.5);
}
.load-more-btn{
    margin-top: 30px;
}
</style>