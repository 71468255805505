<template>
    <div>
        <section v-if="products.length" class="catalogList">
            <span>
                <span v-for="product in products" :key="product.skuNumber" >
                    <div class="productItem cf">
                        <a :href="product.nodeAliasPath" class="productImg">
                            <img :src="product.image" alt="product.description">
                        </a>
                        <article class="productInfo">
                            <a :href="product.nodeAliasPath" class="productTitle">
                                {{product.name}}
                            </a>
                            <span class="productPrice">
                                {{product.price}} Points
                            </span>
                        </article>
                    </div>
                </span>
            </span>
        </section>
        <section v-if="!products.length" class="noProductsAvailable">
            Hi! We're working to bring a new selection of items to the catalog soon.<br>Thank you for your patience.
        </section>
        <span class="note red">* Point costs are subject to change</span>
        <br/>
        <AdditionalRewardsVue></AdditionalRewardsVue>
    </div>
</template>

<script>
import AdditionalRewardsVue from '../AdditionalRewards.vue'
    export default {
        comments: [
            AdditionalRewardsVue
        ],
        data: function() {
            return {
                token: '',
                products: [],
                games: [
                    {
                        name: 1,
                        id: 2
                    }
                ]
            }
        },
        mounted: function() {
            console.log("Winning Catalog")
            this.getToken()
        },
        methods: {
            getToken() {
                console.log('getting token')
                this.getAPItoken()
                .then((token) => {
                    this.getProducts(token);
                });
            },
            getProducts(token) {
                console.log("Getting Products")
                let apiToken = token,
                    urlProtocol = this.getProtocol(),
                    url = process.env.VUE_APP_APIURL+'/1.0/Ecommerce/GetProductList?type=4&top=100';

                return fetch(urlProtocol +  url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + apiToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    this.setStock(json.data);
                })
                .catch(error => {
                    console.log(this.errorMessage = error);
                })
            },
            setStock(json) {
                let data = json,
                    products = [];

                for (let i = 0; i < data.length; i++) {
                    data[i].stock > 0 ? products.push(data[i]) : false;
                }

                this.products = products;
            }
        }
    }
</script>